export class SpecialObjectClass {
  private data: any;

  constructor(data: any[]) {
    this.data = data;
  }

  /**
   * 
   * @returns array of property names and levels
   */
  public getAllPropertyNamesWithLevels(): Array<{name: string; level: number; }> {
    let propertyNames = [];
    this.extractProperties(this.data, propertyNames, 0);
    return propertyNames;
  }

  /**
   * 
   * @param obj 
   * @param propertyNames 
   * @param level 
   * @returns void
   */
  private extractProperties(
    obj: any,
    propertyNames: Array<{ name: string; level: number }>,
    level: number
  ): void {
    Object.keys(obj).forEach((key) => {
      propertyNames.push({ name: key, level });
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        this.extractProperties(obj[key], propertyNames, level + 1);
      }
    });
  }

  /**
   * 
   * @param propertyName 
   * @param level 
   * @returns string[]
   */
  public getValuesForPropertyNameAndLevel(
    propertyName: string,
    level: number
  ): string[] {
    let values = [];
    this.extractValues(this.data, propertyName, level, values);
    return values;
  }

  /**
   * 
   * @param obj 
   * @param propertyName 
   * @param level 
   * @param values 
   * @returns void
   */
  private extractValues(
    obj: any,
    propertyName: string,
    level: number,
    values: string[]
  ): void {
    if (level === 0 && propertyName in obj) {
      values.push(obj[propertyName]);
    } else if (typeof obj === 'object' && obj !== null) {
      Object.values(obj).forEach((childObj) => {
        this.extractValues(childObj, propertyName, level - 1, values);
      });
    }
  }
}

/** EXPLANATION OF CLASS
 This class can get any object. For example:
 object:IProfil[] = [
    {
      name: 'Davut',
      lastName: 'Bilgic',
      age: 25,
      vehicles: [
        {
          name: 'Vehicle Name 1',
          model: 2005,
        },
        {
          name: 'Vehicle Name 2',
          model: 2009,
        },
      ],
    },
    {
      name: 'John',
      lastName: 'Doe',
      age: 30,
      vehicles: [
        {
          name: 'Vehicle Name x',
          model: 2015,
        },
        {
          name: 'Vehicle Name y',
          model: 2019,
        },
      ],
    },
  ];

With this(getAllPropertyNamesWithLevels()) method, you can get all property names with siblings levels
With this(getValuesForPropertyNameAndLevel(  propertyName: string, level: number)) method, you can get all values of given property(with level) name
 */