<ion-list>
    <ion-item>
        {{ getUserEmailShort() }}
    </ion-item>
    <ion-item>
        <ion-button disabled="true" (click)="setTasksModalOpen(true)">Mijn taken</ion-button>
    </ion-item>
    <ion-item>
        <ion-button disabled="false" (click)="openUserStatsModal()">Mijn statistieken</ion-button>
    </ion-item>
    <ion-item>
        <ion-button (click)="logout()">Uitloggen</ion-button>
    </ion-item>
</ion-list>

<!-- Statistieke modal -->
<ion-modal #statsModal [isOpen]="isStatsModalOpen" (ionModalWillDismiss)="setStatsModalOpen(false)">
    <ng-template>
        <ion-header>
            <ion-toolbar>
                <ion-title>Mijn statistieken</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="setStatsModalOpen(false)">Sluiten</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content *ngIf="userID == null"> Statistieken kunnen niet getoond worden omdat er geen gebruiker is ingelogd. </ion-content>
        <ion-content *ngIf="userID != null">
            <pia3-user-performance-chart-transcription-dataminutes [userID]="userID"></pia3-user-performance-chart-transcription-dataminutes>
        </ion-content>
    </ng-template>
</ion-modal>

<!-- Taken modal -->
<!-- <ion-modal [isOpen]="isTasksModalOpen" (ionModalWillDismiss)="setTasksModalOpen(false)">
    <ng-template>
        <ion-header>
            <ion-toolbar>
                <ion-title>Mijn taken</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="setTasksModalOpen(false)">Sluiten</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            takenlijst nog niet geimplementeerd
            <pia3-user-performance-chart-transcription-dataminutes></pia3-user-performance-chart-transcription-dataminutes>
        </ion-content>
    </ng-template>
</ion-modal> -->
