<div *ngIf="clientID == null">
    <ion-text color="warning"> <h1>ClientID niet ingesteld. Neem contact op met support wanneer dit volgens u niet juist werkt.</h1></ion-text>
</div>
<div *ngIf="clientID != null">
    <!-- <form [formGroup]="createContextChannel" (ngSubmit)="submit()"> -->
    <!-- <form (ngSubmit)="submit()"> -->

    <ion-item lines="full">
        <ion-label position="fixed">Klant</ion-label>
        <pia3-client-selector-single (clientSelected)="clientSelected($event)"></pia3-client-selector-single>
    </ion-item>

    <ion-item lines="full">
        <ion-label position="fixed">Naam {{ name }}</ion-label>
        <ion-input placeholder="Voer een naam in" color="primary" type="text" maxlength="30" minlength="2" clearInput="true" [(ngModel)]="name"></ion-input>
    </ion-item>

    <ion-item lines="full">
        <ion-label position="fixed">Omschrijving</ion-label>
        <ion-input placeholder="Voer een omschrijvingen" color="primary" type="text" maxlength="145" minlength="" clearInput="true" [(ngModel)]="description"></ion-input>
    </ion-item>

    <ion-item lines="full">
        <ion-label position="fixed">Talen</ion-label>
        <pia3-language-selector-multi-with-search (languageSelected)="languageSelected($event)"></pia3-language-selector-multi-with-search>
    </ion-item>

    <ion-item lines="full">
        <ion-label position="fixed">Spraakherkenningsmodel</ion-label>
        <pia3-speechmodel-selector-single [clientID]="clientID" (speechmodelSelected)="speechmodelSelected($event)"></pia3-speechmodel-selector-single>
    </ion-item>

    <ion-item lines="full">
        <ion-label position="fixed">Sprekers</ion-label>
        <pia3-speaker-selector-multi-with-search [clientID]="clientID" (speakerSelected)="speakerSelected($event)"></pia3-speaker-selector-multi-with-search>
    </ion-item>

    <ion-item lines="full">
        <pia3-image-cropper (imageSelectedPath)="imageSelected($event)"></pia3-image-cropper>
    </ion-item>

    <!-- ========================================== -->

    <!-- <pia3-language-selector-single (languageSelected)="languageSelected($event)"></pia3-language-selector-single> -->

    <!-- <pia3-language-selector-multi
            (languageSelected)="languageSelected($event)"
          ></pia3-language-selector-multi> -->

    <!-- ========================================== -->

    <!-- <pia3-speaker-selector-single
            [clientID]="clientID"
            (speakerSelected)="speakerSelected($event)"
          ></pia3-speaker-selector-single> -->

    <!-- <pia3-speaker-selector-multi
            [clientID]="clientID"
            (speakerSelected)="speakerSelected($event)"
          ></pia3-speaker-selector-multi> -->

    <!-- =========================================== -->

    <!-- <ion-item lines="full">
        <ion-label>NLP Models: will be implemented</ion-label>
        <ion-select
              slot="end"
              placeholder="Select GNLP model"
              formControlName="GNLPModel"
            >
              <ion-select-option *ngFor="let g of GNLPModels" value="{{ g }}">{{
                g
              }}</ion-select-option>
            </ion-select>
    </ion-item> -->

    <div class="ion-margin">
        <ion-row>
            <ion-col>
                <ion-text color="danger" *ngIf="hasError">Niet alle velden ingevuld. Vullen alle velden in en verzend nogmaals.</ion-text>
                <ion-button class="ion-margin" mode="ios" expand="block" type="submit" (click)="submit()">verzenden</ion-button>
            </ion-col>
        </ion-row>
    </div>
</div>

<!-- </form> -->
