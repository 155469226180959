<!-- SPEAKER SET-->
<ion-avatar
  *ngIf="_speaker != null && _speaker != undefined"
  [title]="getFullname(_speaker)"
>
  <img
    alt="Silhouette of a person's head"
    src="https://i.pravatar.cc/64?u={{ _speaker.id.substring(0, 6) }}"
  />
  <!-- <div>{{ getShortname(_speaker) }}</div> -->
</ion-avatar>

<!-- SPEAKER IS NULL-->
<ion-avatar
  *ngIf="_speaker == undefined || _speaker == null"
  [title]="getUndefinedSpeakerName()"
  [class]="getUndefinedSpeakerName()"
>
  <img
    alt="Silhouette of a person's head"
    src="https://ionicframework.com/docs/img/demos/avatar.svg"
  />
</ion-avatar>
