import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { Auth, CognitoUser } from '@aws-amplify/auth'
import { Router } from '@angular/router'
import { GenericErrorHandlerService } from './../.common/services/generic-error-handler.service'

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _currentLoggedInUser: BehaviorSubject<CognitoUser> = new BehaviorSubject(null)

    public readonly currentLoggedInUser: Observable<CognitoUser> = this._currentLoggedInUser.asObservable()

    constructor(public router: Router, public genericErrorHandlerSerivce: GenericErrorHandlerService) {
        this.getCurrentSignedInUser(true)
    }

    getCurrentSignedInUser(onInit: boolean) {
        if (onInit) {
            //try auto login without error messages
            Auth.currentAuthenticatedUser().then((fulfilled) => {
                this._currentLoggedInUser.next(fulfilled)
            })
        } else {
            //try login with error messages
            Auth.currentAuthenticatedUser()
                .then((fulfilled) => {
                    this._currentLoggedInUser.next(fulfilled)
                }, this.genericErrorHandlerSerivce.handleGenericError)
                .catch(this.genericErrorHandlerSerivce.handleGenericError)
        }
    }

    async signInUser(email: string, password: string) {
        return new Promise((resolve, reject) => {
            try {
                Auth.signIn(email, password)
                    .then(
                        (onfulfilled) => {
                            this._currentLoggedInUser.next(onfulfilled)
                            resolve(onfulfilled)
                        },
                        (onreject) => {
                            ////console.log('onreject: ',onreject)
                            this.genericErrorHandlerSerivce.handleGenericError(onreject)
                            reject(onreject)
                        }
                    )
                    .catch((error) => {
                        ////console.log('error while trying to login user: ',error)
                        this.genericErrorHandlerSerivce.handleGenericError(error)
                        reject(error)
                    })
            } catch (error) {
                ////console.log('error signing in',error)
                return reject(error)
            }
        })
    }

    async signOutUser() {
        try {
            Auth.signOut()
                .then(
                    (onfulfilled) => {
                        this._currentLoggedInUser.next(null)
                        this.router.navigate(['/'])
                    },
                    (onreject) => {
                        ////console.log('onreject: ',onreject)
                    }
                )
                .catch((error) => {
                    ////console.log('error while trying to login user: ',error)
                })
        } catch (error) {
            ////console.log('error signing in',error)
        }
    }
}
