import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject, Subscription } from 'rxjs'

import { Storage } from 'aws-amplify'
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import * as queries from 'src/graphql/queries'
import * as mutations from 'src/graphql/mutations'
import * as subscriptions from 'src/graphql/subscriptions'
import { RouterParamsService } from '../.common/services/router-params.service'

import { CreateContextchannelInput, CreateContextchannelMutation, Contextchannel, GetClientQuery, GetContextchannelQuery, ListContextchannelsQuery, CreateContextchannelSubscriptionMutation } from 'src/app/API.service'
import { GenericErrorHandlerService } from '../.common/services/generic-error-handler.service'
import { Context } from 'vm'
import { timeout } from 'rxjs/operators'
import { Data } from '@angular/router'

@Injectable({
    providedIn: 'root',
})
export class ContextchannelService {
    private _currentContextchannel: BehaviorSubject<Contextchannel> = new BehaviorSubject(null)
    public readonly currentContextchannel: Observable<Contextchannel> = this._currentContextchannel.asObservable()

    public defaultContextchannelImage: string = 'assets/ui/components/pia-video/PIA3_video_poster.png'

    constructor(private routerParamsService: RouterParamsService, private genericErrorHandlerService: GenericErrorHandlerService) {
        this.routerParamsService.params.subscribe(this.triggerGetContextchannelOnContextchannelIdRouteparamChange)
    }

    private triggerGetContextchannelOnContextchannelIdRouteparamChange = (params) => {
        if (params['contextchannelId']) {
            let contextchannelId: string = params['contextchannelId']

            if (this._currentContextchannel.getValue() == null || this._currentContextchannel.getValue().id != contextchannelId) {
                this.getContextchannel(contextchannelId)
                    .then((result) => {
                        let ctx: Contextchannel = result.data.getContextchannel as Contextchannel
                        this._currentContextchannel.next(ctx)
                    }, this.genericErrorHandlerService.handleGenericGraphQLError)
                    .catch(this.genericErrorHandlerService.handleGenericGraphQLError)
            }
        } else {
            this._currentContextchannel.next(null)
        }
    }

    async getContextchannel(id: string): Promise<{ data: GetContextchannelQuery }> {
        const query = await (API.graphql<Contextchannel>(graphqlOperation(queries.getContextchannel, { id: id })) as Promise<{ data: GetContextchannelQuery }>)
        return query
    }

    async getContextchannelTest() {
        setTimeout(() => {
            //console.log('second')
        }, 6000)
    }

    getContextchannelTestSync() {
        //setTimeout(() => {
        //console.log('second but sync')
        //}, 6000);
    }

    async getContextchannelsForCurrentUser() {
        return API.graphql(graphqlOperation(queries.listContextchannels)) as Promise<{
            data: ListContextchannelsQuery
        }>
    }

    async getContextchannelsForCurrentUserWithImages(): Promise<{
        data: ListContextchannelsQuery
    }> {
        const listContextchannelQueryResult = (await API.graphql<Contextchannel[]>({
            query: queries.listContextchannels,
        })) as Promise<{ data: ListContextchannelsQuery }>

        return listContextchannelQueryResult
    }

    async getContextchannelsByClientId(clientId: string) {
        const query = await (API.graphql(graphqlOperation(queries.getClient, { id: clientId })) as Promise<{ data: GetClientQuery }>)
        return query.data.getClient.Contextchannels
    }

    async createContextchannel(input: CreateContextchannelInput) {
        const mutation = await (API.graphql(graphqlOperation(mutations.createContextchannel, { input: input })) as Promise<{ data: CreateContextchannelMutation }>)
        return (await mutation).data.createContextchannel
    }

    async getOnCreateContextchannelsSubscription(next: void, error: void) {
        //const subscription = API.graphql(graphqlOperation(subscriptions.onCreateContextchannel)).subscribe({})
    }

    async getOnUpdateContextchannelsSubscription() {
        // return API.graphql(graphqlOperation(subscriptions.onUpdateContextchannel));
    }

    async getOnDeleteContextchannelsSubscription() {
        // return API.graphql(graphqlOperation(subscriptions.onDeleteContextchannel));
    }

    async subscribeUserToContextchannel(contextchannel: Contextchannel) {
        return API.graphql(graphqlOperation(mutations.createContextchannelSubscription)) as Promise<{
            data: CreateContextchannelSubscriptionMutation
        }>
    }
}
