<!--  -->
<!-- <pia3-create-context-channel></pia3-create-context-channel> -->
<!--  -->
<!-- <pia3-angular-d3-word-cloud
  [wordsWithFreq]="sessionWordsWithFrequency"
  [stopWords]="stopWords"
></pia3-angular-d3-word-cloud> -->
<!--  -->
<!-- <pia3-word-cloud-list
  [wordsWithFreq]="sessionWordsWithFrequency"
  [stopWords]="stopWords"
></pia3-word-cloud-list> -->
<!--  -->
<!-- <pia3-word-tagger
  (taggedWords)="taggedData($event)"
  [categories]="wordTaggerCategories"
  [text]="sessionText"
></pia3-word-tagger> -->

<pia3-textconverter></pia3-textconverter>
<!--  -->
