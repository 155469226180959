<!-- SINGLE LANGUAGE SELECTOR -->
<!-- Enable single selector if selectorMode is SINGLE -->

<ion-select slot="end" placeholder="Select" (ionChange)="languageChanged($event)" *ngIf="languages != undefined && languages.length > 0 && selectorMode == 'SINGLE'" interface="popover">
    <ion-select-option *ngFor="let language of languages" [value]="language">{{ language.name }}</ion-select-option>
</ion-select>

<!-- MULTI LANGUAGES SELECTOR -->
<!-- Enable multi selector if selectorMode is MULTI -->
<div *ngIf="selectorMode == 'MULTI'">
    <ion-buttons slot="end">
        <ion-button color="primary" id="open-modal"
            ><strong>Select</strong>
            <!-- <ion-icon size="small" name="caret-down"></ion-icon> -->
        </ion-button>
    </ion-buttons>

    <ion-modal trigger="open-modal" (willDismiss)="onWillDismiss($event)">
        <ng-template>
            <ion-header>
                <ion-toolbar>
                    <div class="ion-text-center ion-margin">
                        <ion-label><strong>Languages</strong></ion-label>
                    </div>
                </ion-toolbar>
            </ion-header>

            <ion-toolbar>
                <ion-label slot="end" class="ion-margin"><strong>Select All</strong></ion-label>
                <ion-checkbox class="ion-margin" id="checkAll" slot="end" [(ngModel)]="checkParent" [indeterminate]="indeterminateState" (click)="selectAllLanguages()"></ion-checkbox>
            </ion-toolbar>

            <ion-content>
                <ion-list inset="true">
                    <ion-item *ngFor="let l of resultsOfSearch">
                        <ion-label>{{ l.language.name }}</ion-label>
                        <ion-checkbox slot="end" [(ngModel)]="l.isLanguageChecked" (ionChange)="verifyEvent()"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-content>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button id="cancel" color="primary" (click)="cancel()">Cancel</ion-button>
                </ion-buttons>

                <ion-buttons slot="end">
                    <ion-button id="confirm" color="primary" (click)="confirm()">Confirm</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ng-template>
    </ion-modal>
</div>

<!-- show selection multi -->
<ion-list lines="none" *ngIf="showLanguages !== null">
    <ion-item *ngFor="let language of showLanguages; let i = index">
        <ion-icon color="primary" name="language"></ion-icon>
        <ion-label color="primary" class="ion-margin-left">{{ language.name }}</ion-label>
        <ion-icon slot="end" color="danger" name="trash" size="small" (click)="removeSelectionMulti(i)"></ion-icon>
    </ion-item>
</ion-list>

<hr class="solid" *ngIf="showLanguages !== null" />

<!-- MULTI LANGUAGES SELECTOR WITH SEARCH-->
<!-- Enable multi language selector with search if selectorMode is MULTI_WITH_SEARCH -->
<div *ngIf="selectorMode == 'MULTI_WITH_SEARCH'">
    <ion-buttons slot="end">
        <ion-button color="primary" id="open-modal"
            ><strong>Select</strong>
            <!-- <ion-icon size="small" name="caret-down"></ion-icon> -->
        </ion-button>
    </ion-buttons>

    <ion-modal trigger="open-modal" (willDismiss)="onWillDismiss($event)">
        <ng-template>
            <ion-header>
                <ion-toolbar>
                    <div class="ion-text-center ion-margin">
                        <ion-label><strong>Languages</strong></ion-label>
                    </div>

                    <ion-searchbar [debounce]="500" (ionChange)="handleChange($event)"></ion-searchbar>
                </ion-toolbar>
            </ion-header>

            <ion-toolbar>
                <ion-label slot="end" class="ion-margin"><strong>Select All</strong></ion-label>
                <ion-checkbox class="ion-margin" id="checkAll" slot="end" [(ngModel)]="checkParent" [indeterminate]="indeterminateState" (click)="selectAllLanguages()"></ion-checkbox>
            </ion-toolbar>

            <ion-content>
                <ion-list inset="true">
                    <ion-item *ngFor="let l of resultsOfSearch">
                        <ion-label>{{ l.language.name }}</ion-label>
                        <ion-checkbox slot="end" [(ngModel)]="l.isLanguageChecked" (ionChange)="verifyEvent()"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-content>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button id="cancel" color="primary" (click)="cancel()">Cancel</ion-button>
                </ion-buttons>

                <ion-buttons slot="end">
                    <ion-button id="confirm" color="primary" (click)="confirm()">Confirm</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ng-template>
    </ion-modal>
</div>

<!-- show selection multi with search -->
<ion-list lines="none" *ngIf="showLanguagesWithSearch !== null">
    <ion-item *ngFor="let language of showLanguagesWithSearch; let i = index">
        <ion-icon color="primary" name="language"></ion-icon>
        <ion-label color="primary" class="ion-margin-left">{{ language.name }}</ion-label>
        <ion-icon slot="end" color="danger" name="trash" size="small" (click)="removeSelectionMultiWithSearch(i)"></ion-icon>
    </ion-item>
</ion-list>
<hr class="solid" *ngIf="showLanguagesWithSearch !== null" />
