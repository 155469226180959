import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Language } from './../../../API.service'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'

@Component({
    selector: 'pia3-language-selector-multi',
    templateUrl: './pia3-language-selector-multi.component.html',
    styleUrls: ['./pia3-language-selector-multi.component.scss'],
})
export class Pia3LanguageSelectorMultiComponent implements OnInit {
    selectorMode: string = SelectorMode.MULTI
    @Output('languageSelected') languagesSelected = new EventEmitter<Language[]>()

    constructor() {}

    ngOnInit() {}

    _languageSelected($event) {
        this.languagesSelected.emit($event)
    }
}
