import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Client } from '../../../API.service'
import { Pia3ClientService } from '../pia3-client.service'
import { Pia3ContextchannelService } from '../../contextchannel/pia3-contextchannel.service'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'
import { AlertController, IonModal } from '@ionic/angular'
import { OverlayEventDetail } from '@ionic/core'

interface ICheckboxClients {
    isClientChecked: boolean
    client: Client
}

@Component({
    selector: 'pia3-client-selector',
    templateUrl: './pia3-client-selector.component.html',
    styleUrls: ['./pia3-client-selector.component.scss'],
})
export class Pia3ClientSelectorComponent implements OnInit {
    public clients: Client[]

    @Input('selectorMode') selectorMode: string = SelectorMode.MULTI_WITH_SEARCH
    @Output('clientSelected') clientSelected = new EventEmitter<Client[]>()

    //Modal page
    @ViewChild(IonModal) modalClient: IonModal
    message = ''
    name: string

    //Searchbar
    indeterminateState: boolean | undefined
    checkParent: boolean | undefined
    public checkboxClients: ICheckboxClients[] = []
    public resultsOfSearch: ICheckboxClients[] = []

    //show Clients
    showClientsWithSearch: Client[] = null
    showClients: Client[] = null

    constructor(private pia3ClientService: Pia3ClientService, private pia3ContextchannelService: Pia3ContextchannelService, private alertController: AlertController) {}

    async ngOnInit() {
        this.clients = (await this.listClients()).data.listClients.items as Client[]

        //create new Clients array with isClientChecked property for searchbar
        this.clients.forEach((Client) => {
            this.checkboxClients.push({
                isClientChecked: false,
                client: Client,
            })
        })

        //copy entire created checkboxClients to resultsOfSearch to protect first instances
        this.resultsOfSearch = [...this.checkboxClients]
    }

    //get Clients from server
    async listClients() {
        let result = await this.pia3ClientService.listClients(100)
        return result
    }

    //when Client changed emit current Client - this is for single mode
    clientChanged($event) {
        if (this.selectorMode === SelectorMode.SINGLE) {
            let selectedClient: Client[] = $event.detail.value as Client[] //$event is coming as an Event here
            this.clientSelected.emit(selectedClient)
        }

        if (this.selectorMode === SelectorMode.MULTI) {
            let selectedClients: Client[] = $event //$event is an Client[] array here. Coming from confirm()
            this.clientSelected.emit(selectedClients)
            //show selected Clients
            this.showClients = selectedClients
        }

        if (this.selectorMode === SelectorMode.MULTI_WITH_SEARCH) {
            let selectedClients: Client[] = $event //$event is an Client[] array here. Coming from confirm()
            this.clientSelected.emit(selectedClients)
            //show selected Clients
            this.showClientsWithSearch = selectedClients
        }
    }

    //remove selection multi
    removeSelectionMulti(index: number) {
        if (this.showClients.length > 0) {
            this.showClients.splice(index, 1)
        }

        if (this.showClients.length == 0) {
            this.showClients = null
        }
        this.clientChanged(this.showClients)
    }

    //remove selection multi with search
    removeSelectionMultiWithSearch(index: number) {
        if (this.showClientsWithSearch.length > 0) {
            this.showClientsWithSearch.splice(index, 1)
        }

        if (this.showClientsWithSearch.length == 0) {
            this.showClientsWithSearch = null
        }
        this.clientChanged(this.showClientsWithSearch)
    }

    /*Modal page */
    cancel() {
        this.modalClient.dismiss(null, 'cancel')
    }

    confirm() {
        this.modalClient.dismiss(this.name, 'confirm')

        const checkedClients: Client[] = []

        this.resultsOfSearch.forEach((checkboxClients) => {
            checkboxClients.isClientChecked ? checkedClients.push(checkboxClients.client) : ''
        })

        if (checkedClients.length > 0) {
            this.clientChanged(checkedClients)
        }
        if (checkedClients.length == 0) {
            this.presentNoCheckedItemAlert()
            this.showClients = null
            this.showClientsWithSearch = null
            this.clientChanged(null)
        }
    }

    onWillDismiss(event: Event) {
        const ev = event as CustomEvent<OverlayEventDetail<string>>
        if (ev.detail.role === 'confirm') {
            // this.message = `Hello, ${ev.detail.data}!`;
        }
    }

    //searchbar according to fullName
    handleChange($event) {
        const query = $event.detail.value.toLowerCase()
        this.resultsOfSearch = this.checkboxClients.filter((d) => d.client.displayName.toLowerCase().indexOf(query) > -1)
    }

    /*checkbox*/
    verifyEvent() {
        const allItems = this.resultsOfSearch.length
        let selected = 0
        this.resultsOfSearch.map((Client: { isClientChecked: any }) => {
            if (Client.isClientChecked) selected++
        })
        if (selected > 0 && selected < allItems) {
            // One item is selected among all checkbox elements
            this.indeterminateState = true
            this.checkParent = false
        } else if (selected == allItems) {
            // All item selected
            this.checkParent = true
            this.indeterminateState = false
        } else {
            // No item is selected
            this.indeterminateState = false
            this.checkParent = false
        }
    }

    selectAllClients() {
        setTimeout(() => {
            this.resultsOfSearch.forEach((Client: { isClientChecked: boolean | undefined }) => {
                Client.isClientChecked = this.checkParent
            })
        })
    }

    /*Alerts*/
    //alert-no checked item
    async presentNoCheckedItemAlert() {
        const alert = await this.alertController.create({
            header: 'Geen klant geselecteerd',
            // subHeader: 'No results were found in the selected date range!',
            message: 'Vink één of meer klanten aan om door te gaan.',
            buttons: ['OK'],
        })

        await alert.present()
    }
}
