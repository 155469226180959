import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core'
import { Router } from '@angular/router'
import { PopoverController } from '@ionic/angular'
import { AuthService } from '../../auth.service'
import { IonModal } from '@ionic/angular'

@Component({
    selector: 'app-current-user-fab-popover',
    templateUrl: './current-user-fab-popover.component.html',
    styleUrls: ['./current-user-fab-popover.component.scss'],
})
export class CurrentUserFabPopoverComponent implements OnInit {
    @Input('senderEvent') senderEvent
    public currentUser: any = null
    public isStatsModalOpen: boolean = false
    public isTasksModalOpen: boolean = false

    constructor(public authService: AuthService, public router: Router, public popoverController: PopoverController) {
        this.authService.currentLoggedInUser.subscribe((user) => {
            this.currentUser = user
        })
    }

    ngOnInit() {}

    setStatsModalOpen(isOpen: boolean) {
        this.isStatsModalOpen = isOpen
    }

    setTasksModalOpen(isOpen: boolean) {
        this.isTasksModalOpen = isOpen
    }

    logout() {
        this.authService.signOutUser().then(() => {
            this.popoverController.dismiss()
        })
    }
}
