import { NgModule } from '@angular/core'
import { DatePipe } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { Pia3Module } from 'pia3'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'

import { ComponentsModule as AuthComponentsModule } from './auth/components/components.module'

import { ComponentsModule } from './.common/components/components.module'

import { Iso8601timePipe } from './.common/pipes/iso8601time.pipe'
import { HotkeyModule } from 'angular2-hotkeys'

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, IonicModule.forRoot(), Pia3Module, AuthComponentsModule, AppRoutingModule, ComponentsModule, HotkeyModule.forRoot()],
    exports: [ComponentsModule],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, Iso8601timePipe],
    bootstrap: [AppComponent],
})
export class AppModule {}
