import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Client } from '../../../API.service'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'

@Component({
    selector: 'pia3-client-selector-multi',
    templateUrl: './pia3-client-selector-multi.component.html',
    styleUrls: ['./pia3-client-selector-multi.component.scss'],
})
export class Pia3ClientSelectorMultiComponent implements OnInit {
    selectorMode: string = SelectorMode.MULTI
    @Output('clientSelected') clientsSelected = new EventEmitter<Client[]>()

    constructor() {}

    ngOnInit() {}

    _clientSelected($event) {
        this.clientsSelected.emit($event)
    }
}
