import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'

import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import * as subscriptions from '../../graphql/subscriptions'
import { GetClientQuery, ListClientsQuery, Client } from '../../API.service'

import { ErrorhandlingService } from '../_common/errorhandling/errorhandling.service'
import { Pia3ContextchannelService } from '../contextchannel/pia3-contextchannel.service'

@Injectable({
    providedIn: 'root',
})
export class Pia3ClientService {
    constructor(private pia3ContextchannelService: Pia3ContextchannelService) {}

    //#region API Promises

    async get(id: string): Promise<{ data: GetClientQuery }> {
        return API.graphql(graphqlOperation(queries.getClient, { id: id })) as Promise<{ data: GetClientQuery }>
    }

    async listClients(limit: number = 100): Promise<{ data: ListClientsQuery }> {
        return API.graphql(
            graphqlOperation(queries.listClients, {
                limit: limit,
            })
        ) as Promise<{ data: ListClientsQuery }>
    }

    async getContextchannelClientsPromiseAll(contextchannelID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let ctxClientConnectionItems = this.pia3ContextchannelService
                .getContextchannel(contextchannelID)
                .then(
                    (result) => {
                        resolve(result.data.getContextchannel.Client)
                    },
                    (rejected) => {
                        reject(rejected)
                    }
                )
                .catch((error) => {
                    reject(error)
                })
        })
    }

    //#endregion

    //#region Client Helpers

    public getFullname(client: Client) {
        if (client != undefined && client != null) {
            let fullName = client.displayName != null ? client.displayName : 'No name set'
            return fullName
        } else {
            return 'Undefined'
        }
    }

    public getShortName(Client: Client) {
        if (Client != undefined && Client != null) {
            let shortName = Client.displayName != null ? Client.displayName.charAt(0) : '0'
            return shortName
        } else {
            return '00'
        }
    }

    //#endregion

    //TODO: ListClientByContextchannelByLastname (extend api to include an secondary index for ClientsByContextchannel) - not a priority as this only leads to performance issues when there are a lot of Clients for each client and we do not provide functionality to edit/control Clients for the contextchannel at the moment (needed later)
    // async listClientsByContextchannelByLastname(
    //   clientID: string,
    //   limit: number = 999
    // ): Promise<{ data: ClientsByClientByLastNameQuery }> {
    //   return API.graphql(
    //     graphqlOperation(queries.ClientsByClientByLastNameQuery, {
    //       clientID: clientID,
    //       limit: limit,
    //     })
    //   ) as Promise<{ data: ClientsByClientByLastNameQuery }>;
    // }
}
