import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import { IonicModule } from '@ionic/angular'

//Pia Surfer == Pia Audio
import { PiaSurferModule } from './pia-surfer/pia-surfer.module'

//Pia Transcript Module
import { PiaTranscriptsModule } from './pia-transcripts/pia-transcripts.module'

//Pia Video
import { PiaVideoModule } from './pia-video/pia-video.module'

//Pia Speakers
import { PiaSpeakerModule } from './pia-speaker/pia-speaker.module'

//Navigation
import { SectionToolbarComponent } from './section-toolbar/section-toolbar.component'
import { SectionNavigationButtonsComponent } from './section-navigation-buttons/section-navigation-buttons.component'

@NgModule({
    declarations: [SectionToolbarComponent, SectionNavigationButtonsComponent],
    imports: [CommonModule, FormsModule, IonicModule, PiaSurferModule, PiaTranscriptsModule, PiaVideoModule, PiaSpeakerModule],
    exports: [PiaSurferModule, PiaTranscriptsModule, PiaVideoModule, PiaSpeakerModule, SectionToolbarComponent, SectionNavigationButtonsComponent],
})
export class ComponentsModule {}
