import { Injectable } from '@angular/core'
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import { BehaviorSubject, Observable } from 'rxjs'
import { GetLanguageQuery, Language, ListLanguagesQuery, ModelLanguageFilterInput, ModelSortDirection, ModelStringInput } from './../../API.service'
import * as queries from './../../graphql/queries'
import * as mutations from './../../graphql/mutations'
import * as subscriptions from './../../graphql/subscriptions'

@Injectable({
    providedIn: 'root',
})
export class Pia3LanguageService {
    private _currentLanguage: BehaviorSubject<Language> = new BehaviorSubject(null)
    public readonly currentLanguage: Observable<Language> = this._currentLanguage.asObservable()

    constructor() {
        this.currentLanguage.subscribe((lang) => {
            if (lang == null) {
                this.getLanguage('98766ed5-db23-4c97-be79-2b0ddf20c399').then((result) => {
                    this._currentLanguage.next(result.data.getLanguage)
                })
            }
        })
    }

    //#region Local Methods
    async setCurrentLanguage(language: Language) {
        this._currentLanguage.next(language)
    }
    //#endregion

    //#region Remote API Methods
    async getLanguage(id: string): Promise<{ data: GetLanguageQuery }> {
        return API.graphql<Language>(graphqlOperation(queries.getLanguage, { id: id })) as Promise<{ data: GetLanguageQuery }>
    }

    async getLanguages(
        filter: ModelLanguageFilterInput = {
            and: [{ ISO_639_2: { ne: 'tur' } }, { ISO_639_2: { ne: 'ukr' } }],
        },
        sortDirection?: ModelSortDirection,
        limit?: number,
        nexttoken?: string
    ): Promise<{ data: ListLanguagesQuery }> {
        return API.graphql(
            graphqlOperation(queries.listLanguages, {
                filter: filter,
                sortDirection: sortDirection != null ? sortDirection : ModelSortDirection.ASC,
                limit: limit != null ? limit : 100,
                nextToken: nexttoken,
            })
        ) as Promise<{ data: ListLanguagesQuery }>
    }
}
