/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'eu-central-1',
    aws_appsync_graphqlEndpoint: 'https://mv64ykfytjcgvfgdv5cg6nc3iy.appsync-api.eu-central-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-central-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-vr6azzeryzd7xpwobwnqjxonii',
    aws_cognito_identity_pool_id: 'eu-central-1:5458c69f-2a26-4775-883a-9351a8c60166',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_g51GGJJPE',
    aws_user_pools_web_client_id: '1q86vmi8hreg00d6g8qm97vbel',
    oauth: {},
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 12,
        passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_NUMBERS', 'REQUIRES_UPPERCASE'],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_user_files_s3_bucket: 'pia3-dev-storage-d3cjfpdc5fkmrv123442-dev',
    aws_user_files_s3_bucket_region: 'eu-central-1',
}

export default awsmobile
