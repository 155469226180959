import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pia-transcript-custom',
  templateUrl: './pia-transcript-custom.component.html',
  styleUrls: ['./pia-transcript-custom.component.scss'],
})
export class PiaTranscriptCustomComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
