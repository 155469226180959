import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { IonicModule } from '@ionic/angular'

//Npm Pipes
import { OrderModule } from 'ngx-order-pipe'

//Pia Pipes
import { DecimalToTimePipe } from './decimal-to-time.pipe'
import { Iso8601timePipe } from './iso8601time.pipe'

@NgModule({
    declarations: [DecimalToTimePipe, Iso8601timePipe],
    imports: [OrderModule],
    exports: [DecimalToTimePipe, Iso8601timePipe, OrderModule],
})
export class PipesModule {}
