import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Pia3SessionService } from './../../session/pia3-session.service'
import { Pia3Service } from './../../../pia3.service'
import { ExportFormat } from './../../../API.service'
import { IonModal } from '@ionic/angular'
import { OverlayEventDetail } from '@ionic/core'

@Component({
    selector: 'pia3-transcript-export-button',
    templateUrl: './pia3-transcript-export-button.component.html',
    styleUrls: ['./pia3-transcript-export-button.component.scss'],
})
export class Pia3TranscriptExportButtonComponent implements OnInit {
    @ViewChild(IonModal) modal: IonModal
    @Input('sessionID') sessionID: string = null
    @Input('buttonColor') buttonColor: string = 'light'

    public exportFormat = ExportFormat
    public isModalOpen: boolean = false
    public jsonExportIsLoading: boolean = false
    public jsonExportIsLoaded: boolean = false
    public jsonExportIsError: boolean = false
    public jsonExportErrorMessage: string = null
    public jsonExportDownloadUrl: string = null
    public txtExportIsLoading: boolean = false
    public txtExportIsLoaded: boolean = false
    public txtExportIsError: boolean = false
    public txtExportErrorMessage: string = null
    public txtExportDownloadUrl: string = null

    constructor(private pia3Service: Pia3Service, private pia3SessionService: Pia3SessionService) {}

    ngOnInit() {}

    setIsModalOpen(open: boolean) {
        this.isModalOpen = open
        if (this.jsonExportDownloadUrl == null) {
            this.exportTranscriptAsJSON(this.sessionID)
        }
        if (this.txtExportDownloadUrl == null) {
            this.exportTranscriptAsTXT(this.sessionID)
        }
    }

    exportTranscriptAsJSON(sessionID: string) {
        this.jsonExportIsLoading = true
        this.pia3SessionService
            .exportSessionTranscript(this.sessionID, ExportFormat.JSON)
            .then(
                (result) => {
                    this.pia3Service
                        .storageGetS3Url(result.data.exportSession)
                        .then(
                            (url) => {
                                this.jsonExportDownloadUrl = url
                                this.jsonExportIsLoaded = true
                            },
                            (error) => {
                                this.jsonExportIsError = true
                                this.jsonExportErrorMessage = error.message
                                this.jsonExportIsLoaded = true
                            }
                        )
                        .catch((error) => {
                            this.jsonExportIsError = true
                            this.jsonExportErrorMessage = error.message
                            this.jsonExportIsLoaded = true
                        })
                        .finally(() => {
                            this.jsonExportIsLoading = false
                        })
                },
                (error) => {
                    this.jsonExportIsError = true
                    this.jsonExportErrorMessage = error.message
                    this.jsonExportIsLoaded = true
                    this.jsonExportIsLoading = false
                }
            )
            .catch((error) => {
                this.jsonExportIsError = true
                this.jsonExportErrorMessage = error.message
                this.jsonExportIsLoaded = true
                this.jsonExportIsLoading = false
            })
    }

    exportTranscriptAsTXT(sessionID: string) {
        this.txtExportIsLoading = true
        this.pia3SessionService
            .exportSessionTranscript(this.sessionID, ExportFormat.PLAINTEXT)
            .then(
                (result) => {
                    this.pia3Service
                        .storageGetS3Url(result.data.exportSession)
                        .then(
                            (url) => {
                                this.txtExportDownloadUrl = url
                                this.txtExportIsLoaded = true
                            },
                            (error) => {
                                this.txtExportIsError = true
                                this.txtExportErrorMessage = error.message
                                this.txtExportIsLoaded = true
                            }
                        )
                        .catch((error) => {
                            this.txtExportIsError = true
                            this.txtExportErrorMessage = error.message
                            this.txtExportIsLoaded = true
                        })
                        .finally(() => {
                            this.txtExportIsLoading = false
                        })
                },
                (error) => {
                    this.txtExportIsError = true
                    this.txtExportErrorMessage = error.message
                    this.txtExportIsLoaded = true
                    this.txtExportIsLoading = false
                }
            )
            .catch((error) => {
                this.txtExportIsError = true
                this.txtExportErrorMessage = error.message
                this.txtExportIsLoaded = true
                this.txtExportIsLoading = false
            })
    }

    message = 'This modal example uses triggers to automatically open a modal when the button is clicked.'
    name: string

    cancel() {
        this.setIsModalOpen(false)
    }

    // onWillDismiss(event: Event) {
    //   const ev = event as CustomEvent<OverlayEventDetail<string>>;
    //   if (ev.detail.role === 'confirm') {
    //     this.message = `Hello, ${ev.detail.data}!`;
    //   }
    // }
}
