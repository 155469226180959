/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateEnvironmentStatus = /* GraphQL */ `
  subscription OnCreateEnvironmentStatus {
    onCreateEnvironmentStatus {
      id
      underMaintanence
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEnvironmentStatus = /* GraphQL */ `
  subscription OnUpdateEnvironmentStatus {
    onUpdateEnvironmentStatus {
      id
      underMaintanence
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEnvironmentStatus = /* GraphQL */ `
  subscription OnDeleteEnvironmentStatus {
    onDeleteEnvironmentStatus {
      id
      underMaintanence
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient {
    onCreateClient {
      id
      displayName
      cognitoGroupName
      Contextchannels {
        items {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Speakers {
        items {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient {
    onUpdateClient {
      id
      displayName
      cognitoGroupName
      Contextchannels {
        items {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Speakers {
        items {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient {
    onDeleteClient {
      id
      displayName
      cognitoGroupName
      Contextchannels {
        items {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Speakers {
        items {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateWordTaggerModuleOutput = /* GraphQL */ `
  subscription OnCreateWordTaggerModuleOutput {
    onCreateWordTaggerModuleOutput {
      id
      createdAt
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      taggedWords {
        text
        tag
      }
      categories
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateWordTaggerModuleOutput = /* GraphQL */ `
  subscription OnUpdateWordTaggerModuleOutput {
    onUpdateWordTaggerModuleOutput {
      id
      createdAt
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      taggedWords {
        text
        tag
      }
      categories
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteWordTaggerModuleOutput = /* GraphQL */ `
  subscription OnDeleteWordTaggerModuleOutput {
    onDeleteWordTaggerModuleOutput {
      id
      createdAt
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      taggedWords {
        text
        tag
      }
      categories
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContextchannel = /* GraphQL */ `
  subscription OnCreateContextchannel {
    onCreateContextchannel {
      id
      allowedCognitoGroups
      name
      s3ImageUrl
      reference_id
      contactEmailAddresses
      Subscriptions {
        items {
          id
          createdAt
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      allowedUsers {
        items {
          id
          createdAt
          isActive
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      dynamicConfig
      wordTaggerModuleSettings {
        enabled
        categories
      }
      gnlpConfig {
        prompt
        maxTokens
        temperature
        key
        frequencyPenalty
        presencePenalty
        languageISO
      }
      gnlpTasks
      speechmodelID
      SpeechModel {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      wordcloudSettings {
        whitelist
        blacklist
      }
      precomputedWordCountsS3Key
      ClusteringModuleOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          key
          leafIDs
          linkageMatrixS3Url
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      posmodelID
      POSModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nermodelID
      NERModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      summarymodelID
      SummaryModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sentimentmodelID
      SentimentModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categorymodelID
      CategoryModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Sessions {
        items {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Speakers {
        items {
          id
          createdAt
          contextchannelID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Languages {
        items {
          id
          createdAt
          enabled
          contextchannelID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      ContextchannelLogs {
        items {
          id
          allowedCognitoGroups
          createdAt
          clientID
          contextchannelID
          type
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContextchannel = /* GraphQL */ `
  subscription OnUpdateContextchannel {
    onUpdateContextchannel {
      id
      allowedCognitoGroups
      name
      s3ImageUrl
      reference_id
      contactEmailAddresses
      Subscriptions {
        items {
          id
          createdAt
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      allowedUsers {
        items {
          id
          createdAt
          isActive
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      dynamicConfig
      wordTaggerModuleSettings {
        enabled
        categories
      }
      gnlpConfig {
        prompt
        maxTokens
        temperature
        key
        frequencyPenalty
        presencePenalty
        languageISO
      }
      gnlpTasks
      speechmodelID
      SpeechModel {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      wordcloudSettings {
        whitelist
        blacklist
      }
      precomputedWordCountsS3Key
      ClusteringModuleOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          key
          leafIDs
          linkageMatrixS3Url
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      posmodelID
      POSModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nermodelID
      NERModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      summarymodelID
      SummaryModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sentimentmodelID
      SentimentModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categorymodelID
      CategoryModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Sessions {
        items {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Speakers {
        items {
          id
          createdAt
          contextchannelID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Languages {
        items {
          id
          createdAt
          enabled
          contextchannelID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      ContextchannelLogs {
        items {
          id
          allowedCognitoGroups
          createdAt
          clientID
          contextchannelID
          type
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContextchannel = /* GraphQL */ `
  subscription OnDeleteContextchannel {
    onDeleteContextchannel {
      id
      allowedCognitoGroups
      name
      s3ImageUrl
      reference_id
      contactEmailAddresses
      Subscriptions {
        items {
          id
          createdAt
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      allowedUsers {
        items {
          id
          createdAt
          isActive
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      dynamicConfig
      wordTaggerModuleSettings {
        enabled
        categories
      }
      gnlpConfig {
        prompt
        maxTokens
        temperature
        key
        frequencyPenalty
        presencePenalty
        languageISO
      }
      gnlpTasks
      speechmodelID
      SpeechModel {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      wordcloudSettings {
        whitelist
        blacklist
      }
      precomputedWordCountsS3Key
      ClusteringModuleOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          key
          leafIDs
          linkageMatrixS3Url
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      posmodelID
      POSModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nermodelID
      NERModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      summarymodelID
      SummaryModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sentimentmodelID
      SentimentModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categorymodelID
      CategoryModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Sessions {
        items {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Speakers {
        items {
          id
          createdAt
          contextchannelID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Languages {
        items {
          id
          createdAt
          enabled
          contextchannelID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      ContextchannelLogs {
        items {
          id
          allowedCognitoGroups
          createdAt
          clientID
          contextchannelID
          type
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContextchannelLanguages = /* GraphQL */ `
  subscription OnCreateContextchannelLanguages {
    onCreateContextchannelLanguages {
      id
      createdAt
      enabled
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dialects {
        items {
          id
          createdAt
          enabled
          contextchannellanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContextchannelLanguages = /* GraphQL */ `
  subscription OnUpdateContextchannelLanguages {
    onUpdateContextchannelLanguages {
      id
      createdAt
      enabled
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dialects {
        items {
          id
          createdAt
          enabled
          contextchannellanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContextchannelLanguages = /* GraphQL */ `
  subscription OnDeleteContextchannelLanguages {
    onDeleteContextchannelLanguages {
      id
      createdAt
      enabled
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dialects {
        items {
          id
          createdAt
          enabled
          contextchannellanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContextchannelLanguageLanguageDialects = /* GraphQL */ `
  subscription OnCreateContextchannelLanguageLanguageDialects {
    onCreateContextchannelLanguageLanguageDialects {
      id
      createdAt
      enabled
      contextchannellanguageID
      contextchannellanguage {
        id
        createdAt
        enabled
        contextchannelID
        contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dialects {
          nextToken
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      languagedialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContextchannelLanguageLanguageDialects = /* GraphQL */ `
  subscription OnUpdateContextchannelLanguageLanguageDialects {
    onUpdateContextchannelLanguageLanguageDialects {
      id
      createdAt
      enabled
      contextchannellanguageID
      contextchannellanguage {
        id
        createdAt
        enabled
        contextchannelID
        contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dialects {
          nextToken
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      languagedialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContextchannelLanguageLanguageDialects = /* GraphQL */ `
  subscription OnDeleteContextchannelLanguageLanguageDialects {
    onDeleteContextchannelLanguageLanguageDialects {
      id
      createdAt
      enabled
      contextchannellanguageID
      contextchannellanguage {
        id
        createdAt
        enabled
        contextchannelID
        contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dialects {
          nextToken
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      languagedialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContextchannelSpeakers = /* GraphQL */ `
  subscription OnCreateContextchannelSpeakers {
    onCreateContextchannelSpeakers {
      id
      createdAt
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContextchannelSpeakers = /* GraphQL */ `
  subscription OnUpdateContextchannelSpeakers {
    onUpdateContextchannelSpeakers {
      id
      createdAt
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContextchannelSpeakers = /* GraphQL */ `
  subscription OnDeleteContextchannelSpeakers {
    onDeleteContextchannelSpeakers {
      id
      createdAt
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContextchannelLog = /* GraphQL */ `
  subscription OnCreateContextchannelLog {
    onCreateContextchannelLog {
      id
      allowedCognitoGroups
      createdAt
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      ApiCallLogEntry {
        ipAddress
        userName
        method
        route
        httpVersion
        statusCode
        userAgent
      }
      SessionStatusUpdateLogEntry {
        sessionID
        sessionName
        attributeName
        oldValue
        newValue
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContextchannelLog = /* GraphQL */ `
  subscription OnUpdateContextchannelLog {
    onUpdateContextchannelLog {
      id
      allowedCognitoGroups
      createdAt
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      ApiCallLogEntry {
        ipAddress
        userName
        method
        route
        httpVersion
        statusCode
        userAgent
      }
      SessionStatusUpdateLogEntry {
        sessionID
        sessionName
        attributeName
        oldValue
        newValue
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContextchannelLog = /* GraphQL */ `
  subscription OnDeleteContextchannelLog {
    onDeleteContextchannelLog {
      id
      allowedCognitoGroups
      createdAt
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      ApiCallLogEntry {
        ipAddress
        userName
        method
        route
        httpVersion
        statusCode
        userAgent
      }
      SessionStatusUpdateLogEntry {
        sessionID
        sessionName
        attributeName
        oldValue
        newValue
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSession = /* GraphQL */ `
  subscription OnCreateSession {
    onCreateSession {
      id
      createdAt
      name
      clientID
      allowedCognitoGroups
      wordcloudSettings {
        whitelist
        blacklist
      }
      wordTaggerModuleOutputs {
        items {
          id
          createdAt
          clientID
          sessionID
          categories
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      gnlpOutputs
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      title
      summary
      keywords
      rewrittenS3Key
      condensed
      category
      sourceFileS3Key
      s3ImageUrl
      metadataID
      Metadata {
        id
        allowedCognitoGroups
        metadataS3Key
        durationSeconds
        metadataStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      audioID
      Audio {
        id
        allowedCognitoGroups
        uncompressedAudioS3Key
        compressedAudioS3Key
        waveformDataS3Key
        audioStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      videoID
      Video {
        id
        allowedCognitoGroups
        compressedVideoS3Key
        videoStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      autotranscriptID
      AutoTranscript {
        id
        allowedCognitoGroups
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        transcriptSourceS3Key
        transcriptStandardizedS3Key
        transcriptTranslationS3Keys
        transcriptionStatus
        exportPlainTextS3Key
        exportJsonS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        diarizationS3Key
        summaryS3Key
        categoriesJson
        autotranscriptstatsID
        AutoTranscriptStats {
          id
          allowedCognitoGroups
          unknowns
          wordCount
          unknownPercentage
          globalConfidence
          knownConfidence
          autotranscriptID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      goldtranscriptID
      GoldTranscript {
        id
        allowedCognitoGroups
        validationProgressStatus
        LanguageLabels {
          nextToken
          startedAt
        }
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      customtranscriptID
      CustomTranscript {
        id
        allowedCognitoGroups
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      metadataStatus
      audioStatus
      videoStatus
      transcriptionStatus
      diarizationStatus
      subtitleStatus
      gnlpStatus
      nlpStatus
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultSpeaker
          sessionID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Languages {
        items {
          id
          createdAt
          isDefaultLanguage
          sessionID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      contactEmailAddresses
      Subscriptions {
        items {
          id
          createdAt
          userID
          sessionID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      validationProgressStatus
      extra
      tags {
        key
        value
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSession = /* GraphQL */ `
  subscription OnUpdateSession {
    onUpdateSession {
      id
      createdAt
      name
      clientID
      allowedCognitoGroups
      wordcloudSettings {
        whitelist
        blacklist
      }
      wordTaggerModuleOutputs {
        items {
          id
          createdAt
          clientID
          sessionID
          categories
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      gnlpOutputs
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      title
      summary
      keywords
      rewrittenS3Key
      condensed
      category
      sourceFileS3Key
      s3ImageUrl
      metadataID
      Metadata {
        id
        allowedCognitoGroups
        metadataS3Key
        durationSeconds
        metadataStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      audioID
      Audio {
        id
        allowedCognitoGroups
        uncompressedAudioS3Key
        compressedAudioS3Key
        waveformDataS3Key
        audioStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      videoID
      Video {
        id
        allowedCognitoGroups
        compressedVideoS3Key
        videoStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      autotranscriptID
      AutoTranscript {
        id
        allowedCognitoGroups
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        transcriptSourceS3Key
        transcriptStandardizedS3Key
        transcriptTranslationS3Keys
        transcriptionStatus
        exportPlainTextS3Key
        exportJsonS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        diarizationS3Key
        summaryS3Key
        categoriesJson
        autotranscriptstatsID
        AutoTranscriptStats {
          id
          allowedCognitoGroups
          unknowns
          wordCount
          unknownPercentage
          globalConfidence
          knownConfidence
          autotranscriptID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      goldtranscriptID
      GoldTranscript {
        id
        allowedCognitoGroups
        validationProgressStatus
        LanguageLabels {
          nextToken
          startedAt
        }
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      customtranscriptID
      CustomTranscript {
        id
        allowedCognitoGroups
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      metadataStatus
      audioStatus
      videoStatus
      transcriptionStatus
      diarizationStatus
      subtitleStatus
      gnlpStatus
      nlpStatus
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultSpeaker
          sessionID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Languages {
        items {
          id
          createdAt
          isDefaultLanguage
          sessionID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      contactEmailAddresses
      Subscriptions {
        items {
          id
          createdAt
          userID
          sessionID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      validationProgressStatus
      extra
      tags {
        key
        value
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSession = /* GraphQL */ `
  subscription OnDeleteSession {
    onDeleteSession {
      id
      createdAt
      name
      clientID
      allowedCognitoGroups
      wordcloudSettings {
        whitelist
        blacklist
      }
      wordTaggerModuleOutputs {
        items {
          id
          createdAt
          clientID
          sessionID
          categories
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      gnlpOutputs
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      title
      summary
      keywords
      rewrittenS3Key
      condensed
      category
      sourceFileS3Key
      s3ImageUrl
      metadataID
      Metadata {
        id
        allowedCognitoGroups
        metadataS3Key
        durationSeconds
        metadataStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      audioID
      Audio {
        id
        allowedCognitoGroups
        uncompressedAudioS3Key
        compressedAudioS3Key
        waveformDataS3Key
        audioStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      videoID
      Video {
        id
        allowedCognitoGroups
        compressedVideoS3Key
        videoStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      autotranscriptID
      AutoTranscript {
        id
        allowedCognitoGroups
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        transcriptSourceS3Key
        transcriptStandardizedS3Key
        transcriptTranslationS3Keys
        transcriptionStatus
        exportPlainTextS3Key
        exportJsonS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        diarizationS3Key
        summaryS3Key
        categoriesJson
        autotranscriptstatsID
        AutoTranscriptStats {
          id
          allowedCognitoGroups
          unknowns
          wordCount
          unknownPercentage
          globalConfidence
          knownConfidence
          autotranscriptID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      goldtranscriptID
      GoldTranscript {
        id
        allowedCognitoGroups
        validationProgressStatus
        LanguageLabels {
          nextToken
          startedAt
        }
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      customtranscriptID
      CustomTranscript {
        id
        allowedCognitoGroups
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      metadataStatus
      audioStatus
      videoStatus
      transcriptionStatus
      diarizationStatus
      subtitleStatus
      gnlpStatus
      nlpStatus
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultSpeaker
          sessionID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Languages {
        items {
          id
          createdAt
          isDefaultLanguage
          sessionID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      contactEmailAddresses
      Subscriptions {
        items {
          id
          createdAt
          userID
          sessionID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      validationProgressStatus
      extra
      tags {
        key
        value
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNLPModelVersion = /* GraphQL */ `
  subscription OnCreateNLPModelVersion {
    onCreateNLPModelVersion {
      id
      createdAt
      allowedCognitoGroups
      name
      HFModelType
      parentmodelID
      ParentModel {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      childModels {
        items {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      modelVersionType
      checkpointKey
      checkpointStorageType
      inputlanguageID
      inputLanguage {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      outputlanguageID
      outputLanguage {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inputParameters {
        includeSpeakerInInput
        parseSpeakerFromOutput
        batchSize
        maxInputChunkLength
        inputChunkOverlapTokens
        outputChunkOverlapTokens
        outputChunkOverlapDiscardLastTokens
        truncateInput
        maxRecursionDepth
        maxRecursiveOutputLength
        allowConcatenatedOutput
        badGenerationResampleTries
        inputPrefix
        inputSuffix
        outputStartWith
        outputSeperators
        returnNMostFrequentOutputs
      }
      inferenceParameters {
        useCache
        maxNewTokens
        minNewTokens
        earlyStopping
        doSample
        numBeams
        penaltyAlpha
        temperature
        topK
        topP
        diversityPenalty
        lengthPenalty
        exponentialDecayLengthPenaltyStartIndex
        exponentialDecayLengthPenaltyDecayFactor
        repetitionPenalty
        encoderRepetitionPenalty
        noRepeatNgramSize
        encoderNoRepeatNgramSize
        forceWords
        badWords
        suppressTokens
      }
      modelParameters {
        hasQuantizedWeights
        skipQuantizeLayers
        gptqWbits
        gptqGroupsize
        loadIn8bit
        loadIn8bitThreshold
        loadIn8bitSkipModules
        torchDtype
        fromTf
        fromFlax
        ignoreMismatchedSizes
      }
      openAIParameters {
        endpoint
        model
        temperature
        maxTokens
        topP
        frequencyPenalty
        presencePenalty
        stop
      }
      outputType
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNLPModelVersion = /* GraphQL */ `
  subscription OnUpdateNLPModelVersion {
    onUpdateNLPModelVersion {
      id
      createdAt
      allowedCognitoGroups
      name
      HFModelType
      parentmodelID
      ParentModel {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      childModels {
        items {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      modelVersionType
      checkpointKey
      checkpointStorageType
      inputlanguageID
      inputLanguage {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      outputlanguageID
      outputLanguage {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inputParameters {
        includeSpeakerInInput
        parseSpeakerFromOutput
        batchSize
        maxInputChunkLength
        inputChunkOverlapTokens
        outputChunkOverlapTokens
        outputChunkOverlapDiscardLastTokens
        truncateInput
        maxRecursionDepth
        maxRecursiveOutputLength
        allowConcatenatedOutput
        badGenerationResampleTries
        inputPrefix
        inputSuffix
        outputStartWith
        outputSeperators
        returnNMostFrequentOutputs
      }
      inferenceParameters {
        useCache
        maxNewTokens
        minNewTokens
        earlyStopping
        doSample
        numBeams
        penaltyAlpha
        temperature
        topK
        topP
        diversityPenalty
        lengthPenalty
        exponentialDecayLengthPenaltyStartIndex
        exponentialDecayLengthPenaltyDecayFactor
        repetitionPenalty
        encoderRepetitionPenalty
        noRepeatNgramSize
        encoderNoRepeatNgramSize
        forceWords
        badWords
        suppressTokens
      }
      modelParameters {
        hasQuantizedWeights
        skipQuantizeLayers
        gptqWbits
        gptqGroupsize
        loadIn8bit
        loadIn8bitThreshold
        loadIn8bitSkipModules
        torchDtype
        fromTf
        fromFlax
        ignoreMismatchedSizes
      }
      openAIParameters {
        endpoint
        model
        temperature
        maxTokens
        topP
        frequencyPenalty
        presencePenalty
        stop
      }
      outputType
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNLPModelVersion = /* GraphQL */ `
  subscription OnDeleteNLPModelVersion {
    onDeleteNLPModelVersion {
      id
      createdAt
      allowedCognitoGroups
      name
      HFModelType
      parentmodelID
      ParentModel {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      childModels {
        items {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      modelVersionType
      checkpointKey
      checkpointStorageType
      inputlanguageID
      inputLanguage {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      outputlanguageID
      outputLanguage {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inputParameters {
        includeSpeakerInInput
        parseSpeakerFromOutput
        batchSize
        maxInputChunkLength
        inputChunkOverlapTokens
        outputChunkOverlapTokens
        outputChunkOverlapDiscardLastTokens
        truncateInput
        maxRecursionDepth
        maxRecursiveOutputLength
        allowConcatenatedOutput
        badGenerationResampleTries
        inputPrefix
        inputSuffix
        outputStartWith
        outputSeperators
        returnNMostFrequentOutputs
      }
      inferenceParameters {
        useCache
        maxNewTokens
        minNewTokens
        earlyStopping
        doSample
        numBeams
        penaltyAlpha
        temperature
        topK
        topP
        diversityPenalty
        lengthPenalty
        exponentialDecayLengthPenaltyStartIndex
        exponentialDecayLengthPenaltyDecayFactor
        repetitionPenalty
        encoderRepetitionPenalty
        noRepeatNgramSize
        encoderNoRepeatNgramSize
        forceWords
        badWords
        suppressTokens
      }
      modelParameters {
        hasQuantizedWeights
        skipQuantizeLayers
        gptqWbits
        gptqGroupsize
        loadIn8bit
        loadIn8bitThreshold
        loadIn8bitSkipModules
        torchDtype
        fromTf
        fromFlax
        ignoreMismatchedSizes
      }
      openAIParameters {
        endpoint
        model
        temperature
        maxTokens
        topP
        frequencyPenalty
        presencePenalty
        stop
      }
      outputType
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNLPStep = /* GraphQL */ `
  subscription OnCreateNLPStep {
    onCreateNLPStep {
      id
      createdAt
      allowedCognitoGroups
      enabled
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpmodelversionID
      NLPModelVersion {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      outputKey
      outputLanguages {
        items {
          id
          createdAt
          formality
          nlpstepID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      inputstepID
      InputStep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      OutputSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNLPStep = /* GraphQL */ `
  subscription OnUpdateNLPStep {
    onUpdateNLPStep {
      id
      createdAt
      allowedCognitoGroups
      enabled
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpmodelversionID
      NLPModelVersion {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      outputKey
      outputLanguages {
        items {
          id
          createdAt
          formality
          nlpstepID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      inputstepID
      InputStep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      OutputSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNLPStep = /* GraphQL */ `
  subscription OnDeleteNLPStep {
    onDeleteNLPStep {
      id
      createdAt
      allowedCognitoGroups
      enabled
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpmodelversionID
      NLPModelVersion {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      outputKey
      outputLanguages {
        items {
          id
          createdAt
          formality
          nlpstepID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      inputstepID
      InputStep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      OutputSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNLPStepLanguages = /* GraphQL */ `
  subscription OnCreateNLPStepLanguages {
    onCreateNLPStepLanguages {
      id
      createdAt
      formality
      nlpstepID
      nlpstep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNLPStepLanguages = /* GraphQL */ `
  subscription OnUpdateNLPStepLanguages {
    onUpdateNLPStepLanguages {
      id
      createdAt
      formality
      nlpstepID
      nlpstep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNLPStepLanguages = /* GraphQL */ `
  subscription OnDeleteNLPStepLanguages {
    onDeleteNLPStepLanguages {
      id
      createdAt
      formality
      nlpstepID
      nlpstep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNLPOutput = /* GraphQL */ `
  subscription OnCreateNLPOutput {
    onCreateNLPOutput {
      id
      createdAt
      allowedCognitoGroups
      clientID
      contextchannelID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpstepID
      NLPStep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpmodelversionID
      NLPModelVersion {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inputnlpoutputID
      inputNLPOutput {
        id
        createdAt
        allowedCognitoGroups
        clientID
        contextchannelID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpstepID
        NLPStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputnlpoutputID
        inputNLPOutput {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputNLPOutputs {
          nextToken
          startedAt
        }
        key
        outputType
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        value
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      outputNLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      key
      outputType
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      value
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNLPOutput = /* GraphQL */ `
  subscription OnUpdateNLPOutput {
    onUpdateNLPOutput {
      id
      createdAt
      allowedCognitoGroups
      clientID
      contextchannelID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpstepID
      NLPStep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpmodelversionID
      NLPModelVersion {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inputnlpoutputID
      inputNLPOutput {
        id
        createdAt
        allowedCognitoGroups
        clientID
        contextchannelID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpstepID
        NLPStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputnlpoutputID
        inputNLPOutput {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputNLPOutputs {
          nextToken
          startedAt
        }
        key
        outputType
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        value
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      outputNLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      key
      outputType
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      value
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNLPOutput = /* GraphQL */ `
  subscription OnDeleteNLPOutput {
    onDeleteNLPOutput {
      id
      createdAt
      allowedCognitoGroups
      clientID
      contextchannelID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpstepID
      NLPStep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpmodelversionID
      NLPModelVersion {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inputnlpoutputID
      inputNLPOutput {
        id
        createdAt
        allowedCognitoGroups
        clientID
        contextchannelID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpstepID
        NLPStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputnlpoutputID
        inputNLPOutput {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputNLPOutputs {
          nextToken
          startedAt
        }
        key
        outputType
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        value
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      outputNLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      key
      outputType
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      value
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateClusteringModuleOutput = /* GraphQL */ `
  subscription OnCreateClusteringModuleOutput {
    onCreateClusteringModuleOutput {
      id
      createdAt
      allowedCognitoGroups
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      key
      leafIDs
      linkageMatrixS3Url
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateClusteringModuleOutput = /* GraphQL */ `
  subscription OnUpdateClusteringModuleOutput {
    onUpdateClusteringModuleOutput {
      id
      createdAt
      allowedCognitoGroups
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      key
      leafIDs
      linkageMatrixS3Url
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteClusteringModuleOutput = /* GraphQL */ `
  subscription OnDeleteClusteringModuleOutput {
    onDeleteClusteringModuleOutput {
      id
      createdAt
      allowedCognitoGroups
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      key
      leafIDs
      linkageMatrixS3Url
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSessionSpeakers = /* GraphQL */ `
  subscription OnCreateSessionSpeakers {
    onCreateSessionSpeakers {
      id
      createdAt
      isDefaultSpeaker
      sessionID
      session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSessionSpeakers = /* GraphQL */ `
  subscription OnUpdateSessionSpeakers {
    onUpdateSessionSpeakers {
      id
      createdAt
      isDefaultSpeaker
      sessionID
      session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSessionSpeakers = /* GraphQL */ `
  subscription OnDeleteSessionSpeakers {
    onDeleteSessionSpeakers {
      id
      createdAt
      isDefaultSpeaker
      sessionID
      session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSessionLanguages = /* GraphQL */ `
  subscription OnCreateSessionLanguages {
    onCreateSessionLanguages {
      id
      createdAt
      isDefaultLanguage
      sessionID
      session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dialects {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          sessionlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSessionLanguages = /* GraphQL */ `
  subscription OnUpdateSessionLanguages {
    onUpdateSessionLanguages {
      id
      createdAt
      isDefaultLanguage
      sessionID
      session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dialects {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          sessionlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSessionLanguages = /* GraphQL */ `
  subscription OnDeleteSessionLanguages {
    onDeleteSessionLanguages {
      id
      createdAt
      isDefaultLanguage
      sessionID
      session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dialects {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          sessionlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSessionLanguageLanguageDialects = /* GraphQL */ `
  subscription OnCreateSessionLanguageLanguageDialects {
    onCreateSessionLanguageLanguageDialects {
      id
      createdAt
      isDefaultLanguageDialect
      sessionlanguageID
      sessionlanguage {
        id
        createdAt
        isDefaultLanguage
        sessionID
        session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dialects {
          nextToken
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      languagedialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSessionLanguageLanguageDialects = /* GraphQL */ `
  subscription OnUpdateSessionLanguageLanguageDialects {
    onUpdateSessionLanguageLanguageDialects {
      id
      createdAt
      isDefaultLanguageDialect
      sessionlanguageID
      sessionlanguage {
        id
        createdAt
        isDefaultLanguage
        sessionID
        session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dialects {
          nextToken
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      languagedialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSessionLanguageLanguageDialects = /* GraphQL */ `
  subscription OnDeleteSessionLanguageLanguageDialects {
    onDeleteSessionLanguageLanguageDialects {
      id
      createdAt
      isDefaultLanguageDialect
      sessionlanguageID
      sessionlanguage {
        id
        createdAt
        isDefaultLanguage
        sessionID
        session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dialects {
          nextToken
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      languagedialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMetadata = /* GraphQL */ `
  subscription OnCreateMetadata {
    onCreateMetadata {
      id
      allowedCognitoGroups
      metadataS3Key
      durationSeconds
      metadataStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMetadata = /* GraphQL */ `
  subscription OnUpdateMetadata {
    onUpdateMetadata {
      id
      allowedCognitoGroups
      metadataS3Key
      durationSeconds
      metadataStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMetadata = /* GraphQL */ `
  subscription OnDeleteMetadata {
    onDeleteMetadata {
      id
      allowedCognitoGroups
      metadataS3Key
      durationSeconds
      metadataStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAudio = /* GraphQL */ `
  subscription OnCreateAudio {
    onCreateAudio {
      id
      allowedCognitoGroups
      uncompressedAudioS3Key
      compressedAudioS3Key
      waveformDataS3Key
      audioStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAudio = /* GraphQL */ `
  subscription OnUpdateAudio {
    onUpdateAudio {
      id
      allowedCognitoGroups
      uncompressedAudioS3Key
      compressedAudioS3Key
      waveformDataS3Key
      audioStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAudio = /* GraphQL */ `
  subscription OnDeleteAudio {
    onDeleteAudio {
      id
      allowedCognitoGroups
      uncompressedAudioS3Key
      compressedAudioS3Key
      waveformDataS3Key
      audioStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateVideo = /* GraphQL */ `
  subscription OnCreateVideo {
    onCreateVideo {
      id
      allowedCognitoGroups
      compressedVideoS3Key
      videoStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVideo = /* GraphQL */ `
  subscription OnUpdateVideo {
    onUpdateVideo {
      id
      allowedCognitoGroups
      compressedVideoS3Key
      videoStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteVideo = /* GraphQL */ `
  subscription OnDeleteVideo {
    onDeleteVideo {
      id
      allowedCognitoGroups
      compressedVideoS3Key
      videoStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAutoTranscript = /* GraphQL */ `
  subscription OnCreateAutoTranscript {
    onCreateAutoTranscript {
      id
      allowedCognitoGroups
      speechmodelID
      SpeechModel {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      transcriptSourceS3Key
      transcriptStandardizedS3Key
      transcriptTranslationS3Keys
      transcriptionStatus
      exportPlainTextS3Key
      exportJsonS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      diarizationS3Key
      summaryS3Key
      categoriesJson
      autotranscriptstatsID
      AutoTranscriptStats {
        id
        allowedCognitoGroups
        unknowns
        wordCount
        unknownPercentage
        globalConfidence
        knownConfidence
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAutoTranscript = /* GraphQL */ `
  subscription OnUpdateAutoTranscript {
    onUpdateAutoTranscript {
      id
      allowedCognitoGroups
      speechmodelID
      SpeechModel {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      transcriptSourceS3Key
      transcriptStandardizedS3Key
      transcriptTranslationS3Keys
      transcriptionStatus
      exportPlainTextS3Key
      exportJsonS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      diarizationS3Key
      summaryS3Key
      categoriesJson
      autotranscriptstatsID
      AutoTranscriptStats {
        id
        allowedCognitoGroups
        unknowns
        wordCount
        unknownPercentage
        globalConfidence
        knownConfidence
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAutoTranscript = /* GraphQL */ `
  subscription OnDeleteAutoTranscript {
    onDeleteAutoTranscript {
      id
      allowedCognitoGroups
      speechmodelID
      SpeechModel {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      transcriptSourceS3Key
      transcriptStandardizedS3Key
      transcriptTranslationS3Keys
      transcriptionStatus
      exportPlainTextS3Key
      exportJsonS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      diarizationS3Key
      summaryS3Key
      categoriesJson
      autotranscriptstatsID
      AutoTranscriptStats {
        id
        allowedCognitoGroups
        unknowns
        wordCount
        unknownPercentage
        globalConfidence
        knownConfidence
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGoldTranscript = /* GraphQL */ `
  subscription OnCreateGoldTranscript {
    onCreateGoldTranscript {
      id
      allowedCognitoGroups
      validationProgressStatus
      LanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transcriptS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGoldTranscript = /* GraphQL */ `
  subscription OnUpdateGoldTranscript {
    onUpdateGoldTranscript {
      id
      allowedCognitoGroups
      validationProgressStatus
      LanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transcriptS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGoldTranscript = /* GraphQL */ `
  subscription OnDeleteGoldTranscript {
    onDeleteGoldTranscript {
      id
      allowedCognitoGroups
      validationProgressStatus
      LanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transcriptS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCustomTranscript = /* GraphQL */ `
  subscription OnCreateCustomTranscript {
    onCreateCustomTranscript {
      id
      allowedCognitoGroups
      transcriptS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCustomTranscript = /* GraphQL */ `
  subscription OnUpdateCustomTranscript {
    onUpdateCustomTranscript {
      id
      allowedCognitoGroups
      transcriptS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCustomTranscript = /* GraphQL */ `
  subscription OnDeleteCustomTranscript {
    onDeleteCustomTranscript {
      id
      allowedCognitoGroups
      transcriptS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAutoTranscriptStats = /* GraphQL */ `
  subscription OnCreateAutoTranscriptStats {
    onCreateAutoTranscriptStats {
      id
      allowedCognitoGroups
      unknowns
      wordCount
      unknownPercentage
      globalConfidence
      knownConfidence
      autotranscriptID
      AutoTranscript {
        id
        allowedCognitoGroups
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        transcriptSourceS3Key
        transcriptStandardizedS3Key
        transcriptTranslationS3Keys
        transcriptionStatus
        exportPlainTextS3Key
        exportJsonS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        diarizationS3Key
        summaryS3Key
        categoriesJson
        autotranscriptstatsID
        AutoTranscriptStats {
          id
          allowedCognitoGroups
          unknowns
          wordCount
          unknownPercentage
          globalConfidence
          knownConfidence
          autotranscriptID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAutoTranscriptStats = /* GraphQL */ `
  subscription OnUpdateAutoTranscriptStats {
    onUpdateAutoTranscriptStats {
      id
      allowedCognitoGroups
      unknowns
      wordCount
      unknownPercentage
      globalConfidence
      knownConfidence
      autotranscriptID
      AutoTranscript {
        id
        allowedCognitoGroups
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        transcriptSourceS3Key
        transcriptStandardizedS3Key
        transcriptTranslationS3Keys
        transcriptionStatus
        exportPlainTextS3Key
        exportJsonS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        diarizationS3Key
        summaryS3Key
        categoriesJson
        autotranscriptstatsID
        AutoTranscriptStats {
          id
          allowedCognitoGroups
          unknowns
          wordCount
          unknownPercentage
          globalConfidence
          knownConfidence
          autotranscriptID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAutoTranscriptStats = /* GraphQL */ `
  subscription OnDeleteAutoTranscriptStats {
    onDeleteAutoTranscriptStats {
      id
      allowedCognitoGroups
      unknowns
      wordCount
      unknownPercentage
      globalConfidence
      knownConfidence
      autotranscriptID
      AutoTranscript {
        id
        allowedCognitoGroups
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        transcriptSourceS3Key
        transcriptStandardizedS3Key
        transcriptTranslationS3Keys
        transcriptionStatus
        exportPlainTextS3Key
        exportJsonS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        diarizationS3Key
        summaryS3Key
        categoriesJson
        autotranscriptstatsID
        AutoTranscriptStats {
          id
          allowedCognitoGroups
          unknowns
          wordCount
          unknownPercentage
          globalConfidence
          knownConfidence
          autotranscriptID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSpeechModel = /* GraphQL */ `
  subscription OnCreateSpeechModel {
    onCreateSpeechModel {
      id
      name
      description
      s3ImageUrl
      allowedCognitoGroups
      provider
      connectionString
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      LanguageDialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSpeechModel = /* GraphQL */ `
  subscription OnUpdateSpeechModel {
    onUpdateSpeechModel {
      id
      name
      description
      s3ImageUrl
      allowedCognitoGroups
      provider
      connectionString
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      LanguageDialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSpeechModel = /* GraphQL */ `
  subscription OnDeleteSpeechModel {
    onDeleteSpeechModel {
      id
      name
      description
      s3ImageUrl
      allowedCognitoGroups
      provider
      connectionString
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      LanguageDialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLanguageLabel = /* GraphQL */ `
  subscription OnCreateLanguageLabel {
    onCreateLanguageLabel {
      id
      allowedCognitoGroups
      createdAt
      submittedAt
      validatedAt
      submitteduserID
      submittedUser {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      validateduserID
      validatedUser {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      contextchannelID
      sessionID
      goldtranscriptID
      GoldTranscript {
        id
        allowedCognitoGroups
        validationProgressStatus
        LanguageLabels {
          nextToken
          startedAt
        }
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      starttime
      endtime
      speakerID
      Speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      LanguageDialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      contentJson
      sentimentJson
      contentOrigin
      contentJsonOrigin
      sentimentJsonOrigin
      validationStatus
      feedbackMessage
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLanguageLabel = /* GraphQL */ `
  subscription OnUpdateLanguageLabel {
    onUpdateLanguageLabel {
      id
      allowedCognitoGroups
      createdAt
      submittedAt
      validatedAt
      submitteduserID
      submittedUser {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      validateduserID
      validatedUser {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      contextchannelID
      sessionID
      goldtranscriptID
      GoldTranscript {
        id
        allowedCognitoGroups
        validationProgressStatus
        LanguageLabels {
          nextToken
          startedAt
        }
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      starttime
      endtime
      speakerID
      Speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      LanguageDialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      contentJson
      sentimentJson
      contentOrigin
      contentJsonOrigin
      sentimentJsonOrigin
      validationStatus
      feedbackMessage
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLanguageLabel = /* GraphQL */ `
  subscription OnDeleteLanguageLabel {
    onDeleteLanguageLabel {
      id
      allowedCognitoGroups
      createdAt
      submittedAt
      validatedAt
      submitteduserID
      submittedUser {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      validateduserID
      validatedUser {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      contextchannelID
      sessionID
      goldtranscriptID
      GoldTranscript {
        id
        allowedCognitoGroups
        validationProgressStatus
        LanguageLabels {
          nextToken
          startedAt
        }
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      starttime
      endtime
      speakerID
      Speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      LanguageDialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      contentJson
      sentimentJson
      contentOrigin
      contentJsonOrigin
      sentimentJsonOrigin
      validationStatus
      feedbackMessage
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNLPModel = /* GraphQL */ `
  subscription OnCreateNLPModel {
    onCreateNLPModel {
      id
      modelTrainedAt
      name
      huggingfaceRef
      pipelineParametersJson
      inferenceParametersJson
      otherParametersJson
      basemodelID
      BaseModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      ChildModels {
        items {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      nlpmodeltypeID
      NLPModelType {
        id
        name
        huggingfaceTask
        NLPModels {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNLPModel = /* GraphQL */ `
  subscription OnUpdateNLPModel {
    onUpdateNLPModel {
      id
      modelTrainedAt
      name
      huggingfaceRef
      pipelineParametersJson
      inferenceParametersJson
      otherParametersJson
      basemodelID
      BaseModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      ChildModels {
        items {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      nlpmodeltypeID
      NLPModelType {
        id
        name
        huggingfaceTask
        NLPModels {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNLPModel = /* GraphQL */ `
  subscription OnDeleteNLPModel {
    onDeleteNLPModel {
      id
      modelTrainedAt
      name
      huggingfaceRef
      pipelineParametersJson
      inferenceParametersJson
      otherParametersJson
      basemodelID
      BaseModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      ChildModels {
        items {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      nlpmodeltypeID
      NLPModelType {
        id
        name
        huggingfaceTask
        NLPModels {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNLPModelType = /* GraphQL */ `
  subscription OnCreateNLPModelType {
    onCreateNLPModelType {
      id
      name
      huggingfaceTask
      NLPModels {
        items {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNLPModelType = /* GraphQL */ `
  subscription OnUpdateNLPModelType {
    onUpdateNLPModelType {
      id
      name
      huggingfaceTask
      NLPModels {
        items {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNLPModelType = /* GraphQL */ `
  subscription OnDeleteNLPModelType {
    onDeleteNLPModelType {
      id
      name
      huggingfaceTask
      NLPModels {
        items {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSpeaker = /* GraphQL */ `
  subscription OnCreateSpeaker {
    onCreateSpeaker {
      id
      allowedCognitoGroups
      firstName
      infix
      lastName
      gender
      Subscriptions {
        items {
          id
          createdAt
          userID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Languages {
        items {
          id
          createdAt
          isDefaultLanguage
          speakerID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Contextchannels {
        items {
          id
          createdAt
          contextchannelID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultSpeaker
          sessionID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      LanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userID
      User {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSpeaker = /* GraphQL */ `
  subscription OnUpdateSpeaker {
    onUpdateSpeaker {
      id
      allowedCognitoGroups
      firstName
      infix
      lastName
      gender
      Subscriptions {
        items {
          id
          createdAt
          userID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Languages {
        items {
          id
          createdAt
          isDefaultLanguage
          speakerID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Contextchannels {
        items {
          id
          createdAt
          contextchannelID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultSpeaker
          sessionID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      LanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userID
      User {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSpeaker = /* GraphQL */ `
  subscription OnDeleteSpeaker {
    onDeleteSpeaker {
      id
      allowedCognitoGroups
      firstName
      infix
      lastName
      gender
      Subscriptions {
        items {
          id
          createdAt
          userID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Languages {
        items {
          id
          createdAt
          isDefaultLanguage
          speakerID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Contextchannels {
        items {
          id
          createdAt
          contextchannelID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultSpeaker
          sessionID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      LanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userID
      User {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSpeakerLanguages = /* GraphQL */ `
  subscription OnCreateSpeakerLanguages {
    onCreateSpeakerLanguages {
      id
      createdAt
      isDefaultLanguage
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dialects {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          speakerlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSpeakerLanguages = /* GraphQL */ `
  subscription OnUpdateSpeakerLanguages {
    onUpdateSpeakerLanguages {
      id
      createdAt
      isDefaultLanguage
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dialects {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          speakerlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSpeakerLanguages = /* GraphQL */ `
  subscription OnDeleteSpeakerLanguages {
    onDeleteSpeakerLanguages {
      id
      createdAt
      isDefaultLanguage
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      dialects {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          speakerlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSpeakerLanguageLanguageDialects = /* GraphQL */ `
  subscription OnCreateSpeakerLanguageLanguageDialects {
    onCreateSpeakerLanguageLanguageDialects {
      id
      createdAt
      isDefaultLanguageDialect
      speakerlanguageID
      speakerlanguage {
        id
        createdAt
        isDefaultLanguage
        speakerID
        speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dialects {
          nextToken
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      languagedialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSpeakerLanguageLanguageDialects = /* GraphQL */ `
  subscription OnUpdateSpeakerLanguageLanguageDialects {
    onUpdateSpeakerLanguageLanguageDialects {
      id
      createdAt
      isDefaultLanguageDialect
      speakerlanguageID
      speakerlanguage {
        id
        createdAt
        isDefaultLanguage
        speakerID
        speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dialects {
          nextToken
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      languagedialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSpeakerLanguageLanguageDialects = /* GraphQL */ `
  subscription OnDeleteSpeakerLanguageLanguageDialects {
    onDeleteSpeakerLanguageLanguageDialects {
      id
      createdAt
      isDefaultLanguageDialect
      speakerlanguageID
      speakerlanguage {
        id
        createdAt
        isDefaultLanguage
        speakerID
        speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        dialects {
          nextToken
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      languagedialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLanguage = /* GraphQL */ `
  subscription OnCreateLanguage {
    onCreateLanguage {
      id
      name
      ISO_639_1
      ISO_639_2
      ISO_639_3
      stopwords
      LanguageDialects {
        items {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SpeechModels {
        items {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contextchannels {
        items {
          id
          createdAt
          enabled
          contextchannelID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      NLPSteps {
        items {
          id
          createdAt
          formality
          nlpstepID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultLanguage
          sessionID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultLanguage
          speakerID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLanguage = /* GraphQL */ `
  subscription OnUpdateLanguage {
    onUpdateLanguage {
      id
      name
      ISO_639_1
      ISO_639_2
      ISO_639_3
      stopwords
      LanguageDialects {
        items {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SpeechModels {
        items {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contextchannels {
        items {
          id
          createdAt
          enabled
          contextchannelID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      NLPSteps {
        items {
          id
          createdAt
          formality
          nlpstepID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultLanguage
          sessionID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultLanguage
          speakerID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLanguage = /* GraphQL */ `
  subscription OnDeleteLanguage {
    onDeleteLanguage {
      id
      name
      ISO_639_1
      ISO_639_2
      ISO_639_3
      stopwords
      LanguageDialects {
        items {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SpeechModels {
        items {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contextchannels {
        items {
          id
          createdAt
          enabled
          contextchannelID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      NLPSteps {
        items {
          id
          createdAt
          formality
          nlpstepID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultLanguage
          sessionID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultLanguage
          speakerID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLanguageDialect = /* GraphQL */ `
  subscription OnCreateLanguageDialect {
    onCreateLanguageDialect {
      id
      name
      ISO_639_2_dialect
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      SpeechModels {
        items {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contextchannels {
        items {
          id
          createdAt
          enabled
          contextchannellanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          sessionlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          speakerlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLanguageDialect = /* GraphQL */ `
  subscription OnUpdateLanguageDialect {
    onUpdateLanguageDialect {
      id
      name
      ISO_639_2_dialect
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      SpeechModels {
        items {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contextchannels {
        items {
          id
          createdAt
          enabled
          contextchannellanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          sessionlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          speakerlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLanguageDialect = /* GraphQL */ `
  subscription OnDeleteLanguageDialect {
    onDeleteLanguageDialect {
      id
      name
      ISO_639_2_dialect
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      SpeechModels {
        items {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contextchannels {
        items {
          id
          createdAt
          enabled
          contextchannellanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          sessionlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          speakerlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($id: String) {
    onCreateUser(id: $id) {
      id
      teamGroup
      isActive
      email
      needsEmailChange
      firstName
      infix
      lastName
      avatarS3Url
      speakerID
      Speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      submittedLanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      validatedLanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      allowedContextchannels {
        items {
          id
          createdAt
          isActive
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      ContextchannelSubscriptions {
        items {
          id
          createdAt
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SessionSubscriptions {
        items {
          id
          createdAt
          userID
          sessionID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SpeakerSubscriptions {
        items {
          id
          createdAt
          userID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: String) {
    onUpdateUser(id: $id) {
      id
      teamGroup
      isActive
      email
      needsEmailChange
      firstName
      infix
      lastName
      avatarS3Url
      speakerID
      Speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      submittedLanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      validatedLanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      allowedContextchannels {
        items {
          id
          createdAt
          isActive
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      ContextchannelSubscriptions {
        items {
          id
          createdAt
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SessionSubscriptions {
        items {
          id
          createdAt
          userID
          sessionID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SpeakerSubscriptions {
        items {
          id
          createdAt
          userID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($id: String) {
    onDeleteUser(id: $id) {
      id
      teamGroup
      isActive
      email
      needsEmailChange
      firstName
      infix
      lastName
      avatarS3Url
      speakerID
      Speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      submittedLanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      validatedLanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      allowedContextchannels {
        items {
          id
          createdAt
          isActive
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      ContextchannelSubscriptions {
        items {
          id
          createdAt
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SessionSubscriptions {
        items {
          id
          createdAt
          userID
          sessionID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SpeakerSubscriptions {
        items {
          id
          createdAt
          userID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserContextchannels = /* GraphQL */ `
  subscription OnCreateUserContextchannels {
    onCreateUserContextchannels {
      id
      createdAt
      isActive
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserContextchannels = /* GraphQL */ `
  subscription OnUpdateUserContextchannels {
    onUpdateUserContextchannels {
      id
      createdAt
      isActive
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserContextchannels = /* GraphQL */ `
  subscription OnDeleteUserContextchannels {
    onDeleteUserContextchannels {
      id
      createdAt
      isActive
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContextchannelSubscription = /* GraphQL */ `
  subscription OnCreateContextchannelSubscription($userID: String) {
    onCreateContextchannelSubscription(userID: $userID) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContextchannelSubscription = /* GraphQL */ `
  subscription OnUpdateContextchannelSubscription($userID: String) {
    onUpdateContextchannelSubscription(userID: $userID) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContextchannelSubscription = /* GraphQL */ `
  subscription OnDeleteContextchannelSubscription($userID: String) {
    onDeleteContextchannelSubscription(userID: $userID) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSessionSubscription = /* GraphQL */ `
  subscription OnCreateSessionSubscription($userID: String) {
    onCreateSessionSubscription(userID: $userID) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sessionID
      session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSessionSubscription = /* GraphQL */ `
  subscription OnUpdateSessionSubscription($userID: String) {
    onUpdateSessionSubscription(userID: $userID) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sessionID
      session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSessionSubscription = /* GraphQL */ `
  subscription OnDeleteSessionSubscription($userID: String) {
    onDeleteSessionSubscription(userID: $userID) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sessionID
      session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSpeakerSubscription = /* GraphQL */ `
  subscription OnCreateSpeakerSubscription($userID: String) {
    onCreateSpeakerSubscription(userID: $userID) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSpeakerSubscription = /* GraphQL */ `
  subscription OnUpdateSpeakerSubscription($userID: String) {
    onUpdateSpeakerSubscription(userID: $userID) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSpeakerSubscription = /* GraphQL */ `
  subscription OnDeleteSpeakerSubscription($userID: String) {
    onDeleteSpeakerSubscription(userID: $userID) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
