/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInCognitoInput = {
  email: string,
  firstName?: string | null,
  infix?: string | null,
  lastName?: string | null,
  teamGroup: string,
  clientGroups?: Array< string | null > | null,
  roleGroups?: Array< string | null > | null,
};

export type User = {
  __typename: "User",
  id: string,
  teamGroup?: string | null,
  isActive?: boolean | null,
  email?: string | null,
  needsEmailChange?: boolean | null,
  firstName?: string | null,
  infix?: string | null,
  lastName?: string | null,
  avatarS3Url?: string | null,
  speakerID?: string | null,
  Speaker?: Speaker | null,
  submittedLanguageLabels?: ModelLanguageLabelConnection | null,
  validatedLanguageLabels?: ModelLanguageLabelConnection | null,
  allowedContextchannels?: ModelUserContextchannelsConnection | null,
  ContextchannelSubscriptions?: ModelContextchannelSubscriptionConnection | null,
  SessionSubscriptions?: ModelSessionSubscriptionConnection | null,
  SpeakerSubscriptions?: ModelSpeakerSubscriptionConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Speaker = {
  __typename: "Speaker",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  firstName: string,
  infix?: string | null,
  lastName: string,
  gender: Gender,
  Subscriptions?: ModelSpeakerSubscriptionConnection | null,
  languageID: string,
  Language?: Language | null,
  Languages?: ModelSpeakerLanguagesConnection | null,
  Contextchannels?: ModelContextchannelSpeakersConnection | null,
  Sessions?: ModelSessionSpeakersConnection | null,
  clientID: string,
  Client?: Client | null,
  LanguageLabels?: ModelLanguageLabelConnection | null,
  userID?: string | null,
  User?: User | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum Gender {
  male = "male",
  female = "female",
  other = "other",
}


export type ModelSpeakerSubscriptionConnection = {
  __typename: "ModelSpeakerSubscriptionConnection",
  items:  Array<SpeakerSubscription | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SpeakerSubscription = {
  __typename: "SpeakerSubscription",
  id: string,
  createdAt: string,
  userID: string,
  user?: User | null,
  speakerID: string,
  speaker?: Speaker | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Language = {
  __typename: "Language",
  id: string,
  name: string,
  ISO_639_1?: string | null,
  ISO_639_2: string,
  ISO_639_3?: string | null,
  stopwords?: Array< string | null > | null,
  LanguageDialects?: ModelLanguageDialectConnection | null,
  SpeechModels?: ModelSpeechModelConnection | null,
  Contextchannels?: ModelContextchannelLanguagesConnection | null,
  NLPSteps?: ModelNLPStepLanguagesConnection | null,
  Sessions?: ModelSessionLanguagesConnection | null,
  Speakers?: ModelSpeakerLanguagesConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelLanguageDialectConnection = {
  __typename: "ModelLanguageDialectConnection",
  items:  Array<LanguageDialect | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LanguageDialect = {
  __typename: "LanguageDialect",
  id: string,
  name: string,
  ISO_639_2_dialect: string,
  languageID: string,
  Language?: Language | null,
  SpeechModels?: ModelSpeechModelConnection | null,
  Contextchannels?: ModelContextchannelLanguageLanguageDialectsConnection | null,
  Sessions?: ModelSessionLanguageLanguageDialectsConnection | null,
  Speakers?: ModelSpeakerLanguageLanguageDialectsConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelSpeechModelConnection = {
  __typename: "ModelSpeechModelConnection",
  items:  Array<SpeechModel | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SpeechModel = {
  __typename: "SpeechModel",
  id: string,
  name?: string | null,
  description?: string | null,
  s3ImageUrl?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  provider: SpeechModelProvider,
  connectionString?: string | null,
  languageID: string,
  Language?: Language | null,
  languagedialectID?: string | null,
  LanguageDialect?: LanguageDialect | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum SpeechModelProvider {
  Azure = "Azure",
  Custom = "Custom",
  Wav2Vec2 = "Wav2Vec2",
  Disabled = "Disabled",
}


export type ModelContextchannelLanguageLanguageDialectsConnection = {
  __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
  items:  Array<ContextchannelLanguageLanguageDialects | null >,
  nextToken?: string | null,
};

export type ContextchannelLanguageLanguageDialects = {
  __typename: "ContextchannelLanguageLanguageDialects",
  id: string,
  createdAt: string,
  enabled?: boolean | null,
  contextchannellanguageID: string,
  contextchannellanguage?: ContextchannelLanguages | null,
  languagedialectID: string,
  languagedialect?: LanguageDialect | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ContextchannelLanguages = {
  __typename: "ContextchannelLanguages",
  id: string,
  createdAt: string,
  enabled?: boolean | null,
  contextchannelID: string,
  contextchannel?: Contextchannel | null,
  languageID: string,
  language?: Language | null,
  dialects?: ModelContextchannelLanguageLanguageDialectsConnection | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Contextchannel = {
  __typename: "Contextchannel",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  name: string,
  s3ImageUrl?: string | null,
  reference_id?: string | null,
  contactEmailAddresses?: Array< string | null > | null,
  Subscriptions?: ModelContextchannelSubscriptionConnection | null,
  allowedUsers?: ModelUserContextchannelsConnection | null,
  dynamicConfig?: string | null,
  wordTaggerModuleSettings?: WordTaggerModuleSettings | null,
  gnlpConfig?:  Array<GNLPPrompt | null > | null,
  gnlpTasks?: Array< string | null > | null,
  speechmodelID: string,
  SpeechModel?: SpeechModel | null,
  wordcloudSettings?: WordcloudSettings | null,
  precomputedWordCountsS3Key?: string | null,
  ClusteringModuleOutputs?: ModelClusteringModuleOutputConnection | null,
  NLPSteps?: ModelNLPStepConnection | null,
  posmodelID?: string | null,
  POSModel?: NLPModel | null,
  nermodelID?: string | null,
  NERModel?: NLPModel | null,
  summarymodelID?: string | null,
  SummaryModel?: NLPModel | null,
  sentimentmodelID?: string | null,
  SentimentModel?: NLPModel | null,
  categorymodelID?: string | null,
  CategoryModel?: NLPModel | null,
  clientID: string,
  Client?: Client | null,
  Sessions?: ModelSessionConnection | null,
  Speakers?: ModelContextchannelSpeakersConnection | null,
  Languages?: ModelContextchannelLanguagesConnection | null,
  ContextchannelLogs?: ModelContextchannelLogConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelContextchannelSubscriptionConnection = {
  __typename: "ModelContextchannelSubscriptionConnection",
  items:  Array<ContextchannelSubscription | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ContextchannelSubscription = {
  __typename: "ContextchannelSubscription",
  id: string,
  createdAt: string,
  userID: string,
  user?: User | null,
  contextchannelID: string,
  contextchannel?: Contextchannel | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelUserContextchannelsConnection = {
  __typename: "ModelUserContextchannelsConnection",
  items:  Array<UserContextchannels | null >,
  nextToken?: string | null,
};

export type UserContextchannels = {
  __typename: "UserContextchannels",
  id: string,
  createdAt: string,
  isActive?: boolean | null,
  userID: string,
  user?: User | null,
  contextchannelID: string,
  contextchannel?: Contextchannel | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type WordTaggerModuleSettings = {
  __typename: "WordTaggerModuleSettings",
  enabled?: boolean | null,
  categories?: Array< string | null > | null,
};

export type GNLPPrompt = {
  __typename: "GNLPPrompt",
  prompt: string,
  maxTokens: number,
  temperature: number,
  key: string,
  frequencyPenalty: number,
  presencePenalty: number,
  languageISO?: string | null,
};

export type WordcloudSettings = {
  __typename: "WordcloudSettings",
  whitelist?: Array< string | null > | null,
  blacklist?: Array< string | null > | null,
};

export type ModelClusteringModuleOutputConnection = {
  __typename: "ModelClusteringModuleOutputConnection",
  items:  Array<ClusteringModuleOutput | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ClusteringModuleOutput = {
  __typename: "ClusteringModuleOutput",
  id: string,
  createdAt: string,
  allowedCognitoGroups?: Array< string | null > | null,
  clientID: string,
  contextchannelID: string,
  Contextchannel?: Contextchannel | null,
  key: string,
  leafIDs: Array< string | null >,
  linkageMatrixS3Url: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelNLPStepConnection = {
  __typename: "ModelNLPStepConnection",
  items:  Array<NLPStep | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type NLPStep = {
  __typename: "NLPStep",
  id: string,
  createdAt: string,
  allowedCognitoGroups?: Array< string | null > | null,
  enabled?: boolean | null,
  clientID: string,
  contextchannelID: string,
  Contextchannel?: Contextchannel | null,
  nlpmodelversionID: string,
  NLPModelVersion?: NLPModelVersion | null,
  name: string,
  outputKey: string,
  outputLanguages?: ModelNLPStepLanguagesConnection | null,
  NLPOutputs?: ModelNLPOutputConnection | null,
  inputstepID?: string | null,
  InputStep?: NLPStep | null,
  OutputSteps?: ModelNLPStepConnection | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type NLPModelVersion = {
  __typename: "NLPModelVersion",
  id: string,
  createdAt: string,
  allowedCognitoGroups?: Array< string | null > | null,
  name: string,
  HFModelType?: HFModelType | null,
  parentmodelID?: string | null,
  ParentModel?: NLPModelVersion | null,
  childModels?: ModelNLPModelVersionConnection | null,
  NLPSteps?: ModelNLPStepConnection | null,
  NLPOutputs?: ModelNLPOutputConnection | null,
  modelVersionType?: ModelVersionType | null,
  checkpointKey?: string | null,
  checkpointStorageType?: CheckpointStorageType | null,
  inputlanguageID: string,
  inputLanguage?: Language | null,
  outputlanguageID: string,
  outputLanguage?: Language | null,
  inputParameters?: InputParameters | null,
  inferenceParameters?: InferenceParameters | null,
  modelParameters?: ModelParameters | null,
  openAIParameters?: OpenAIParameters | null,
  outputType: NLPOutputType,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum HFModelType {
  CausalLM = "CausalLM",
  DepthEstimation = "DepthEstimation",
  MaskedLM = "MaskedLM",
  Seq2SeqLM = "Seq2SeqLM",
  SequenceClassification = "SequenceClassification",
  MultipleChoice = "MultipleChoice",
  NextSentencePrediction = "NextSentencePrediction",
  TokenClassification = "TokenClassification",
  QuestionAnswering = "QuestionAnswering",
  TableQuestionAnswering = "TableQuestionAnswering",
  DocumentQuestionAnswering = "DocumentQuestionAnswering",
  ImageClassification = "ImageClassification",
  VideoClassification = "VideoClassification",
  Vision2Seq = "Vision2Seq",
  VisualQuestionAnswering = "VisualQuestionAnswering",
  AudioClassification = "AudioClassification",
  AudioFrameClassification = "AudioFrameClassification",
  CTC = "CTC",
  SpeechSeq2Seq = "SpeechSeq2Seq",
  AudioXVector = "AudioXVector",
  MaskedImageModeling = "MaskedImageModeling",
  ObjectDetection = "ObjectDetection",
  ImageSegmentation = "ImageSegmentation",
  SemanticSegmentation = "SemanticSegmentation",
  InstanceSegmentation = "InstanceSegmentation",
  ZeroShotObjectDetection = "ZeroShotObjectDetection",
}


export type ModelNLPModelVersionConnection = {
  __typename: "ModelNLPModelVersionConnection",
  items:  Array<NLPModelVersion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelNLPOutputConnection = {
  __typename: "ModelNLPOutputConnection",
  items:  Array<NLPOutput | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type NLPOutput = {
  __typename: "NLPOutput",
  id: string,
  createdAt: string,
  allowedCognitoGroups?: Array< string | null > | null,
  clientID: string,
  contextchannelID: string,
  sessionID: string,
  Session?: Session | null,
  nlpstepID: string,
  NLPStep?: NLPStep | null,
  nlpmodelversionID: string,
  NLPModelVersion?: NLPModelVersion | null,
  inputnlpoutputID?: string | null,
  inputNLPOutput?: NLPOutput | null,
  outputNLPOutputs?: ModelNLPOutputConnection | null,
  key: string,
  outputType: NLPOutputType,
  languageID: string,
  language?: Language | null,
  value?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Session = {
  __typename: "Session",
  id: string,
  createdAt: string,
  name?: string | null,
  clientID: string,
  allowedCognitoGroups?: Array< string | null > | null,
  wordcloudSettings?: WordcloudSettings | null,
  wordTaggerModuleOutputs?: ModelWordTaggerModuleOutputConnection | null,
  gnlpOutputs?: string | null,
  NLPOutputs?: ModelNLPOutputConnection | null,
  title?: string | null,
  summary?: string | null,
  keywords?: Array< string | null > | null,
  rewrittenS3Key?: string | null,
  condensed?: string | null,
  category?: string | null,
  sourceFileS3Key?: string | null,
  s3ImageUrl?: string | null,
  metadataID?: string | null,
  Metadata?: Metadata | null,
  audioID?: string | null,
  Audio?: Audio | null,
  videoID?: string | null,
  Video?: Video | null,
  autotranscriptID?: string | null,
  AutoTranscript?: AutoTranscript | null,
  goldtranscriptID?: string | null,
  GoldTranscript?: GoldTranscript | null,
  customtranscriptID?: string | null,
  CustomTranscript?: CustomTranscript | null,
  metadataStatus?: MetadataStatus | null,
  audioStatus?: AudioStatus | null,
  videoStatus?: VideoStatus | null,
  transcriptionStatus?: TranscriptionStatus | null,
  diarizationStatus?: DiarizationStatus | null,
  subtitleStatus?: SubtitleStatus | null,
  gnlpStatus?: GNLPStatus | null,
  nlpStatus?: NLPStatus | null,
  contextchannelID: string,
  Contextchannel?: Contextchannel | null,
  Speakers?: ModelSessionSpeakersConnection | null,
  Languages?: ModelSessionLanguagesConnection | null,
  contactEmailAddresses?: Array< string | null > | null,
  Subscriptions?: ModelSessionSubscriptionConnection | null,
  validationProgressStatus?: ValidationProgressStatus | null,
  extra?: string | null,
  tags?:  Array<Tag | null > | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelWordTaggerModuleOutputConnection = {
  __typename: "ModelWordTaggerModuleOutputConnection",
  items:  Array<WordTaggerModuleOutput | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type WordTaggerModuleOutput = {
  __typename: "WordTaggerModuleOutput",
  id: string,
  createdAt: string,
  clientID: string,
  Client?: Client | null,
  sessionID: string,
  Session?: Session | null,
  taggedWords?:  Array<TaggedWord | null > | null,
  categories?: Array< string | null > | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Client = {
  __typename: "Client",
  id: string,
  displayName: string,
  cognitoGroupName: string,
  Contextchannels?: ModelContextchannelConnection | null,
  Speakers?: ModelSpeakerConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelContextchannelConnection = {
  __typename: "ModelContextchannelConnection",
  items:  Array<Contextchannel | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSpeakerConnection = {
  __typename: "ModelSpeakerConnection",
  items:  Array<Speaker | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TaggedWord = {
  __typename: "TaggedWord",
  text?: string | null,
  tag?: string | null,
};

export type Metadata = {
  __typename: "Metadata",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  metadataS3Key?: string | null,
  durationSeconds?: number | null,
  metadataStatus?: MetadataStatus | null,
  clientID: string,
  sessionID: string,
  Session?: Session | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum MetadataStatus {
  NotStarted = "NotStarted",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
}


export type Audio = {
  __typename: "Audio",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  uncompressedAudioS3Key?: string | null,
  compressedAudioS3Key?: string | null,
  waveformDataS3Key?: string | null,
  audioStatus?: AudioStatus | null,
  clientID: string,
  sessionID: string,
  Session?: Session | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum AudioStatus {
  NotStarted = "NotStarted",
  Extracting = "Extracting",
  Compressing = "Compressing",
  Normalizing = "Normalizing",
  Done = "Done",
  Failed = "Failed",
}


export type Video = {
  __typename: "Video",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  compressedVideoS3Key?: string | null,
  videoStatus?: VideoStatus | null,
  clientID: string,
  sessionID: string,
  Session?: Session | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum VideoStatus {
  NotStarted = "NotStarted",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
}


export type AutoTranscript = {
  __typename: "AutoTranscript",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  speechmodelID: string,
  SpeechModel?: SpeechModel | null,
  transcriptSourceS3Key?: string | null,
  transcriptStandardizedS3Key?: string | null,
  transcriptTranslationS3Keys?: string | null,
  transcriptionStatus?: TranscriptionStatus | null,
  exportPlainTextS3Key?: string | null,
  exportJsonS3Key?: string | null,
  subtitlesSrtS3Key?: string | null,
  subtitlesVttS3Key?: string | null,
  subtitleStatus?: SubtitleStatus | null,
  diarizationS3Key?: string | null,
  summaryS3Key?: string | null,
  categoriesJson?: string | null,
  autotranscriptstatsID?: string | null,
  AutoTranscriptStats?: AutoTranscriptStats | null,
  clientID: string,
  sessionID: string,
  Session?: Session | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum TranscriptionStatus {
  NotStarted = "NotStarted",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
}


export enum SubtitleStatus {
  NotStarted = "NotStarted",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
}


export type AutoTranscriptStats = {
  __typename: "AutoTranscriptStats",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  unknowns?: number | null,
  wordCount?: number | null,
  unknownPercentage?: number | null,
  globalConfidence?: number | null,
  knownConfidence?: number | null,
  autotranscriptID: string,
  AutoTranscript?: AutoTranscript | null,
  clientID: string,
  sessionID: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type GoldTranscript = {
  __typename: "GoldTranscript",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  validationProgressStatus?: ValidationProgressStatus | null,
  LanguageLabels?: ModelLanguageLabelConnection | null,
  transcriptS3Key?: string | null,
  subtitlesSrtS3Key?: string | null,
  subtitlesVttS3Key?: string | null,
  subtitleStatus?: SubtitleStatus | null,
  clientID: string,
  sessionID: string,
  Session?: Session | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum ValidationProgressStatus {
  NotStarted = "NotStarted",
  Initializing = "Initializing",
  Initialized = "Initialized",
  ValidationRequested = "ValidationRequested",
  Validated = "Validated",
  Failed = "Failed",
}


export type ModelLanguageLabelConnection = {
  __typename: "ModelLanguageLabelConnection",
  items:  Array<LanguageLabel | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LanguageLabel = {
  __typename: "LanguageLabel",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  createdAt: string,
  submittedAt?: string | null,
  validatedAt?: string | null,
  submitteduserID?: string | null,
  submittedUser?: User | null,
  validateduserID?: string | null,
  validatedUser?: User | null,
  clientID: string,
  contextchannelID: string,
  sessionID: string,
  goldtranscriptID: string,
  GoldTranscript?: GoldTranscript | null,
  starttime: string,
  endtime: string,
  speakerID?: string | null,
  Speaker?: Speaker | null,
  languageID?: string | null,
  Language?: Language | null,
  languagedialectID?: string | null,
  LanguageDialect?: LanguageDialect | null,
  content: string,
  contentJson?: string | null,
  sentimentJson?: string | null,
  contentOrigin?: string | null,
  contentJsonOrigin?: string | null,
  sentimentJsonOrigin?: string | null,
  validationStatus?: ValidationStatus | null,
  feedbackMessage?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum ValidationStatus {
  New = "New",
  Updated = "Updated",
  ValidationRequested = "ValidationRequested",
  Validated = "Validated",
  Rejected = "Rejected",
}


export type CustomTranscript = {
  __typename: "CustomTranscript",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  transcriptS3Key?: string | null,
  subtitlesSrtS3Key?: string | null,
  subtitlesVttS3Key?: string | null,
  subtitleStatus?: SubtitleStatus | null,
  clientID: string,
  sessionID: string,
  Session?: Session | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum DiarizationStatus {
  NotStarted = "NotStarted",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
}


export enum GNLPStatus {
  NotStarted = "NotStarted",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
}


export enum NLPStatus {
  NotStarted = "NotStarted",
  Loading = "Loading",
  Running = "Running",
  Succeeded = "Succeeded",
  Failed = "Failed",
}


export type ModelSessionSpeakersConnection = {
  __typename: "ModelSessionSpeakersConnection",
  items:  Array<SessionSpeakers | null >,
  nextToken?: string | null,
};

export type SessionSpeakers = {
  __typename: "SessionSpeakers",
  id: string,
  createdAt: string,
  isDefaultSpeaker?: boolean | null,
  sessionID: string,
  session?: Session | null,
  speakerID: string,
  speaker?: Speaker | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelSessionLanguagesConnection = {
  __typename: "ModelSessionLanguagesConnection",
  items:  Array<SessionLanguages | null >,
  nextToken?: string | null,
};

export type SessionLanguages = {
  __typename: "SessionLanguages",
  id: string,
  createdAt: string,
  isDefaultLanguage?: boolean | null,
  sessionID: string,
  session?: Session | null,
  languageID: string,
  language?: Language | null,
  dialects?: ModelSessionLanguageLanguageDialectsConnection | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelSessionLanguageLanguageDialectsConnection = {
  __typename: "ModelSessionLanguageLanguageDialectsConnection",
  items:  Array<SessionLanguageLanguageDialects | null >,
  nextToken?: string | null,
};

export type SessionLanguageLanguageDialects = {
  __typename: "SessionLanguageLanguageDialects",
  id: string,
  createdAt: string,
  isDefaultLanguageDialect?: boolean | null,
  sessionlanguageID: string,
  sessionlanguage?: SessionLanguages | null,
  languagedialectID: string,
  languagedialect?: LanguageDialect | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelSessionSubscriptionConnection = {
  __typename: "ModelSessionSubscriptionConnection",
  items:  Array<SessionSubscription | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SessionSubscription = {
  __typename: "SessionSubscription",
  id: string,
  createdAt: string,
  userID: string,
  user?: User | null,
  sessionID: string,
  session?: Session | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Tag = {
  __typename: "Tag",
  key?: string | null,
  value?: string | null,
};

export enum NLPOutputType {
  VECTOR = "VECTOR",
  S3KEY = "S3KEY",
  STRING = "STRING",
  CSV = "CSV",
}


export enum ModelVersionType {
  OPENAI = "OPENAI",
  FULL = "FULL",
  ADAPTER = "ADAPTER",
  CONFIG = "CONFIG",
}


export enum CheckpointStorageType {
  S3 = "S3",
  EFS = "EFS",
}


export type InputParameters = {
  __typename: "InputParameters",
  includeSpeakerInInput?: boolean | null,
  parseSpeakerFromOutput?: boolean | null,
  batchSize?: number | null,
  maxInputChunkLength?: number | null,
  inputChunkOverlapTokens?: number | null,
  outputChunkOverlapTokens?: number | null,
  outputChunkOverlapDiscardLastTokens?: number | null,
  truncateInput?: boolean | null,
  maxRecursionDepth?: number | null,
  maxRecursiveOutputLength?: number | null,
  allowConcatenatedOutput?: boolean | null,
  badGenerationResampleTries?: number | null,
  inputPrefix?: string | null,
  inputSuffix?: string | null,
  outputStartWith?: string | null,
  outputSeperators?: Array< string | null > | null,
  returnNMostFrequentOutputs?: number | null,
};

export type InferenceParameters = {
  __typename: "InferenceParameters",
  useCache?: boolean | null,
  maxNewTokens?: number | null,
  minNewTokens?: number | null,
  earlyStopping?: boolean | null,
  doSample?: boolean | null,
  numBeams?: number | null,
  penaltyAlpha?: number | null,
  temperature?: number | null,
  topK?: number | null,
  topP?: number | null,
  diversityPenalty?: number | null,
  lengthPenalty?: number | null,
  exponentialDecayLengthPenaltyStartIndex?: number | null,
  exponentialDecayLengthPenaltyDecayFactor?: number | null,
  repetitionPenalty?: number | null,
  encoderRepetitionPenalty?: number | null,
  noRepeatNgramSize?: number | null,
  encoderNoRepeatNgramSize?: number | null,
  forceWords?: Array< Array< string | null > | null > | null,
  badWords?: Array< string | null > | null,
  suppressTokens?: Array< number | null > | null,
};

export type ModelParameters = {
  __typename: "ModelParameters",
  hasQuantizedWeights?: boolean | null,
  skipQuantizeLayers?: Array< string | null > | null,
  gptqWbits?: number | null,
  gptqGroupsize?: number | null,
  loadIn8bit?: boolean | null,
  loadIn8bitThreshold?: number | null,
  loadIn8bitSkipModules?: Array< string | null > | null,
  torchDtype?: string | null,
  fromTf?: boolean | null,
  fromFlax?: boolean | null,
  ignoreMismatchedSizes?: boolean | null,
};

export type OpenAIParameters = {
  __typename: "OpenAIParameters",
  endpoint: string,
  model: string,
  temperature?: number | null,
  maxTokens?: number | null,
  topP?: number | null,
  frequencyPenalty?: number | null,
  presencePenalty?: number | null,
  stop?: Array< string | null > | null,
};

export type ModelNLPStepLanguagesConnection = {
  __typename: "ModelNLPStepLanguagesConnection",
  items:  Array<NLPStepLanguages | null >,
  nextToken?: string | null,
};

export type NLPStepLanguages = {
  __typename: "NLPStepLanguages",
  id: string,
  createdAt: string,
  formality?: Formality | null,
  nlpstepID: string,
  nlpstep?: NLPStep | null,
  languageID: string,
  language?: Language | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum Formality {
  FORMAL = "FORMAL",
  INFORMAL = "INFORMAL",
}


export type NLPModel = {
  __typename: "NLPModel",
  id: string,
  modelTrainedAt?: string | null,
  name?: string | null,
  huggingfaceRef?: string | null,
  pipelineParametersJson?: string | null,
  inferenceParametersJson?: string | null,
  otherParametersJson?: string | null,
  basemodelID?: string | null,
  BaseModel?: NLPModel | null,
  ChildModels?: ModelNLPModelConnection | null,
  nlpmodeltypeID: string,
  NLPModelType?: NLPModelType | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelNLPModelConnection = {
  __typename: "ModelNLPModelConnection",
  items:  Array<NLPModel | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type NLPModelType = {
  __typename: "NLPModelType",
  id: string,
  name: string,
  huggingfaceTask: HuggingfaceTask,
  NLPModels?: ModelNLPModelConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum HuggingfaceTask {
  AudioClassification = "AudioClassification",
  AutomaticSpeechRecognition = "AutomaticSpeechRecognition",
  Conversational = "Conversational",
  FeatureExtraction = "FeatureExtraction",
  FillMask = "FillMask",
  ImageClassification = "ImageClassification",
  QuestionAnswering = "QuestionAnswering",
  TableQuestionAnswering = "TableQuestionAnswering",
  Text2TextGeneration = "Text2TextGeneration",
  TextClassification = "TextClassification",
  TextGeneration = "TextGeneration",
  TokenClassification = "TokenClassification",
  Translation = "Translation",
  Summarization = "Summarization",
  ZeroShotClassification = "ZeroShotClassification",
}


export type ModelSessionConnection = {
  __typename: "ModelSessionConnection",
  items:  Array<Session | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelContextchannelSpeakersConnection = {
  __typename: "ModelContextchannelSpeakersConnection",
  items:  Array<ContextchannelSpeakers | null >,
  nextToken?: string | null,
};

export type ContextchannelSpeakers = {
  __typename: "ContextchannelSpeakers",
  id: string,
  createdAt: string,
  contextchannelID: string,
  contextchannel?: Contextchannel | null,
  speakerID: string,
  speaker?: Speaker | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelContextchannelLanguagesConnection = {
  __typename: "ModelContextchannelLanguagesConnection",
  items:  Array<ContextchannelLanguages | null >,
  nextToken?: string | null,
};

export type ModelContextchannelLogConnection = {
  __typename: "ModelContextchannelLogConnection",
  items:  Array<ContextchannelLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ContextchannelLog = {
  __typename: "ContextchannelLog",
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  createdAt: string,
  clientID: string,
  contextchannelID: string,
  Contextchannel?: Contextchannel | null,
  type: ContextchannelLogType,
  ApiCallLogEntry?: ApiCallLogEntry | null,
  SessionStatusUpdateLogEntry?: SessionStatusUpdateLogEntry | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum ContextchannelLogType {
  ApiCall = "ApiCall",
  SessionStatusUpdate = "SessionStatusUpdate",
}


export type ApiCallLogEntry = {
  __typename: "ApiCallLogEntry",
  ipAddress: string,
  userName: string,
  method: RequestMethod,
  route: string,
  httpVersion: string,
  statusCode: number,
  userAgent: string,
};

export enum RequestMethod {
  GET = "GET",
  POST = "POST",
}


export type SessionStatusUpdateLogEntry = {
  __typename: "SessionStatusUpdateLogEntry",
  sessionID: string,
  sessionName?: string | null,
  attributeName: string,
  oldValue: string,
  newValue: string,
};

export type ModelSpeakerLanguageLanguageDialectsConnection = {
  __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
  items:  Array<SpeakerLanguageLanguageDialects | null >,
  nextToken?: string | null,
};

export type SpeakerLanguageLanguageDialects = {
  __typename: "SpeakerLanguageLanguageDialects",
  id: string,
  createdAt: string,
  isDefaultLanguageDialect?: boolean | null,
  speakerlanguageID: string,
  speakerlanguage?: SpeakerLanguages | null,
  languagedialectID: string,
  languagedialect?: LanguageDialect | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type SpeakerLanguages = {
  __typename: "SpeakerLanguages",
  id: string,
  createdAt: string,
  isDefaultLanguage?: boolean | null,
  speakerID: string,
  speaker?: Speaker | null,
  languageID: string,
  language?: Language | null,
  dialects?: ModelSpeakerLanguageLanguageDialectsConnection | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelSpeakerLanguagesConnection = {
  __typename: "ModelSpeakerLanguagesConnection",
  items:  Array<SpeakerLanguages | null >,
  nextToken?: string | null,
};

export type UpdateUserInCognitoInput = {
  id: string,
  teamGroup?: string | null,
  clientGroups?: Array< string | null > | null,
  roleGroups?: Array< string | null > | null,
};

export type CognitoGroupsForUser = {
  __typename: "CognitoGroupsForUser",
  teamGroup?: string | null,
  clientGroups?: Array< string | null > | null,
  roleGroups?: Array< string | null > | null,
};

export type DisableUserInCognitoInput = {
  id: string,
};

export type CreateEnvironmentStatusInput = {
  id?: string | null,
  underMaintanence: boolean,
  notes?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelEnvironmentStatusConditionInput = {
  underMaintanence?: ModelBooleanInput | null,
  notes?: ModelStringInput | null,
  and?: Array< ModelEnvironmentStatusConditionInput | null > | null,
  or?: Array< ModelEnvironmentStatusConditionInput | null > | null,
  not?: ModelEnvironmentStatusConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type EnvironmentStatus = {
  __typename: "EnvironmentStatus",
  id: string,
  underMaintanence: boolean,
  notes?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateEnvironmentStatusInput = {
  id: string,
  underMaintanence?: boolean | null,
  notes?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteEnvironmentStatusInput = {
  id: string,
  _version?: number | null,
};

export type CreateClientInput = {
  id?: string | null,
  displayName: string,
  cognitoGroupName: string,
  _version?: number | null,
};

export type ModelClientConditionInput = {
  displayName?: ModelStringInput | null,
  cognitoGroupName?: ModelStringInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
};

export type UpdateClientInput = {
  id: string,
  displayName?: string | null,
  cognitoGroupName?: string | null,
  _version?: number | null,
};

export type DeleteClientInput = {
  id: string,
  _version?: number | null,
};

export type CreateWordTaggerModuleOutputInput = {
  id?: string | null,
  createdAt?: string | null,
  clientID: string,
  sessionID: string,
  taggedWords?: Array< TaggedWordInput | null > | null,
  categories?: Array< string | null > | null,
  _version?: number | null,
};

export type TaggedWordInput = {
  text?: string | null,
  tag?: string | null,
};

export type ModelWordTaggerModuleOutputConditionInput = {
  createdAt?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  categories?: ModelStringInput | null,
  and?: Array< ModelWordTaggerModuleOutputConditionInput | null > | null,
  or?: Array< ModelWordTaggerModuleOutputConditionInput | null > | null,
  not?: ModelWordTaggerModuleOutputConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateWordTaggerModuleOutputInput = {
  id: string,
  createdAt?: string | null,
  clientID?: string | null,
  sessionID?: string | null,
  taggedWords?: Array< TaggedWordInput | null > | null,
  categories?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteWordTaggerModuleOutputInput = {
  id: string,
  _version?: number | null,
};

export type CreateContextchannelInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  name: string,
  s3ImageUrl?: string | null,
  reference_id?: string | null,
  contactEmailAddresses?: Array< string | null > | null,
  dynamicConfig?: string | null,
  wordTaggerModuleSettings?: WordTaggerModuleSettingsInput | null,
  gnlpConfig?: Array< GNLPPromptInput | null > | null,
  gnlpTasks?: Array< string | null > | null,
  speechmodelID: string,
  wordcloudSettings?: WordcloudSettingsInput | null,
  precomputedWordCountsS3Key?: string | null,
  posmodelID?: string | null,
  nermodelID?: string | null,
  summarymodelID?: string | null,
  sentimentmodelID?: string | null,
  categorymodelID?: string | null,
  clientID: string,
  _version?: number | null,
};

export type WordTaggerModuleSettingsInput = {
  enabled?: boolean | null,
  categories?: Array< string | null > | null,
};

export type GNLPPromptInput = {
  prompt: string,
  maxTokens: number,
  temperature: number,
  key: string,
  frequencyPenalty: number,
  presencePenalty: number,
  languageISO?: string | null,
};

export type WordcloudSettingsInput = {
  whitelist?: Array< string | null > | null,
  blacklist?: Array< string | null > | null,
};

export type ModelContextchannelConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  name?: ModelStringInput | null,
  s3ImageUrl?: ModelStringInput | null,
  reference_id?: ModelStringInput | null,
  contactEmailAddresses?: ModelStringInput | null,
  dynamicConfig?: ModelStringInput | null,
  gnlpTasks?: ModelStringInput | null,
  speechmodelID?: ModelIDInput | null,
  precomputedWordCountsS3Key?: ModelStringInput | null,
  posmodelID?: ModelIDInput | null,
  nermodelID?: ModelIDInput | null,
  summarymodelID?: ModelIDInput | null,
  sentimentmodelID?: ModelIDInput | null,
  categorymodelID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  and?: Array< ModelContextchannelConditionInput | null > | null,
  or?: Array< ModelContextchannelConditionInput | null > | null,
  not?: ModelContextchannelConditionInput | null,
};

export type UpdateContextchannelInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  name?: string | null,
  s3ImageUrl?: string | null,
  reference_id?: string | null,
  contactEmailAddresses?: Array< string | null > | null,
  dynamicConfig?: string | null,
  wordTaggerModuleSettings?: WordTaggerModuleSettingsInput | null,
  gnlpConfig?: Array< GNLPPromptInput | null > | null,
  gnlpTasks?: Array< string | null > | null,
  speechmodelID?: string | null,
  wordcloudSettings?: WordcloudSettingsInput | null,
  precomputedWordCountsS3Key?: string | null,
  posmodelID?: string | null,
  nermodelID?: string | null,
  summarymodelID?: string | null,
  sentimentmodelID?: string | null,
  categorymodelID?: string | null,
  clientID?: string | null,
  _version?: number | null,
};

export type DeleteContextchannelInput = {
  id: string,
  _version?: number | null,
};

export type CreateContextchannelLanguagesInput = {
  id?: string | null,
  createdAt?: string | null,
  enabled?: boolean | null,
  contextchannelID: string,
  languageID: string,
  _version?: number | null,
};

export type ModelContextchannelLanguagesConditionInput = {
  createdAt?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  contextchannelID?: ModelIDInput | null,
  languageID?: ModelIDInput | null,
  and?: Array< ModelContextchannelLanguagesConditionInput | null > | null,
  or?: Array< ModelContextchannelLanguagesConditionInput | null > | null,
  not?: ModelContextchannelLanguagesConditionInput | null,
};

export type UpdateContextchannelLanguagesInput = {
  id: string,
  createdAt?: string | null,
  enabled?: boolean | null,
  contextchannelID?: string | null,
  languageID?: string | null,
  _version?: number | null,
};

export type DeleteContextchannelLanguagesInput = {
  id: string,
  _version?: number | null,
};

export type CreateContextchannelLanguageLanguageDialectsInput = {
  id?: string | null,
  createdAt?: string | null,
  enabled?: boolean | null,
  contextchannellanguageID: string,
  languagedialectID: string,
  _version?: number | null,
};

export type ModelContextchannelLanguageLanguageDialectsConditionInput = {
  createdAt?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  contextchannellanguageID?: ModelIDInput | null,
  languagedialectID?: ModelIDInput | null,
  and?: Array< ModelContextchannelLanguageLanguageDialectsConditionInput | null > | null,
  or?: Array< ModelContextchannelLanguageLanguageDialectsConditionInput | null > | null,
  not?: ModelContextchannelLanguageLanguageDialectsConditionInput | null,
};

export type UpdateContextchannelLanguageLanguageDialectsInput = {
  id: string,
  createdAt?: string | null,
  enabled?: boolean | null,
  contextchannellanguageID?: string | null,
  languagedialectID?: string | null,
  _version?: number | null,
};

export type DeleteContextchannelLanguageLanguageDialectsInput = {
  id: string,
  _version?: number | null,
};

export type CreateContextchannelSpeakersInput = {
  id?: string | null,
  createdAt?: string | null,
  contextchannelID: string,
  speakerID: string,
  _version?: number | null,
};

export type ModelContextchannelSpeakersConditionInput = {
  createdAt?: ModelStringInput | null,
  contextchannelID?: ModelIDInput | null,
  speakerID?: ModelIDInput | null,
  and?: Array< ModelContextchannelSpeakersConditionInput | null > | null,
  or?: Array< ModelContextchannelSpeakersConditionInput | null > | null,
  not?: ModelContextchannelSpeakersConditionInput | null,
};

export type UpdateContextchannelSpeakersInput = {
  id: string,
  createdAt?: string | null,
  contextchannelID?: string | null,
  speakerID?: string | null,
  _version?: number | null,
};

export type DeleteContextchannelSpeakersInput = {
  id: string,
  _version?: number | null,
};

export type CreateContextchannelLogInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  createdAt?: string | null,
  clientID: string,
  contextchannelID: string,
  type: ContextchannelLogType,
  ApiCallLogEntry?: ApiCallLogEntryInput | null,
  SessionStatusUpdateLogEntry?: SessionStatusUpdateLogEntryInput | null,
  _version?: number | null,
};

export type ApiCallLogEntryInput = {
  ipAddress: string,
  userName: string,
  method: RequestMethod,
  route: string,
  httpVersion: string,
  statusCode: number,
  userAgent: string,
};

export type SessionStatusUpdateLogEntryInput = {
  sessionID: string,
  sessionName?: string | null,
  attributeName: string,
  oldValue: string,
  newValue: string,
};

export type ModelContextchannelLogConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  type?: ModelContextchannelLogTypeInput | null,
  and?: Array< ModelContextchannelLogConditionInput | null > | null,
  or?: Array< ModelContextchannelLogConditionInput | null > | null,
  not?: ModelContextchannelLogConditionInput | null,
};

export type ModelContextchannelLogTypeInput = {
  eq?: ContextchannelLogType | null,
  ne?: ContextchannelLogType | null,
};

export type UpdateContextchannelLogInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  createdAt?: string | null,
  clientID?: string | null,
  contextchannelID?: string | null,
  type?: ContextchannelLogType | null,
  ApiCallLogEntry?: ApiCallLogEntryInput | null,
  SessionStatusUpdateLogEntry?: SessionStatusUpdateLogEntryInput | null,
  _version?: number | null,
};

export type DeleteContextchannelLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateSessionInput = {
  id?: string | null,
  createdAt?: string | null,
  name?: string | null,
  clientID: string,
  allowedCognitoGroups?: Array< string | null > | null,
  wordcloudSettings?: WordcloudSettingsInput | null,
  gnlpOutputs?: string | null,
  title?: string | null,
  summary?: string | null,
  keywords?: Array< string | null > | null,
  rewrittenS3Key?: string | null,
  condensed?: string | null,
  category?: string | null,
  sourceFileS3Key?: string | null,
  s3ImageUrl?: string | null,
  metadataID?: string | null,
  audioID?: string | null,
  videoID?: string | null,
  autotranscriptID?: string | null,
  goldtranscriptID?: string | null,
  customtranscriptID?: string | null,
  metadataStatus?: MetadataStatus | null,
  audioStatus?: AudioStatus | null,
  videoStatus?: VideoStatus | null,
  transcriptionStatus?: TranscriptionStatus | null,
  diarizationStatus?: DiarizationStatus | null,
  subtitleStatus?: SubtitleStatus | null,
  gnlpStatus?: GNLPStatus | null,
  nlpStatus?: NLPStatus | null,
  contextchannelID: string,
  contactEmailAddresses?: Array< string | null > | null,
  validationProgressStatus?: ValidationProgressStatus | null,
  extra?: string | null,
  tags?: Array< TagInput | null > | null,
  _version?: number | null,
};

export type TagInput = {
  key?: string | null,
  value?: string | null,
};

export type ModelSessionConditionInput = {
  createdAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  gnlpOutputs?: ModelStringInput | null,
  title?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  keywords?: ModelStringInput | null,
  rewrittenS3Key?: ModelStringInput | null,
  condensed?: ModelStringInput | null,
  category?: ModelStringInput | null,
  sourceFileS3Key?: ModelStringInput | null,
  s3ImageUrl?: ModelStringInput | null,
  metadataID?: ModelIDInput | null,
  audioID?: ModelIDInput | null,
  videoID?: ModelIDInput | null,
  autotranscriptID?: ModelIDInput | null,
  goldtranscriptID?: ModelIDInput | null,
  customtranscriptID?: ModelIDInput | null,
  metadataStatus?: ModelMetadataStatusInput | null,
  audioStatus?: ModelAudioStatusInput | null,
  videoStatus?: ModelVideoStatusInput | null,
  transcriptionStatus?: ModelTranscriptionStatusInput | null,
  diarizationStatus?: ModelDiarizationStatusInput | null,
  subtitleStatus?: ModelSubtitleStatusInput | null,
  gnlpStatus?: ModelGNLPStatusInput | null,
  nlpStatus?: ModelNLPStatusInput | null,
  contextchannelID?: ModelIDInput | null,
  contactEmailAddresses?: ModelStringInput | null,
  validationProgressStatus?: ModelValidationProgressStatusInput | null,
  extra?: ModelStringInput | null,
  and?: Array< ModelSessionConditionInput | null > | null,
  or?: Array< ModelSessionConditionInput | null > | null,
  not?: ModelSessionConditionInput | null,
};

export type ModelMetadataStatusInput = {
  eq?: MetadataStatus | null,
  ne?: MetadataStatus | null,
};

export type ModelAudioStatusInput = {
  eq?: AudioStatus | null,
  ne?: AudioStatus | null,
};

export type ModelVideoStatusInput = {
  eq?: VideoStatus | null,
  ne?: VideoStatus | null,
};

export type ModelTranscriptionStatusInput = {
  eq?: TranscriptionStatus | null,
  ne?: TranscriptionStatus | null,
};

export type ModelDiarizationStatusInput = {
  eq?: DiarizationStatus | null,
  ne?: DiarizationStatus | null,
};

export type ModelSubtitleStatusInput = {
  eq?: SubtitleStatus | null,
  ne?: SubtitleStatus | null,
};

export type ModelGNLPStatusInput = {
  eq?: GNLPStatus | null,
  ne?: GNLPStatus | null,
};

export type ModelNLPStatusInput = {
  eq?: NLPStatus | null,
  ne?: NLPStatus | null,
};

export type ModelValidationProgressStatusInput = {
  eq?: ValidationProgressStatus | null,
  ne?: ValidationProgressStatus | null,
};

export type UpdateSessionInput = {
  id: string,
  createdAt?: string | null,
  name?: string | null,
  clientID?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  wordcloudSettings?: WordcloudSettingsInput | null,
  gnlpOutputs?: string | null,
  title?: string | null,
  summary?: string | null,
  keywords?: Array< string | null > | null,
  rewrittenS3Key?: string | null,
  condensed?: string | null,
  category?: string | null,
  sourceFileS3Key?: string | null,
  s3ImageUrl?: string | null,
  metadataID?: string | null,
  audioID?: string | null,
  videoID?: string | null,
  autotranscriptID?: string | null,
  goldtranscriptID?: string | null,
  customtranscriptID?: string | null,
  metadataStatus?: MetadataStatus | null,
  audioStatus?: AudioStatus | null,
  videoStatus?: VideoStatus | null,
  transcriptionStatus?: TranscriptionStatus | null,
  diarizationStatus?: DiarizationStatus | null,
  subtitleStatus?: SubtitleStatus | null,
  gnlpStatus?: GNLPStatus | null,
  nlpStatus?: NLPStatus | null,
  contextchannelID?: string | null,
  contactEmailAddresses?: Array< string | null > | null,
  validationProgressStatus?: ValidationProgressStatus | null,
  extra?: string | null,
  tags?: Array< TagInput | null > | null,
  _version?: number | null,
};

export type DeleteSessionInput = {
  id: string,
  _version?: number | null,
};

export type CreateNLPModelVersionInput = {
  id?: string | null,
  createdAt?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  name: string,
  HFModelType?: HFModelType | null,
  parentmodelID?: string | null,
  modelVersionType?: ModelVersionType | null,
  checkpointKey?: string | null,
  checkpointStorageType?: CheckpointStorageType | null,
  inputlanguageID: string,
  outputlanguageID: string,
  inputParameters?: InputParametersInput | null,
  inferenceParameters?: InferenceParametersInput | null,
  modelParameters?: ModelParametersInput | null,
  openAIParameters?: OpenAIParametersInput | null,
  outputType: NLPOutputType,
  _version?: number | null,
};

export type InputParametersInput = {
  includeSpeakerInInput?: boolean | null,
  parseSpeakerFromOutput?: boolean | null,
  batchSize?: number | null,
  maxInputChunkLength?: number | null,
  inputChunkOverlapTokens?: number | null,
  outputChunkOverlapTokens?: number | null,
  outputChunkOverlapDiscardLastTokens?: number | null,
  truncateInput?: boolean | null,
  maxRecursionDepth?: number | null,
  maxRecursiveOutputLength?: number | null,
  allowConcatenatedOutput?: boolean | null,
  badGenerationResampleTries?: number | null,
  inputPrefix?: string | null,
  inputSuffix?: string | null,
  outputStartWith?: string | null,
  outputSeperators?: Array< string | null > | null,
  returnNMostFrequentOutputs?: number | null,
};

export type InferenceParametersInput = {
  useCache?: boolean | null,
  maxNewTokens?: number | null,
  minNewTokens?: number | null,
  earlyStopping?: boolean | null,
  doSample?: boolean | null,
  numBeams?: number | null,
  penaltyAlpha?: number | null,
  temperature?: number | null,
  topK?: number | null,
  topP?: number | null,
  diversityPenalty?: number | null,
  lengthPenalty?: number | null,
  exponentialDecayLengthPenaltyStartIndex?: number | null,
  exponentialDecayLengthPenaltyDecayFactor?: number | null,
  repetitionPenalty?: number | null,
  encoderRepetitionPenalty?: number | null,
  noRepeatNgramSize?: number | null,
  encoderNoRepeatNgramSize?: number | null,
  forceWords?: Array< Array< string | null > | null > | null,
  badWords?: Array< string | null > | null,
  suppressTokens?: Array< number | null > | null,
};

export type ModelParametersInput = {
  hasQuantizedWeights?: boolean | null,
  skipQuantizeLayers?: Array< string | null > | null,
  gptqWbits?: number | null,
  gptqGroupsize?: number | null,
  loadIn8bit?: boolean | null,
  loadIn8bitThreshold?: number | null,
  loadIn8bitSkipModules?: Array< string | null > | null,
  torchDtype?: string | null,
  fromTf?: boolean | null,
  fromFlax?: boolean | null,
  ignoreMismatchedSizes?: boolean | null,
};

export type OpenAIParametersInput = {
  endpoint: string,
  model: string,
  temperature?: number | null,
  maxTokens?: number | null,
  topP?: number | null,
  frequencyPenalty?: number | null,
  presencePenalty?: number | null,
  stop?: Array< string | null > | null,
};

export type ModelNLPModelVersionConditionInput = {
  createdAt?: ModelStringInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  name?: ModelStringInput | null,
  HFModelType?: ModelHFModelTypeInput | null,
  parentmodelID?: ModelIDInput | null,
  modelVersionType?: ModelModelVersionTypeInput | null,
  checkpointKey?: ModelStringInput | null,
  checkpointStorageType?: ModelCheckpointStorageTypeInput | null,
  inputlanguageID?: ModelIDInput | null,
  outputlanguageID?: ModelIDInput | null,
  outputType?: ModelNLPOutputTypeInput | null,
  and?: Array< ModelNLPModelVersionConditionInput | null > | null,
  or?: Array< ModelNLPModelVersionConditionInput | null > | null,
  not?: ModelNLPModelVersionConditionInput | null,
};

export type ModelHFModelTypeInput = {
  eq?: HFModelType | null,
  ne?: HFModelType | null,
};

export type ModelModelVersionTypeInput = {
  eq?: ModelVersionType | null,
  ne?: ModelVersionType | null,
};

export type ModelCheckpointStorageTypeInput = {
  eq?: CheckpointStorageType | null,
  ne?: CheckpointStorageType | null,
};

export type ModelNLPOutputTypeInput = {
  eq?: NLPOutputType | null,
  ne?: NLPOutputType | null,
};

export type UpdateNLPModelVersionInput = {
  id: string,
  createdAt?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  name?: string | null,
  HFModelType?: HFModelType | null,
  parentmodelID?: string | null,
  modelVersionType?: ModelVersionType | null,
  checkpointKey?: string | null,
  checkpointStorageType?: CheckpointStorageType | null,
  inputlanguageID?: string | null,
  outputlanguageID?: string | null,
  inputParameters?: InputParametersInput | null,
  inferenceParameters?: InferenceParametersInput | null,
  modelParameters?: ModelParametersInput | null,
  openAIParameters?: OpenAIParametersInput | null,
  outputType?: NLPOutputType | null,
  _version?: number | null,
};

export type DeleteNLPModelVersionInput = {
  id: string,
  _version?: number | null,
};

export type CreateNLPStepInput = {
  id?: string | null,
  createdAt?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  enabled?: boolean | null,
  clientID: string,
  contextchannelID: string,
  nlpmodelversionID: string,
  name: string,
  outputKey: string,
  inputstepID?: string | null,
  _version?: number | null,
};

export type ModelNLPStepConditionInput = {
  createdAt?: ModelStringInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  clientID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  nlpmodelversionID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  outputKey?: ModelStringInput | null,
  inputstepID?: ModelIDInput | null,
  and?: Array< ModelNLPStepConditionInput | null > | null,
  or?: Array< ModelNLPStepConditionInput | null > | null,
  not?: ModelNLPStepConditionInput | null,
};

export type UpdateNLPStepInput = {
  id: string,
  createdAt?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  enabled?: boolean | null,
  clientID?: string | null,
  contextchannelID?: string | null,
  nlpmodelversionID?: string | null,
  name?: string | null,
  outputKey?: string | null,
  inputstepID?: string | null,
  _version?: number | null,
};

export type DeleteNLPStepInput = {
  id: string,
  _version?: number | null,
};

export type CreateNLPStepLanguagesInput = {
  id?: string | null,
  createdAt?: string | null,
  formality?: Formality | null,
  nlpstepID: string,
  languageID: string,
  _version?: number | null,
};

export type ModelNLPStepLanguagesConditionInput = {
  createdAt?: ModelStringInput | null,
  formality?: ModelFormalityInput | null,
  nlpstepID?: ModelIDInput | null,
  languageID?: ModelIDInput | null,
  and?: Array< ModelNLPStepLanguagesConditionInput | null > | null,
  or?: Array< ModelNLPStepLanguagesConditionInput | null > | null,
  not?: ModelNLPStepLanguagesConditionInput | null,
};

export type ModelFormalityInput = {
  eq?: Formality | null,
  ne?: Formality | null,
};

export type UpdateNLPStepLanguagesInput = {
  id: string,
  createdAt?: string | null,
  formality?: Formality | null,
  nlpstepID?: string | null,
  languageID?: string | null,
  _version?: number | null,
};

export type DeleteNLPStepLanguagesInput = {
  id: string,
  _version?: number | null,
};

export type CreateNLPOutputInput = {
  id?: string | null,
  createdAt?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  clientID: string,
  contextchannelID: string,
  sessionID: string,
  nlpstepID: string,
  nlpmodelversionID: string,
  inputnlpoutputID?: string | null,
  key: string,
  outputType: NLPOutputType,
  languageID: string,
  value?: string | null,
  _version?: number | null,
};

export type ModelNLPOutputConditionInput = {
  createdAt?: ModelStringInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  nlpstepID?: ModelIDInput | null,
  nlpmodelversionID?: ModelIDInput | null,
  inputnlpoutputID?: ModelIDInput | null,
  key?: ModelStringInput | null,
  outputType?: ModelNLPOutputTypeInput | null,
  languageID?: ModelIDInput | null,
  value?: ModelStringInput | null,
  and?: Array< ModelNLPOutputConditionInput | null > | null,
  or?: Array< ModelNLPOutputConditionInput | null > | null,
  not?: ModelNLPOutputConditionInput | null,
};

export type UpdateNLPOutputInput = {
  id: string,
  createdAt?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  clientID?: string | null,
  contextchannelID?: string | null,
  sessionID?: string | null,
  nlpstepID?: string | null,
  nlpmodelversionID?: string | null,
  inputnlpoutputID?: string | null,
  key?: string | null,
  outputType?: NLPOutputType | null,
  languageID?: string | null,
  value?: string | null,
  _version?: number | null,
};

export type DeleteNLPOutputInput = {
  id: string,
  _version?: number | null,
};

export type CreateClusteringModuleOutputInput = {
  id?: string | null,
  createdAt?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  clientID: string,
  contextchannelID: string,
  key: string,
  leafIDs: Array< string | null >,
  linkageMatrixS3Url: string,
  _version?: number | null,
};

export type ModelClusteringModuleOutputConditionInput = {
  createdAt?: ModelStringInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  key?: ModelStringInput | null,
  leafIDs?: ModelIDInput | null,
  linkageMatrixS3Url?: ModelStringInput | null,
  and?: Array< ModelClusteringModuleOutputConditionInput | null > | null,
  or?: Array< ModelClusteringModuleOutputConditionInput | null > | null,
  not?: ModelClusteringModuleOutputConditionInput | null,
};

export type UpdateClusteringModuleOutputInput = {
  id: string,
  createdAt?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  clientID?: string | null,
  contextchannelID?: string | null,
  key?: string | null,
  leafIDs?: Array< string | null > | null,
  linkageMatrixS3Url?: string | null,
  _version?: number | null,
};

export type DeleteClusteringModuleOutputInput = {
  id: string,
  _version?: number | null,
};

export type CreateSessionSpeakersInput = {
  id?: string | null,
  createdAt?: string | null,
  isDefaultSpeaker?: boolean | null,
  sessionID: string,
  speakerID: string,
  _version?: number | null,
};

export type ModelSessionSpeakersConditionInput = {
  createdAt?: ModelStringInput | null,
  isDefaultSpeaker?: ModelBooleanInput | null,
  sessionID?: ModelIDInput | null,
  speakerID?: ModelIDInput | null,
  and?: Array< ModelSessionSpeakersConditionInput | null > | null,
  or?: Array< ModelSessionSpeakersConditionInput | null > | null,
  not?: ModelSessionSpeakersConditionInput | null,
};

export type UpdateSessionSpeakersInput = {
  id: string,
  createdAt?: string | null,
  isDefaultSpeaker?: boolean | null,
  sessionID?: string | null,
  speakerID?: string | null,
  _version?: number | null,
};

export type DeleteSessionSpeakersInput = {
  id: string,
  _version?: number | null,
};

export type CreateSessionLanguagesInput = {
  id?: string | null,
  createdAt?: string | null,
  isDefaultLanguage?: boolean | null,
  sessionID: string,
  languageID: string,
  _version?: number | null,
};

export type ModelSessionLanguagesConditionInput = {
  createdAt?: ModelStringInput | null,
  isDefaultLanguage?: ModelBooleanInput | null,
  sessionID?: ModelIDInput | null,
  languageID?: ModelIDInput | null,
  and?: Array< ModelSessionLanguagesConditionInput | null > | null,
  or?: Array< ModelSessionLanguagesConditionInput | null > | null,
  not?: ModelSessionLanguagesConditionInput | null,
};

export type UpdateSessionLanguagesInput = {
  id: string,
  createdAt?: string | null,
  isDefaultLanguage?: boolean | null,
  sessionID?: string | null,
  languageID?: string | null,
  _version?: number | null,
};

export type DeleteSessionLanguagesInput = {
  id: string,
  _version?: number | null,
};

export type CreateSessionLanguageLanguageDialectsInput = {
  id?: string | null,
  createdAt?: string | null,
  isDefaultLanguageDialect?: boolean | null,
  sessionlanguageID: string,
  languagedialectID: string,
  _version?: number | null,
};

export type ModelSessionLanguageLanguageDialectsConditionInput = {
  createdAt?: ModelStringInput | null,
  isDefaultLanguageDialect?: ModelBooleanInput | null,
  sessionlanguageID?: ModelIDInput | null,
  languagedialectID?: ModelIDInput | null,
  and?: Array< ModelSessionLanguageLanguageDialectsConditionInput | null > | null,
  or?: Array< ModelSessionLanguageLanguageDialectsConditionInput | null > | null,
  not?: ModelSessionLanguageLanguageDialectsConditionInput | null,
};

export type UpdateSessionLanguageLanguageDialectsInput = {
  id: string,
  createdAt?: string | null,
  isDefaultLanguageDialect?: boolean | null,
  sessionlanguageID?: string | null,
  languagedialectID?: string | null,
  _version?: number | null,
};

export type DeleteSessionLanguageLanguageDialectsInput = {
  id: string,
  _version?: number | null,
};

export type CreateMetadataInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  metadataS3Key?: string | null,
  durationSeconds?: number | null,
  metadataStatus?: MetadataStatus | null,
  clientID: string,
  sessionID: string,
  _version?: number | null,
};

export type ModelMetadataConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  metadataS3Key?: ModelStringInput | null,
  durationSeconds?: ModelFloatInput | null,
  metadataStatus?: ModelMetadataStatusInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelMetadataConditionInput | null > | null,
  or?: Array< ModelMetadataConditionInput | null > | null,
  not?: ModelMetadataConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateMetadataInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  metadataS3Key?: string | null,
  durationSeconds?: number | null,
  metadataStatus?: MetadataStatus | null,
  clientID?: string | null,
  sessionID?: string | null,
  _version?: number | null,
};

export type DeleteMetadataInput = {
  id: string,
  _version?: number | null,
};

export type CreateAudioInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  uncompressedAudioS3Key?: string | null,
  compressedAudioS3Key?: string | null,
  waveformDataS3Key?: string | null,
  audioStatus?: AudioStatus | null,
  clientID: string,
  sessionID: string,
  _version?: number | null,
};

export type ModelAudioConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  uncompressedAudioS3Key?: ModelStringInput | null,
  compressedAudioS3Key?: ModelStringInput | null,
  waveformDataS3Key?: ModelStringInput | null,
  audioStatus?: ModelAudioStatusInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelAudioConditionInput | null > | null,
  or?: Array< ModelAudioConditionInput | null > | null,
  not?: ModelAudioConditionInput | null,
};

export type UpdateAudioInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  uncompressedAudioS3Key?: string | null,
  compressedAudioS3Key?: string | null,
  waveformDataS3Key?: string | null,
  audioStatus?: AudioStatus | null,
  clientID?: string | null,
  sessionID?: string | null,
  _version?: number | null,
};

export type DeleteAudioInput = {
  id: string,
  _version?: number | null,
};

export type CreateVideoInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  compressedVideoS3Key?: string | null,
  videoStatus?: VideoStatus | null,
  clientID: string,
  sessionID: string,
  _version?: number | null,
};

export type ModelVideoConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  compressedVideoS3Key?: ModelStringInput | null,
  videoStatus?: ModelVideoStatusInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelVideoConditionInput | null > | null,
  or?: Array< ModelVideoConditionInput | null > | null,
  not?: ModelVideoConditionInput | null,
};

export type UpdateVideoInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  compressedVideoS3Key?: string | null,
  videoStatus?: VideoStatus | null,
  clientID?: string | null,
  sessionID?: string | null,
  _version?: number | null,
};

export type DeleteVideoInput = {
  id: string,
  _version?: number | null,
};

export type CreateAutoTranscriptInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  speechmodelID: string,
  transcriptSourceS3Key?: string | null,
  transcriptStandardizedS3Key?: string | null,
  transcriptTranslationS3Keys?: string | null,
  transcriptionStatus?: TranscriptionStatus | null,
  exportPlainTextS3Key?: string | null,
  exportJsonS3Key?: string | null,
  subtitlesSrtS3Key?: string | null,
  subtitlesVttS3Key?: string | null,
  subtitleStatus?: SubtitleStatus | null,
  diarizationS3Key?: string | null,
  summaryS3Key?: string | null,
  categoriesJson?: string | null,
  autotranscriptstatsID?: string | null,
  clientID: string,
  sessionID: string,
  _version?: number | null,
};

export type ModelAutoTranscriptConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  speechmodelID?: ModelIDInput | null,
  transcriptSourceS3Key?: ModelStringInput | null,
  transcriptStandardizedS3Key?: ModelStringInput | null,
  transcriptTranslationS3Keys?: ModelStringInput | null,
  transcriptionStatus?: ModelTranscriptionStatusInput | null,
  exportPlainTextS3Key?: ModelStringInput | null,
  exportJsonS3Key?: ModelStringInput | null,
  subtitlesSrtS3Key?: ModelStringInput | null,
  subtitlesVttS3Key?: ModelStringInput | null,
  subtitleStatus?: ModelSubtitleStatusInput | null,
  diarizationS3Key?: ModelStringInput | null,
  summaryS3Key?: ModelStringInput | null,
  categoriesJson?: ModelStringInput | null,
  autotranscriptstatsID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelAutoTranscriptConditionInput | null > | null,
  or?: Array< ModelAutoTranscriptConditionInput | null > | null,
  not?: ModelAutoTranscriptConditionInput | null,
};

export type UpdateAutoTranscriptInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  speechmodelID?: string | null,
  transcriptSourceS3Key?: string | null,
  transcriptStandardizedS3Key?: string | null,
  transcriptTranslationS3Keys?: string | null,
  transcriptionStatus?: TranscriptionStatus | null,
  exportPlainTextS3Key?: string | null,
  exportJsonS3Key?: string | null,
  subtitlesSrtS3Key?: string | null,
  subtitlesVttS3Key?: string | null,
  subtitleStatus?: SubtitleStatus | null,
  diarizationS3Key?: string | null,
  summaryS3Key?: string | null,
  categoriesJson?: string | null,
  autotranscriptstatsID?: string | null,
  clientID?: string | null,
  sessionID?: string | null,
  _version?: number | null,
};

export type DeleteAutoTranscriptInput = {
  id: string,
  _version?: number | null,
};

export type CreateGoldTranscriptInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  validationProgressStatus?: ValidationProgressStatus | null,
  transcriptS3Key?: string | null,
  subtitlesSrtS3Key?: string | null,
  subtitlesVttS3Key?: string | null,
  subtitleStatus?: SubtitleStatus | null,
  clientID: string,
  sessionID: string,
  _version?: number | null,
};

export type ModelGoldTranscriptConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  validationProgressStatus?: ModelValidationProgressStatusInput | null,
  transcriptS3Key?: ModelStringInput | null,
  subtitlesSrtS3Key?: ModelStringInput | null,
  subtitlesVttS3Key?: ModelStringInput | null,
  subtitleStatus?: ModelSubtitleStatusInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelGoldTranscriptConditionInput | null > | null,
  or?: Array< ModelGoldTranscriptConditionInput | null > | null,
  not?: ModelGoldTranscriptConditionInput | null,
};

export type UpdateGoldTranscriptInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  validationProgressStatus?: ValidationProgressStatus | null,
  transcriptS3Key?: string | null,
  subtitlesSrtS3Key?: string | null,
  subtitlesVttS3Key?: string | null,
  subtitleStatus?: SubtitleStatus | null,
  clientID?: string | null,
  sessionID?: string | null,
  _version?: number | null,
};

export type DeleteGoldTranscriptInput = {
  id: string,
  _version?: number | null,
};

export type CreateCustomTranscriptInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  transcriptS3Key?: string | null,
  subtitlesSrtS3Key?: string | null,
  subtitlesVttS3Key?: string | null,
  subtitleStatus?: SubtitleStatus | null,
  clientID: string,
  sessionID: string,
  _version?: number | null,
};

export type ModelCustomTranscriptConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  transcriptS3Key?: ModelStringInput | null,
  subtitlesSrtS3Key?: ModelStringInput | null,
  subtitlesVttS3Key?: ModelStringInput | null,
  subtitleStatus?: ModelSubtitleStatusInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelCustomTranscriptConditionInput | null > | null,
  or?: Array< ModelCustomTranscriptConditionInput | null > | null,
  not?: ModelCustomTranscriptConditionInput | null,
};

export type UpdateCustomTranscriptInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  transcriptS3Key?: string | null,
  subtitlesSrtS3Key?: string | null,
  subtitlesVttS3Key?: string | null,
  subtitleStatus?: SubtitleStatus | null,
  clientID?: string | null,
  sessionID?: string | null,
  _version?: number | null,
};

export type DeleteCustomTranscriptInput = {
  id: string,
  _version?: number | null,
};

export type CreateAutoTranscriptStatsInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  unknowns?: number | null,
  wordCount?: number | null,
  unknownPercentage?: number | null,
  globalConfidence?: number | null,
  knownConfidence?: number | null,
  autotranscriptID: string,
  clientID: string,
  sessionID: string,
  _version?: number | null,
};

export type ModelAutoTranscriptStatsConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  unknowns?: ModelIntInput | null,
  wordCount?: ModelIntInput | null,
  unknownPercentage?: ModelFloatInput | null,
  globalConfidence?: ModelFloatInput | null,
  knownConfidence?: ModelFloatInput | null,
  autotranscriptID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelAutoTranscriptStatsConditionInput | null > | null,
  or?: Array< ModelAutoTranscriptStatsConditionInput | null > | null,
  not?: ModelAutoTranscriptStatsConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateAutoTranscriptStatsInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  unknowns?: number | null,
  wordCount?: number | null,
  unknownPercentage?: number | null,
  globalConfidence?: number | null,
  knownConfidence?: number | null,
  autotranscriptID?: string | null,
  clientID?: string | null,
  sessionID?: string | null,
  _version?: number | null,
};

export type DeleteAutoTranscriptStatsInput = {
  id: string,
  _version?: number | null,
};

export type CreateSpeechModelInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  s3ImageUrl?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  provider: SpeechModelProvider,
  connectionString?: string | null,
  languageID: string,
  languagedialectID?: string | null,
  _version?: number | null,
};

export type ModelSpeechModelConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  s3ImageUrl?: ModelStringInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  provider?: ModelSpeechModelProviderInput | null,
  connectionString?: ModelStringInput | null,
  languageID?: ModelIDInput | null,
  languagedialectID?: ModelIDInput | null,
  and?: Array< ModelSpeechModelConditionInput | null > | null,
  or?: Array< ModelSpeechModelConditionInput | null > | null,
  not?: ModelSpeechModelConditionInput | null,
};

export type ModelSpeechModelProviderInput = {
  eq?: SpeechModelProvider | null,
  ne?: SpeechModelProvider | null,
};

export type UpdateSpeechModelInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  s3ImageUrl?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  provider?: SpeechModelProvider | null,
  connectionString?: string | null,
  languageID?: string | null,
  languagedialectID?: string | null,
  _version?: number | null,
};

export type DeleteSpeechModelInput = {
  id: string,
  _version?: number | null,
};

export type CreateLanguageLabelInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  createdAt?: string | null,
  submittedAt?: string | null,
  validatedAt?: string | null,
  submitteduserID?: string | null,
  validateduserID?: string | null,
  clientID: string,
  contextchannelID: string,
  sessionID: string,
  goldtranscriptID: string,
  starttime: string,
  endtime: string,
  speakerID?: string | null,
  languageID?: string | null,
  languagedialectID?: string | null,
  content: string,
  contentJson?: string | null,
  sentimentJson?: string | null,
  contentOrigin?: string | null,
  contentJsonOrigin?: string | null,
  sentimentJsonOrigin?: string | null,
  validationStatus?: ValidationStatus | null,
  feedbackMessage?: string | null,
  _version?: number | null,
};

export type ModelLanguageLabelConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  submittedAt?: ModelStringInput | null,
  validatedAt?: ModelStringInput | null,
  submitteduserID?: ModelIDInput | null,
  validateduserID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  goldtranscriptID?: ModelIDInput | null,
  starttime?: ModelStringInput | null,
  endtime?: ModelStringInput | null,
  speakerID?: ModelIDInput | null,
  languageID?: ModelIDInput | null,
  languagedialectID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  contentJson?: ModelStringInput | null,
  sentimentJson?: ModelStringInput | null,
  contentOrigin?: ModelStringInput | null,
  contentJsonOrigin?: ModelStringInput | null,
  sentimentJsonOrigin?: ModelStringInput | null,
  validationStatus?: ModelValidationStatusInput | null,
  feedbackMessage?: ModelStringInput | null,
  and?: Array< ModelLanguageLabelConditionInput | null > | null,
  or?: Array< ModelLanguageLabelConditionInput | null > | null,
  not?: ModelLanguageLabelConditionInput | null,
};

export type ModelValidationStatusInput = {
  eq?: ValidationStatus | null,
  ne?: ValidationStatus | null,
};

export type UpdateLanguageLabelInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  createdAt?: string | null,
  submittedAt?: string | null,
  validatedAt?: string | null,
  submitteduserID?: string | null,
  validateduserID?: string | null,
  clientID?: string | null,
  contextchannelID?: string | null,
  sessionID?: string | null,
  goldtranscriptID?: string | null,
  starttime?: string | null,
  endtime?: string | null,
  speakerID?: string | null,
  languageID?: string | null,
  languagedialectID?: string | null,
  content?: string | null,
  contentJson?: string | null,
  sentimentJson?: string | null,
  contentOrigin?: string | null,
  contentJsonOrigin?: string | null,
  sentimentJsonOrigin?: string | null,
  validationStatus?: ValidationStatus | null,
  feedbackMessage?: string | null,
  _version?: number | null,
};

export type DeleteLanguageLabelInput = {
  id: string,
  _version?: number | null,
};

export type CreateNLPModelInput = {
  id?: string | null,
  modelTrainedAt?: string | null,
  name?: string | null,
  huggingfaceRef?: string | null,
  pipelineParametersJson?: string | null,
  inferenceParametersJson?: string | null,
  otherParametersJson?: string | null,
  basemodelID?: string | null,
  nlpmodeltypeID: string,
  _version?: number | null,
};

export type ModelNLPModelConditionInput = {
  modelTrainedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  huggingfaceRef?: ModelStringInput | null,
  pipelineParametersJson?: ModelStringInput | null,
  inferenceParametersJson?: ModelStringInput | null,
  otherParametersJson?: ModelStringInput | null,
  basemodelID?: ModelIDInput | null,
  nlpmodeltypeID?: ModelIDInput | null,
  and?: Array< ModelNLPModelConditionInput | null > | null,
  or?: Array< ModelNLPModelConditionInput | null > | null,
  not?: ModelNLPModelConditionInput | null,
};

export type UpdateNLPModelInput = {
  id: string,
  modelTrainedAt?: string | null,
  name?: string | null,
  huggingfaceRef?: string | null,
  pipelineParametersJson?: string | null,
  inferenceParametersJson?: string | null,
  otherParametersJson?: string | null,
  basemodelID?: string | null,
  nlpmodeltypeID?: string | null,
  _version?: number | null,
};

export type DeleteNLPModelInput = {
  id: string,
  _version?: number | null,
};

export type CreateNLPModelTypeInput = {
  id?: string | null,
  name: string,
  huggingfaceTask: HuggingfaceTask,
  _version?: number | null,
};

export type ModelNLPModelTypeConditionInput = {
  name?: ModelStringInput | null,
  huggingfaceTask?: ModelHuggingfaceTaskInput | null,
  and?: Array< ModelNLPModelTypeConditionInput | null > | null,
  or?: Array< ModelNLPModelTypeConditionInput | null > | null,
  not?: ModelNLPModelTypeConditionInput | null,
};

export type ModelHuggingfaceTaskInput = {
  eq?: HuggingfaceTask | null,
  ne?: HuggingfaceTask | null,
};

export type UpdateNLPModelTypeInput = {
  id: string,
  name?: string | null,
  huggingfaceTask?: HuggingfaceTask | null,
  _version?: number | null,
};

export type DeleteNLPModelTypeInput = {
  id: string,
  _version?: number | null,
};

export type CreateSpeakerInput = {
  id?: string | null,
  allowedCognitoGroups?: Array< string | null > | null,
  firstName: string,
  infix?: string | null,
  lastName: string,
  gender: Gender,
  languageID: string,
  clientID: string,
  userID?: string | null,
  _version?: number | null,
};

export type ModelSpeakerConditionInput = {
  allowedCognitoGroups?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  infix?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  languageID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelSpeakerConditionInput | null > | null,
  or?: Array< ModelSpeakerConditionInput | null > | null,
  not?: ModelSpeakerConditionInput | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type UpdateSpeakerInput = {
  id: string,
  allowedCognitoGroups?: Array< string | null > | null,
  firstName?: string | null,
  infix?: string | null,
  lastName?: string | null,
  gender?: Gender | null,
  languageID?: string | null,
  clientID?: string | null,
  userID?: string | null,
  _version?: number | null,
};

export type DeleteSpeakerInput = {
  id: string,
  _version?: number | null,
};

export type CreateSpeakerLanguagesInput = {
  id?: string | null,
  createdAt?: string | null,
  isDefaultLanguage?: boolean | null,
  speakerID: string,
  languageID: string,
  _version?: number | null,
};

export type ModelSpeakerLanguagesConditionInput = {
  createdAt?: ModelStringInput | null,
  isDefaultLanguage?: ModelBooleanInput | null,
  speakerID?: ModelIDInput | null,
  languageID?: ModelIDInput | null,
  and?: Array< ModelSpeakerLanguagesConditionInput | null > | null,
  or?: Array< ModelSpeakerLanguagesConditionInput | null > | null,
  not?: ModelSpeakerLanguagesConditionInput | null,
};

export type UpdateSpeakerLanguagesInput = {
  id: string,
  createdAt?: string | null,
  isDefaultLanguage?: boolean | null,
  speakerID?: string | null,
  languageID?: string | null,
  _version?: number | null,
};

export type DeleteSpeakerLanguagesInput = {
  id: string,
  _version?: number | null,
};

export type CreateSpeakerLanguageLanguageDialectsInput = {
  id?: string | null,
  createdAt?: string | null,
  isDefaultLanguageDialect?: boolean | null,
  speakerlanguageID: string,
  languagedialectID: string,
  _version?: number | null,
};

export type ModelSpeakerLanguageLanguageDialectsConditionInput = {
  createdAt?: ModelStringInput | null,
  isDefaultLanguageDialect?: ModelBooleanInput | null,
  speakerlanguageID?: ModelIDInput | null,
  languagedialectID?: ModelIDInput | null,
  and?: Array< ModelSpeakerLanguageLanguageDialectsConditionInput | null > | null,
  or?: Array< ModelSpeakerLanguageLanguageDialectsConditionInput | null > | null,
  not?: ModelSpeakerLanguageLanguageDialectsConditionInput | null,
};

export type UpdateSpeakerLanguageLanguageDialectsInput = {
  id: string,
  createdAt?: string | null,
  isDefaultLanguageDialect?: boolean | null,
  speakerlanguageID?: string | null,
  languagedialectID?: string | null,
  _version?: number | null,
};

export type DeleteSpeakerLanguageLanguageDialectsInput = {
  id: string,
  _version?: number | null,
};

export type CreateLanguageInput = {
  id?: string | null,
  name: string,
  ISO_639_1?: string | null,
  ISO_639_2: string,
  ISO_639_3?: string | null,
  stopwords?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelLanguageConditionInput = {
  name?: ModelStringInput | null,
  ISO_639_1?: ModelStringInput | null,
  ISO_639_2?: ModelStringInput | null,
  ISO_639_3?: ModelStringInput | null,
  stopwords?: ModelStringInput | null,
  and?: Array< ModelLanguageConditionInput | null > | null,
  or?: Array< ModelLanguageConditionInput | null > | null,
  not?: ModelLanguageConditionInput | null,
};

export type UpdateLanguageInput = {
  id: string,
  name?: string | null,
  ISO_639_1?: string | null,
  ISO_639_2?: string | null,
  ISO_639_3?: string | null,
  stopwords?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteLanguageInput = {
  id: string,
  _version?: number | null,
};

export type CreateLanguageDialectInput = {
  id?: string | null,
  name: string,
  ISO_639_2_dialect: string,
  languageID: string,
  _version?: number | null,
};

export type ModelLanguageDialectConditionInput = {
  name?: ModelStringInput | null,
  ISO_639_2_dialect?: ModelStringInput | null,
  languageID?: ModelIDInput | null,
  and?: Array< ModelLanguageDialectConditionInput | null > | null,
  or?: Array< ModelLanguageDialectConditionInput | null > | null,
  not?: ModelLanguageDialectConditionInput | null,
};

export type UpdateLanguageDialectInput = {
  id: string,
  name?: string | null,
  ISO_639_2_dialect?: string | null,
  languageID?: string | null,
  _version?: number | null,
};

export type DeleteLanguageDialectInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  teamGroup?: string | null,
  isActive?: boolean | null,
  email?: string | null,
  needsEmailChange?: boolean | null,
  firstName?: string | null,
  infix?: string | null,
  lastName?: string | null,
  avatarS3Url?: string | null,
  speakerID?: string | null,
  _version?: number | null,
};

export type ModelUserConditionInput = {
  teamGroup?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  email?: ModelStringInput | null,
  needsEmailChange?: ModelBooleanInput | null,
  firstName?: ModelStringInput | null,
  infix?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  avatarS3Url?: ModelStringInput | null,
  speakerID?: ModelIDInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type UpdateUserInput = {
  id: string,
  teamGroup?: string | null,
  isActive?: boolean | null,
  email?: string | null,
  needsEmailChange?: boolean | null,
  firstName?: string | null,
  infix?: string | null,
  lastName?: string | null,
  avatarS3Url?: string | null,
  speakerID?: string | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserContextchannelsInput = {
  id?: string | null,
  createdAt?: string | null,
  isActive?: boolean | null,
  userID: string,
  contextchannelID: string,
  _version?: number | null,
};

export type ModelUserContextchannelsConditionInput = {
  createdAt?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  userID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  and?: Array< ModelUserContextchannelsConditionInput | null > | null,
  or?: Array< ModelUserContextchannelsConditionInput | null > | null,
  not?: ModelUserContextchannelsConditionInput | null,
};

export type UpdateUserContextchannelsInput = {
  id: string,
  createdAt?: string | null,
  isActive?: boolean | null,
  userID?: string | null,
  contextchannelID?: string | null,
  _version?: number | null,
};

export type DeleteUserContextchannelsInput = {
  id: string,
  _version?: number | null,
};

export type CreateContextchannelSubscriptionInput = {
  id?: string | null,
  createdAt?: string | null,
  userID: string,
  contextchannelID: string,
  _version?: number | null,
};

export type ModelContextchannelSubscriptionConditionInput = {
  createdAt?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  and?: Array< ModelContextchannelSubscriptionConditionInput | null > | null,
  or?: Array< ModelContextchannelSubscriptionConditionInput | null > | null,
  not?: ModelContextchannelSubscriptionConditionInput | null,
};

export type UpdateContextchannelSubscriptionInput = {
  id: string,
  createdAt?: string | null,
  userID?: string | null,
  contextchannelID?: string | null,
  _version?: number | null,
};

export type DeleteContextchannelSubscriptionInput = {
  id: string,
  _version?: number | null,
};

export type CreateSessionSubscriptionInput = {
  id?: string | null,
  createdAt?: string | null,
  userID: string,
  sessionID: string,
  _version?: number | null,
};

export type ModelSessionSubscriptionConditionInput = {
  createdAt?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelSessionSubscriptionConditionInput | null > | null,
  or?: Array< ModelSessionSubscriptionConditionInput | null > | null,
  not?: ModelSessionSubscriptionConditionInput | null,
};

export type UpdateSessionSubscriptionInput = {
  id: string,
  createdAt?: string | null,
  userID?: string | null,
  sessionID?: string | null,
  _version?: number | null,
};

export type DeleteSessionSubscriptionInput = {
  id: string,
  _version?: number | null,
};

export type CreateSpeakerSubscriptionInput = {
  id?: string | null,
  createdAt?: string | null,
  userID: string,
  speakerID: string,
  _version?: number | null,
};

export type ModelSpeakerSubscriptionConditionInput = {
  createdAt?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  speakerID?: ModelIDInput | null,
  and?: Array< ModelSpeakerSubscriptionConditionInput | null > | null,
  or?: Array< ModelSpeakerSubscriptionConditionInput | null > | null,
  not?: ModelSpeakerSubscriptionConditionInput | null,
};

export type UpdateSpeakerSubscriptionInput = {
  id: string,
  createdAt?: string | null,
  userID?: string | null,
  speakerID?: string | null,
  _version?: number | null,
};

export type DeleteSpeakerSubscriptionInput = {
  id: string,
  _version?: number | null,
};

export type GPTCompletionInput = {
  instruction?: string | null,
  examples?: Array< GPTCompletionExampleInput | null > | null,
  input: string,
  maxLength?: number | null,
  temperature?: number | null,
  topP?: number | null,
  frequencyPenalty?: number | null,
  presencePenalty?: number | null,
};

export type GPTCompletionExampleInput = {
  input?: string | null,
  output?: string | null,
};

export enum ExportFormat {
  JSON = "JSON",
  PLAINTEXT = "PLAINTEXT",
}


export type SearchableSessionFilterInput = {
  id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  clientID?: SearchableIDFilterInput | null,
  allowedCognitoGroups?: SearchableStringFilterInput | null,
  gnlpOutputs?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  summary?: SearchableStringFilterInput | null,
  keywords?: SearchableStringFilterInput | null,
  rewrittenS3Key?: SearchableStringFilterInput | null,
  condensed?: SearchableStringFilterInput | null,
  category?: SearchableStringFilterInput | null,
  sourceFileS3Key?: SearchableStringFilterInput | null,
  s3ImageUrl?: SearchableStringFilterInput | null,
  metadataID?: SearchableIDFilterInput | null,
  audioID?: SearchableIDFilterInput | null,
  videoID?: SearchableIDFilterInput | null,
  autotranscriptID?: SearchableIDFilterInput | null,
  goldtranscriptID?: SearchableIDFilterInput | null,
  customtranscriptID?: SearchableIDFilterInput | null,
  contextchannelID?: SearchableIDFilterInput | null,
  contactEmailAddresses?: SearchableStringFilterInput | null,
  extra?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  metadataStatus?: SearchableStringFilterInput | null,
  audioStatus?: SearchableStringFilterInput | null,
  videoStatus?: SearchableStringFilterInput | null,
  transcriptionStatus?: SearchableStringFilterInput | null,
  diarizationStatus?: SearchableStringFilterInput | null,
  subtitleStatus?: SearchableStringFilterInput | null,
  gnlpStatus?: SearchableStringFilterInput | null,
  nlpStatus?: SearchableStringFilterInput | null,
  validationProgressStatus?: SearchableStringFilterInput | null,
  and?: Array< SearchableSessionFilterInput | null > | null,
  or?: Array< SearchableSessionFilterInput | null > | null,
  not?: SearchableSessionFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableSessionSortInput = {
  field?: SearchableSessionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableSessionSortableFields {
  id = "id",
  createdAt = "createdAt",
  name = "name",
  clientID = "clientID",
  allowedCognitoGroups = "allowedCognitoGroups",
  gnlpOutputs = "gnlpOutputs",
  title = "title",
  summary = "summary",
  keywords = "keywords",
  rewrittenS3Key = "rewrittenS3Key",
  condensed = "condensed",
  category = "category",
  sourceFileS3Key = "sourceFileS3Key",
  s3ImageUrl = "s3ImageUrl",
  metadataID = "metadataID",
  audioID = "audioID",
  videoID = "videoID",
  autotranscriptID = "autotranscriptID",
  goldtranscriptID = "goldtranscriptID",
  customtranscriptID = "customtranscriptID",
  contextchannelID = "contextchannelID",
  contactEmailAddresses = "contactEmailAddresses",
  extra = "extra",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableSessionAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableSessionAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableSessionAggregateField {
  id = "id",
  createdAt = "createdAt",
  name = "name",
  clientID = "clientID",
  allowedCognitoGroups = "allowedCognitoGroups",
  gnlpOutputs = "gnlpOutputs",
  title = "title",
  summary = "summary",
  keywords = "keywords",
  rewrittenS3Key = "rewrittenS3Key",
  condensed = "condensed",
  category = "category",
  sourceFileS3Key = "sourceFileS3Key",
  s3ImageUrl = "s3ImageUrl",
  metadataID = "metadataID",
  audioID = "audioID",
  videoID = "videoID",
  autotranscriptID = "autotranscriptID",
  goldtranscriptID = "goldtranscriptID",
  customtranscriptID = "customtranscriptID",
  metadataStatus = "metadataStatus",
  audioStatus = "audioStatus",
  videoStatus = "videoStatus",
  transcriptionStatus = "transcriptionStatus",
  diarizationStatus = "diarizationStatus",
  subtitleStatus = "subtitleStatus",
  gnlpStatus = "gnlpStatus",
  nlpStatus = "nlpStatus",
  contextchannelID = "contextchannelID",
  contactEmailAddresses = "contactEmailAddresses",
  validationProgressStatus = "validationProgressStatus",
  extra = "extra",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableSessionConnection = {
  __typename: "SearchableSessionConnection",
  items:  Array<Session | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type SearchableNLPOutputFilterInput = {
  id?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  allowedCognitoGroups?: SearchableStringFilterInput | null,
  clientID?: SearchableIDFilterInput | null,
  contextchannelID?: SearchableIDFilterInput | null,
  sessionID?: SearchableIDFilterInput | null,
  nlpstepID?: SearchableIDFilterInput | null,
  nlpmodelversionID?: SearchableIDFilterInput | null,
  inputnlpoutputID?: SearchableIDFilterInput | null,
  key?: SearchableStringFilterInput | null,
  languageID?: SearchableIDFilterInput | null,
  value?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  outputType?: SearchableStringFilterInput | null,
  and?: Array< SearchableNLPOutputFilterInput | null > | null,
  or?: Array< SearchableNLPOutputFilterInput | null > | null,
  not?: SearchableNLPOutputFilterInput | null,
};

export type SearchableNLPOutputSortInput = {
  field?: SearchableNLPOutputSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableNLPOutputSortableFields {
  id = "id",
  createdAt = "createdAt",
  allowedCognitoGroups = "allowedCognitoGroups",
  clientID = "clientID",
  contextchannelID = "contextchannelID",
  sessionID = "sessionID",
  nlpstepID = "nlpstepID",
  nlpmodelversionID = "nlpmodelversionID",
  inputnlpoutputID = "inputnlpoutputID",
  key = "key",
  languageID = "languageID",
  value = "value",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableNLPOutputAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableNLPOutputAggregateField,
};

export enum SearchableNLPOutputAggregateField {
  id = "id",
  createdAt = "createdAt",
  allowedCognitoGroups = "allowedCognitoGroups",
  clientID = "clientID",
  contextchannelID = "contextchannelID",
  sessionID = "sessionID",
  nlpstepID = "nlpstepID",
  nlpmodelversionID = "nlpmodelversionID",
  inputnlpoutputID = "inputnlpoutputID",
  key = "key",
  outputType = "outputType",
  languageID = "languageID",
  value = "value",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableNLPOutputConnection = {
  __typename: "SearchableNLPOutputConnection",
  items:  Array<NLPOutput | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableLanguageLabelFilterInput = {
  id?: SearchableIDFilterInput | null,
  allowedCognitoGroups?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  submittedAt?: SearchableStringFilterInput | null,
  validatedAt?: SearchableStringFilterInput | null,
  submitteduserID?: SearchableIDFilterInput | null,
  validateduserID?: SearchableIDFilterInput | null,
  clientID?: SearchableIDFilterInput | null,
  contextchannelID?: SearchableIDFilterInput | null,
  sessionID?: SearchableIDFilterInput | null,
  goldtranscriptID?: SearchableIDFilterInput | null,
  starttime?: SearchableStringFilterInput | null,
  endtime?: SearchableStringFilterInput | null,
  speakerID?: SearchableIDFilterInput | null,
  languageID?: SearchableIDFilterInput | null,
  languagedialectID?: SearchableIDFilterInput | null,
  content?: SearchableStringFilterInput | null,
  contentJson?: SearchableStringFilterInput | null,
  sentimentJson?: SearchableStringFilterInput | null,
  contentOrigin?: SearchableStringFilterInput | null,
  contentJsonOrigin?: SearchableStringFilterInput | null,
  sentimentJsonOrigin?: SearchableStringFilterInput | null,
  feedbackMessage?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  validationStatus?: SearchableStringFilterInput | null,
  and?: Array< SearchableLanguageLabelFilterInput | null > | null,
  or?: Array< SearchableLanguageLabelFilterInput | null > | null,
  not?: SearchableLanguageLabelFilterInput | null,
};

export type SearchableLanguageLabelSortInput = {
  field?: SearchableLanguageLabelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableLanguageLabelSortableFields {
  id = "id",
  allowedCognitoGroups = "allowedCognitoGroups",
  createdAt = "createdAt",
  submittedAt = "submittedAt",
  validatedAt = "validatedAt",
  submitteduserID = "submitteduserID",
  validateduserID = "validateduserID",
  clientID = "clientID",
  contextchannelID = "contextchannelID",
  sessionID = "sessionID",
  goldtranscriptID = "goldtranscriptID",
  starttime = "starttime",
  endtime = "endtime",
  speakerID = "speakerID",
  languageID = "languageID",
  languagedialectID = "languagedialectID",
  content = "content",
  contentJson = "contentJson",
  sentimentJson = "sentimentJson",
  contentOrigin = "contentOrigin",
  contentJsonOrigin = "contentJsonOrigin",
  sentimentJsonOrigin = "sentimentJsonOrigin",
  feedbackMessage = "feedbackMessage",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableLanguageLabelAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableLanguageLabelAggregateField,
};

export enum SearchableLanguageLabelAggregateField {
  id = "id",
  allowedCognitoGroups = "allowedCognitoGroups",
  createdAt = "createdAt",
  submittedAt = "submittedAt",
  validatedAt = "validatedAt",
  submitteduserID = "submitteduserID",
  validateduserID = "validateduserID",
  clientID = "clientID",
  contextchannelID = "contextchannelID",
  sessionID = "sessionID",
  goldtranscriptID = "goldtranscriptID",
  starttime = "starttime",
  endtime = "endtime",
  speakerID = "speakerID",
  languageID = "languageID",
  languagedialectID = "languagedialectID",
  content = "content",
  contentJson = "contentJson",
  sentimentJson = "sentimentJson",
  contentOrigin = "contentOrigin",
  contentJsonOrigin = "contentJsonOrigin",
  sentimentJsonOrigin = "sentimentJsonOrigin",
  validationStatus = "validationStatus",
  feedbackMessage = "feedbackMessage",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableLanguageLabelConnection = {
  __typename: "SearchableLanguageLabelConnection",
  items:  Array<LanguageLabel | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelEnvironmentStatusFilterInput = {
  id?: ModelIDInput | null,
  underMaintanence?: ModelBooleanInput | null,
  notes?: ModelStringInput | null,
  and?: Array< ModelEnvironmentStatusFilterInput | null > | null,
  or?: Array< ModelEnvironmentStatusFilterInput | null > | null,
  not?: ModelEnvironmentStatusFilterInput | null,
};

export type ModelEnvironmentStatusConnection = {
  __typename: "ModelEnvironmentStatusConnection",
  items:  Array<EnvironmentStatus | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  displayName?: ModelStringInput | null,
  cognitoGroupName?: ModelStringInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWordTaggerModuleOutputFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  categories?: ModelStringInput | null,
  and?: Array< ModelWordTaggerModuleOutputFilterInput | null > | null,
  or?: Array< ModelWordTaggerModuleOutputFilterInput | null > | null,
  not?: ModelWordTaggerModuleOutputFilterInput | null,
};

export type ModelContextchannelFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  name?: ModelStringInput | null,
  s3ImageUrl?: ModelStringInput | null,
  reference_id?: ModelStringInput | null,
  contactEmailAddresses?: ModelStringInput | null,
  dynamicConfig?: ModelStringInput | null,
  gnlpTasks?: ModelStringInput | null,
  speechmodelID?: ModelIDInput | null,
  precomputedWordCountsS3Key?: ModelStringInput | null,
  posmodelID?: ModelIDInput | null,
  nermodelID?: ModelIDInput | null,
  summarymodelID?: ModelIDInput | null,
  sentimentmodelID?: ModelIDInput | null,
  categorymodelID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  and?: Array< ModelContextchannelFilterInput | null > | null,
  or?: Array< ModelContextchannelFilterInput | null > | null,
  not?: ModelContextchannelFilterInput | null,
};

export type ModelContextchannelLogFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  type?: ModelContextchannelLogTypeInput | null,
  and?: Array< ModelContextchannelLogFilterInput | null > | null,
  or?: Array< ModelContextchannelLogFilterInput | null > | null,
  not?: ModelContextchannelLogFilterInput | null,
};

export type ModelSessionFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  gnlpOutputs?: ModelStringInput | null,
  title?: ModelStringInput | null,
  summary?: ModelStringInput | null,
  keywords?: ModelStringInput | null,
  rewrittenS3Key?: ModelStringInput | null,
  condensed?: ModelStringInput | null,
  category?: ModelStringInput | null,
  sourceFileS3Key?: ModelStringInput | null,
  s3ImageUrl?: ModelStringInput | null,
  metadataID?: ModelIDInput | null,
  audioID?: ModelIDInput | null,
  videoID?: ModelIDInput | null,
  autotranscriptID?: ModelIDInput | null,
  goldtranscriptID?: ModelIDInput | null,
  customtranscriptID?: ModelIDInput | null,
  metadataStatus?: ModelMetadataStatusInput | null,
  audioStatus?: ModelAudioStatusInput | null,
  videoStatus?: ModelVideoStatusInput | null,
  transcriptionStatus?: ModelTranscriptionStatusInput | null,
  diarizationStatus?: ModelDiarizationStatusInput | null,
  subtitleStatus?: ModelSubtitleStatusInput | null,
  gnlpStatus?: ModelGNLPStatusInput | null,
  nlpStatus?: ModelNLPStatusInput | null,
  contextchannelID?: ModelIDInput | null,
  contactEmailAddresses?: ModelStringInput | null,
  validationProgressStatus?: ModelValidationProgressStatusInput | null,
  extra?: ModelStringInput | null,
  and?: Array< ModelSessionFilterInput | null > | null,
  or?: Array< ModelSessionFilterInput | null > | null,
  not?: ModelSessionFilterInput | null,
};

export type ModelNLPModelVersionFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  name?: ModelStringInput | null,
  HFModelType?: ModelHFModelTypeInput | null,
  parentmodelID?: ModelIDInput | null,
  modelVersionType?: ModelModelVersionTypeInput | null,
  checkpointKey?: ModelStringInput | null,
  checkpointStorageType?: ModelCheckpointStorageTypeInput | null,
  inputlanguageID?: ModelIDInput | null,
  outputlanguageID?: ModelIDInput | null,
  outputType?: ModelNLPOutputTypeInput | null,
  and?: Array< ModelNLPModelVersionFilterInput | null > | null,
  or?: Array< ModelNLPModelVersionFilterInput | null > | null,
  not?: ModelNLPModelVersionFilterInput | null,
};

export type ModelNLPStepFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  clientID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  nlpmodelversionID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  outputKey?: ModelStringInput | null,
  inputstepID?: ModelIDInput | null,
  and?: Array< ModelNLPStepFilterInput | null > | null,
  or?: Array< ModelNLPStepFilterInput | null > | null,
  not?: ModelNLPStepFilterInput | null,
};

export type ModelNLPOutputFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  nlpstepID?: ModelIDInput | null,
  nlpmodelversionID?: ModelIDInput | null,
  inputnlpoutputID?: ModelIDInput | null,
  key?: ModelStringInput | null,
  outputType?: ModelNLPOutputTypeInput | null,
  languageID?: ModelIDInput | null,
  value?: ModelStringInput | null,
  and?: Array< ModelNLPOutputFilterInput | null > | null,
  or?: Array< ModelNLPOutputFilterInput | null > | null,
  not?: ModelNLPOutputFilterInput | null,
};

export type ModelClusteringModuleOutputFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  clientID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  key?: ModelStringInput | null,
  leafIDs?: ModelIDInput | null,
  linkageMatrixS3Url?: ModelStringInput | null,
  and?: Array< ModelClusteringModuleOutputFilterInput | null > | null,
  or?: Array< ModelClusteringModuleOutputFilterInput | null > | null,
  not?: ModelClusteringModuleOutputFilterInput | null,
};

export type ModelMetadataFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  metadataS3Key?: ModelStringInput | null,
  durationSeconds?: ModelFloatInput | null,
  metadataStatus?: ModelMetadataStatusInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelMetadataFilterInput | null > | null,
  or?: Array< ModelMetadataFilterInput | null > | null,
  not?: ModelMetadataFilterInput | null,
};

export type ModelMetadataConnection = {
  __typename: "ModelMetadataConnection",
  items:  Array<Metadata | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAudioFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  uncompressedAudioS3Key?: ModelStringInput | null,
  compressedAudioS3Key?: ModelStringInput | null,
  waveformDataS3Key?: ModelStringInput | null,
  audioStatus?: ModelAudioStatusInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelAudioFilterInput | null > | null,
  or?: Array< ModelAudioFilterInput | null > | null,
  not?: ModelAudioFilterInput | null,
};

export type ModelAudioConnection = {
  __typename: "ModelAudioConnection",
  items:  Array<Audio | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelVideoFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  compressedVideoS3Key?: ModelStringInput | null,
  videoStatus?: ModelVideoStatusInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelVideoFilterInput | null > | null,
  or?: Array< ModelVideoFilterInput | null > | null,
  not?: ModelVideoFilterInput | null,
};

export type ModelVideoConnection = {
  __typename: "ModelVideoConnection",
  items:  Array<Video | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAutoTranscriptFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  speechmodelID?: ModelIDInput | null,
  transcriptSourceS3Key?: ModelStringInput | null,
  transcriptStandardizedS3Key?: ModelStringInput | null,
  transcriptTranslationS3Keys?: ModelStringInput | null,
  transcriptionStatus?: ModelTranscriptionStatusInput | null,
  exportPlainTextS3Key?: ModelStringInput | null,
  exportJsonS3Key?: ModelStringInput | null,
  subtitlesSrtS3Key?: ModelStringInput | null,
  subtitlesVttS3Key?: ModelStringInput | null,
  subtitleStatus?: ModelSubtitleStatusInput | null,
  diarizationS3Key?: ModelStringInput | null,
  summaryS3Key?: ModelStringInput | null,
  categoriesJson?: ModelStringInput | null,
  autotranscriptstatsID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelAutoTranscriptFilterInput | null > | null,
  or?: Array< ModelAutoTranscriptFilterInput | null > | null,
  not?: ModelAutoTranscriptFilterInput | null,
};

export type ModelAutoTranscriptConnection = {
  __typename: "ModelAutoTranscriptConnection",
  items:  Array<AutoTranscript | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGoldTranscriptFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  validationProgressStatus?: ModelValidationProgressStatusInput | null,
  transcriptS3Key?: ModelStringInput | null,
  subtitlesSrtS3Key?: ModelStringInput | null,
  subtitlesVttS3Key?: ModelStringInput | null,
  subtitleStatus?: ModelSubtitleStatusInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelGoldTranscriptFilterInput | null > | null,
  or?: Array< ModelGoldTranscriptFilterInput | null > | null,
  not?: ModelGoldTranscriptFilterInput | null,
};

export type ModelGoldTranscriptConnection = {
  __typename: "ModelGoldTranscriptConnection",
  items:  Array<GoldTranscript | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCustomTranscriptFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  transcriptS3Key?: ModelStringInput | null,
  subtitlesSrtS3Key?: ModelStringInput | null,
  subtitlesVttS3Key?: ModelStringInput | null,
  subtitleStatus?: ModelSubtitleStatusInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelCustomTranscriptFilterInput | null > | null,
  or?: Array< ModelCustomTranscriptFilterInput | null > | null,
  not?: ModelCustomTranscriptFilterInput | null,
};

export type ModelCustomTranscriptConnection = {
  __typename: "ModelCustomTranscriptConnection",
  items:  Array<CustomTranscript | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAutoTranscriptStatsFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  unknowns?: ModelIntInput | null,
  wordCount?: ModelIntInput | null,
  unknownPercentage?: ModelFloatInput | null,
  globalConfidence?: ModelFloatInput | null,
  knownConfidence?: ModelFloatInput | null,
  autotranscriptID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelAutoTranscriptStatsFilterInput | null > | null,
  or?: Array< ModelAutoTranscriptStatsFilterInput | null > | null,
  not?: ModelAutoTranscriptStatsFilterInput | null,
};

export type ModelAutoTranscriptStatsConnection = {
  __typename: "ModelAutoTranscriptStatsConnection",
  items:  Array<AutoTranscriptStats | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSpeechModelFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  s3ImageUrl?: ModelStringInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  provider?: ModelSpeechModelProviderInput | null,
  connectionString?: ModelStringInput | null,
  languageID?: ModelIDInput | null,
  languagedialectID?: ModelIDInput | null,
  and?: Array< ModelSpeechModelFilterInput | null > | null,
  or?: Array< ModelSpeechModelFilterInput | null > | null,
  not?: ModelSpeechModelFilterInput | null,
};

export type ModelLanguageLabelFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  submittedAt?: ModelStringInput | null,
  validatedAt?: ModelStringInput | null,
  submitteduserID?: ModelIDInput | null,
  validateduserID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  goldtranscriptID?: ModelIDInput | null,
  starttime?: ModelStringInput | null,
  endtime?: ModelStringInput | null,
  speakerID?: ModelIDInput | null,
  languageID?: ModelIDInput | null,
  languagedialectID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  contentJson?: ModelStringInput | null,
  sentimentJson?: ModelStringInput | null,
  contentOrigin?: ModelStringInput | null,
  contentJsonOrigin?: ModelStringInput | null,
  sentimentJsonOrigin?: ModelStringInput | null,
  validationStatus?: ModelValidationStatusInput | null,
  feedbackMessage?: ModelStringInput | null,
  and?: Array< ModelLanguageLabelFilterInput | null > | null,
  or?: Array< ModelLanguageLabelFilterInput | null > | null,
  not?: ModelLanguageLabelFilterInput | null,
};

export type ModelNLPModelFilterInput = {
  id?: ModelIDInput | null,
  modelTrainedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  huggingfaceRef?: ModelStringInput | null,
  pipelineParametersJson?: ModelStringInput | null,
  inferenceParametersJson?: ModelStringInput | null,
  otherParametersJson?: ModelStringInput | null,
  basemodelID?: ModelIDInput | null,
  nlpmodeltypeID?: ModelIDInput | null,
  and?: Array< ModelNLPModelFilterInput | null > | null,
  or?: Array< ModelNLPModelFilterInput | null > | null,
  not?: ModelNLPModelFilterInput | null,
};

export type ModelNLPModelTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  huggingfaceTask?: ModelHuggingfaceTaskInput | null,
  and?: Array< ModelNLPModelTypeFilterInput | null > | null,
  or?: Array< ModelNLPModelTypeFilterInput | null > | null,
  not?: ModelNLPModelTypeFilterInput | null,
};

export type ModelNLPModelTypeConnection = {
  __typename: "ModelNLPModelTypeConnection",
  items:  Array<NLPModelType | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSpeakerFilterInput = {
  id?: ModelIDInput | null,
  allowedCognitoGroups?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  infix?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  languageID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelSpeakerFilterInput | null > | null,
  or?: Array< ModelSpeakerFilterInput | null > | null,
  not?: ModelSpeakerFilterInput | null,
};

export type ModelLanguageFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  ISO_639_1?: ModelStringInput | null,
  ISO_639_2?: ModelStringInput | null,
  ISO_639_3?: ModelStringInput | null,
  stopwords?: ModelStringInput | null,
  and?: Array< ModelLanguageFilterInput | null > | null,
  or?: Array< ModelLanguageFilterInput | null > | null,
  not?: ModelLanguageFilterInput | null,
};

export type ModelLanguageConnection = {
  __typename: "ModelLanguageConnection",
  items:  Array<Language | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLanguageDialectFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  ISO_639_2_dialect?: ModelStringInput | null,
  languageID?: ModelIDInput | null,
  and?: Array< ModelLanguageDialectFilterInput | null > | null,
  or?: Array< ModelLanguageDialectFilterInput | null > | null,
  not?: ModelLanguageDialectFilterInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  teamGroup?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  email?: ModelStringInput | null,
  needsEmailChange?: ModelBooleanInput | null,
  firstName?: ModelStringInput | null,
  infix?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  avatarS3Url?: ModelStringInput | null,
  speakerID?: ModelIDInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelContextchannelSubscriptionFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  contextchannelID?: ModelIDInput | null,
  and?: Array< ModelContextchannelSubscriptionFilterInput | null > | null,
  or?: Array< ModelContextchannelSubscriptionFilterInput | null > | null,
  not?: ModelContextchannelSubscriptionFilterInput | null,
};

export type ModelSessionSubscriptionFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  sessionID?: ModelIDInput | null,
  and?: Array< ModelSessionSubscriptionFilterInput | null > | null,
  or?: Array< ModelSessionSubscriptionFilterInput | null > | null,
  not?: ModelSessionSubscriptionFilterInput | null,
};

export type ModelSpeakerSubscriptionFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  speakerID?: ModelIDInput | null,
  and?: Array< ModelSpeakerSubscriptionFilterInput | null > | null,
  or?: Array< ModelSpeakerSubscriptionFilterInput | null > | null,
  not?: ModelSpeakerSubscriptionFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyConditionInput = {
  eq?: ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyInput | null,
  le?: ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyInput | null,
  lt?: ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyInput | null,
  ge?: ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyInput | null,
  gt?: ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyInput | null,
  between?: Array< ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyInput | null > | null,
  beginsWith?: ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyInput | null,
};

export type ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyInput = {
  type?: ContextchannelLogType | null,
  createdAt?: string | null,
};

export type CreateUserInCognitoMutationVariables = {
  input: CreateUserInCognitoInput,
};

export type CreateUserInCognitoMutation = {
  createUserInCognito?:  {
    __typename: "User",
    id: string,
    teamGroup?: string | null,
    isActive?: boolean | null,
    email?: string | null,
    needsEmailChange?: boolean | null,
    firstName?: string | null,
    infix?: string | null,
    lastName?: string | null,
    avatarS3Url?: string | null,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    submittedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validatedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedContextchannels?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelSubscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SessionSubscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeakerSubscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCognitoGroupsForUserMutationVariables = {
  input: UpdateUserInCognitoInput,
};

export type UpdateCognitoGroupsForUserMutation = {
  updateCognitoGroupsForUser?:  {
    __typename: "CognitoGroupsForUser",
    teamGroup?: string | null,
    clientGroups?: Array< string | null > | null,
    roleGroups?: Array< string | null > | null,
  } | null,
};

export type DisableUserInCognitoMutationVariables = {
  input: DisableUserInCognitoInput,
};

export type DisableUserInCognitoMutation = {
  disableUserInCognito?:  {
    __typename: "User",
    id: string,
    teamGroup?: string | null,
    isActive?: boolean | null,
    email?: string | null,
    needsEmailChange?: boolean | null,
    firstName?: string | null,
    infix?: string | null,
    lastName?: string | null,
    avatarS3Url?: string | null,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    submittedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validatedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedContextchannels?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelSubscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SessionSubscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeakerSubscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEnvironmentStatusMutationVariables = {
  input: CreateEnvironmentStatusInput,
  condition?: ModelEnvironmentStatusConditionInput | null,
};

export type CreateEnvironmentStatusMutation = {
  createEnvironmentStatus?:  {
    __typename: "EnvironmentStatus",
    id: string,
    underMaintanence: boolean,
    notes?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEnvironmentStatusMutationVariables = {
  input: UpdateEnvironmentStatusInput,
  condition?: ModelEnvironmentStatusConditionInput | null,
};

export type UpdateEnvironmentStatusMutation = {
  updateEnvironmentStatus?:  {
    __typename: "EnvironmentStatus",
    id: string,
    underMaintanence: boolean,
    notes?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEnvironmentStatusMutationVariables = {
  input: DeleteEnvironmentStatusInput,
  condition?: ModelEnvironmentStatusConditionInput | null,
};

export type DeleteEnvironmentStatusMutation = {
  deleteEnvironmentStatus?:  {
    __typename: "EnvironmentStatus",
    id: string,
    underMaintanence: boolean,
    notes?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?:  {
    __typename: "Client",
    id: string,
    displayName: string,
    cognitoGroupName: string,
    Contextchannels?:  {
      __typename: "ModelContextchannelConnection",
      items:  Array< {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerConnection",
      items:  Array< {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    id: string,
    displayName: string,
    cognitoGroupName: string,
    Contextchannels?:  {
      __typename: "ModelContextchannelConnection",
      items:  Array< {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerConnection",
      items:  Array< {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    id: string,
    displayName: string,
    cognitoGroupName: string,
    Contextchannels?:  {
      __typename: "ModelContextchannelConnection",
      items:  Array< {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerConnection",
      items:  Array< {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWordTaggerModuleOutputMutationVariables = {
  input: CreateWordTaggerModuleOutputInput,
  condition?: ModelWordTaggerModuleOutputConditionInput | null,
};

export type CreateWordTaggerModuleOutputMutation = {
  createWordTaggerModuleOutput?:  {
    __typename: "WordTaggerModuleOutput",
    id: string,
    createdAt: string,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    taggedWords?:  Array< {
      __typename: "TaggedWord",
      text?: string | null,
      tag?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWordTaggerModuleOutputMutationVariables = {
  input: UpdateWordTaggerModuleOutputInput,
  condition?: ModelWordTaggerModuleOutputConditionInput | null,
};

export type UpdateWordTaggerModuleOutputMutation = {
  updateWordTaggerModuleOutput?:  {
    __typename: "WordTaggerModuleOutput",
    id: string,
    createdAt: string,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    taggedWords?:  Array< {
      __typename: "TaggedWord",
      text?: string | null,
      tag?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWordTaggerModuleOutputMutationVariables = {
  input: DeleteWordTaggerModuleOutputInput,
  condition?: ModelWordTaggerModuleOutputConditionInput | null,
};

export type DeleteWordTaggerModuleOutputMutation = {
  deleteWordTaggerModuleOutput?:  {
    __typename: "WordTaggerModuleOutput",
    id: string,
    createdAt: string,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    taggedWords?:  Array< {
      __typename: "TaggedWord",
      text?: string | null,
      tag?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContextchannelMutationVariables = {
  input: CreateContextchannelInput,
  condition?: ModelContextchannelConditionInput | null,
};

export type CreateContextchannelMutation = {
  createContextchannel?:  {
    __typename: "Contextchannel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    s3ImageUrl?: string | null,
    reference_id?: string | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUsers?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dynamicConfig?: string | null,
    wordTaggerModuleSettings?:  {
      __typename: "WordTaggerModuleSettings",
      enabled?: boolean | null,
      categories?: Array< string | null > | null,
    } | null,
    gnlpConfig?:  Array< {
      __typename: "GNLPPrompt",
      prompt: string,
      maxTokens: number,
      temperature: number,
      key: string,
      frequencyPenalty: number,
      presencePenalty: number,
      languageISO?: string | null,
    } | null > | null,
    gnlpTasks?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    precomputedWordCountsS3Key?: string | null,
    ClusteringModuleOutputs?:  {
      __typename: "ModelClusteringModuleOutputConnection",
      items:  Array< {
        __typename: "ClusteringModuleOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        key: string,
        leafIDs: Array< string | null >,
        linkageMatrixS3Url: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    posmodelID?: string | null,
    POSModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nermodelID?: string | null,
    NERModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    summarymodelID?: string | null,
    SummaryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sentimentmodelID?: string | null,
    SentimentModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categorymodelID?: string | null,
    CategoryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelLogs?:  {
      __typename: "ModelContextchannelLogConnection",
      items:  Array< {
        __typename: "ContextchannelLog",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        clientID: string,
        contextchannelID: string,
        type: ContextchannelLogType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContextchannelMutationVariables = {
  input: UpdateContextchannelInput,
  condition?: ModelContextchannelConditionInput | null,
};

export type UpdateContextchannelMutation = {
  updateContextchannel?:  {
    __typename: "Contextchannel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    s3ImageUrl?: string | null,
    reference_id?: string | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUsers?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dynamicConfig?: string | null,
    wordTaggerModuleSettings?:  {
      __typename: "WordTaggerModuleSettings",
      enabled?: boolean | null,
      categories?: Array< string | null > | null,
    } | null,
    gnlpConfig?:  Array< {
      __typename: "GNLPPrompt",
      prompt: string,
      maxTokens: number,
      temperature: number,
      key: string,
      frequencyPenalty: number,
      presencePenalty: number,
      languageISO?: string | null,
    } | null > | null,
    gnlpTasks?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    precomputedWordCountsS3Key?: string | null,
    ClusteringModuleOutputs?:  {
      __typename: "ModelClusteringModuleOutputConnection",
      items:  Array< {
        __typename: "ClusteringModuleOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        key: string,
        leafIDs: Array< string | null >,
        linkageMatrixS3Url: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    posmodelID?: string | null,
    POSModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nermodelID?: string | null,
    NERModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    summarymodelID?: string | null,
    SummaryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sentimentmodelID?: string | null,
    SentimentModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categorymodelID?: string | null,
    CategoryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelLogs?:  {
      __typename: "ModelContextchannelLogConnection",
      items:  Array< {
        __typename: "ContextchannelLog",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        clientID: string,
        contextchannelID: string,
        type: ContextchannelLogType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContextchannelMutationVariables = {
  input: DeleteContextchannelInput,
  condition?: ModelContextchannelConditionInput | null,
};

export type DeleteContextchannelMutation = {
  deleteContextchannel?:  {
    __typename: "Contextchannel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    s3ImageUrl?: string | null,
    reference_id?: string | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUsers?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dynamicConfig?: string | null,
    wordTaggerModuleSettings?:  {
      __typename: "WordTaggerModuleSettings",
      enabled?: boolean | null,
      categories?: Array< string | null > | null,
    } | null,
    gnlpConfig?:  Array< {
      __typename: "GNLPPrompt",
      prompt: string,
      maxTokens: number,
      temperature: number,
      key: string,
      frequencyPenalty: number,
      presencePenalty: number,
      languageISO?: string | null,
    } | null > | null,
    gnlpTasks?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    precomputedWordCountsS3Key?: string | null,
    ClusteringModuleOutputs?:  {
      __typename: "ModelClusteringModuleOutputConnection",
      items:  Array< {
        __typename: "ClusteringModuleOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        key: string,
        leafIDs: Array< string | null >,
        linkageMatrixS3Url: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    posmodelID?: string | null,
    POSModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nermodelID?: string | null,
    NERModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    summarymodelID?: string | null,
    SummaryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sentimentmodelID?: string | null,
    SentimentModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categorymodelID?: string | null,
    CategoryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelLogs?:  {
      __typename: "ModelContextchannelLogConnection",
      items:  Array< {
        __typename: "ContextchannelLog",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        clientID: string,
        contextchannelID: string,
        type: ContextchannelLogType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContextchannelLanguagesMutationVariables = {
  input: CreateContextchannelLanguagesInput,
  condition?: ModelContextchannelLanguagesConditionInput | null,
};

export type CreateContextchannelLanguagesMutation = {
  createContextchannelLanguages?:  {
    __typename: "ContextchannelLanguages",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContextchannelLanguagesMutationVariables = {
  input: UpdateContextchannelLanguagesInput,
  condition?: ModelContextchannelLanguagesConditionInput | null,
};

export type UpdateContextchannelLanguagesMutation = {
  updateContextchannelLanguages?:  {
    __typename: "ContextchannelLanguages",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContextchannelLanguagesMutationVariables = {
  input: DeleteContextchannelLanguagesInput,
  condition?: ModelContextchannelLanguagesConditionInput | null,
};

export type DeleteContextchannelLanguagesMutation = {
  deleteContextchannelLanguages?:  {
    __typename: "ContextchannelLanguages",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContextchannelLanguageLanguageDialectsMutationVariables = {
  input: CreateContextchannelLanguageLanguageDialectsInput,
  condition?: ModelContextchannelLanguageLanguageDialectsConditionInput | null,
};

export type CreateContextchannelLanguageLanguageDialectsMutation = {
  createContextchannelLanguageLanguageDialects?:  {
    __typename: "ContextchannelLanguageLanguageDialects",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannellanguageID: string,
    contextchannellanguage?:  {
      __typename: "ContextchannelLanguages",
      id: string,
      createdAt: string,
      enabled?: boolean | null,
      contextchannelID: string,
      contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContextchannelLanguageLanguageDialectsMutationVariables = {
  input: UpdateContextchannelLanguageLanguageDialectsInput,
  condition?: ModelContextchannelLanguageLanguageDialectsConditionInput | null,
};

export type UpdateContextchannelLanguageLanguageDialectsMutation = {
  updateContextchannelLanguageLanguageDialects?:  {
    __typename: "ContextchannelLanguageLanguageDialects",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannellanguageID: string,
    contextchannellanguage?:  {
      __typename: "ContextchannelLanguages",
      id: string,
      createdAt: string,
      enabled?: boolean | null,
      contextchannelID: string,
      contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContextchannelLanguageLanguageDialectsMutationVariables = {
  input: DeleteContextchannelLanguageLanguageDialectsInput,
  condition?: ModelContextchannelLanguageLanguageDialectsConditionInput | null,
};

export type DeleteContextchannelLanguageLanguageDialectsMutation = {
  deleteContextchannelLanguageLanguageDialects?:  {
    __typename: "ContextchannelLanguageLanguageDialects",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannellanguageID: string,
    contextchannellanguage?:  {
      __typename: "ContextchannelLanguages",
      id: string,
      createdAt: string,
      enabled?: boolean | null,
      contextchannelID: string,
      contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContextchannelSpeakersMutationVariables = {
  input: CreateContextchannelSpeakersInput,
  condition?: ModelContextchannelSpeakersConditionInput | null,
};

export type CreateContextchannelSpeakersMutation = {
  createContextchannelSpeakers?:  {
    __typename: "ContextchannelSpeakers",
    id: string,
    createdAt: string,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContextchannelSpeakersMutationVariables = {
  input: UpdateContextchannelSpeakersInput,
  condition?: ModelContextchannelSpeakersConditionInput | null,
};

export type UpdateContextchannelSpeakersMutation = {
  updateContextchannelSpeakers?:  {
    __typename: "ContextchannelSpeakers",
    id: string,
    createdAt: string,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContextchannelSpeakersMutationVariables = {
  input: DeleteContextchannelSpeakersInput,
  condition?: ModelContextchannelSpeakersConditionInput | null,
};

export type DeleteContextchannelSpeakersMutation = {
  deleteContextchannelSpeakers?:  {
    __typename: "ContextchannelSpeakers",
    id: string,
    createdAt: string,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContextchannelLogMutationVariables = {
  input: CreateContextchannelLogInput,
  condition?: ModelContextchannelLogConditionInput | null,
};

export type CreateContextchannelLogMutation = {
  createContextchannelLog?:  {
    __typename: "ContextchannelLog",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: ContextchannelLogType,
    ApiCallLogEntry?:  {
      __typename: "ApiCallLogEntry",
      ipAddress: string,
      userName: string,
      method: RequestMethod,
      route: string,
      httpVersion: string,
      statusCode: number,
      userAgent: string,
    } | null,
    SessionStatusUpdateLogEntry?:  {
      __typename: "SessionStatusUpdateLogEntry",
      sessionID: string,
      sessionName?: string | null,
      attributeName: string,
      oldValue: string,
      newValue: string,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContextchannelLogMutationVariables = {
  input: UpdateContextchannelLogInput,
  condition?: ModelContextchannelLogConditionInput | null,
};

export type UpdateContextchannelLogMutation = {
  updateContextchannelLog?:  {
    __typename: "ContextchannelLog",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: ContextchannelLogType,
    ApiCallLogEntry?:  {
      __typename: "ApiCallLogEntry",
      ipAddress: string,
      userName: string,
      method: RequestMethod,
      route: string,
      httpVersion: string,
      statusCode: number,
      userAgent: string,
    } | null,
    SessionStatusUpdateLogEntry?:  {
      __typename: "SessionStatusUpdateLogEntry",
      sessionID: string,
      sessionName?: string | null,
      attributeName: string,
      oldValue: string,
      newValue: string,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContextchannelLogMutationVariables = {
  input: DeleteContextchannelLogInput,
  condition?: ModelContextchannelLogConditionInput | null,
};

export type DeleteContextchannelLogMutation = {
  deleteContextchannelLog?:  {
    __typename: "ContextchannelLog",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: ContextchannelLogType,
    ApiCallLogEntry?:  {
      __typename: "ApiCallLogEntry",
      ipAddress: string,
      userName: string,
      method: RequestMethod,
      route: string,
      httpVersion: string,
      statusCode: number,
      userAgent: string,
    } | null,
    SessionStatusUpdateLogEntry?:  {
      __typename: "SessionStatusUpdateLogEntry",
      sessionID: string,
      sessionName?: string | null,
      attributeName: string,
      oldValue: string,
      newValue: string,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSessionMutationVariables = {
  input: CreateSessionInput,
  condition?: ModelSessionConditionInput | null,
};

export type CreateSessionMutation = {
  createSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    name?: string | null,
    clientID: string,
    allowedCognitoGroups?: Array< string | null > | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    wordTaggerModuleOutputs?:  {
      __typename: "ModelWordTaggerModuleOutputConnection",
      items:  Array< {
        __typename: "WordTaggerModuleOutput",
        id: string,
        createdAt: string,
        clientID: string,
        sessionID: string,
        categories?: Array< string | null > | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gnlpOutputs?: string | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    title?: string | null,
    summary?: string | null,
    keywords?: Array< string | null > | null,
    rewrittenS3Key?: string | null,
    condensed?: string | null,
    category?: string | null,
    sourceFileS3Key?: string | null,
    s3ImageUrl?: string | null,
    metadataID?: string | null,
    Metadata?:  {
      __typename: "Metadata",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      metadataS3Key?: string | null,
      durationSeconds?: number | null,
      metadataStatus?: MetadataStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    audioID?: string | null,
    Audio?:  {
      __typename: "Audio",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      uncompressedAudioS3Key?: string | null,
      compressedAudioS3Key?: string | null,
      waveformDataS3Key?: string | null,
      audioStatus?: AudioStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    videoID?: string | null,
    Video?:  {
      __typename: "Video",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      compressedVideoS3Key?: string | null,
      videoStatus?: VideoStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    autotranscriptID?: string | null,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    goldtranscriptID?: string | null,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customtranscriptID?: string | null,
    CustomTranscript?:  {
      __typename: "CustomTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    metadataStatus?: MetadataStatus | null,
    audioStatus?: AudioStatus | null,
    videoStatus?: VideoStatus | null,
    transcriptionStatus?: TranscriptionStatus | null,
    diarizationStatus?: DiarizationStatus | null,
    subtitleStatus?: SubtitleStatus | null,
    gnlpStatus?: GNLPStatus | null,
    nlpStatus?: NLPStatus | null,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Speakers?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    extra?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSessionMutationVariables = {
  input: UpdateSessionInput,
  condition?: ModelSessionConditionInput | null,
};

export type UpdateSessionMutation = {
  updateSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    name?: string | null,
    clientID: string,
    allowedCognitoGroups?: Array< string | null > | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    wordTaggerModuleOutputs?:  {
      __typename: "ModelWordTaggerModuleOutputConnection",
      items:  Array< {
        __typename: "WordTaggerModuleOutput",
        id: string,
        createdAt: string,
        clientID: string,
        sessionID: string,
        categories?: Array< string | null > | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gnlpOutputs?: string | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    title?: string | null,
    summary?: string | null,
    keywords?: Array< string | null > | null,
    rewrittenS3Key?: string | null,
    condensed?: string | null,
    category?: string | null,
    sourceFileS3Key?: string | null,
    s3ImageUrl?: string | null,
    metadataID?: string | null,
    Metadata?:  {
      __typename: "Metadata",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      metadataS3Key?: string | null,
      durationSeconds?: number | null,
      metadataStatus?: MetadataStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    audioID?: string | null,
    Audio?:  {
      __typename: "Audio",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      uncompressedAudioS3Key?: string | null,
      compressedAudioS3Key?: string | null,
      waveformDataS3Key?: string | null,
      audioStatus?: AudioStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    videoID?: string | null,
    Video?:  {
      __typename: "Video",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      compressedVideoS3Key?: string | null,
      videoStatus?: VideoStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    autotranscriptID?: string | null,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    goldtranscriptID?: string | null,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customtranscriptID?: string | null,
    CustomTranscript?:  {
      __typename: "CustomTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    metadataStatus?: MetadataStatus | null,
    audioStatus?: AudioStatus | null,
    videoStatus?: VideoStatus | null,
    transcriptionStatus?: TranscriptionStatus | null,
    diarizationStatus?: DiarizationStatus | null,
    subtitleStatus?: SubtitleStatus | null,
    gnlpStatus?: GNLPStatus | null,
    nlpStatus?: NLPStatus | null,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Speakers?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    extra?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSessionMutationVariables = {
  input: DeleteSessionInput,
  condition?: ModelSessionConditionInput | null,
};

export type DeleteSessionMutation = {
  deleteSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    name?: string | null,
    clientID: string,
    allowedCognitoGroups?: Array< string | null > | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    wordTaggerModuleOutputs?:  {
      __typename: "ModelWordTaggerModuleOutputConnection",
      items:  Array< {
        __typename: "WordTaggerModuleOutput",
        id: string,
        createdAt: string,
        clientID: string,
        sessionID: string,
        categories?: Array< string | null > | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gnlpOutputs?: string | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    title?: string | null,
    summary?: string | null,
    keywords?: Array< string | null > | null,
    rewrittenS3Key?: string | null,
    condensed?: string | null,
    category?: string | null,
    sourceFileS3Key?: string | null,
    s3ImageUrl?: string | null,
    metadataID?: string | null,
    Metadata?:  {
      __typename: "Metadata",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      metadataS3Key?: string | null,
      durationSeconds?: number | null,
      metadataStatus?: MetadataStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    audioID?: string | null,
    Audio?:  {
      __typename: "Audio",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      uncompressedAudioS3Key?: string | null,
      compressedAudioS3Key?: string | null,
      waveformDataS3Key?: string | null,
      audioStatus?: AudioStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    videoID?: string | null,
    Video?:  {
      __typename: "Video",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      compressedVideoS3Key?: string | null,
      videoStatus?: VideoStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    autotranscriptID?: string | null,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    goldtranscriptID?: string | null,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customtranscriptID?: string | null,
    CustomTranscript?:  {
      __typename: "CustomTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    metadataStatus?: MetadataStatus | null,
    audioStatus?: AudioStatus | null,
    videoStatus?: VideoStatus | null,
    transcriptionStatus?: TranscriptionStatus | null,
    diarizationStatus?: DiarizationStatus | null,
    subtitleStatus?: SubtitleStatus | null,
    gnlpStatus?: GNLPStatus | null,
    nlpStatus?: NLPStatus | null,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Speakers?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    extra?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNLPModelVersionMutationVariables = {
  input: CreateNLPModelVersionInput,
  condition?: ModelNLPModelVersionConditionInput | null,
};

export type CreateNLPModelVersionMutation = {
  createNLPModelVersion?:  {
    __typename: "NLPModelVersion",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    HFModelType?: HFModelType | null,
    parentmodelID?: string | null,
    ParentModel?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    childModels?:  {
      __typename: "ModelNLPModelVersionConnection",
      items:  Array< {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    modelVersionType?: ModelVersionType | null,
    checkpointKey?: string | null,
    checkpointStorageType?: CheckpointStorageType | null,
    inputlanguageID: string,
    inputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputlanguageID: string,
    outputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputParameters?:  {
      __typename: "InputParameters",
      includeSpeakerInInput?: boolean | null,
      parseSpeakerFromOutput?: boolean | null,
      batchSize?: number | null,
      maxInputChunkLength?: number | null,
      inputChunkOverlapTokens?: number | null,
      outputChunkOverlapTokens?: number | null,
      outputChunkOverlapDiscardLastTokens?: number | null,
      truncateInput?: boolean | null,
      maxRecursionDepth?: number | null,
      maxRecursiveOutputLength?: number | null,
      allowConcatenatedOutput?: boolean | null,
      badGenerationResampleTries?: number | null,
      inputPrefix?: string | null,
      inputSuffix?: string | null,
      outputStartWith?: string | null,
      outputSeperators?: Array< string | null > | null,
      returnNMostFrequentOutputs?: number | null,
    } | null,
    inferenceParameters?:  {
      __typename: "InferenceParameters",
      useCache?: boolean | null,
      maxNewTokens?: number | null,
      minNewTokens?: number | null,
      earlyStopping?: boolean | null,
      doSample?: boolean | null,
      numBeams?: number | null,
      penaltyAlpha?: number | null,
      temperature?: number | null,
      topK?: number | null,
      topP?: number | null,
      diversityPenalty?: number | null,
      lengthPenalty?: number | null,
      exponentialDecayLengthPenaltyStartIndex?: number | null,
      exponentialDecayLengthPenaltyDecayFactor?: number | null,
      repetitionPenalty?: number | null,
      encoderRepetitionPenalty?: number | null,
      noRepeatNgramSize?: number | null,
      encoderNoRepeatNgramSize?: number | null,
      forceWords?: Array< Array< string | null > | null > | null,
      badWords?: Array< string | null > | null,
      suppressTokens?: Array< number | null > | null,
    } | null,
    modelParameters?:  {
      __typename: "ModelParameters",
      hasQuantizedWeights?: boolean | null,
      skipQuantizeLayers?: Array< string | null > | null,
      gptqWbits?: number | null,
      gptqGroupsize?: number | null,
      loadIn8bit?: boolean | null,
      loadIn8bitThreshold?: number | null,
      loadIn8bitSkipModules?: Array< string | null > | null,
      torchDtype?: string | null,
      fromTf?: boolean | null,
      fromFlax?: boolean | null,
      ignoreMismatchedSizes?: boolean | null,
    } | null,
    openAIParameters?:  {
      __typename: "OpenAIParameters",
      endpoint: string,
      model: string,
      temperature?: number | null,
      maxTokens?: number | null,
      topP?: number | null,
      frequencyPenalty?: number | null,
      presencePenalty?: number | null,
      stop?: Array< string | null > | null,
    } | null,
    outputType: NLPOutputType,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNLPModelVersionMutationVariables = {
  input: UpdateNLPModelVersionInput,
  condition?: ModelNLPModelVersionConditionInput | null,
};

export type UpdateNLPModelVersionMutation = {
  updateNLPModelVersion?:  {
    __typename: "NLPModelVersion",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    HFModelType?: HFModelType | null,
    parentmodelID?: string | null,
    ParentModel?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    childModels?:  {
      __typename: "ModelNLPModelVersionConnection",
      items:  Array< {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    modelVersionType?: ModelVersionType | null,
    checkpointKey?: string | null,
    checkpointStorageType?: CheckpointStorageType | null,
    inputlanguageID: string,
    inputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputlanguageID: string,
    outputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputParameters?:  {
      __typename: "InputParameters",
      includeSpeakerInInput?: boolean | null,
      parseSpeakerFromOutput?: boolean | null,
      batchSize?: number | null,
      maxInputChunkLength?: number | null,
      inputChunkOverlapTokens?: number | null,
      outputChunkOverlapTokens?: number | null,
      outputChunkOverlapDiscardLastTokens?: number | null,
      truncateInput?: boolean | null,
      maxRecursionDepth?: number | null,
      maxRecursiveOutputLength?: number | null,
      allowConcatenatedOutput?: boolean | null,
      badGenerationResampleTries?: number | null,
      inputPrefix?: string | null,
      inputSuffix?: string | null,
      outputStartWith?: string | null,
      outputSeperators?: Array< string | null > | null,
      returnNMostFrequentOutputs?: number | null,
    } | null,
    inferenceParameters?:  {
      __typename: "InferenceParameters",
      useCache?: boolean | null,
      maxNewTokens?: number | null,
      minNewTokens?: number | null,
      earlyStopping?: boolean | null,
      doSample?: boolean | null,
      numBeams?: number | null,
      penaltyAlpha?: number | null,
      temperature?: number | null,
      topK?: number | null,
      topP?: number | null,
      diversityPenalty?: number | null,
      lengthPenalty?: number | null,
      exponentialDecayLengthPenaltyStartIndex?: number | null,
      exponentialDecayLengthPenaltyDecayFactor?: number | null,
      repetitionPenalty?: number | null,
      encoderRepetitionPenalty?: number | null,
      noRepeatNgramSize?: number | null,
      encoderNoRepeatNgramSize?: number | null,
      forceWords?: Array< Array< string | null > | null > | null,
      badWords?: Array< string | null > | null,
      suppressTokens?: Array< number | null > | null,
    } | null,
    modelParameters?:  {
      __typename: "ModelParameters",
      hasQuantizedWeights?: boolean | null,
      skipQuantizeLayers?: Array< string | null > | null,
      gptqWbits?: number | null,
      gptqGroupsize?: number | null,
      loadIn8bit?: boolean | null,
      loadIn8bitThreshold?: number | null,
      loadIn8bitSkipModules?: Array< string | null > | null,
      torchDtype?: string | null,
      fromTf?: boolean | null,
      fromFlax?: boolean | null,
      ignoreMismatchedSizes?: boolean | null,
    } | null,
    openAIParameters?:  {
      __typename: "OpenAIParameters",
      endpoint: string,
      model: string,
      temperature?: number | null,
      maxTokens?: number | null,
      topP?: number | null,
      frequencyPenalty?: number | null,
      presencePenalty?: number | null,
      stop?: Array< string | null > | null,
    } | null,
    outputType: NLPOutputType,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNLPModelVersionMutationVariables = {
  input: DeleteNLPModelVersionInput,
  condition?: ModelNLPModelVersionConditionInput | null,
};

export type DeleteNLPModelVersionMutation = {
  deleteNLPModelVersion?:  {
    __typename: "NLPModelVersion",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    HFModelType?: HFModelType | null,
    parentmodelID?: string | null,
    ParentModel?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    childModels?:  {
      __typename: "ModelNLPModelVersionConnection",
      items:  Array< {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    modelVersionType?: ModelVersionType | null,
    checkpointKey?: string | null,
    checkpointStorageType?: CheckpointStorageType | null,
    inputlanguageID: string,
    inputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputlanguageID: string,
    outputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputParameters?:  {
      __typename: "InputParameters",
      includeSpeakerInInput?: boolean | null,
      parseSpeakerFromOutput?: boolean | null,
      batchSize?: number | null,
      maxInputChunkLength?: number | null,
      inputChunkOverlapTokens?: number | null,
      outputChunkOverlapTokens?: number | null,
      outputChunkOverlapDiscardLastTokens?: number | null,
      truncateInput?: boolean | null,
      maxRecursionDepth?: number | null,
      maxRecursiveOutputLength?: number | null,
      allowConcatenatedOutput?: boolean | null,
      badGenerationResampleTries?: number | null,
      inputPrefix?: string | null,
      inputSuffix?: string | null,
      outputStartWith?: string | null,
      outputSeperators?: Array< string | null > | null,
      returnNMostFrequentOutputs?: number | null,
    } | null,
    inferenceParameters?:  {
      __typename: "InferenceParameters",
      useCache?: boolean | null,
      maxNewTokens?: number | null,
      minNewTokens?: number | null,
      earlyStopping?: boolean | null,
      doSample?: boolean | null,
      numBeams?: number | null,
      penaltyAlpha?: number | null,
      temperature?: number | null,
      topK?: number | null,
      topP?: number | null,
      diversityPenalty?: number | null,
      lengthPenalty?: number | null,
      exponentialDecayLengthPenaltyStartIndex?: number | null,
      exponentialDecayLengthPenaltyDecayFactor?: number | null,
      repetitionPenalty?: number | null,
      encoderRepetitionPenalty?: number | null,
      noRepeatNgramSize?: number | null,
      encoderNoRepeatNgramSize?: number | null,
      forceWords?: Array< Array< string | null > | null > | null,
      badWords?: Array< string | null > | null,
      suppressTokens?: Array< number | null > | null,
    } | null,
    modelParameters?:  {
      __typename: "ModelParameters",
      hasQuantizedWeights?: boolean | null,
      skipQuantizeLayers?: Array< string | null > | null,
      gptqWbits?: number | null,
      gptqGroupsize?: number | null,
      loadIn8bit?: boolean | null,
      loadIn8bitThreshold?: number | null,
      loadIn8bitSkipModules?: Array< string | null > | null,
      torchDtype?: string | null,
      fromTf?: boolean | null,
      fromFlax?: boolean | null,
      ignoreMismatchedSizes?: boolean | null,
    } | null,
    openAIParameters?:  {
      __typename: "OpenAIParameters",
      endpoint: string,
      model: string,
      temperature?: number | null,
      maxTokens?: number | null,
      topP?: number | null,
      frequencyPenalty?: number | null,
      presencePenalty?: number | null,
      stop?: Array< string | null > | null,
    } | null,
    outputType: NLPOutputType,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNLPStepMutationVariables = {
  input: CreateNLPStepInput,
  condition?: ModelNLPStepConditionInput | null,
};

export type CreateNLPStepMutation = {
  createNLPStep?:  {
    __typename: "NLPStep",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    outputKey: string,
    outputLanguages?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    inputstepID?: string | null,
    InputStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    OutputSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNLPStepMutationVariables = {
  input: UpdateNLPStepInput,
  condition?: ModelNLPStepConditionInput | null,
};

export type UpdateNLPStepMutation = {
  updateNLPStep?:  {
    __typename: "NLPStep",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    outputKey: string,
    outputLanguages?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    inputstepID?: string | null,
    InputStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    OutputSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNLPStepMutationVariables = {
  input: DeleteNLPStepInput,
  condition?: ModelNLPStepConditionInput | null,
};

export type DeleteNLPStepMutation = {
  deleteNLPStep?:  {
    __typename: "NLPStep",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    outputKey: string,
    outputLanguages?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    inputstepID?: string | null,
    InputStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    OutputSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNLPStepLanguagesMutationVariables = {
  input: CreateNLPStepLanguagesInput,
  condition?: ModelNLPStepLanguagesConditionInput | null,
};

export type CreateNLPStepLanguagesMutation = {
  createNLPStepLanguages?:  {
    __typename: "NLPStepLanguages",
    id: string,
    createdAt: string,
    formality?: Formality | null,
    nlpstepID: string,
    nlpstep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNLPStepLanguagesMutationVariables = {
  input: UpdateNLPStepLanguagesInput,
  condition?: ModelNLPStepLanguagesConditionInput | null,
};

export type UpdateNLPStepLanguagesMutation = {
  updateNLPStepLanguages?:  {
    __typename: "NLPStepLanguages",
    id: string,
    createdAt: string,
    formality?: Formality | null,
    nlpstepID: string,
    nlpstep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNLPStepLanguagesMutationVariables = {
  input: DeleteNLPStepLanguagesInput,
  condition?: ModelNLPStepLanguagesConditionInput | null,
};

export type DeleteNLPStepLanguagesMutation = {
  deleteNLPStepLanguages?:  {
    __typename: "NLPStepLanguages",
    id: string,
    createdAt: string,
    formality?: Formality | null,
    nlpstepID: string,
    nlpstep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNLPOutputMutationVariables = {
  input: CreateNLPOutputInput,
  condition?: ModelNLPOutputConditionInput | null,
};

export type CreateNLPOutputMutation = {
  createNLPOutput?:  {
    __typename: "NLPOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpstepID: string,
    NLPStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputnlpoutputID?: string | null,
    inputNLPOutput?:  {
      __typename: "NLPOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpstepID: string,
      NLPStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputnlpoutputID?: string | null,
      inputNLPOutput?:  {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputNLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      key: string,
      outputType: NLPOutputType,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputNLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    key: string,
    outputType: NLPOutputType,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNLPOutputMutationVariables = {
  input: UpdateNLPOutputInput,
  condition?: ModelNLPOutputConditionInput | null,
};

export type UpdateNLPOutputMutation = {
  updateNLPOutput?:  {
    __typename: "NLPOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpstepID: string,
    NLPStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputnlpoutputID?: string | null,
    inputNLPOutput?:  {
      __typename: "NLPOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpstepID: string,
      NLPStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputnlpoutputID?: string | null,
      inputNLPOutput?:  {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputNLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      key: string,
      outputType: NLPOutputType,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputNLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    key: string,
    outputType: NLPOutputType,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNLPOutputMutationVariables = {
  input: DeleteNLPOutputInput,
  condition?: ModelNLPOutputConditionInput | null,
};

export type DeleteNLPOutputMutation = {
  deleteNLPOutput?:  {
    __typename: "NLPOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpstepID: string,
    NLPStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputnlpoutputID?: string | null,
    inputNLPOutput?:  {
      __typename: "NLPOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpstepID: string,
      NLPStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputnlpoutputID?: string | null,
      inputNLPOutput?:  {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputNLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      key: string,
      outputType: NLPOutputType,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputNLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    key: string,
    outputType: NLPOutputType,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateClusteringModuleOutputMutationVariables = {
  input: CreateClusteringModuleOutputInput,
  condition?: ModelClusteringModuleOutputConditionInput | null,
};

export type CreateClusteringModuleOutputMutation = {
  createClusteringModuleOutput?:  {
    __typename: "ClusteringModuleOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    key: string,
    leafIDs: Array< string | null >,
    linkageMatrixS3Url: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateClusteringModuleOutputMutationVariables = {
  input: UpdateClusteringModuleOutputInput,
  condition?: ModelClusteringModuleOutputConditionInput | null,
};

export type UpdateClusteringModuleOutputMutation = {
  updateClusteringModuleOutput?:  {
    __typename: "ClusteringModuleOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    key: string,
    leafIDs: Array< string | null >,
    linkageMatrixS3Url: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteClusteringModuleOutputMutationVariables = {
  input: DeleteClusteringModuleOutputInput,
  condition?: ModelClusteringModuleOutputConditionInput | null,
};

export type DeleteClusteringModuleOutputMutation = {
  deleteClusteringModuleOutput?:  {
    __typename: "ClusteringModuleOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    key: string,
    leafIDs: Array< string | null >,
    linkageMatrixS3Url: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSessionSpeakersMutationVariables = {
  input: CreateSessionSpeakersInput,
  condition?: ModelSessionSpeakersConditionInput | null,
};

export type CreateSessionSpeakersMutation = {
  createSessionSpeakers?:  {
    __typename: "SessionSpeakers",
    id: string,
    createdAt: string,
    isDefaultSpeaker?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSessionSpeakersMutationVariables = {
  input: UpdateSessionSpeakersInput,
  condition?: ModelSessionSpeakersConditionInput | null,
};

export type UpdateSessionSpeakersMutation = {
  updateSessionSpeakers?:  {
    __typename: "SessionSpeakers",
    id: string,
    createdAt: string,
    isDefaultSpeaker?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSessionSpeakersMutationVariables = {
  input: DeleteSessionSpeakersInput,
  condition?: ModelSessionSpeakersConditionInput | null,
};

export type DeleteSessionSpeakersMutation = {
  deleteSessionSpeakers?:  {
    __typename: "SessionSpeakers",
    id: string,
    createdAt: string,
    isDefaultSpeaker?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSessionLanguagesMutationVariables = {
  input: CreateSessionLanguagesInput,
  condition?: ModelSessionLanguagesConditionInput | null,
};

export type CreateSessionLanguagesMutation = {
  createSessionLanguages?:  {
    __typename: "SessionLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSessionLanguagesMutationVariables = {
  input: UpdateSessionLanguagesInput,
  condition?: ModelSessionLanguagesConditionInput | null,
};

export type UpdateSessionLanguagesMutation = {
  updateSessionLanguages?:  {
    __typename: "SessionLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSessionLanguagesMutationVariables = {
  input: DeleteSessionLanguagesInput,
  condition?: ModelSessionLanguagesConditionInput | null,
};

export type DeleteSessionLanguagesMutation = {
  deleteSessionLanguages?:  {
    __typename: "SessionLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSessionLanguageLanguageDialectsMutationVariables = {
  input: CreateSessionLanguageLanguageDialectsInput,
  condition?: ModelSessionLanguageLanguageDialectsConditionInput | null,
};

export type CreateSessionLanguageLanguageDialectsMutation = {
  createSessionLanguageLanguageDialects?:  {
    __typename: "SessionLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    sessionlanguageID: string,
    sessionlanguage?:  {
      __typename: "SessionLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      sessionID: string,
      session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSessionLanguageLanguageDialectsMutationVariables = {
  input: UpdateSessionLanguageLanguageDialectsInput,
  condition?: ModelSessionLanguageLanguageDialectsConditionInput | null,
};

export type UpdateSessionLanguageLanguageDialectsMutation = {
  updateSessionLanguageLanguageDialects?:  {
    __typename: "SessionLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    sessionlanguageID: string,
    sessionlanguage?:  {
      __typename: "SessionLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      sessionID: string,
      session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSessionLanguageLanguageDialectsMutationVariables = {
  input: DeleteSessionLanguageLanguageDialectsInput,
  condition?: ModelSessionLanguageLanguageDialectsConditionInput | null,
};

export type DeleteSessionLanguageLanguageDialectsMutation = {
  deleteSessionLanguageLanguageDialects?:  {
    __typename: "SessionLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    sessionlanguageID: string,
    sessionlanguage?:  {
      __typename: "SessionLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      sessionID: string,
      session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMetadataMutationVariables = {
  input: CreateMetadataInput,
  condition?: ModelMetadataConditionInput | null,
};

export type CreateMetadataMutation = {
  createMetadata?:  {
    __typename: "Metadata",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    metadataS3Key?: string | null,
    durationSeconds?: number | null,
    metadataStatus?: MetadataStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMetadataMutationVariables = {
  input: UpdateMetadataInput,
  condition?: ModelMetadataConditionInput | null,
};

export type UpdateMetadataMutation = {
  updateMetadata?:  {
    __typename: "Metadata",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    metadataS3Key?: string | null,
    durationSeconds?: number | null,
    metadataStatus?: MetadataStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMetadataMutationVariables = {
  input: DeleteMetadataInput,
  condition?: ModelMetadataConditionInput | null,
};

export type DeleteMetadataMutation = {
  deleteMetadata?:  {
    __typename: "Metadata",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    metadataS3Key?: string | null,
    durationSeconds?: number | null,
    metadataStatus?: MetadataStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAudioMutationVariables = {
  input: CreateAudioInput,
  condition?: ModelAudioConditionInput | null,
};

export type CreateAudioMutation = {
  createAudio?:  {
    __typename: "Audio",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    uncompressedAudioS3Key?: string | null,
    compressedAudioS3Key?: string | null,
    waveformDataS3Key?: string | null,
    audioStatus?: AudioStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAudioMutationVariables = {
  input: UpdateAudioInput,
  condition?: ModelAudioConditionInput | null,
};

export type UpdateAudioMutation = {
  updateAudio?:  {
    __typename: "Audio",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    uncompressedAudioS3Key?: string | null,
    compressedAudioS3Key?: string | null,
    waveformDataS3Key?: string | null,
    audioStatus?: AudioStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAudioMutationVariables = {
  input: DeleteAudioInput,
  condition?: ModelAudioConditionInput | null,
};

export type DeleteAudioMutation = {
  deleteAudio?:  {
    __typename: "Audio",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    uncompressedAudioS3Key?: string | null,
    compressedAudioS3Key?: string | null,
    waveformDataS3Key?: string | null,
    audioStatus?: AudioStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateVideoMutationVariables = {
  input: CreateVideoInput,
  condition?: ModelVideoConditionInput | null,
};

export type CreateVideoMutation = {
  createVideo?:  {
    __typename: "Video",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    compressedVideoS3Key?: string | null,
    videoStatus?: VideoStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateVideoMutationVariables = {
  input: UpdateVideoInput,
  condition?: ModelVideoConditionInput | null,
};

export type UpdateVideoMutation = {
  updateVideo?:  {
    __typename: "Video",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    compressedVideoS3Key?: string | null,
    videoStatus?: VideoStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteVideoMutationVariables = {
  input: DeleteVideoInput,
  condition?: ModelVideoConditionInput | null,
};

export type DeleteVideoMutation = {
  deleteVideo?:  {
    __typename: "Video",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    compressedVideoS3Key?: string | null,
    videoStatus?: VideoStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAutoTranscriptMutationVariables = {
  input: CreateAutoTranscriptInput,
  condition?: ModelAutoTranscriptConditionInput | null,
};

export type CreateAutoTranscriptMutation = {
  createAutoTranscript?:  {
    __typename: "AutoTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transcriptSourceS3Key?: string | null,
    transcriptStandardizedS3Key?: string | null,
    transcriptTranslationS3Keys?: string | null,
    transcriptionStatus?: TranscriptionStatus | null,
    exportPlainTextS3Key?: string | null,
    exportJsonS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    diarizationS3Key?: string | null,
    summaryS3Key?: string | null,
    categoriesJson?: string | null,
    autotranscriptstatsID?: string | null,
    AutoTranscriptStats?:  {
      __typename: "AutoTranscriptStats",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      unknowns?: number | null,
      wordCount?: number | null,
      unknownPercentage?: number | null,
      globalConfidence?: number | null,
      knownConfidence?: number | null,
      autotranscriptID: string,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAutoTranscriptMutationVariables = {
  input: UpdateAutoTranscriptInput,
  condition?: ModelAutoTranscriptConditionInput | null,
};

export type UpdateAutoTranscriptMutation = {
  updateAutoTranscript?:  {
    __typename: "AutoTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transcriptSourceS3Key?: string | null,
    transcriptStandardizedS3Key?: string | null,
    transcriptTranslationS3Keys?: string | null,
    transcriptionStatus?: TranscriptionStatus | null,
    exportPlainTextS3Key?: string | null,
    exportJsonS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    diarizationS3Key?: string | null,
    summaryS3Key?: string | null,
    categoriesJson?: string | null,
    autotranscriptstatsID?: string | null,
    AutoTranscriptStats?:  {
      __typename: "AutoTranscriptStats",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      unknowns?: number | null,
      wordCount?: number | null,
      unknownPercentage?: number | null,
      globalConfidence?: number | null,
      knownConfidence?: number | null,
      autotranscriptID: string,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAutoTranscriptMutationVariables = {
  input: DeleteAutoTranscriptInput,
  condition?: ModelAutoTranscriptConditionInput | null,
};

export type DeleteAutoTranscriptMutation = {
  deleteAutoTranscript?:  {
    __typename: "AutoTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transcriptSourceS3Key?: string | null,
    transcriptStandardizedS3Key?: string | null,
    transcriptTranslationS3Keys?: string | null,
    transcriptionStatus?: TranscriptionStatus | null,
    exportPlainTextS3Key?: string | null,
    exportJsonS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    diarizationS3Key?: string | null,
    summaryS3Key?: string | null,
    categoriesJson?: string | null,
    autotranscriptstatsID?: string | null,
    AutoTranscriptStats?:  {
      __typename: "AutoTranscriptStats",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      unknowns?: number | null,
      wordCount?: number | null,
      unknownPercentage?: number | null,
      globalConfidence?: number | null,
      knownConfidence?: number | null,
      autotranscriptID: string,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateGoldTranscriptMutationVariables = {
  input: CreateGoldTranscriptInput,
  condition?: ModelGoldTranscriptConditionInput | null,
};

export type CreateGoldTranscriptMutation = {
  createGoldTranscript?:  {
    __typename: "GoldTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateGoldTranscriptMutationVariables = {
  input: UpdateGoldTranscriptInput,
  condition?: ModelGoldTranscriptConditionInput | null,
};

export type UpdateGoldTranscriptMutation = {
  updateGoldTranscript?:  {
    __typename: "GoldTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteGoldTranscriptMutationVariables = {
  input: DeleteGoldTranscriptInput,
  condition?: ModelGoldTranscriptConditionInput | null,
};

export type DeleteGoldTranscriptMutation = {
  deleteGoldTranscript?:  {
    __typename: "GoldTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomTranscriptMutationVariables = {
  input: CreateCustomTranscriptInput,
  condition?: ModelCustomTranscriptConditionInput | null,
};

export type CreateCustomTranscriptMutation = {
  createCustomTranscript?:  {
    __typename: "CustomTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomTranscriptMutationVariables = {
  input: UpdateCustomTranscriptInput,
  condition?: ModelCustomTranscriptConditionInput | null,
};

export type UpdateCustomTranscriptMutation = {
  updateCustomTranscript?:  {
    __typename: "CustomTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomTranscriptMutationVariables = {
  input: DeleteCustomTranscriptInput,
  condition?: ModelCustomTranscriptConditionInput | null,
};

export type DeleteCustomTranscriptMutation = {
  deleteCustomTranscript?:  {
    __typename: "CustomTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAutoTranscriptStatsMutationVariables = {
  input: CreateAutoTranscriptStatsInput,
  condition?: ModelAutoTranscriptStatsConditionInput | null,
};

export type CreateAutoTranscriptStatsMutation = {
  createAutoTranscriptStats?:  {
    __typename: "AutoTranscriptStats",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    unknowns?: number | null,
    wordCount?: number | null,
    unknownPercentage?: number | null,
    globalConfidence?: number | null,
    knownConfidence?: number | null,
    autotranscriptID: string,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAutoTranscriptStatsMutationVariables = {
  input: UpdateAutoTranscriptStatsInput,
  condition?: ModelAutoTranscriptStatsConditionInput | null,
};

export type UpdateAutoTranscriptStatsMutation = {
  updateAutoTranscriptStats?:  {
    __typename: "AutoTranscriptStats",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    unknowns?: number | null,
    wordCount?: number | null,
    unknownPercentage?: number | null,
    globalConfidence?: number | null,
    knownConfidence?: number | null,
    autotranscriptID: string,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAutoTranscriptStatsMutationVariables = {
  input: DeleteAutoTranscriptStatsInput,
  condition?: ModelAutoTranscriptStatsConditionInput | null,
};

export type DeleteAutoTranscriptStatsMutation = {
  deleteAutoTranscriptStats?:  {
    __typename: "AutoTranscriptStats",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    unknowns?: number | null,
    wordCount?: number | null,
    unknownPercentage?: number | null,
    globalConfidence?: number | null,
    knownConfidence?: number | null,
    autotranscriptID: string,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSpeechModelMutationVariables = {
  input: CreateSpeechModelInput,
  condition?: ModelSpeechModelConditionInput | null,
};

export type CreateSpeechModelMutation = {
  createSpeechModel?:  {
    __typename: "SpeechModel",
    id: string,
    name?: string | null,
    description?: string | null,
    s3ImageUrl?: string | null,
    allowedCognitoGroups?: Array< string | null > | null,
    provider: SpeechModelProvider,
    connectionString?: string | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSpeechModelMutationVariables = {
  input: UpdateSpeechModelInput,
  condition?: ModelSpeechModelConditionInput | null,
};

export type UpdateSpeechModelMutation = {
  updateSpeechModel?:  {
    __typename: "SpeechModel",
    id: string,
    name?: string | null,
    description?: string | null,
    s3ImageUrl?: string | null,
    allowedCognitoGroups?: Array< string | null > | null,
    provider: SpeechModelProvider,
    connectionString?: string | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSpeechModelMutationVariables = {
  input: DeleteSpeechModelInput,
  condition?: ModelSpeechModelConditionInput | null,
};

export type DeleteSpeechModelMutation = {
  deleteSpeechModel?:  {
    __typename: "SpeechModel",
    id: string,
    name?: string | null,
    description?: string | null,
    s3ImageUrl?: string | null,
    allowedCognitoGroups?: Array< string | null > | null,
    provider: SpeechModelProvider,
    connectionString?: string | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLanguageLabelMutationVariables = {
  input: CreateLanguageLabelInput,
  condition?: ModelLanguageLabelConditionInput | null,
};

export type CreateLanguageLabelMutation = {
  createLanguageLabel?:  {
    __typename: "LanguageLabel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    submittedAt?: string | null,
    validatedAt?: string | null,
    submitteduserID?: string | null,
    submittedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    validateduserID?: string | null,
    validatedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    goldtranscriptID: string,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    starttime: string,
    endtime: string,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID?: string | null,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    content: string,
    contentJson?: string | null,
    sentimentJson?: string | null,
    contentOrigin?: string | null,
    contentJsonOrigin?: string | null,
    sentimentJsonOrigin?: string | null,
    validationStatus?: ValidationStatus | null,
    feedbackMessage?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLanguageLabelMutationVariables = {
  input: UpdateLanguageLabelInput,
  condition?: ModelLanguageLabelConditionInput | null,
};

export type UpdateLanguageLabelMutation = {
  updateLanguageLabel?:  {
    __typename: "LanguageLabel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    submittedAt?: string | null,
    validatedAt?: string | null,
    submitteduserID?: string | null,
    submittedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    validateduserID?: string | null,
    validatedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    goldtranscriptID: string,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    starttime: string,
    endtime: string,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID?: string | null,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    content: string,
    contentJson?: string | null,
    sentimentJson?: string | null,
    contentOrigin?: string | null,
    contentJsonOrigin?: string | null,
    sentimentJsonOrigin?: string | null,
    validationStatus?: ValidationStatus | null,
    feedbackMessage?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLanguageLabelMutationVariables = {
  input: DeleteLanguageLabelInput,
  condition?: ModelLanguageLabelConditionInput | null,
};

export type DeleteLanguageLabelMutation = {
  deleteLanguageLabel?:  {
    __typename: "LanguageLabel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    submittedAt?: string | null,
    validatedAt?: string | null,
    submitteduserID?: string | null,
    submittedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    validateduserID?: string | null,
    validatedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    goldtranscriptID: string,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    starttime: string,
    endtime: string,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID?: string | null,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    content: string,
    contentJson?: string | null,
    sentimentJson?: string | null,
    contentOrigin?: string | null,
    contentJsonOrigin?: string | null,
    sentimentJsonOrigin?: string | null,
    validationStatus?: ValidationStatus | null,
    feedbackMessage?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNLPModelMutationVariables = {
  input: CreateNLPModelInput,
  condition?: ModelNLPModelConditionInput | null,
};

export type CreateNLPModelMutation = {
  createNLPModel?:  {
    __typename: "NLPModel",
    id: string,
    modelTrainedAt?: string | null,
    name?: string | null,
    huggingfaceRef?: string | null,
    pipelineParametersJson?: string | null,
    inferenceParametersJson?: string | null,
    otherParametersJson?: string | null,
    basemodelID?: string | null,
    BaseModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ChildModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nlpmodeltypeID: string,
    NLPModelType?:  {
      __typename: "NLPModelType",
      id: string,
      name: string,
      huggingfaceTask: HuggingfaceTask,
      NLPModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNLPModelMutationVariables = {
  input: UpdateNLPModelInput,
  condition?: ModelNLPModelConditionInput | null,
};

export type UpdateNLPModelMutation = {
  updateNLPModel?:  {
    __typename: "NLPModel",
    id: string,
    modelTrainedAt?: string | null,
    name?: string | null,
    huggingfaceRef?: string | null,
    pipelineParametersJson?: string | null,
    inferenceParametersJson?: string | null,
    otherParametersJson?: string | null,
    basemodelID?: string | null,
    BaseModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ChildModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nlpmodeltypeID: string,
    NLPModelType?:  {
      __typename: "NLPModelType",
      id: string,
      name: string,
      huggingfaceTask: HuggingfaceTask,
      NLPModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNLPModelMutationVariables = {
  input: DeleteNLPModelInput,
  condition?: ModelNLPModelConditionInput | null,
};

export type DeleteNLPModelMutation = {
  deleteNLPModel?:  {
    __typename: "NLPModel",
    id: string,
    modelTrainedAt?: string | null,
    name?: string | null,
    huggingfaceRef?: string | null,
    pipelineParametersJson?: string | null,
    inferenceParametersJson?: string | null,
    otherParametersJson?: string | null,
    basemodelID?: string | null,
    BaseModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ChildModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nlpmodeltypeID: string,
    NLPModelType?:  {
      __typename: "NLPModelType",
      id: string,
      name: string,
      huggingfaceTask: HuggingfaceTask,
      NLPModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNLPModelTypeMutationVariables = {
  input: CreateNLPModelTypeInput,
  condition?: ModelNLPModelTypeConditionInput | null,
};

export type CreateNLPModelTypeMutation = {
  createNLPModelType?:  {
    __typename: "NLPModelType",
    id: string,
    name: string,
    huggingfaceTask: HuggingfaceTask,
    NLPModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNLPModelTypeMutationVariables = {
  input: UpdateNLPModelTypeInput,
  condition?: ModelNLPModelTypeConditionInput | null,
};

export type UpdateNLPModelTypeMutation = {
  updateNLPModelType?:  {
    __typename: "NLPModelType",
    id: string,
    name: string,
    huggingfaceTask: HuggingfaceTask,
    NLPModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNLPModelTypeMutationVariables = {
  input: DeleteNLPModelTypeInput,
  condition?: ModelNLPModelTypeConditionInput | null,
};

export type DeleteNLPModelTypeMutation = {
  deleteNLPModelType?:  {
    __typename: "NLPModelType",
    id: string,
    name: string,
    huggingfaceTask: HuggingfaceTask,
    NLPModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSpeakerMutationVariables = {
  input: CreateSpeakerInput,
  condition?: ModelSpeakerConditionInput | null,
};

export type CreateSpeakerMutation = {
  createSpeaker?:  {
    __typename: "Speaker",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    firstName: string,
    infix?: string | null,
    lastName: string,
    gender: Gender,
    Subscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Languages?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSpeakerMutationVariables = {
  input: UpdateSpeakerInput,
  condition?: ModelSpeakerConditionInput | null,
};

export type UpdateSpeakerMutation = {
  updateSpeaker?:  {
    __typename: "Speaker",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    firstName: string,
    infix?: string | null,
    lastName: string,
    gender: Gender,
    Subscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Languages?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSpeakerMutationVariables = {
  input: DeleteSpeakerInput,
  condition?: ModelSpeakerConditionInput | null,
};

export type DeleteSpeakerMutation = {
  deleteSpeaker?:  {
    __typename: "Speaker",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    firstName: string,
    infix?: string | null,
    lastName: string,
    gender: Gender,
    Subscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Languages?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSpeakerLanguagesMutationVariables = {
  input: CreateSpeakerLanguagesInput,
  condition?: ModelSpeakerLanguagesConditionInput | null,
};

export type CreateSpeakerLanguagesMutation = {
  createSpeakerLanguages?:  {
    __typename: "SpeakerLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSpeakerLanguagesMutationVariables = {
  input: UpdateSpeakerLanguagesInput,
  condition?: ModelSpeakerLanguagesConditionInput | null,
};

export type UpdateSpeakerLanguagesMutation = {
  updateSpeakerLanguages?:  {
    __typename: "SpeakerLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSpeakerLanguagesMutationVariables = {
  input: DeleteSpeakerLanguagesInput,
  condition?: ModelSpeakerLanguagesConditionInput | null,
};

export type DeleteSpeakerLanguagesMutation = {
  deleteSpeakerLanguages?:  {
    __typename: "SpeakerLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSpeakerLanguageLanguageDialectsMutationVariables = {
  input: CreateSpeakerLanguageLanguageDialectsInput,
  condition?: ModelSpeakerLanguageLanguageDialectsConditionInput | null,
};

export type CreateSpeakerLanguageLanguageDialectsMutation = {
  createSpeakerLanguageLanguageDialects?:  {
    __typename: "SpeakerLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    speakerlanguageID: string,
    speakerlanguage?:  {
      __typename: "SpeakerLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      speakerID: string,
      speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSpeakerLanguageLanguageDialectsMutationVariables = {
  input: UpdateSpeakerLanguageLanguageDialectsInput,
  condition?: ModelSpeakerLanguageLanguageDialectsConditionInput | null,
};

export type UpdateSpeakerLanguageLanguageDialectsMutation = {
  updateSpeakerLanguageLanguageDialects?:  {
    __typename: "SpeakerLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    speakerlanguageID: string,
    speakerlanguage?:  {
      __typename: "SpeakerLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      speakerID: string,
      speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSpeakerLanguageLanguageDialectsMutationVariables = {
  input: DeleteSpeakerLanguageLanguageDialectsInput,
  condition?: ModelSpeakerLanguageLanguageDialectsConditionInput | null,
};

export type DeleteSpeakerLanguageLanguageDialectsMutation = {
  deleteSpeakerLanguageLanguageDialects?:  {
    __typename: "SpeakerLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    speakerlanguageID: string,
    speakerlanguage?:  {
      __typename: "SpeakerLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      speakerID: string,
      speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLanguageMutationVariables = {
  input: CreateLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type CreateLanguageMutation = {
  createLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    ISO_639_1?: string | null,
    ISO_639_2: string,
    ISO_639_3?: string | null,
    stopwords?: Array< string | null > | null,
    LanguageDialects?:  {
      __typename: "ModelLanguageDialectConnection",
      items:  Array< {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLanguageMutationVariables = {
  input: UpdateLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type UpdateLanguageMutation = {
  updateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    ISO_639_1?: string | null,
    ISO_639_2: string,
    ISO_639_3?: string | null,
    stopwords?: Array< string | null > | null,
    LanguageDialects?:  {
      __typename: "ModelLanguageDialectConnection",
      items:  Array< {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLanguageMutationVariables = {
  input: DeleteLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type DeleteLanguageMutation = {
  deleteLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    ISO_639_1?: string | null,
    ISO_639_2: string,
    ISO_639_3?: string | null,
    stopwords?: Array< string | null > | null,
    LanguageDialects?:  {
      __typename: "ModelLanguageDialectConnection",
      items:  Array< {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLanguageDialectMutationVariables = {
  input: CreateLanguageDialectInput,
  condition?: ModelLanguageDialectConditionInput | null,
};

export type CreateLanguageDialectMutation = {
  createLanguageDialect?:  {
    __typename: "LanguageDialect",
    id: string,
    name: string,
    ISO_639_2_dialect: string,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLanguageDialectMutationVariables = {
  input: UpdateLanguageDialectInput,
  condition?: ModelLanguageDialectConditionInput | null,
};

export type UpdateLanguageDialectMutation = {
  updateLanguageDialect?:  {
    __typename: "LanguageDialect",
    id: string,
    name: string,
    ISO_639_2_dialect: string,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLanguageDialectMutationVariables = {
  input: DeleteLanguageDialectInput,
  condition?: ModelLanguageDialectConditionInput | null,
};

export type DeleteLanguageDialectMutation = {
  deleteLanguageDialect?:  {
    __typename: "LanguageDialect",
    id: string,
    name: string,
    ISO_639_2_dialect: string,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    teamGroup?: string | null,
    isActive?: boolean | null,
    email?: string | null,
    needsEmailChange?: boolean | null,
    firstName?: string | null,
    infix?: string | null,
    lastName?: string | null,
    avatarS3Url?: string | null,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    submittedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validatedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedContextchannels?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelSubscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SessionSubscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeakerSubscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    teamGroup?: string | null,
    isActive?: boolean | null,
    email?: string | null,
    needsEmailChange?: boolean | null,
    firstName?: string | null,
    infix?: string | null,
    lastName?: string | null,
    avatarS3Url?: string | null,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    submittedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validatedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedContextchannels?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelSubscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SessionSubscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeakerSubscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    teamGroup?: string | null,
    isActive?: boolean | null,
    email?: string | null,
    needsEmailChange?: boolean | null,
    firstName?: string | null,
    infix?: string | null,
    lastName?: string | null,
    avatarS3Url?: string | null,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    submittedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validatedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedContextchannels?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelSubscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SessionSubscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeakerSubscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserContextchannelsMutationVariables = {
  input: CreateUserContextchannelsInput,
  condition?: ModelUserContextchannelsConditionInput | null,
};

export type CreateUserContextchannelsMutation = {
  createUserContextchannels?:  {
    __typename: "UserContextchannels",
    id: string,
    createdAt: string,
    isActive?: boolean | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserContextchannelsMutationVariables = {
  input: UpdateUserContextchannelsInput,
  condition?: ModelUserContextchannelsConditionInput | null,
};

export type UpdateUserContextchannelsMutation = {
  updateUserContextchannels?:  {
    __typename: "UserContextchannels",
    id: string,
    createdAt: string,
    isActive?: boolean | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserContextchannelsMutationVariables = {
  input: DeleteUserContextchannelsInput,
  condition?: ModelUserContextchannelsConditionInput | null,
};

export type DeleteUserContextchannelsMutation = {
  deleteUserContextchannels?:  {
    __typename: "UserContextchannels",
    id: string,
    createdAt: string,
    isActive?: boolean | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContextchannelSubscriptionMutationVariables = {
  input: CreateContextchannelSubscriptionInput,
  condition?: ModelContextchannelSubscriptionConditionInput | null,
};

export type CreateContextchannelSubscriptionMutation = {
  createContextchannelSubscription?:  {
    __typename: "ContextchannelSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContextchannelSubscriptionMutationVariables = {
  input: UpdateContextchannelSubscriptionInput,
  condition?: ModelContextchannelSubscriptionConditionInput | null,
};

export type UpdateContextchannelSubscriptionMutation = {
  updateContextchannelSubscription?:  {
    __typename: "ContextchannelSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContextchannelSubscriptionMutationVariables = {
  input: DeleteContextchannelSubscriptionInput,
  condition?: ModelContextchannelSubscriptionConditionInput | null,
};

export type DeleteContextchannelSubscriptionMutation = {
  deleteContextchannelSubscription?:  {
    __typename: "ContextchannelSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSessionSubscriptionMutationVariables = {
  input: CreateSessionSubscriptionInput,
  condition?: ModelSessionSubscriptionConditionInput | null,
};

export type CreateSessionSubscriptionMutation = {
  createSessionSubscription?:  {
    __typename: "SessionSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSessionSubscriptionMutationVariables = {
  input: UpdateSessionSubscriptionInput,
  condition?: ModelSessionSubscriptionConditionInput | null,
};

export type UpdateSessionSubscriptionMutation = {
  updateSessionSubscription?:  {
    __typename: "SessionSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSessionSubscriptionMutationVariables = {
  input: DeleteSessionSubscriptionInput,
  condition?: ModelSessionSubscriptionConditionInput | null,
};

export type DeleteSessionSubscriptionMutation = {
  deleteSessionSubscription?:  {
    __typename: "SessionSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSpeakerSubscriptionMutationVariables = {
  input: CreateSpeakerSubscriptionInput,
  condition?: ModelSpeakerSubscriptionConditionInput | null,
};

export type CreateSpeakerSubscriptionMutation = {
  createSpeakerSubscription?:  {
    __typename: "SpeakerSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSpeakerSubscriptionMutationVariables = {
  input: UpdateSpeakerSubscriptionInput,
  condition?: ModelSpeakerSubscriptionConditionInput | null,
};

export type UpdateSpeakerSubscriptionMutation = {
  updateSpeakerSubscription?:  {
    __typename: "SpeakerSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSpeakerSubscriptionMutationVariables = {
  input: DeleteSpeakerSubscriptionInput,
  condition?: ModelSpeakerSubscriptionConditionInput | null,
};

export type DeleteSpeakerSubscriptionMutation = {
  deleteSpeakerSubscription?:  {
    __typename: "SpeakerSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GptCompletionQueryVariables = {
  input: GPTCompletionInput,
};

export type GptCompletionQuery = {
  gptCompletion?: string | null,
};

export type ExportSessionQueryVariables = {
  sessionID: string,
  exportFormat: ExportFormat,
};

export type ExportSessionQuery = {
  exportSession?: string | null,
};

export type GetCognitoGroupsForUserQueryVariables = {
  id: string,
};

export type GetCognitoGroupsForUserQuery = {
  getCognitoGroupsForUser?:  {
    __typename: "CognitoGroupsForUser",
    teamGroup?: string | null,
    clientGroups?: Array< string | null > | null,
    roleGroups?: Array< string | null > | null,
  } | null,
};

export type SearchSessionsQueryVariables = {
  filter?: SearchableSessionFilterInput | null,
  sort?: Array< SearchableSessionSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableSessionAggregationInput | null > | null,
};

export type SearchSessionsQuery = {
  searchSessions?:  {
    __typename: "SearchableSessionConnection",
    items:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type SearchNLPOutputsQueryVariables = {
  filter?: SearchableNLPOutputFilterInput | null,
  sort?: Array< SearchableNLPOutputSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableNLPOutputAggregationInput | null > | null,
};

export type SearchNLPOutputsQuery = {
  searchNLPOutputs?:  {
    __typename: "SearchableNLPOutputConnection",
    items:  Array< {
      __typename: "NLPOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpstepID: string,
      NLPStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputnlpoutputID?: string | null,
      inputNLPOutput?:  {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputNLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      key: string,
      outputType: NLPOutputType,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type SearchLanguageLabelsQueryVariables = {
  filter?: SearchableLanguageLabelFilterInput | null,
  sort?: Array< SearchableLanguageLabelSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableLanguageLabelAggregationInput | null > | null,
};

export type SearchLanguageLabelsQuery = {
  searchLanguageLabels?:  {
    __typename: "SearchableLanguageLabelConnection",
    items:  Array< {
      __typename: "LanguageLabel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      createdAt: string,
      submittedAt?: string | null,
      validatedAt?: string | null,
      submitteduserID?: string | null,
      submittedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      validateduserID?: string | null,
      validatedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      goldtranscriptID: string,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      starttime: string,
      endtime: string,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID?: string | null,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      content: string,
      contentJson?: string | null,
      sentimentJson?: string | null,
      contentOrigin?: string | null,
      contentJsonOrigin?: string | null,
      sentimentJsonOrigin?: string | null,
      validationStatus?: ValidationStatus | null,
      feedbackMessage?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetEnvironmentStatusQueryVariables = {
  id: string,
};

export type GetEnvironmentStatusQuery = {
  getEnvironmentStatus?:  {
    __typename: "EnvironmentStatus",
    id: string,
    underMaintanence: boolean,
    notes?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEnvironmentStatusesQueryVariables = {
  filter?: ModelEnvironmentStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEnvironmentStatusesQuery = {
  listEnvironmentStatuses?:  {
    __typename: "ModelEnvironmentStatusConnection",
    items:  Array< {
      __typename: "EnvironmentStatus",
      id: string,
      underMaintanence: boolean,
      notes?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEnvironmentStatusesQueryVariables = {
  filter?: ModelEnvironmentStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEnvironmentStatusesQuery = {
  syncEnvironmentStatuses?:  {
    __typename: "ModelEnvironmentStatusConnection",
    items:  Array< {
      __typename: "EnvironmentStatus",
      id: string,
      underMaintanence: boolean,
      notes?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?:  {
    __typename: "Client",
    id: string,
    displayName: string,
    cognitoGroupName: string,
    Contextchannels?:  {
      __typename: "ModelContextchannelConnection",
      items:  Array< {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerConnection",
      items:  Array< {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClientsQuery = {
  syncClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWordTaggerModuleOutputQueryVariables = {
  id: string,
};

export type GetWordTaggerModuleOutputQuery = {
  getWordTaggerModuleOutput?:  {
    __typename: "WordTaggerModuleOutput",
    id: string,
    createdAt: string,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    taggedWords?:  Array< {
      __typename: "TaggedWord",
      text?: string | null,
      tag?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWordTaggerModuleOutputsQueryVariables = {
  filter?: ModelWordTaggerModuleOutputFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWordTaggerModuleOutputsQuery = {
  listWordTaggerModuleOutputs?:  {
    __typename: "ModelWordTaggerModuleOutputConnection",
    items:  Array< {
      __typename: "WordTaggerModuleOutput",
      id: string,
      createdAt: string,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      taggedWords?:  Array< {
        __typename: "TaggedWord",
        text?: string | null,
        tag?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWordTaggerModuleOutputsQueryVariables = {
  filter?: ModelWordTaggerModuleOutputFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWordTaggerModuleOutputsQuery = {
  syncWordTaggerModuleOutputs?:  {
    __typename: "ModelWordTaggerModuleOutputConnection",
    items:  Array< {
      __typename: "WordTaggerModuleOutput",
      id: string,
      createdAt: string,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      taggedWords?:  Array< {
        __typename: "TaggedWord",
        text?: string | null,
        tag?: string | null,
      } | null > | null,
      categories?: Array< string | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetContextchannelQueryVariables = {
  id: string,
};

export type GetContextchannelQuery = {
  getContextchannel?:  {
    __typename: "Contextchannel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    s3ImageUrl?: string | null,
    reference_id?: string | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUsers?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dynamicConfig?: string | null,
    wordTaggerModuleSettings?:  {
      __typename: "WordTaggerModuleSettings",
      enabled?: boolean | null,
      categories?: Array< string | null > | null,
    } | null,
    gnlpConfig?:  Array< {
      __typename: "GNLPPrompt",
      prompt: string,
      maxTokens: number,
      temperature: number,
      key: string,
      frequencyPenalty: number,
      presencePenalty: number,
      languageISO?: string | null,
    } | null > | null,
    gnlpTasks?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    precomputedWordCountsS3Key?: string | null,
    ClusteringModuleOutputs?:  {
      __typename: "ModelClusteringModuleOutputConnection",
      items:  Array< {
        __typename: "ClusteringModuleOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        key: string,
        leafIDs: Array< string | null >,
        linkageMatrixS3Url: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    posmodelID?: string | null,
    POSModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nermodelID?: string | null,
    NERModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    summarymodelID?: string | null,
    SummaryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sentimentmodelID?: string | null,
    SentimentModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categorymodelID?: string | null,
    CategoryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelLogs?:  {
      __typename: "ModelContextchannelLogConnection",
      items:  Array< {
        __typename: "ContextchannelLog",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        clientID: string,
        contextchannelID: string,
        type: ContextchannelLogType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListContextchannelsQueryVariables = {
  filter?: ModelContextchannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContextchannelsQuery = {
  listContextchannels?:  {
    __typename: "ModelContextchannelConnection",
    items:  Array< {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncContextchannelsQueryVariables = {
  filter?: ModelContextchannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncContextchannelsQuery = {
  syncContextchannels?:  {
    __typename: "ModelContextchannelConnection",
    items:  Array< {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetContextchannelLogQueryVariables = {
  id: string,
};

export type GetContextchannelLogQuery = {
  getContextchannelLog?:  {
    __typename: "ContextchannelLog",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: ContextchannelLogType,
    ApiCallLogEntry?:  {
      __typename: "ApiCallLogEntry",
      ipAddress: string,
      userName: string,
      method: RequestMethod,
      route: string,
      httpVersion: string,
      statusCode: number,
      userAgent: string,
    } | null,
    SessionStatusUpdateLogEntry?:  {
      __typename: "SessionStatusUpdateLogEntry",
      sessionID: string,
      sessionName?: string | null,
      attributeName: string,
      oldValue: string,
      newValue: string,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListContextchannelLogsQueryVariables = {
  filter?: ModelContextchannelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContextchannelLogsQuery = {
  listContextchannelLogs?:  {
    __typename: "ModelContextchannelLogConnection",
    items:  Array< {
      __typename: "ContextchannelLog",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      createdAt: string,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: ContextchannelLogType,
      ApiCallLogEntry?:  {
        __typename: "ApiCallLogEntry",
        ipAddress: string,
        userName: string,
        method: RequestMethod,
        route: string,
        httpVersion: string,
        statusCode: number,
        userAgent: string,
      } | null,
      SessionStatusUpdateLogEntry?:  {
        __typename: "SessionStatusUpdateLogEntry",
        sessionID: string,
        sessionName?: string | null,
        attributeName: string,
        oldValue: string,
        newValue: string,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncContextchannelLogsQueryVariables = {
  filter?: ModelContextchannelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncContextchannelLogsQuery = {
  syncContextchannelLogs?:  {
    __typename: "ModelContextchannelLogConnection",
    items:  Array< {
      __typename: "ContextchannelLog",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      createdAt: string,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: ContextchannelLogType,
      ApiCallLogEntry?:  {
        __typename: "ApiCallLogEntry",
        ipAddress: string,
        userName: string,
        method: RequestMethod,
        route: string,
        httpVersion: string,
        statusCode: number,
        userAgent: string,
      } | null,
      SessionStatusUpdateLogEntry?:  {
        __typename: "SessionStatusUpdateLogEntry",
        sessionID: string,
        sessionName?: string | null,
        attributeName: string,
        oldValue: string,
        newValue: string,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSessionQueryVariables = {
  id: string,
};

export type GetSessionQuery = {
  getSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    name?: string | null,
    clientID: string,
    allowedCognitoGroups?: Array< string | null > | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    wordTaggerModuleOutputs?:  {
      __typename: "ModelWordTaggerModuleOutputConnection",
      items:  Array< {
        __typename: "WordTaggerModuleOutput",
        id: string,
        createdAt: string,
        clientID: string,
        sessionID: string,
        categories?: Array< string | null > | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gnlpOutputs?: string | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    title?: string | null,
    summary?: string | null,
    keywords?: Array< string | null > | null,
    rewrittenS3Key?: string | null,
    condensed?: string | null,
    category?: string | null,
    sourceFileS3Key?: string | null,
    s3ImageUrl?: string | null,
    metadataID?: string | null,
    Metadata?:  {
      __typename: "Metadata",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      metadataS3Key?: string | null,
      durationSeconds?: number | null,
      metadataStatus?: MetadataStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    audioID?: string | null,
    Audio?:  {
      __typename: "Audio",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      uncompressedAudioS3Key?: string | null,
      compressedAudioS3Key?: string | null,
      waveformDataS3Key?: string | null,
      audioStatus?: AudioStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    videoID?: string | null,
    Video?:  {
      __typename: "Video",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      compressedVideoS3Key?: string | null,
      videoStatus?: VideoStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    autotranscriptID?: string | null,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    goldtranscriptID?: string | null,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customtranscriptID?: string | null,
    CustomTranscript?:  {
      __typename: "CustomTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    metadataStatus?: MetadataStatus | null,
    audioStatus?: AudioStatus | null,
    videoStatus?: VideoStatus | null,
    transcriptionStatus?: TranscriptionStatus | null,
    diarizationStatus?: DiarizationStatus | null,
    subtitleStatus?: SubtitleStatus | null,
    gnlpStatus?: GNLPStatus | null,
    nlpStatus?: NLPStatus | null,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Speakers?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    extra?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSessionsQueryVariables = {
  filter?: ModelSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSessionsQuery = {
  listSessions?:  {
    __typename: "ModelSessionConnection",
    items:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSessionsQueryVariables = {
  filter?: ModelSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSessionsQuery = {
  syncSessions?:  {
    __typename: "ModelSessionConnection",
    items:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNLPModelVersionQueryVariables = {
  id: string,
};

export type GetNLPModelVersionQuery = {
  getNLPModelVersion?:  {
    __typename: "NLPModelVersion",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    HFModelType?: HFModelType | null,
    parentmodelID?: string | null,
    ParentModel?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    childModels?:  {
      __typename: "ModelNLPModelVersionConnection",
      items:  Array< {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    modelVersionType?: ModelVersionType | null,
    checkpointKey?: string | null,
    checkpointStorageType?: CheckpointStorageType | null,
    inputlanguageID: string,
    inputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputlanguageID: string,
    outputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputParameters?:  {
      __typename: "InputParameters",
      includeSpeakerInInput?: boolean | null,
      parseSpeakerFromOutput?: boolean | null,
      batchSize?: number | null,
      maxInputChunkLength?: number | null,
      inputChunkOverlapTokens?: number | null,
      outputChunkOverlapTokens?: number | null,
      outputChunkOverlapDiscardLastTokens?: number | null,
      truncateInput?: boolean | null,
      maxRecursionDepth?: number | null,
      maxRecursiveOutputLength?: number | null,
      allowConcatenatedOutput?: boolean | null,
      badGenerationResampleTries?: number | null,
      inputPrefix?: string | null,
      inputSuffix?: string | null,
      outputStartWith?: string | null,
      outputSeperators?: Array< string | null > | null,
      returnNMostFrequentOutputs?: number | null,
    } | null,
    inferenceParameters?:  {
      __typename: "InferenceParameters",
      useCache?: boolean | null,
      maxNewTokens?: number | null,
      minNewTokens?: number | null,
      earlyStopping?: boolean | null,
      doSample?: boolean | null,
      numBeams?: number | null,
      penaltyAlpha?: number | null,
      temperature?: number | null,
      topK?: number | null,
      topP?: number | null,
      diversityPenalty?: number | null,
      lengthPenalty?: number | null,
      exponentialDecayLengthPenaltyStartIndex?: number | null,
      exponentialDecayLengthPenaltyDecayFactor?: number | null,
      repetitionPenalty?: number | null,
      encoderRepetitionPenalty?: number | null,
      noRepeatNgramSize?: number | null,
      encoderNoRepeatNgramSize?: number | null,
      forceWords?: Array< Array< string | null > | null > | null,
      badWords?: Array< string | null > | null,
      suppressTokens?: Array< number | null > | null,
    } | null,
    modelParameters?:  {
      __typename: "ModelParameters",
      hasQuantizedWeights?: boolean | null,
      skipQuantizeLayers?: Array< string | null > | null,
      gptqWbits?: number | null,
      gptqGroupsize?: number | null,
      loadIn8bit?: boolean | null,
      loadIn8bitThreshold?: number | null,
      loadIn8bitSkipModules?: Array< string | null > | null,
      torchDtype?: string | null,
      fromTf?: boolean | null,
      fromFlax?: boolean | null,
      ignoreMismatchedSizes?: boolean | null,
    } | null,
    openAIParameters?:  {
      __typename: "OpenAIParameters",
      endpoint: string,
      model: string,
      temperature?: number | null,
      maxTokens?: number | null,
      topP?: number | null,
      frequencyPenalty?: number | null,
      presencePenalty?: number | null,
      stop?: Array< string | null > | null,
    } | null,
    outputType: NLPOutputType,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNLPModelVersionsQueryVariables = {
  filter?: ModelNLPModelVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNLPModelVersionsQuery = {
  listNLPModelVersions?:  {
    __typename: "ModelNLPModelVersionConnection",
    items:  Array< {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNLPModelVersionsQueryVariables = {
  filter?: ModelNLPModelVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNLPModelVersionsQuery = {
  syncNLPModelVersions?:  {
    __typename: "ModelNLPModelVersionConnection",
    items:  Array< {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNLPStepQueryVariables = {
  id: string,
};

export type GetNLPStepQuery = {
  getNLPStep?:  {
    __typename: "NLPStep",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    outputKey: string,
    outputLanguages?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    inputstepID?: string | null,
    InputStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    OutputSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNLPStepsQueryVariables = {
  filter?: ModelNLPStepFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNLPStepsQuery = {
  listNLPSteps?:  {
    __typename: "ModelNLPStepConnection",
    items:  Array< {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNLPStepsQueryVariables = {
  filter?: ModelNLPStepFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNLPStepsQuery = {
  syncNLPSteps?:  {
    __typename: "ModelNLPStepConnection",
    items:  Array< {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNLPOutputQueryVariables = {
  id: string,
};

export type GetNLPOutputQuery = {
  getNLPOutput?:  {
    __typename: "NLPOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpstepID: string,
    NLPStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputnlpoutputID?: string | null,
    inputNLPOutput?:  {
      __typename: "NLPOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpstepID: string,
      NLPStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputnlpoutputID?: string | null,
      inputNLPOutput?:  {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputNLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      key: string,
      outputType: NLPOutputType,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputNLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    key: string,
    outputType: NLPOutputType,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNLPOutputsQueryVariables = {
  filter?: ModelNLPOutputFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNLPOutputsQuery = {
  listNLPOutputs?:  {
    __typename: "ModelNLPOutputConnection",
    items:  Array< {
      __typename: "NLPOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpstepID: string,
      NLPStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputnlpoutputID?: string | null,
      inputNLPOutput?:  {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputNLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      key: string,
      outputType: NLPOutputType,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNLPOutputsQueryVariables = {
  filter?: ModelNLPOutputFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNLPOutputsQuery = {
  syncNLPOutputs?:  {
    __typename: "ModelNLPOutputConnection",
    items:  Array< {
      __typename: "NLPOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpstepID: string,
      NLPStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputnlpoutputID?: string | null,
      inputNLPOutput?:  {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputNLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      key: string,
      outputType: NLPOutputType,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClusteringModuleOutputQueryVariables = {
  id: string,
};

export type GetClusteringModuleOutputQuery = {
  getClusteringModuleOutput?:  {
    __typename: "ClusteringModuleOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    key: string,
    leafIDs: Array< string | null >,
    linkageMatrixS3Url: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListClusteringModuleOutputsQueryVariables = {
  filter?: ModelClusteringModuleOutputFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClusteringModuleOutputsQuery = {
  listClusteringModuleOutputs?:  {
    __typename: "ModelClusteringModuleOutputConnection",
    items:  Array< {
      __typename: "ClusteringModuleOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      key: string,
      leafIDs: Array< string | null >,
      linkageMatrixS3Url: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClusteringModuleOutputsQueryVariables = {
  filter?: ModelClusteringModuleOutputFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClusteringModuleOutputsQuery = {
  syncClusteringModuleOutputs?:  {
    __typename: "ModelClusteringModuleOutputConnection",
    items:  Array< {
      __typename: "ClusteringModuleOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      key: string,
      leafIDs: Array< string | null >,
      linkageMatrixS3Url: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMetadataQueryVariables = {
  id: string,
};

export type GetMetadataQuery = {
  getMetadata?:  {
    __typename: "Metadata",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    metadataS3Key?: string | null,
    durationSeconds?: number | null,
    metadataStatus?: MetadataStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMetadataQueryVariables = {
  filter?: ModelMetadataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMetadataQuery = {
  listMetadata?:  {
    __typename: "ModelMetadataConnection",
    items:  Array< {
      __typename: "Metadata",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      metadataS3Key?: string | null,
      durationSeconds?: number | null,
      metadataStatus?: MetadataStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMetadataQueryVariables = {
  filter?: ModelMetadataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMetadataQuery = {
  syncMetadata?:  {
    __typename: "ModelMetadataConnection",
    items:  Array< {
      __typename: "Metadata",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      metadataS3Key?: string | null,
      durationSeconds?: number | null,
      metadataStatus?: MetadataStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAudioQueryVariables = {
  id: string,
};

export type GetAudioQuery = {
  getAudio?:  {
    __typename: "Audio",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    uncompressedAudioS3Key?: string | null,
    compressedAudioS3Key?: string | null,
    waveformDataS3Key?: string | null,
    audioStatus?: AudioStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAudioQueryVariables = {
  filter?: ModelAudioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAudioQuery = {
  listAudio?:  {
    __typename: "ModelAudioConnection",
    items:  Array< {
      __typename: "Audio",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      uncompressedAudioS3Key?: string | null,
      compressedAudioS3Key?: string | null,
      waveformDataS3Key?: string | null,
      audioStatus?: AudioStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAudioQueryVariables = {
  filter?: ModelAudioFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAudioQuery = {
  syncAudio?:  {
    __typename: "ModelAudioConnection",
    items:  Array< {
      __typename: "Audio",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      uncompressedAudioS3Key?: string | null,
      compressedAudioS3Key?: string | null,
      waveformDataS3Key?: string | null,
      audioStatus?: AudioStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVideoQueryVariables = {
  id: string,
};

export type GetVideoQuery = {
  getVideo?:  {
    __typename: "Video",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    compressedVideoS3Key?: string | null,
    videoStatus?: VideoStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListVideosQueryVariables = {
  filter?: ModelVideoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVideosQuery = {
  listVideos?:  {
    __typename: "ModelVideoConnection",
    items:  Array< {
      __typename: "Video",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      compressedVideoS3Key?: string | null,
      videoStatus?: VideoStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVideosQueryVariables = {
  filter?: ModelVideoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVideosQuery = {
  syncVideos?:  {
    __typename: "ModelVideoConnection",
    items:  Array< {
      __typename: "Video",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      compressedVideoS3Key?: string | null,
      videoStatus?: VideoStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAutoTranscriptQueryVariables = {
  id: string,
};

export type GetAutoTranscriptQuery = {
  getAutoTranscript?:  {
    __typename: "AutoTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transcriptSourceS3Key?: string | null,
    transcriptStandardizedS3Key?: string | null,
    transcriptTranslationS3Keys?: string | null,
    transcriptionStatus?: TranscriptionStatus | null,
    exportPlainTextS3Key?: string | null,
    exportJsonS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    diarizationS3Key?: string | null,
    summaryS3Key?: string | null,
    categoriesJson?: string | null,
    autotranscriptstatsID?: string | null,
    AutoTranscriptStats?:  {
      __typename: "AutoTranscriptStats",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      unknowns?: number | null,
      wordCount?: number | null,
      unknownPercentage?: number | null,
      globalConfidence?: number | null,
      knownConfidence?: number | null,
      autotranscriptID: string,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAutoTranscriptsQueryVariables = {
  filter?: ModelAutoTranscriptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAutoTranscriptsQuery = {
  listAutoTranscripts?:  {
    __typename: "ModelAutoTranscriptConnection",
    items:  Array< {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAutoTranscriptsQueryVariables = {
  filter?: ModelAutoTranscriptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAutoTranscriptsQuery = {
  syncAutoTranscripts?:  {
    __typename: "ModelAutoTranscriptConnection",
    items:  Array< {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGoldTranscriptQueryVariables = {
  id: string,
};

export type GetGoldTranscriptQuery = {
  getGoldTranscript?:  {
    __typename: "GoldTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListGoldTranscriptsQueryVariables = {
  filter?: ModelGoldTranscriptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoldTranscriptsQuery = {
  listGoldTranscripts?:  {
    __typename: "ModelGoldTranscriptConnection",
    items:  Array< {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGoldTranscriptsQueryVariables = {
  filter?: ModelGoldTranscriptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGoldTranscriptsQuery = {
  syncGoldTranscripts?:  {
    __typename: "ModelGoldTranscriptConnection",
    items:  Array< {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomTranscriptQueryVariables = {
  id: string,
};

export type GetCustomTranscriptQuery = {
  getCustomTranscript?:  {
    __typename: "CustomTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomTranscriptsQueryVariables = {
  filter?: ModelCustomTranscriptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomTranscriptsQuery = {
  listCustomTranscripts?:  {
    __typename: "ModelCustomTranscriptConnection",
    items:  Array< {
      __typename: "CustomTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomTranscriptsQueryVariables = {
  filter?: ModelCustomTranscriptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomTranscriptsQuery = {
  syncCustomTranscripts?:  {
    __typename: "ModelCustomTranscriptConnection",
    items:  Array< {
      __typename: "CustomTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAutoTranscriptStatsQueryVariables = {
  id: string,
};

export type GetAutoTranscriptStatsQuery = {
  getAutoTranscriptStats?:  {
    __typename: "AutoTranscriptStats",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    unknowns?: number | null,
    wordCount?: number | null,
    unknownPercentage?: number | null,
    globalConfidence?: number | null,
    knownConfidence?: number | null,
    autotranscriptID: string,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAutoTranscriptStatsQueryVariables = {
  filter?: ModelAutoTranscriptStatsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAutoTranscriptStatsQuery = {
  listAutoTranscriptStats?:  {
    __typename: "ModelAutoTranscriptStatsConnection",
    items:  Array< {
      __typename: "AutoTranscriptStats",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      unknowns?: number | null,
      wordCount?: number | null,
      unknownPercentage?: number | null,
      globalConfidence?: number | null,
      knownConfidence?: number | null,
      autotranscriptID: string,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAutoTranscriptStatsQueryVariables = {
  filter?: ModelAutoTranscriptStatsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAutoTranscriptStatsQuery = {
  syncAutoTranscriptStats?:  {
    __typename: "ModelAutoTranscriptStatsConnection",
    items:  Array< {
      __typename: "AutoTranscriptStats",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      unknowns?: number | null,
      wordCount?: number | null,
      unknownPercentage?: number | null,
      globalConfidence?: number | null,
      knownConfidence?: number | null,
      autotranscriptID: string,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSpeechModelQueryVariables = {
  id: string,
};

export type GetSpeechModelQuery = {
  getSpeechModel?:  {
    __typename: "SpeechModel",
    id: string,
    name?: string | null,
    description?: string | null,
    s3ImageUrl?: string | null,
    allowedCognitoGroups?: Array< string | null > | null,
    provider: SpeechModelProvider,
    connectionString?: string | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSpeechModelsQueryVariables = {
  filter?: ModelSpeechModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpeechModelsQuery = {
  listSpeechModels?:  {
    __typename: "ModelSpeechModelConnection",
    items:  Array< {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSpeechModelsQueryVariables = {
  filter?: ModelSpeechModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSpeechModelsQuery = {
  syncSpeechModels?:  {
    __typename: "ModelSpeechModelConnection",
    items:  Array< {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLanguageLabelQueryVariables = {
  id: string,
};

export type GetLanguageLabelQuery = {
  getLanguageLabel?:  {
    __typename: "LanguageLabel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    submittedAt?: string | null,
    validatedAt?: string | null,
    submitteduserID?: string | null,
    submittedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    validateduserID?: string | null,
    validatedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    goldtranscriptID: string,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    starttime: string,
    endtime: string,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID?: string | null,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    content: string,
    contentJson?: string | null,
    sentimentJson?: string | null,
    contentOrigin?: string | null,
    contentJsonOrigin?: string | null,
    sentimentJsonOrigin?: string | null,
    validationStatus?: ValidationStatus | null,
    feedbackMessage?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLanguageLabelsQueryVariables = {
  filter?: ModelLanguageLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLanguageLabelsQuery = {
  listLanguageLabels?:  {
    __typename: "ModelLanguageLabelConnection",
    items:  Array< {
      __typename: "LanguageLabel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      createdAt: string,
      submittedAt?: string | null,
      validatedAt?: string | null,
      submitteduserID?: string | null,
      submittedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      validateduserID?: string | null,
      validatedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      goldtranscriptID: string,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      starttime: string,
      endtime: string,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID?: string | null,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      content: string,
      contentJson?: string | null,
      sentimentJson?: string | null,
      contentOrigin?: string | null,
      contentJsonOrigin?: string | null,
      sentimentJsonOrigin?: string | null,
      validationStatus?: ValidationStatus | null,
      feedbackMessage?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLanguageLabelsQueryVariables = {
  filter?: ModelLanguageLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLanguageLabelsQuery = {
  syncLanguageLabels?:  {
    __typename: "ModelLanguageLabelConnection",
    items:  Array< {
      __typename: "LanguageLabel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      createdAt: string,
      submittedAt?: string | null,
      validatedAt?: string | null,
      submitteduserID?: string | null,
      submittedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      validateduserID?: string | null,
      validatedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      goldtranscriptID: string,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      starttime: string,
      endtime: string,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID?: string | null,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      content: string,
      contentJson?: string | null,
      sentimentJson?: string | null,
      contentOrigin?: string | null,
      contentJsonOrigin?: string | null,
      sentimentJsonOrigin?: string | null,
      validationStatus?: ValidationStatus | null,
      feedbackMessage?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNLPModelQueryVariables = {
  id: string,
};

export type GetNLPModelQuery = {
  getNLPModel?:  {
    __typename: "NLPModel",
    id: string,
    modelTrainedAt?: string | null,
    name?: string | null,
    huggingfaceRef?: string | null,
    pipelineParametersJson?: string | null,
    inferenceParametersJson?: string | null,
    otherParametersJson?: string | null,
    basemodelID?: string | null,
    BaseModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ChildModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nlpmodeltypeID: string,
    NLPModelType?:  {
      __typename: "NLPModelType",
      id: string,
      name: string,
      huggingfaceTask: HuggingfaceTask,
      NLPModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNLPModelsQueryVariables = {
  filter?: ModelNLPModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNLPModelsQuery = {
  listNLPModels?:  {
    __typename: "ModelNLPModelConnection",
    items:  Array< {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNLPModelsQueryVariables = {
  filter?: ModelNLPModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNLPModelsQuery = {
  syncNLPModels?:  {
    __typename: "ModelNLPModelConnection",
    items:  Array< {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNLPModelTypeQueryVariables = {
  id: string,
};

export type GetNLPModelTypeQuery = {
  getNLPModelType?:  {
    __typename: "NLPModelType",
    id: string,
    name: string,
    huggingfaceTask: HuggingfaceTask,
    NLPModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNLPModelTypesQueryVariables = {
  filter?: ModelNLPModelTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNLPModelTypesQuery = {
  listNLPModelTypes?:  {
    __typename: "ModelNLPModelTypeConnection",
    items:  Array< {
      __typename: "NLPModelType",
      id: string,
      name: string,
      huggingfaceTask: HuggingfaceTask,
      NLPModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNLPModelTypesQueryVariables = {
  filter?: ModelNLPModelTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNLPModelTypesQuery = {
  syncNLPModelTypes?:  {
    __typename: "ModelNLPModelTypeConnection",
    items:  Array< {
      __typename: "NLPModelType",
      id: string,
      name: string,
      huggingfaceTask: HuggingfaceTask,
      NLPModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSpeakerQueryVariables = {
  id: string,
};

export type GetSpeakerQuery = {
  getSpeaker?:  {
    __typename: "Speaker",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    firstName: string,
    infix?: string | null,
    lastName: string,
    gender: Gender,
    Subscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Languages?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSpeakersQueryVariables = {
  filter?: ModelSpeakerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpeakersQuery = {
  listSpeakers?:  {
    __typename: "ModelSpeakerConnection",
    items:  Array< {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSpeakersQueryVariables = {
  filter?: ModelSpeakerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSpeakersQuery = {
  syncSpeakers?:  {
    __typename: "ModelSpeakerConnection",
    items:  Array< {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLanguageQueryVariables = {
  id: string,
};

export type GetLanguageQuery = {
  getLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    ISO_639_1?: string | null,
    ISO_639_2: string,
    ISO_639_3?: string | null,
    stopwords?: Array< string | null > | null,
    LanguageDialects?:  {
      __typename: "ModelLanguageDialectConnection",
      items:  Array< {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLanguagesQueryVariables = {
  filter?: ModelLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLanguagesQuery = {
  listLanguages?:  {
    __typename: "ModelLanguageConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLanguagesQueryVariables = {
  filter?: ModelLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLanguagesQuery = {
  syncLanguages?:  {
    __typename: "ModelLanguageConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLanguageDialectQueryVariables = {
  id: string,
};

export type GetLanguageDialectQuery = {
  getLanguageDialect?:  {
    __typename: "LanguageDialect",
    id: string,
    name: string,
    ISO_639_2_dialect: string,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLanguageDialectsQueryVariables = {
  filter?: ModelLanguageDialectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLanguageDialectsQuery = {
  listLanguageDialects?:  {
    __typename: "ModelLanguageDialectConnection",
    items:  Array< {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLanguageDialectsQueryVariables = {
  filter?: ModelLanguageDialectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLanguageDialectsQuery = {
  syncLanguageDialects?:  {
    __typename: "ModelLanguageDialectConnection",
    items:  Array< {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    teamGroup?: string | null,
    isActive?: boolean | null,
    email?: string | null,
    needsEmailChange?: boolean | null,
    firstName?: string | null,
    infix?: string | null,
    lastName?: string | null,
    avatarS3Url?: string | null,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    submittedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validatedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedContextchannels?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelSubscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SessionSubscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeakerSubscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetContextchannelSubscriptionQueryVariables = {
  id: string,
};

export type GetContextchannelSubscriptionQuery = {
  getContextchannelSubscription?:  {
    __typename: "ContextchannelSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListContextchannelSubscriptionsQueryVariables = {
  filter?: ModelContextchannelSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContextchannelSubscriptionsQuery = {
  listContextchannelSubscriptions?:  {
    __typename: "ModelContextchannelSubscriptionConnection",
    items:  Array< {
      __typename: "ContextchannelSubscription",
      id: string,
      createdAt: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contextchannelID: string,
      contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncContextchannelSubscriptionsQueryVariables = {
  filter?: ModelContextchannelSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncContextchannelSubscriptionsQuery = {
  syncContextchannelSubscriptions?:  {
    __typename: "ModelContextchannelSubscriptionConnection",
    items:  Array< {
      __typename: "ContextchannelSubscription",
      id: string,
      createdAt: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contextchannelID: string,
      contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSessionSubscriptionQueryVariables = {
  id: string,
};

export type GetSessionSubscriptionQuery = {
  getSessionSubscription?:  {
    __typename: "SessionSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSessionSubscriptionsQueryVariables = {
  filter?: ModelSessionSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSessionSubscriptionsQuery = {
  listSessionSubscriptions?:  {
    __typename: "ModelSessionSubscriptionConnection",
    items:  Array< {
      __typename: "SessionSubscription",
      id: string,
      createdAt: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sessionID: string,
      session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSessionSubscriptionsQueryVariables = {
  filter?: ModelSessionSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSessionSubscriptionsQuery = {
  syncSessionSubscriptions?:  {
    __typename: "ModelSessionSubscriptionConnection",
    items:  Array< {
      __typename: "SessionSubscription",
      id: string,
      createdAt: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sessionID: string,
      session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSpeakerSubscriptionQueryVariables = {
  id: string,
};

export type GetSpeakerSubscriptionQuery = {
  getSpeakerSubscription?:  {
    __typename: "SpeakerSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSpeakerSubscriptionsQueryVariables = {
  filter?: ModelSpeakerSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpeakerSubscriptionsQuery = {
  listSpeakerSubscriptions?:  {
    __typename: "ModelSpeakerSubscriptionConnection",
    items:  Array< {
      __typename: "SpeakerSubscription",
      id: string,
      createdAt: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      speakerID: string,
      speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSpeakerSubscriptionsQueryVariables = {
  filter?: ModelSpeakerSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSpeakerSubscriptionsQuery = {
  syncSpeakerSubscriptions?:  {
    __typename: "ModelSpeakerSubscriptionConnection",
    items:  Array< {
      __typename: "SpeakerSubscription",
      id: string,
      createdAt: string,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      speakerID: string,
      speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ContextchannelsByClientByNameQueryVariables = {
  clientID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContextchannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContextchannelsByClientByNameQuery = {
  ContextchannelsByClientByName?:  {
    __typename: "ModelContextchannelConnection",
    items:  Array< {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ContextchannelLogsByContextchannelByTypeByCreatedAtQueryVariables = {
  contextchannelID: string,
  typeCreatedAt?: ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContextchannelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContextchannelLogsByContextchannelByTypeByCreatedAtQuery = {
  ContextchannelLogsByContextchannelByTypeByCreatedAt?:  {
    __typename: "ModelContextchannelLogConnection",
    items:  Array< {
      __typename: "ContextchannelLog",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      createdAt: string,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: ContextchannelLogType,
      ApiCallLogEntry?:  {
        __typename: "ApiCallLogEntry",
        ipAddress: string,
        userName: string,
        method: RequestMethod,
        route: string,
        httpVersion: string,
        statusCode: number,
        userAgent: string,
      } | null,
      SessionStatusUpdateLogEntry?:  {
        __typename: "SessionStatusUpdateLogEntry",
        sessionID: string,
        sessionName?: string | null,
        attributeName: string,
        oldValue: string,
        newValue: string,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SessionsByContextchannelByCreatedAtQueryVariables = {
  contextchannelID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SessionsByContextchannelByCreatedAtQuery = {
  SessionsByContextchannelByCreatedAt?:  {
    __typename: "ModelSessionConnection",
    items:  Array< {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ClusteringModuleOutputsByContextchannelByCreatedAtQueryVariables = {
  contextchannelID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClusteringModuleOutputFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClusteringModuleOutputsByContextchannelByCreatedAtQuery = {
  clusteringModuleOutputsByContextchannelByCreatedAt?:  {
    __typename: "ModelClusteringModuleOutputConnection",
    items:  Array< {
      __typename: "ClusteringModuleOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      key: string,
      leafIDs: Array< string | null >,
      linkageMatrixS3Url: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SpeechModelsByLanguageQueryVariables = {
  languageID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpeechModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpeechModelsByLanguageQuery = {
  SpeechModelsByLanguage?:  {
    __typename: "ModelSpeechModelConnection",
    items:  Array< {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SpeechModelsByLanguageDialectQueryVariables = {
  languagedialectID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpeechModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpeechModelsByLanguageDialectQuery = {
  SpeechModelsByLanguageDialect?:  {
    __typename: "ModelSpeechModelConnection",
    items:  Array< {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LanguageLabelsBySubmittedUserBySubmittedAtQueryVariables = {
  submitteduserID: string,
  submittedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLanguageLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LanguageLabelsBySubmittedUserBySubmittedAtQuery = {
  LanguageLabelsBySubmittedUserBySubmittedAt?:  {
    __typename: "ModelLanguageLabelConnection",
    items:  Array< {
      __typename: "LanguageLabel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      createdAt: string,
      submittedAt?: string | null,
      validatedAt?: string | null,
      submitteduserID?: string | null,
      submittedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      validateduserID?: string | null,
      validatedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      goldtranscriptID: string,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      starttime: string,
      endtime: string,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID?: string | null,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      content: string,
      contentJson?: string | null,
      sentimentJson?: string | null,
      contentOrigin?: string | null,
      contentJsonOrigin?: string | null,
      sentimentJsonOrigin?: string | null,
      validationStatus?: ValidationStatus | null,
      feedbackMessage?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LanguageLabelsByValidatedUserByValidatedAtQueryVariables = {
  validateduserID: string,
  validatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLanguageLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LanguageLabelsByValidatedUserByValidatedAtQuery = {
  LanguageLabelsByValidatedUserByValidatedAt?:  {
    __typename: "ModelLanguageLabelConnection",
    items:  Array< {
      __typename: "LanguageLabel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      createdAt: string,
      submittedAt?: string | null,
      validatedAt?: string | null,
      submitteduserID?: string | null,
      submittedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      validateduserID?: string | null,
      validatedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      goldtranscriptID: string,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      starttime: string,
      endtime: string,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID?: string | null,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      content: string,
      contentJson?: string | null,
      sentimentJson?: string | null,
      contentOrigin?: string | null,
      contentJsonOrigin?: string | null,
      sentimentJsonOrigin?: string | null,
      validationStatus?: ValidationStatus | null,
      feedbackMessage?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LanguageLabelsByGoldTranscriptByStarttimeQueryVariables = {
  goldtranscriptID: string,
  starttime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLanguageLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LanguageLabelsByGoldTranscriptByStarttimeQuery = {
  LanguageLabelsByGoldTranscriptByStarttime?:  {
    __typename: "ModelLanguageLabelConnection",
    items:  Array< {
      __typename: "LanguageLabel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      createdAt: string,
      submittedAt?: string | null,
      validatedAt?: string | null,
      submitteduserID?: string | null,
      submittedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      validateduserID?: string | null,
      validatedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      goldtranscriptID: string,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      starttime: string,
      endtime: string,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID?: string | null,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      content: string,
      contentJson?: string | null,
      sentimentJson?: string | null,
      contentOrigin?: string | null,
      contentJsonOrigin?: string | null,
      sentimentJsonOrigin?: string | null,
      validationStatus?: ValidationStatus | null,
      feedbackMessage?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LanguageLabelsBySpeakerByCreatedAtQueryVariables = {
  speakerID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLanguageLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LanguageLabelsBySpeakerByCreatedAtQuery = {
  LanguageLabelsBySpeakerByCreatedAt?:  {
    __typename: "ModelLanguageLabelConnection",
    items:  Array< {
      __typename: "LanguageLabel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      createdAt: string,
      submittedAt?: string | null,
      validatedAt?: string | null,
      submitteduserID?: string | null,
      submittedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      validateduserID?: string | null,
      validatedUser?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      goldtranscriptID: string,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      starttime: string,
      endtime: string,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID?: string | null,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      content: string,
      contentJson?: string | null,
      sentimentJson?: string | null,
      contentOrigin?: string | null,
      contentJsonOrigin?: string | null,
      sentimentJsonOrigin?: string | null,
      validationStatus?: ValidationStatus | null,
      feedbackMessage?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type NLPModelsByBaseModelByModelTrainedAtQueryVariables = {
  basemodelID: string,
  modelTrainedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNLPModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NLPModelsByBaseModelByModelTrainedAtQuery = {
  NLPModelsByBaseModelByModelTrainedAt?:  {
    __typename: "ModelNLPModelConnection",
    items:  Array< {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type NLPModelsByNLPModelTypeQueryVariables = {
  nlpmodeltypeID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNLPModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NLPModelsByNLPModelTypeQuery = {
  NLPModelsByNLPModelType?:  {
    __typename: "ModelNLPModelConnection",
    items:  Array< {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SpeakersByLanguageByLastNameQueryVariables = {
  languageID: string,
  lastName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpeakerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpeakersByLanguageByLastNameQuery = {
  SpeakersByLanguageByLastName?:  {
    __typename: "ModelSpeakerConnection",
    items:  Array< {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SpeakersByClientByLastNameQueryVariables = {
  clientID: string,
  lastName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpeakerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpeakersByClientByLastNameQuery = {
  SpeakersByClientByLastName?:  {
    __typename: "ModelSpeakerConnection",
    items:  Array< {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LanguageDialectsByLanguageByNameQueryVariables = {
  languageID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLanguageDialectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LanguageDialectsByLanguageByNameQuery = {
  LanguageDialectsByLanguageByName?:  {
    __typename: "ModelLanguageDialectConnection",
    items:  Array< {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UsersByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByEmailQuery = {
  UsersByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateEnvironmentStatusSubscription = {
  onCreateEnvironmentStatus?:  {
    __typename: "EnvironmentStatus",
    id: string,
    underMaintanence: boolean,
    notes?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEnvironmentStatusSubscription = {
  onUpdateEnvironmentStatus?:  {
    __typename: "EnvironmentStatus",
    id: string,
    underMaintanence: boolean,
    notes?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEnvironmentStatusSubscription = {
  onDeleteEnvironmentStatus?:  {
    __typename: "EnvironmentStatus",
    id: string,
    underMaintanence: boolean,
    notes?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateClientSubscription = {
  onCreateClient?:  {
    __typename: "Client",
    id: string,
    displayName: string,
    cognitoGroupName: string,
    Contextchannels?:  {
      __typename: "ModelContextchannelConnection",
      items:  Array< {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerConnection",
      items:  Array< {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateClientSubscription = {
  onUpdateClient?:  {
    __typename: "Client",
    id: string,
    displayName: string,
    cognitoGroupName: string,
    Contextchannels?:  {
      __typename: "ModelContextchannelConnection",
      items:  Array< {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerConnection",
      items:  Array< {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteClientSubscription = {
  onDeleteClient?:  {
    __typename: "Client",
    id: string,
    displayName: string,
    cognitoGroupName: string,
    Contextchannels?:  {
      __typename: "ModelContextchannelConnection",
      items:  Array< {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerConnection",
      items:  Array< {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWordTaggerModuleOutputSubscription = {
  onCreateWordTaggerModuleOutput?:  {
    __typename: "WordTaggerModuleOutput",
    id: string,
    createdAt: string,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    taggedWords?:  Array< {
      __typename: "TaggedWord",
      text?: string | null,
      tag?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWordTaggerModuleOutputSubscription = {
  onUpdateWordTaggerModuleOutput?:  {
    __typename: "WordTaggerModuleOutput",
    id: string,
    createdAt: string,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    taggedWords?:  Array< {
      __typename: "TaggedWord",
      text?: string | null,
      tag?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWordTaggerModuleOutputSubscription = {
  onDeleteWordTaggerModuleOutput?:  {
    __typename: "WordTaggerModuleOutput",
    id: string,
    createdAt: string,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    taggedWords?:  Array< {
      __typename: "TaggedWord",
      text?: string | null,
      tag?: string | null,
    } | null > | null,
    categories?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContextchannelSubscription = {
  onCreateContextchannel?:  {
    __typename: "Contextchannel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    s3ImageUrl?: string | null,
    reference_id?: string | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUsers?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dynamicConfig?: string | null,
    wordTaggerModuleSettings?:  {
      __typename: "WordTaggerModuleSettings",
      enabled?: boolean | null,
      categories?: Array< string | null > | null,
    } | null,
    gnlpConfig?:  Array< {
      __typename: "GNLPPrompt",
      prompt: string,
      maxTokens: number,
      temperature: number,
      key: string,
      frequencyPenalty: number,
      presencePenalty: number,
      languageISO?: string | null,
    } | null > | null,
    gnlpTasks?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    precomputedWordCountsS3Key?: string | null,
    ClusteringModuleOutputs?:  {
      __typename: "ModelClusteringModuleOutputConnection",
      items:  Array< {
        __typename: "ClusteringModuleOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        key: string,
        leafIDs: Array< string | null >,
        linkageMatrixS3Url: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    posmodelID?: string | null,
    POSModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nermodelID?: string | null,
    NERModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    summarymodelID?: string | null,
    SummaryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sentimentmodelID?: string | null,
    SentimentModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categorymodelID?: string | null,
    CategoryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelLogs?:  {
      __typename: "ModelContextchannelLogConnection",
      items:  Array< {
        __typename: "ContextchannelLog",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        clientID: string,
        contextchannelID: string,
        type: ContextchannelLogType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContextchannelSubscription = {
  onUpdateContextchannel?:  {
    __typename: "Contextchannel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    s3ImageUrl?: string | null,
    reference_id?: string | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUsers?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dynamicConfig?: string | null,
    wordTaggerModuleSettings?:  {
      __typename: "WordTaggerModuleSettings",
      enabled?: boolean | null,
      categories?: Array< string | null > | null,
    } | null,
    gnlpConfig?:  Array< {
      __typename: "GNLPPrompt",
      prompt: string,
      maxTokens: number,
      temperature: number,
      key: string,
      frequencyPenalty: number,
      presencePenalty: number,
      languageISO?: string | null,
    } | null > | null,
    gnlpTasks?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    precomputedWordCountsS3Key?: string | null,
    ClusteringModuleOutputs?:  {
      __typename: "ModelClusteringModuleOutputConnection",
      items:  Array< {
        __typename: "ClusteringModuleOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        key: string,
        leafIDs: Array< string | null >,
        linkageMatrixS3Url: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    posmodelID?: string | null,
    POSModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nermodelID?: string | null,
    NERModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    summarymodelID?: string | null,
    SummaryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sentimentmodelID?: string | null,
    SentimentModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categorymodelID?: string | null,
    CategoryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelLogs?:  {
      __typename: "ModelContextchannelLogConnection",
      items:  Array< {
        __typename: "ContextchannelLog",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        clientID: string,
        contextchannelID: string,
        type: ContextchannelLogType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContextchannelSubscription = {
  onDeleteContextchannel?:  {
    __typename: "Contextchannel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    s3ImageUrl?: string | null,
    reference_id?: string | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedUsers?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    dynamicConfig?: string | null,
    wordTaggerModuleSettings?:  {
      __typename: "WordTaggerModuleSettings",
      enabled?: boolean | null,
      categories?: Array< string | null > | null,
    } | null,
    gnlpConfig?:  Array< {
      __typename: "GNLPPrompt",
      prompt: string,
      maxTokens: number,
      temperature: number,
      key: string,
      frequencyPenalty: number,
      presencePenalty: number,
      languageISO?: string | null,
    } | null > | null,
    gnlpTasks?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    precomputedWordCountsS3Key?: string | null,
    ClusteringModuleOutputs?:  {
      __typename: "ModelClusteringModuleOutputConnection",
      items:  Array< {
        __typename: "ClusteringModuleOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        key: string,
        leafIDs: Array< string | null >,
        linkageMatrixS3Url: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    posmodelID?: string | null,
    POSModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nermodelID?: string | null,
    NERModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    summarymodelID?: string | null,
    SummaryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sentimentmodelID?: string | null,
    SentimentModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categorymodelID?: string | null,
    CategoryModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Speakers?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelLogs?:  {
      __typename: "ModelContextchannelLogConnection",
      items:  Array< {
        __typename: "ContextchannelLog",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        clientID: string,
        contextchannelID: string,
        type: ContextchannelLogType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContextchannelLanguagesSubscription = {
  onCreateContextchannelLanguages?:  {
    __typename: "ContextchannelLanguages",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContextchannelLanguagesSubscription = {
  onUpdateContextchannelLanguages?:  {
    __typename: "ContextchannelLanguages",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContextchannelLanguagesSubscription = {
  onDeleteContextchannelLanguages?:  {
    __typename: "ContextchannelLanguages",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContextchannelLanguageLanguageDialectsSubscription = {
  onCreateContextchannelLanguageLanguageDialects?:  {
    __typename: "ContextchannelLanguageLanguageDialects",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannellanguageID: string,
    contextchannellanguage?:  {
      __typename: "ContextchannelLanguages",
      id: string,
      createdAt: string,
      enabled?: boolean | null,
      contextchannelID: string,
      contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContextchannelLanguageLanguageDialectsSubscription = {
  onUpdateContextchannelLanguageLanguageDialects?:  {
    __typename: "ContextchannelLanguageLanguageDialects",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannellanguageID: string,
    contextchannellanguage?:  {
      __typename: "ContextchannelLanguages",
      id: string,
      createdAt: string,
      enabled?: boolean | null,
      contextchannelID: string,
      contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContextchannelLanguageLanguageDialectsSubscription = {
  onDeleteContextchannelLanguageLanguageDialects?:  {
    __typename: "ContextchannelLanguageLanguageDialects",
    id: string,
    createdAt: string,
    enabled?: boolean | null,
    contextchannellanguageID: string,
    contextchannellanguage?:  {
      __typename: "ContextchannelLanguages",
      id: string,
      createdAt: string,
      enabled?: boolean | null,
      contextchannelID: string,
      contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContextchannelSpeakersSubscription = {
  onCreateContextchannelSpeakers?:  {
    __typename: "ContextchannelSpeakers",
    id: string,
    createdAt: string,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContextchannelSpeakersSubscription = {
  onUpdateContextchannelSpeakers?:  {
    __typename: "ContextchannelSpeakers",
    id: string,
    createdAt: string,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContextchannelSpeakersSubscription = {
  onDeleteContextchannelSpeakers?:  {
    __typename: "ContextchannelSpeakers",
    id: string,
    createdAt: string,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContextchannelLogSubscription = {
  onCreateContextchannelLog?:  {
    __typename: "ContextchannelLog",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: ContextchannelLogType,
    ApiCallLogEntry?:  {
      __typename: "ApiCallLogEntry",
      ipAddress: string,
      userName: string,
      method: RequestMethod,
      route: string,
      httpVersion: string,
      statusCode: number,
      userAgent: string,
    } | null,
    SessionStatusUpdateLogEntry?:  {
      __typename: "SessionStatusUpdateLogEntry",
      sessionID: string,
      sessionName?: string | null,
      attributeName: string,
      oldValue: string,
      newValue: string,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContextchannelLogSubscription = {
  onUpdateContextchannelLog?:  {
    __typename: "ContextchannelLog",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: ContextchannelLogType,
    ApiCallLogEntry?:  {
      __typename: "ApiCallLogEntry",
      ipAddress: string,
      userName: string,
      method: RequestMethod,
      route: string,
      httpVersion: string,
      statusCode: number,
      userAgent: string,
    } | null,
    SessionStatusUpdateLogEntry?:  {
      __typename: "SessionStatusUpdateLogEntry",
      sessionID: string,
      sessionName?: string | null,
      attributeName: string,
      oldValue: string,
      newValue: string,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContextchannelLogSubscription = {
  onDeleteContextchannelLog?:  {
    __typename: "ContextchannelLog",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: ContextchannelLogType,
    ApiCallLogEntry?:  {
      __typename: "ApiCallLogEntry",
      ipAddress: string,
      userName: string,
      method: RequestMethod,
      route: string,
      httpVersion: string,
      statusCode: number,
      userAgent: string,
    } | null,
    SessionStatusUpdateLogEntry?:  {
      __typename: "SessionStatusUpdateLogEntry",
      sessionID: string,
      sessionName?: string | null,
      attributeName: string,
      oldValue: string,
      newValue: string,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSessionSubscription = {
  onCreateSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    name?: string | null,
    clientID: string,
    allowedCognitoGroups?: Array< string | null > | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    wordTaggerModuleOutputs?:  {
      __typename: "ModelWordTaggerModuleOutputConnection",
      items:  Array< {
        __typename: "WordTaggerModuleOutput",
        id: string,
        createdAt: string,
        clientID: string,
        sessionID: string,
        categories?: Array< string | null > | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gnlpOutputs?: string | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    title?: string | null,
    summary?: string | null,
    keywords?: Array< string | null > | null,
    rewrittenS3Key?: string | null,
    condensed?: string | null,
    category?: string | null,
    sourceFileS3Key?: string | null,
    s3ImageUrl?: string | null,
    metadataID?: string | null,
    Metadata?:  {
      __typename: "Metadata",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      metadataS3Key?: string | null,
      durationSeconds?: number | null,
      metadataStatus?: MetadataStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    audioID?: string | null,
    Audio?:  {
      __typename: "Audio",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      uncompressedAudioS3Key?: string | null,
      compressedAudioS3Key?: string | null,
      waveformDataS3Key?: string | null,
      audioStatus?: AudioStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    videoID?: string | null,
    Video?:  {
      __typename: "Video",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      compressedVideoS3Key?: string | null,
      videoStatus?: VideoStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    autotranscriptID?: string | null,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    goldtranscriptID?: string | null,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customtranscriptID?: string | null,
    CustomTranscript?:  {
      __typename: "CustomTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    metadataStatus?: MetadataStatus | null,
    audioStatus?: AudioStatus | null,
    videoStatus?: VideoStatus | null,
    transcriptionStatus?: TranscriptionStatus | null,
    diarizationStatus?: DiarizationStatus | null,
    subtitleStatus?: SubtitleStatus | null,
    gnlpStatus?: GNLPStatus | null,
    nlpStatus?: NLPStatus | null,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Speakers?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    extra?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSessionSubscription = {
  onUpdateSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    name?: string | null,
    clientID: string,
    allowedCognitoGroups?: Array< string | null > | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    wordTaggerModuleOutputs?:  {
      __typename: "ModelWordTaggerModuleOutputConnection",
      items:  Array< {
        __typename: "WordTaggerModuleOutput",
        id: string,
        createdAt: string,
        clientID: string,
        sessionID: string,
        categories?: Array< string | null > | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gnlpOutputs?: string | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    title?: string | null,
    summary?: string | null,
    keywords?: Array< string | null > | null,
    rewrittenS3Key?: string | null,
    condensed?: string | null,
    category?: string | null,
    sourceFileS3Key?: string | null,
    s3ImageUrl?: string | null,
    metadataID?: string | null,
    Metadata?:  {
      __typename: "Metadata",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      metadataS3Key?: string | null,
      durationSeconds?: number | null,
      metadataStatus?: MetadataStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    audioID?: string | null,
    Audio?:  {
      __typename: "Audio",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      uncompressedAudioS3Key?: string | null,
      compressedAudioS3Key?: string | null,
      waveformDataS3Key?: string | null,
      audioStatus?: AudioStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    videoID?: string | null,
    Video?:  {
      __typename: "Video",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      compressedVideoS3Key?: string | null,
      videoStatus?: VideoStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    autotranscriptID?: string | null,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    goldtranscriptID?: string | null,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customtranscriptID?: string | null,
    CustomTranscript?:  {
      __typename: "CustomTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    metadataStatus?: MetadataStatus | null,
    audioStatus?: AudioStatus | null,
    videoStatus?: VideoStatus | null,
    transcriptionStatus?: TranscriptionStatus | null,
    diarizationStatus?: DiarizationStatus | null,
    subtitleStatus?: SubtitleStatus | null,
    gnlpStatus?: GNLPStatus | null,
    nlpStatus?: NLPStatus | null,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Speakers?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    extra?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSessionSubscription = {
  onDeleteSession?:  {
    __typename: "Session",
    id: string,
    createdAt: string,
    name?: string | null,
    clientID: string,
    allowedCognitoGroups?: Array< string | null > | null,
    wordcloudSettings?:  {
      __typename: "WordcloudSettings",
      whitelist?: Array< string | null > | null,
      blacklist?: Array< string | null > | null,
    } | null,
    wordTaggerModuleOutputs?:  {
      __typename: "ModelWordTaggerModuleOutputConnection",
      items:  Array< {
        __typename: "WordTaggerModuleOutput",
        id: string,
        createdAt: string,
        clientID: string,
        sessionID: string,
        categories?: Array< string | null > | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gnlpOutputs?: string | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    title?: string | null,
    summary?: string | null,
    keywords?: Array< string | null > | null,
    rewrittenS3Key?: string | null,
    condensed?: string | null,
    category?: string | null,
    sourceFileS3Key?: string | null,
    s3ImageUrl?: string | null,
    metadataID?: string | null,
    Metadata?:  {
      __typename: "Metadata",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      metadataS3Key?: string | null,
      durationSeconds?: number | null,
      metadataStatus?: MetadataStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    audioID?: string | null,
    Audio?:  {
      __typename: "Audio",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      uncompressedAudioS3Key?: string | null,
      compressedAudioS3Key?: string | null,
      waveformDataS3Key?: string | null,
      audioStatus?: AudioStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    videoID?: string | null,
    Video?:  {
      __typename: "Video",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      compressedVideoS3Key?: string | null,
      videoStatus?: VideoStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    autotranscriptID?: string | null,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    goldtranscriptID?: string | null,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customtranscriptID?: string | null,
    CustomTranscript?:  {
      __typename: "CustomTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    metadataStatus?: MetadataStatus | null,
    audioStatus?: AudioStatus | null,
    videoStatus?: VideoStatus | null,
    transcriptionStatus?: TranscriptionStatus | null,
    diarizationStatus?: DiarizationStatus | null,
    subtitleStatus?: SubtitleStatus | null,
    gnlpStatus?: GNLPStatus | null,
    nlpStatus?: NLPStatus | null,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Speakers?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Languages?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    contactEmailAddresses?: Array< string | null > | null,
    Subscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    extra?: string | null,
    tags?:  Array< {
      __typename: "Tag",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNLPModelVersionSubscription = {
  onCreateNLPModelVersion?:  {
    __typename: "NLPModelVersion",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    HFModelType?: HFModelType | null,
    parentmodelID?: string | null,
    ParentModel?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    childModels?:  {
      __typename: "ModelNLPModelVersionConnection",
      items:  Array< {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    modelVersionType?: ModelVersionType | null,
    checkpointKey?: string | null,
    checkpointStorageType?: CheckpointStorageType | null,
    inputlanguageID: string,
    inputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputlanguageID: string,
    outputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputParameters?:  {
      __typename: "InputParameters",
      includeSpeakerInInput?: boolean | null,
      parseSpeakerFromOutput?: boolean | null,
      batchSize?: number | null,
      maxInputChunkLength?: number | null,
      inputChunkOverlapTokens?: number | null,
      outputChunkOverlapTokens?: number | null,
      outputChunkOverlapDiscardLastTokens?: number | null,
      truncateInput?: boolean | null,
      maxRecursionDepth?: number | null,
      maxRecursiveOutputLength?: number | null,
      allowConcatenatedOutput?: boolean | null,
      badGenerationResampleTries?: number | null,
      inputPrefix?: string | null,
      inputSuffix?: string | null,
      outputStartWith?: string | null,
      outputSeperators?: Array< string | null > | null,
      returnNMostFrequentOutputs?: number | null,
    } | null,
    inferenceParameters?:  {
      __typename: "InferenceParameters",
      useCache?: boolean | null,
      maxNewTokens?: number | null,
      minNewTokens?: number | null,
      earlyStopping?: boolean | null,
      doSample?: boolean | null,
      numBeams?: number | null,
      penaltyAlpha?: number | null,
      temperature?: number | null,
      topK?: number | null,
      topP?: number | null,
      diversityPenalty?: number | null,
      lengthPenalty?: number | null,
      exponentialDecayLengthPenaltyStartIndex?: number | null,
      exponentialDecayLengthPenaltyDecayFactor?: number | null,
      repetitionPenalty?: number | null,
      encoderRepetitionPenalty?: number | null,
      noRepeatNgramSize?: number | null,
      encoderNoRepeatNgramSize?: number | null,
      forceWords?: Array< Array< string | null > | null > | null,
      badWords?: Array< string | null > | null,
      suppressTokens?: Array< number | null > | null,
    } | null,
    modelParameters?:  {
      __typename: "ModelParameters",
      hasQuantizedWeights?: boolean | null,
      skipQuantizeLayers?: Array< string | null > | null,
      gptqWbits?: number | null,
      gptqGroupsize?: number | null,
      loadIn8bit?: boolean | null,
      loadIn8bitThreshold?: number | null,
      loadIn8bitSkipModules?: Array< string | null > | null,
      torchDtype?: string | null,
      fromTf?: boolean | null,
      fromFlax?: boolean | null,
      ignoreMismatchedSizes?: boolean | null,
    } | null,
    openAIParameters?:  {
      __typename: "OpenAIParameters",
      endpoint: string,
      model: string,
      temperature?: number | null,
      maxTokens?: number | null,
      topP?: number | null,
      frequencyPenalty?: number | null,
      presencePenalty?: number | null,
      stop?: Array< string | null > | null,
    } | null,
    outputType: NLPOutputType,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNLPModelVersionSubscription = {
  onUpdateNLPModelVersion?:  {
    __typename: "NLPModelVersion",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    HFModelType?: HFModelType | null,
    parentmodelID?: string | null,
    ParentModel?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    childModels?:  {
      __typename: "ModelNLPModelVersionConnection",
      items:  Array< {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    modelVersionType?: ModelVersionType | null,
    checkpointKey?: string | null,
    checkpointStorageType?: CheckpointStorageType | null,
    inputlanguageID: string,
    inputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputlanguageID: string,
    outputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputParameters?:  {
      __typename: "InputParameters",
      includeSpeakerInInput?: boolean | null,
      parseSpeakerFromOutput?: boolean | null,
      batchSize?: number | null,
      maxInputChunkLength?: number | null,
      inputChunkOverlapTokens?: number | null,
      outputChunkOverlapTokens?: number | null,
      outputChunkOverlapDiscardLastTokens?: number | null,
      truncateInput?: boolean | null,
      maxRecursionDepth?: number | null,
      maxRecursiveOutputLength?: number | null,
      allowConcatenatedOutput?: boolean | null,
      badGenerationResampleTries?: number | null,
      inputPrefix?: string | null,
      inputSuffix?: string | null,
      outputStartWith?: string | null,
      outputSeperators?: Array< string | null > | null,
      returnNMostFrequentOutputs?: number | null,
    } | null,
    inferenceParameters?:  {
      __typename: "InferenceParameters",
      useCache?: boolean | null,
      maxNewTokens?: number | null,
      minNewTokens?: number | null,
      earlyStopping?: boolean | null,
      doSample?: boolean | null,
      numBeams?: number | null,
      penaltyAlpha?: number | null,
      temperature?: number | null,
      topK?: number | null,
      topP?: number | null,
      diversityPenalty?: number | null,
      lengthPenalty?: number | null,
      exponentialDecayLengthPenaltyStartIndex?: number | null,
      exponentialDecayLengthPenaltyDecayFactor?: number | null,
      repetitionPenalty?: number | null,
      encoderRepetitionPenalty?: number | null,
      noRepeatNgramSize?: number | null,
      encoderNoRepeatNgramSize?: number | null,
      forceWords?: Array< Array< string | null > | null > | null,
      badWords?: Array< string | null > | null,
      suppressTokens?: Array< number | null > | null,
    } | null,
    modelParameters?:  {
      __typename: "ModelParameters",
      hasQuantizedWeights?: boolean | null,
      skipQuantizeLayers?: Array< string | null > | null,
      gptqWbits?: number | null,
      gptqGroupsize?: number | null,
      loadIn8bit?: boolean | null,
      loadIn8bitThreshold?: number | null,
      loadIn8bitSkipModules?: Array< string | null > | null,
      torchDtype?: string | null,
      fromTf?: boolean | null,
      fromFlax?: boolean | null,
      ignoreMismatchedSizes?: boolean | null,
    } | null,
    openAIParameters?:  {
      __typename: "OpenAIParameters",
      endpoint: string,
      model: string,
      temperature?: number | null,
      maxTokens?: number | null,
      topP?: number | null,
      frequencyPenalty?: number | null,
      presencePenalty?: number | null,
      stop?: Array< string | null > | null,
    } | null,
    outputType: NLPOutputType,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNLPModelVersionSubscription = {
  onDeleteNLPModelVersion?:  {
    __typename: "NLPModelVersion",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    name: string,
    HFModelType?: HFModelType | null,
    parentmodelID?: string | null,
    ParentModel?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    childModels?:  {
      __typename: "ModelNLPModelVersionConnection",
      items:  Array< {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    modelVersionType?: ModelVersionType | null,
    checkpointKey?: string | null,
    checkpointStorageType?: CheckpointStorageType | null,
    inputlanguageID: string,
    inputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputlanguageID: string,
    outputLanguage?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputParameters?:  {
      __typename: "InputParameters",
      includeSpeakerInInput?: boolean | null,
      parseSpeakerFromOutput?: boolean | null,
      batchSize?: number | null,
      maxInputChunkLength?: number | null,
      inputChunkOverlapTokens?: number | null,
      outputChunkOverlapTokens?: number | null,
      outputChunkOverlapDiscardLastTokens?: number | null,
      truncateInput?: boolean | null,
      maxRecursionDepth?: number | null,
      maxRecursiveOutputLength?: number | null,
      allowConcatenatedOutput?: boolean | null,
      badGenerationResampleTries?: number | null,
      inputPrefix?: string | null,
      inputSuffix?: string | null,
      outputStartWith?: string | null,
      outputSeperators?: Array< string | null > | null,
      returnNMostFrequentOutputs?: number | null,
    } | null,
    inferenceParameters?:  {
      __typename: "InferenceParameters",
      useCache?: boolean | null,
      maxNewTokens?: number | null,
      minNewTokens?: number | null,
      earlyStopping?: boolean | null,
      doSample?: boolean | null,
      numBeams?: number | null,
      penaltyAlpha?: number | null,
      temperature?: number | null,
      topK?: number | null,
      topP?: number | null,
      diversityPenalty?: number | null,
      lengthPenalty?: number | null,
      exponentialDecayLengthPenaltyStartIndex?: number | null,
      exponentialDecayLengthPenaltyDecayFactor?: number | null,
      repetitionPenalty?: number | null,
      encoderRepetitionPenalty?: number | null,
      noRepeatNgramSize?: number | null,
      encoderNoRepeatNgramSize?: number | null,
      forceWords?: Array< Array< string | null > | null > | null,
      badWords?: Array< string | null > | null,
      suppressTokens?: Array< number | null > | null,
    } | null,
    modelParameters?:  {
      __typename: "ModelParameters",
      hasQuantizedWeights?: boolean | null,
      skipQuantizeLayers?: Array< string | null > | null,
      gptqWbits?: number | null,
      gptqGroupsize?: number | null,
      loadIn8bit?: boolean | null,
      loadIn8bitThreshold?: number | null,
      loadIn8bitSkipModules?: Array< string | null > | null,
      torchDtype?: string | null,
      fromTf?: boolean | null,
      fromFlax?: boolean | null,
      ignoreMismatchedSizes?: boolean | null,
    } | null,
    openAIParameters?:  {
      __typename: "OpenAIParameters",
      endpoint: string,
      model: string,
      temperature?: number | null,
      maxTokens?: number | null,
      topP?: number | null,
      frequencyPenalty?: number | null,
      presencePenalty?: number | null,
      stop?: Array< string | null > | null,
    } | null,
    outputType: NLPOutputType,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNLPStepSubscription = {
  onCreateNLPStep?:  {
    __typename: "NLPStep",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    outputKey: string,
    outputLanguages?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    inputstepID?: string | null,
    InputStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    OutputSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNLPStepSubscription = {
  onUpdateNLPStep?:  {
    __typename: "NLPStep",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    outputKey: string,
    outputLanguages?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    inputstepID?: string | null,
    InputStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    OutputSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNLPStepSubscription = {
  onDeleteNLPStep?:  {
    __typename: "NLPStep",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    name: string,
    outputKey: string,
    outputLanguages?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    inputstepID?: string | null,
    InputStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    OutputSteps?:  {
      __typename: "ModelNLPStepConnection",
      items:  Array< {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNLPStepLanguagesSubscription = {
  onCreateNLPStepLanguages?:  {
    __typename: "NLPStepLanguages",
    id: string,
    createdAt: string,
    formality?: Formality | null,
    nlpstepID: string,
    nlpstep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNLPStepLanguagesSubscription = {
  onUpdateNLPStepLanguages?:  {
    __typename: "NLPStepLanguages",
    id: string,
    createdAt: string,
    formality?: Formality | null,
    nlpstepID: string,
    nlpstep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNLPStepLanguagesSubscription = {
  onDeleteNLPStepLanguages?:  {
    __typename: "NLPStepLanguages",
    id: string,
    createdAt: string,
    formality?: Formality | null,
    nlpstepID: string,
    nlpstep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNLPOutputSubscription = {
  onCreateNLPOutput?:  {
    __typename: "NLPOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpstepID: string,
    NLPStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputnlpoutputID?: string | null,
    inputNLPOutput?:  {
      __typename: "NLPOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpstepID: string,
      NLPStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputnlpoutputID?: string | null,
      inputNLPOutput?:  {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputNLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      key: string,
      outputType: NLPOutputType,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputNLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    key: string,
    outputType: NLPOutputType,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNLPOutputSubscription = {
  onUpdateNLPOutput?:  {
    __typename: "NLPOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpstepID: string,
    NLPStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputnlpoutputID?: string | null,
    inputNLPOutput?:  {
      __typename: "NLPOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpstepID: string,
      NLPStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputnlpoutputID?: string | null,
      inputNLPOutput?:  {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputNLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      key: string,
      outputType: NLPOutputType,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputNLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    key: string,
    outputType: NLPOutputType,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNLPOutputSubscription = {
  onDeleteNLPOutput?:  {
    __typename: "NLPOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpstepID: string,
    NLPStep?:  {
      __typename: "NLPStep",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      clientID: string,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      name: string,
      outputKey: string,
      outputLanguages?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      inputstepID?: string | null,
      InputStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      OutputSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    nlpmodelversionID: string,
    NLPModelVersion?:  {
      __typename: "NLPModelVersion",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      HFModelType?: HFModelType | null,
      parentmodelID?: string | null,
      ParentModel?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      childModels?:  {
        __typename: "ModelNLPModelVersionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      modelVersionType?: ModelVersionType | null,
      checkpointKey?: string | null,
      checkpointStorageType?: CheckpointStorageType | null,
      inputlanguageID: string,
      inputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputlanguageID: string,
      outputLanguage?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputParameters?:  {
        __typename: "InputParameters",
        includeSpeakerInInput?: boolean | null,
        parseSpeakerFromOutput?: boolean | null,
        batchSize?: number | null,
        maxInputChunkLength?: number | null,
        inputChunkOverlapTokens?: number | null,
        outputChunkOverlapTokens?: number | null,
        outputChunkOverlapDiscardLastTokens?: number | null,
        truncateInput?: boolean | null,
        maxRecursionDepth?: number | null,
        maxRecursiveOutputLength?: number | null,
        allowConcatenatedOutput?: boolean | null,
        badGenerationResampleTries?: number | null,
        inputPrefix?: string | null,
        inputSuffix?: string | null,
        outputStartWith?: string | null,
        outputSeperators?: Array< string | null > | null,
        returnNMostFrequentOutputs?: number | null,
      } | null,
      inferenceParameters?:  {
        __typename: "InferenceParameters",
        useCache?: boolean | null,
        maxNewTokens?: number | null,
        minNewTokens?: number | null,
        earlyStopping?: boolean | null,
        doSample?: boolean | null,
        numBeams?: number | null,
        penaltyAlpha?: number | null,
        temperature?: number | null,
        topK?: number | null,
        topP?: number | null,
        diversityPenalty?: number | null,
        lengthPenalty?: number | null,
        exponentialDecayLengthPenaltyStartIndex?: number | null,
        exponentialDecayLengthPenaltyDecayFactor?: number | null,
        repetitionPenalty?: number | null,
        encoderRepetitionPenalty?: number | null,
        noRepeatNgramSize?: number | null,
        encoderNoRepeatNgramSize?: number | null,
        forceWords?: Array< Array< string | null > | null > | null,
        badWords?: Array< string | null > | null,
        suppressTokens?: Array< number | null > | null,
      } | null,
      modelParameters?:  {
        __typename: "ModelParameters",
        hasQuantizedWeights?: boolean | null,
        skipQuantizeLayers?: Array< string | null > | null,
        gptqWbits?: number | null,
        gptqGroupsize?: number | null,
        loadIn8bit?: boolean | null,
        loadIn8bitThreshold?: number | null,
        loadIn8bitSkipModules?: Array< string | null > | null,
        torchDtype?: string | null,
        fromTf?: boolean | null,
        fromFlax?: boolean | null,
        ignoreMismatchedSizes?: boolean | null,
      } | null,
      openAIParameters?:  {
        __typename: "OpenAIParameters",
        endpoint: string,
        model: string,
        temperature?: number | null,
        maxTokens?: number | null,
        topP?: number | null,
        frequencyPenalty?: number | null,
        presencePenalty?: number | null,
        stop?: Array< string | null > | null,
      } | null,
      outputType: NLPOutputType,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inputnlpoutputID?: string | null,
    inputNLPOutput?:  {
      __typename: "NLPOutput",
      id: string,
      createdAt: string,
      allowedCognitoGroups?: Array< string | null > | null,
      clientID: string,
      contextchannelID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpstepID: string,
      NLPStep?:  {
        __typename: "NLPStep",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        clientID: string,
        contextchannelID: string,
        nlpmodelversionID: string,
        name: string,
        outputKey: string,
        inputstepID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nlpmodelversionID: string,
      NLPModelVersion?:  {
        __typename: "NLPModelVersion",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        HFModelType?: HFModelType | null,
        parentmodelID?: string | null,
        modelVersionType?: ModelVersionType | null,
        checkpointKey?: string | null,
        checkpointStorageType?: CheckpointStorageType | null,
        inputlanguageID: string,
        outputlanguageID: string,
        outputType: NLPOutputType,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inputnlpoutputID?: string | null,
      inputNLPOutput?:  {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      outputNLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      key: string,
      outputType: NLPOutputType,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    outputNLPOutputs?:  {
      __typename: "ModelNLPOutputConnection",
      items:  Array< {
        __typename: "NLPOutput",
        id: string,
        createdAt: string,
        allowedCognitoGroups?: Array< string | null > | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        nlpstepID: string,
        nlpmodelversionID: string,
        inputnlpoutputID?: string | null,
        key: string,
        outputType: NLPOutputType,
        languageID: string,
        value?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    key: string,
    outputType: NLPOutputType,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateClusteringModuleOutputSubscription = {
  onCreateClusteringModuleOutput?:  {
    __typename: "ClusteringModuleOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    key: string,
    leafIDs: Array< string | null >,
    linkageMatrixS3Url: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateClusteringModuleOutputSubscription = {
  onUpdateClusteringModuleOutput?:  {
    __typename: "ClusteringModuleOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    key: string,
    leafIDs: Array< string | null >,
    linkageMatrixS3Url: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteClusteringModuleOutputSubscription = {
  onDeleteClusteringModuleOutput?:  {
    __typename: "ClusteringModuleOutput",
    id: string,
    createdAt: string,
    allowedCognitoGroups?: Array< string | null > | null,
    clientID: string,
    contextchannelID: string,
    Contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    key: string,
    leafIDs: Array< string | null >,
    linkageMatrixS3Url: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSessionSpeakersSubscription = {
  onCreateSessionSpeakers?:  {
    __typename: "SessionSpeakers",
    id: string,
    createdAt: string,
    isDefaultSpeaker?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSessionSpeakersSubscription = {
  onUpdateSessionSpeakers?:  {
    __typename: "SessionSpeakers",
    id: string,
    createdAt: string,
    isDefaultSpeaker?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSessionSpeakersSubscription = {
  onDeleteSessionSpeakers?:  {
    __typename: "SessionSpeakers",
    id: string,
    createdAt: string,
    isDefaultSpeaker?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSessionLanguagesSubscription = {
  onCreateSessionLanguages?:  {
    __typename: "SessionLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSessionLanguagesSubscription = {
  onUpdateSessionLanguages?:  {
    __typename: "SessionLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSessionLanguagesSubscription = {
  onDeleteSessionLanguages?:  {
    __typename: "SessionLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSessionLanguageLanguageDialectsSubscription = {
  onCreateSessionLanguageLanguageDialects?:  {
    __typename: "SessionLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    sessionlanguageID: string,
    sessionlanguage?:  {
      __typename: "SessionLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      sessionID: string,
      session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSessionLanguageLanguageDialectsSubscription = {
  onUpdateSessionLanguageLanguageDialects?:  {
    __typename: "SessionLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    sessionlanguageID: string,
    sessionlanguage?:  {
      __typename: "SessionLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      sessionID: string,
      session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSessionLanguageLanguageDialectsSubscription = {
  onDeleteSessionLanguageLanguageDialects?:  {
    __typename: "SessionLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    sessionlanguageID: string,
    sessionlanguage?:  {
      __typename: "SessionLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      sessionID: string,
      session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMetadataSubscription = {
  onCreateMetadata?:  {
    __typename: "Metadata",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    metadataS3Key?: string | null,
    durationSeconds?: number | null,
    metadataStatus?: MetadataStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMetadataSubscription = {
  onUpdateMetadata?:  {
    __typename: "Metadata",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    metadataS3Key?: string | null,
    durationSeconds?: number | null,
    metadataStatus?: MetadataStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMetadataSubscription = {
  onDeleteMetadata?:  {
    __typename: "Metadata",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    metadataS3Key?: string | null,
    durationSeconds?: number | null,
    metadataStatus?: MetadataStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAudioSubscription = {
  onCreateAudio?:  {
    __typename: "Audio",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    uncompressedAudioS3Key?: string | null,
    compressedAudioS3Key?: string | null,
    waveformDataS3Key?: string | null,
    audioStatus?: AudioStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAudioSubscription = {
  onUpdateAudio?:  {
    __typename: "Audio",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    uncompressedAudioS3Key?: string | null,
    compressedAudioS3Key?: string | null,
    waveformDataS3Key?: string | null,
    audioStatus?: AudioStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAudioSubscription = {
  onDeleteAudio?:  {
    __typename: "Audio",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    uncompressedAudioS3Key?: string | null,
    compressedAudioS3Key?: string | null,
    waveformDataS3Key?: string | null,
    audioStatus?: AudioStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateVideoSubscription = {
  onCreateVideo?:  {
    __typename: "Video",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    compressedVideoS3Key?: string | null,
    videoStatus?: VideoStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateVideoSubscription = {
  onUpdateVideo?:  {
    __typename: "Video",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    compressedVideoS3Key?: string | null,
    videoStatus?: VideoStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteVideoSubscription = {
  onDeleteVideo?:  {
    __typename: "Video",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    compressedVideoS3Key?: string | null,
    videoStatus?: VideoStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAutoTranscriptSubscription = {
  onCreateAutoTranscript?:  {
    __typename: "AutoTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transcriptSourceS3Key?: string | null,
    transcriptStandardizedS3Key?: string | null,
    transcriptTranslationS3Keys?: string | null,
    transcriptionStatus?: TranscriptionStatus | null,
    exportPlainTextS3Key?: string | null,
    exportJsonS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    diarizationS3Key?: string | null,
    summaryS3Key?: string | null,
    categoriesJson?: string | null,
    autotranscriptstatsID?: string | null,
    AutoTranscriptStats?:  {
      __typename: "AutoTranscriptStats",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      unknowns?: number | null,
      wordCount?: number | null,
      unknownPercentage?: number | null,
      globalConfidence?: number | null,
      knownConfidence?: number | null,
      autotranscriptID: string,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAutoTranscriptSubscription = {
  onUpdateAutoTranscript?:  {
    __typename: "AutoTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transcriptSourceS3Key?: string | null,
    transcriptStandardizedS3Key?: string | null,
    transcriptTranslationS3Keys?: string | null,
    transcriptionStatus?: TranscriptionStatus | null,
    exportPlainTextS3Key?: string | null,
    exportJsonS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    diarizationS3Key?: string | null,
    summaryS3Key?: string | null,
    categoriesJson?: string | null,
    autotranscriptstatsID?: string | null,
    AutoTranscriptStats?:  {
      __typename: "AutoTranscriptStats",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      unknowns?: number | null,
      wordCount?: number | null,
      unknownPercentage?: number | null,
      globalConfidence?: number | null,
      knownConfidence?: number | null,
      autotranscriptID: string,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAutoTranscriptSubscription = {
  onDeleteAutoTranscript?:  {
    __typename: "AutoTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    speechmodelID: string,
    SpeechModel?:  {
      __typename: "SpeechModel",
      id: string,
      name?: string | null,
      description?: string | null,
      s3ImageUrl?: string | null,
      allowedCognitoGroups?: Array< string | null > | null,
      provider: SpeechModelProvider,
      connectionString?: string | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languagedialectID?: string | null,
      LanguageDialect?:  {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transcriptSourceS3Key?: string | null,
    transcriptStandardizedS3Key?: string | null,
    transcriptTranslationS3Keys?: string | null,
    transcriptionStatus?: TranscriptionStatus | null,
    exportPlainTextS3Key?: string | null,
    exportJsonS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    diarizationS3Key?: string | null,
    summaryS3Key?: string | null,
    categoriesJson?: string | null,
    autotranscriptstatsID?: string | null,
    AutoTranscriptStats?:  {
      __typename: "AutoTranscriptStats",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      unknowns?: number | null,
      wordCount?: number | null,
      unknownPercentage?: number | null,
      globalConfidence?: number | null,
      knownConfidence?: number | null,
      autotranscriptID: string,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateGoldTranscriptSubscription = {
  onCreateGoldTranscript?:  {
    __typename: "GoldTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateGoldTranscriptSubscription = {
  onUpdateGoldTranscript?:  {
    __typename: "GoldTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteGoldTranscriptSubscription = {
  onDeleteGoldTranscript?:  {
    __typename: "GoldTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    validationProgressStatus?: ValidationProgressStatus | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomTranscriptSubscription = {
  onCreateCustomTranscript?:  {
    __typename: "CustomTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomTranscriptSubscription = {
  onUpdateCustomTranscript?:  {
    __typename: "CustomTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomTranscriptSubscription = {
  onDeleteCustomTranscript?:  {
    __typename: "CustomTranscript",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    transcriptS3Key?: string | null,
    subtitlesSrtS3Key?: string | null,
    subtitlesVttS3Key?: string | null,
    subtitleStatus?: SubtitleStatus | null,
    clientID: string,
    sessionID: string,
    Session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAutoTranscriptStatsSubscription = {
  onCreateAutoTranscriptStats?:  {
    __typename: "AutoTranscriptStats",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    unknowns?: number | null,
    wordCount?: number | null,
    unknownPercentage?: number | null,
    globalConfidence?: number | null,
    knownConfidence?: number | null,
    autotranscriptID: string,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAutoTranscriptStatsSubscription = {
  onUpdateAutoTranscriptStats?:  {
    __typename: "AutoTranscriptStats",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    unknowns?: number | null,
    wordCount?: number | null,
    unknownPercentage?: number | null,
    globalConfidence?: number | null,
    knownConfidence?: number | null,
    autotranscriptID: string,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAutoTranscriptStatsSubscription = {
  onDeleteAutoTranscriptStats?:  {
    __typename: "AutoTranscriptStats",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    unknowns?: number | null,
    wordCount?: number | null,
    unknownPercentage?: number | null,
    globalConfidence?: number | null,
    knownConfidence?: number | null,
    autotranscriptID: string,
    AutoTranscript?:  {
      __typename: "AutoTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transcriptSourceS3Key?: string | null,
      transcriptStandardizedS3Key?: string | null,
      transcriptTranslationS3Keys?: string | null,
      transcriptionStatus?: TranscriptionStatus | null,
      exportPlainTextS3Key?: string | null,
      exportJsonS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      diarizationS3Key?: string | null,
      summaryS3Key?: string | null,
      categoriesJson?: string | null,
      autotranscriptstatsID?: string | null,
      AutoTranscriptStats?:  {
        __typename: "AutoTranscriptStats",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        unknowns?: number | null,
        wordCount?: number | null,
        unknownPercentage?: number | null,
        globalConfidence?: number | null,
        knownConfidence?: number | null,
        autotranscriptID: string,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    sessionID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSpeechModelSubscription = {
  onCreateSpeechModel?:  {
    __typename: "SpeechModel",
    id: string,
    name?: string | null,
    description?: string | null,
    s3ImageUrl?: string | null,
    allowedCognitoGroups?: Array< string | null > | null,
    provider: SpeechModelProvider,
    connectionString?: string | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSpeechModelSubscription = {
  onUpdateSpeechModel?:  {
    __typename: "SpeechModel",
    id: string,
    name?: string | null,
    description?: string | null,
    s3ImageUrl?: string | null,
    allowedCognitoGroups?: Array< string | null > | null,
    provider: SpeechModelProvider,
    connectionString?: string | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSpeechModelSubscription = {
  onDeleteSpeechModel?:  {
    __typename: "SpeechModel",
    id: string,
    name?: string | null,
    description?: string | null,
    s3ImageUrl?: string | null,
    allowedCognitoGroups?: Array< string | null > | null,
    provider: SpeechModelProvider,
    connectionString?: string | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLanguageLabelSubscription = {
  onCreateLanguageLabel?:  {
    __typename: "LanguageLabel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    submittedAt?: string | null,
    validatedAt?: string | null,
    submitteduserID?: string | null,
    submittedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    validateduserID?: string | null,
    validatedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    goldtranscriptID: string,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    starttime: string,
    endtime: string,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID?: string | null,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    content: string,
    contentJson?: string | null,
    sentimentJson?: string | null,
    contentOrigin?: string | null,
    contentJsonOrigin?: string | null,
    sentimentJsonOrigin?: string | null,
    validationStatus?: ValidationStatus | null,
    feedbackMessage?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLanguageLabelSubscription = {
  onUpdateLanguageLabel?:  {
    __typename: "LanguageLabel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    submittedAt?: string | null,
    validatedAt?: string | null,
    submitteduserID?: string | null,
    submittedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    validateduserID?: string | null,
    validatedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    goldtranscriptID: string,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    starttime: string,
    endtime: string,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID?: string | null,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    content: string,
    contentJson?: string | null,
    sentimentJson?: string | null,
    contentOrigin?: string | null,
    contentJsonOrigin?: string | null,
    sentimentJsonOrigin?: string | null,
    validationStatus?: ValidationStatus | null,
    feedbackMessage?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLanguageLabelSubscription = {
  onDeleteLanguageLabel?:  {
    __typename: "LanguageLabel",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    createdAt: string,
    submittedAt?: string | null,
    validatedAt?: string | null,
    submitteduserID?: string | null,
    submittedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    validateduserID?: string | null,
    validatedUser?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientID: string,
    contextchannelID: string,
    sessionID: string,
    goldtranscriptID: string,
    GoldTranscript?:  {
      __typename: "GoldTranscript",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptS3Key?: string | null,
      subtitlesSrtS3Key?: string | null,
      subtitlesVttS3Key?: string | null,
      subtitleStatus?: SubtitleStatus | null,
      clientID: string,
      sessionID: string,
      Session?:  {
        __typename: "Session",
        id: string,
        createdAt: string,
        name?: string | null,
        clientID: string,
        allowedCognitoGroups?: Array< string | null > | null,
        gnlpOutputs?: string | null,
        title?: string | null,
        summary?: string | null,
        keywords?: Array< string | null > | null,
        rewrittenS3Key?: string | null,
        condensed?: string | null,
        category?: string | null,
        sourceFileS3Key?: string | null,
        s3ImageUrl?: string | null,
        metadataID?: string | null,
        audioID?: string | null,
        videoID?: string | null,
        autotranscriptID?: string | null,
        goldtranscriptID?: string | null,
        customtranscriptID?: string | null,
        metadataStatus?: MetadataStatus | null,
        audioStatus?: AudioStatus | null,
        videoStatus?: VideoStatus | null,
        transcriptionStatus?: TranscriptionStatus | null,
        diarizationStatus?: DiarizationStatus | null,
        subtitleStatus?: SubtitleStatus | null,
        gnlpStatus?: GNLPStatus | null,
        nlpStatus?: NLPStatus | null,
        contextchannelID: string,
        contactEmailAddresses?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        extra?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    starttime: string,
    endtime: string,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID?: string | null,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID?: string | null,
    LanguageDialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    content: string,
    contentJson?: string | null,
    sentimentJson?: string | null,
    contentOrigin?: string | null,
    contentJsonOrigin?: string | null,
    sentimentJsonOrigin?: string | null,
    validationStatus?: ValidationStatus | null,
    feedbackMessage?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNLPModelSubscription = {
  onCreateNLPModel?:  {
    __typename: "NLPModel",
    id: string,
    modelTrainedAt?: string | null,
    name?: string | null,
    huggingfaceRef?: string | null,
    pipelineParametersJson?: string | null,
    inferenceParametersJson?: string | null,
    otherParametersJson?: string | null,
    basemodelID?: string | null,
    BaseModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ChildModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nlpmodeltypeID: string,
    NLPModelType?:  {
      __typename: "NLPModelType",
      id: string,
      name: string,
      huggingfaceTask: HuggingfaceTask,
      NLPModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNLPModelSubscription = {
  onUpdateNLPModel?:  {
    __typename: "NLPModel",
    id: string,
    modelTrainedAt?: string | null,
    name?: string | null,
    huggingfaceRef?: string | null,
    pipelineParametersJson?: string | null,
    inferenceParametersJson?: string | null,
    otherParametersJson?: string | null,
    basemodelID?: string | null,
    BaseModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ChildModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nlpmodeltypeID: string,
    NLPModelType?:  {
      __typename: "NLPModelType",
      id: string,
      name: string,
      huggingfaceTask: HuggingfaceTask,
      NLPModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNLPModelSubscription = {
  onDeleteNLPModel?:  {
    __typename: "NLPModel",
    id: string,
    modelTrainedAt?: string | null,
    name?: string | null,
    huggingfaceRef?: string | null,
    pipelineParametersJson?: string | null,
    inferenceParametersJson?: string | null,
    otherParametersJson?: string | null,
    basemodelID?: string | null,
    BaseModel?:  {
      __typename: "NLPModel",
      id: string,
      modelTrainedAt?: string | null,
      name?: string | null,
      huggingfaceRef?: string | null,
      pipelineParametersJson?: string | null,
      inferenceParametersJson?: string | null,
      otherParametersJson?: string | null,
      basemodelID?: string | null,
      BaseModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ChildModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      nlpmodeltypeID: string,
      NLPModelType?:  {
        __typename: "NLPModelType",
        id: string,
        name: string,
        huggingfaceTask: HuggingfaceTask,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ChildModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    nlpmodeltypeID: string,
    NLPModelType?:  {
      __typename: "NLPModelType",
      id: string,
      name: string,
      huggingfaceTask: HuggingfaceTask,
      NLPModels?:  {
        __typename: "ModelNLPModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNLPModelTypeSubscription = {
  onCreateNLPModelType?:  {
    __typename: "NLPModelType",
    id: string,
    name: string,
    huggingfaceTask: HuggingfaceTask,
    NLPModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNLPModelTypeSubscription = {
  onUpdateNLPModelType?:  {
    __typename: "NLPModelType",
    id: string,
    name: string,
    huggingfaceTask: HuggingfaceTask,
    NLPModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNLPModelTypeSubscription = {
  onDeleteNLPModelType?:  {
    __typename: "NLPModelType",
    id: string,
    name: string,
    huggingfaceTask: HuggingfaceTask,
    NLPModels?:  {
      __typename: "ModelNLPModelConnection",
      items:  Array< {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSpeakerSubscription = {
  onCreateSpeaker?:  {
    __typename: "Speaker",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    firstName: string,
    infix?: string | null,
    lastName: string,
    gender: Gender,
    Subscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Languages?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSpeakerSubscription = {
  onUpdateSpeaker?:  {
    __typename: "Speaker",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    firstName: string,
    infix?: string | null,
    lastName: string,
    gender: Gender,
    Subscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Languages?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSpeakerSubscription = {
  onDeleteSpeaker?:  {
    __typename: "Speaker",
    id: string,
    allowedCognitoGroups?: Array< string | null > | null,
    firstName: string,
    infix?: string | null,
    lastName: string,
    gender: Gender,
    Subscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Languages?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelSpeakersConnection",
      items:  Array< {
        __typename: "ContextchannelSpeakers",
        id: string,
        createdAt: string,
        contextchannelID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionSpeakersConnection",
      items:  Array< {
        __typename: "SessionSpeakers",
        id: string,
        createdAt: string,
        isDefaultSpeaker?: boolean | null,
        sessionID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    clientID: string,
    Client?:  {
      __typename: "Client",
      id: string,
      displayName: string,
      cognitoGroupName: string,
      Contextchannels?:  {
        __typename: "ModelContextchannelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    LanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID?: string | null,
    User?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSpeakerLanguagesSubscription = {
  onCreateSpeakerLanguages?:  {
    __typename: "SpeakerLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSpeakerLanguagesSubscription = {
  onUpdateSpeakerLanguages?:  {
    __typename: "SpeakerLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSpeakerLanguagesSubscription = {
  onDeleteSpeakerLanguages?:  {
    __typename: "SpeakerLanguages",
    id: string,
    createdAt: string,
    isDefaultLanguage?: boolean | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languageID: string,
    language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dialects?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSpeakerLanguageLanguageDialectsSubscription = {
  onCreateSpeakerLanguageLanguageDialects?:  {
    __typename: "SpeakerLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    speakerlanguageID: string,
    speakerlanguage?:  {
      __typename: "SpeakerLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      speakerID: string,
      speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSpeakerLanguageLanguageDialectsSubscription = {
  onUpdateSpeakerLanguageLanguageDialects?:  {
    __typename: "SpeakerLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    speakerlanguageID: string,
    speakerlanguage?:  {
      __typename: "SpeakerLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      speakerID: string,
      speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSpeakerLanguageLanguageDialectsSubscription = {
  onDeleteSpeakerLanguageLanguageDialects?:  {
    __typename: "SpeakerLanguageLanguageDialects",
    id: string,
    createdAt: string,
    isDefaultLanguageDialect?: boolean | null,
    speakerlanguageID: string,
    speakerlanguage?:  {
      __typename: "SpeakerLanguages",
      id: string,
      createdAt: string,
      isDefaultLanguage?: boolean | null,
      speakerID: string,
      speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      languageID: string,
      language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dialects?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    languagedialectID: string,
    languagedialect?:  {
      __typename: "LanguageDialect",
      id: string,
      name: string,
      ISO_639_2_dialect: string,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLanguageSubscription = {
  onCreateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    ISO_639_1?: string | null,
    ISO_639_2: string,
    ISO_639_3?: string | null,
    stopwords?: Array< string | null > | null,
    LanguageDialects?:  {
      __typename: "ModelLanguageDialectConnection",
      items:  Array< {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLanguageSubscription = {
  onUpdateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    ISO_639_1?: string | null,
    ISO_639_2: string,
    ISO_639_3?: string | null,
    stopwords?: Array< string | null > | null,
    LanguageDialects?:  {
      __typename: "ModelLanguageDialectConnection",
      items:  Array< {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLanguageSubscription = {
  onDeleteLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    ISO_639_1?: string | null,
    ISO_639_2: string,
    ISO_639_3?: string | null,
    stopwords?: Array< string | null > | null,
    LanguageDialects?:  {
      __typename: "ModelLanguageDialectConnection",
      items:  Array< {
        __typename: "LanguageDialect",
        id: string,
        name: string,
        ISO_639_2_dialect: string,
        languageID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguagesConnection",
      items:  Array< {
        __typename: "ContextchannelLanguages",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannelID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    NLPSteps?:  {
      __typename: "ModelNLPStepLanguagesConnection",
      items:  Array< {
        __typename: "NLPStepLanguages",
        id: string,
        createdAt: string,
        formality?: Formality | null,
        nlpstepID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguagesConnection",
      items:  Array< {
        __typename: "SessionLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        sessionID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguagesConnection",
      items:  Array< {
        __typename: "SpeakerLanguages",
        id: string,
        createdAt: string,
        isDefaultLanguage?: boolean | null,
        speakerID: string,
        languageID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLanguageDialectSubscription = {
  onCreateLanguageDialect?:  {
    __typename: "LanguageDialect",
    id: string,
    name: string,
    ISO_639_2_dialect: string,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLanguageDialectSubscription = {
  onUpdateLanguageDialect?:  {
    __typename: "LanguageDialect",
    id: string,
    name: string,
    ISO_639_2_dialect: string,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLanguageDialectSubscription = {
  onDeleteLanguageDialect?:  {
    __typename: "LanguageDialect",
    id: string,
    name: string,
    ISO_639_2_dialect: string,
    languageID: string,
    Language?:  {
      __typename: "Language",
      id: string,
      name: string,
      ISO_639_1?: string | null,
      ISO_639_2: string,
      ISO_639_3?: string | null,
      stopwords?: Array< string | null > | null,
      LanguageDialects?:  {
        __typename: "ModelLanguageDialectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeechModels?:  {
        __typename: "ModelSpeechModelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Speakers?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    SpeechModels?:  {
      __typename: "ModelSpeechModelConnection",
      items:  Array< {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contextchannels?:  {
      __typename: "ModelContextchannelLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "ContextchannelLanguageLanguageDialects",
        id: string,
        createdAt: string,
        enabled?: boolean | null,
        contextchannellanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Sessions?:  {
      __typename: "ModelSessionLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SessionLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        sessionlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    Speakers?:  {
      __typename: "ModelSpeakerLanguageLanguageDialectsConnection",
      items:  Array< {
        __typename: "SpeakerLanguageLanguageDialects",
        id: string,
        createdAt: string,
        isDefaultLanguageDialect?: boolean | null,
        speakerlanguageID: string,
        languagedialectID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    teamGroup?: string | null,
    isActive?: boolean | null,
    email?: string | null,
    needsEmailChange?: boolean | null,
    firstName?: string | null,
    infix?: string | null,
    lastName?: string | null,
    avatarS3Url?: string | null,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    submittedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validatedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedContextchannels?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelSubscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SessionSubscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeakerSubscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    teamGroup?: string | null,
    isActive?: boolean | null,
    email?: string | null,
    needsEmailChange?: boolean | null,
    firstName?: string | null,
    infix?: string | null,
    lastName?: string | null,
    avatarS3Url?: string | null,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    submittedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validatedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedContextchannels?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelSubscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SessionSubscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeakerSubscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    teamGroup?: string | null,
    isActive?: boolean | null,
    email?: string | null,
    needsEmailChange?: boolean | null,
    firstName?: string | null,
    infix?: string | null,
    lastName?: string | null,
    avatarS3Url?: string | null,
    speakerID?: string | null,
    Speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    submittedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    validatedLanguageLabels?:  {
      __typename: "ModelLanguageLabelConnection",
      items:  Array< {
        __typename: "LanguageLabel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        createdAt: string,
        submittedAt?: string | null,
        validatedAt?: string | null,
        submitteduserID?: string | null,
        validateduserID?: string | null,
        clientID: string,
        contextchannelID: string,
        sessionID: string,
        goldtranscriptID: string,
        starttime: string,
        endtime: string,
        speakerID?: string | null,
        languageID?: string | null,
        languagedialectID?: string | null,
        content: string,
        contentJson?: string | null,
        sentimentJson?: string | null,
        contentOrigin?: string | null,
        contentJsonOrigin?: string | null,
        sentimentJsonOrigin?: string | null,
        validationStatus?: ValidationStatus | null,
        feedbackMessage?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    allowedContextchannels?:  {
      __typename: "ModelUserContextchannelsConnection",
      items:  Array< {
        __typename: "UserContextchannels",
        id: string,
        createdAt: string,
        isActive?: boolean | null,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    ContextchannelSubscriptions?:  {
      __typename: "ModelContextchannelSubscriptionConnection",
      items:  Array< {
        __typename: "ContextchannelSubscription",
        id: string,
        createdAt: string,
        userID: string,
        contextchannelID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SessionSubscriptions?:  {
      __typename: "ModelSessionSubscriptionConnection",
      items:  Array< {
        __typename: "SessionSubscription",
        id: string,
        createdAt: string,
        userID: string,
        sessionID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    SpeakerSubscriptions?:  {
      __typename: "ModelSpeakerSubscriptionConnection",
      items:  Array< {
        __typename: "SpeakerSubscription",
        id: string,
        createdAt: string,
        userID: string,
        speakerID: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserContextchannelsSubscription = {
  onCreateUserContextchannels?:  {
    __typename: "UserContextchannels",
    id: string,
    createdAt: string,
    isActive?: boolean | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserContextchannelsSubscription = {
  onUpdateUserContextchannels?:  {
    __typename: "UserContextchannels",
    id: string,
    createdAt: string,
    isActive?: boolean | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserContextchannelsSubscription = {
  onDeleteUserContextchannels?:  {
    __typename: "UserContextchannels",
    id: string,
    createdAt: string,
    isActive?: boolean | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContextchannelSubscriptionSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateContextchannelSubscriptionSubscription = {
  onCreateContextchannelSubscription?:  {
    __typename: "ContextchannelSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContextchannelSubscriptionSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateContextchannelSubscriptionSubscription = {
  onUpdateContextchannelSubscription?:  {
    __typename: "ContextchannelSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContextchannelSubscriptionSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteContextchannelSubscriptionSubscription = {
  onDeleteContextchannelSubscription?:  {
    __typename: "ContextchannelSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contextchannelID: string,
    contextchannel?:  {
      __typename: "Contextchannel",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      name: string,
      s3ImageUrl?: string | null,
      reference_id?: string | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedUsers?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      dynamicConfig?: string | null,
      wordTaggerModuleSettings?:  {
        __typename: "WordTaggerModuleSettings",
        enabled?: boolean | null,
        categories?: Array< string | null > | null,
      } | null,
      gnlpConfig?:  Array< {
        __typename: "GNLPPrompt",
        prompt: string,
        maxTokens: number,
        temperature: number,
        key: string,
        frequencyPenalty: number,
        presencePenalty: number,
        languageISO?: string | null,
      } | null > | null,
      gnlpTasks?: Array< string | null > | null,
      speechmodelID: string,
      SpeechModel?:  {
        __typename: "SpeechModel",
        id: string,
        name?: string | null,
        description?: string | null,
        s3ImageUrl?: string | null,
        allowedCognitoGroups?: Array< string | null > | null,
        provider: SpeechModelProvider,
        connectionString?: string | null,
        languageID: string,
        languagedialectID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      precomputedWordCountsS3Key?: string | null,
      ClusteringModuleOutputs?:  {
        __typename: "ModelClusteringModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NLPSteps?:  {
        __typename: "ModelNLPStepConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      posmodelID?: string | null,
      POSModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      nermodelID?: string | null,
      NERModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      summarymodelID?: string | null,
      SummaryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      sentimentmodelID?: string | null,
      SentimentModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categorymodelID?: string | null,
      CategoryModel?:  {
        __typename: "NLPModel",
        id: string,
        modelTrainedAt?: string | null,
        name?: string | null,
        huggingfaceRef?: string | null,
        pipelineParametersJson?: string | null,
        inferenceParametersJson?: string | null,
        otherParametersJson?: string | null,
        basemodelID?: string | null,
        nlpmodeltypeID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Speakers?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelContextchannelLanguagesConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelLogs?:  {
        __typename: "ModelContextchannelLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSessionSubscriptionSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateSessionSubscriptionSubscription = {
  onCreateSessionSubscription?:  {
    __typename: "SessionSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSessionSubscriptionSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateSessionSubscriptionSubscription = {
  onUpdateSessionSubscription?:  {
    __typename: "SessionSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSessionSubscriptionSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteSessionSubscriptionSubscription = {
  onDeleteSessionSubscription?:  {
    __typename: "SessionSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    sessionID: string,
    session?:  {
      __typename: "Session",
      id: string,
      createdAt: string,
      name?: string | null,
      clientID: string,
      allowedCognitoGroups?: Array< string | null > | null,
      wordcloudSettings?:  {
        __typename: "WordcloudSettings",
        whitelist?: Array< string | null > | null,
        blacklist?: Array< string | null > | null,
      } | null,
      wordTaggerModuleOutputs?:  {
        __typename: "ModelWordTaggerModuleOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gnlpOutputs?: string | null,
      NLPOutputs?:  {
        __typename: "ModelNLPOutputConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      title?: string | null,
      summary?: string | null,
      keywords?: Array< string | null > | null,
      rewrittenS3Key?: string | null,
      condensed?: string | null,
      category?: string | null,
      sourceFileS3Key?: string | null,
      s3ImageUrl?: string | null,
      metadataID?: string | null,
      Metadata?:  {
        __typename: "Metadata",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        metadataS3Key?: string | null,
        durationSeconds?: number | null,
        metadataStatus?: MetadataStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      audioID?: string | null,
      Audio?:  {
        __typename: "Audio",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        uncompressedAudioS3Key?: string | null,
        compressedAudioS3Key?: string | null,
        waveformDataS3Key?: string | null,
        audioStatus?: AudioStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      videoID?: string | null,
      Video?:  {
        __typename: "Video",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        compressedVideoS3Key?: string | null,
        videoStatus?: VideoStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      autotranscriptID?: string | null,
      AutoTranscript?:  {
        __typename: "AutoTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        speechmodelID: string,
        transcriptSourceS3Key?: string | null,
        transcriptStandardizedS3Key?: string | null,
        transcriptTranslationS3Keys?: string | null,
        transcriptionStatus?: TranscriptionStatus | null,
        exportPlainTextS3Key?: string | null,
        exportJsonS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        diarizationS3Key?: string | null,
        summaryS3Key?: string | null,
        categoriesJson?: string | null,
        autotranscriptstatsID?: string | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      goldtranscriptID?: string | null,
      GoldTranscript?:  {
        __typename: "GoldTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        validationProgressStatus?: ValidationProgressStatus | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customtranscriptID?: string | null,
      CustomTranscript?:  {
        __typename: "CustomTranscript",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        transcriptS3Key?: string | null,
        subtitlesSrtS3Key?: string | null,
        subtitlesVttS3Key?: string | null,
        subtitleStatus?: SubtitleStatus | null,
        clientID: string,
        sessionID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      metadataStatus?: MetadataStatus | null,
      audioStatus?: AudioStatus | null,
      videoStatus?: VideoStatus | null,
      transcriptionStatus?: TranscriptionStatus | null,
      diarizationStatus?: DiarizationStatus | null,
      subtitleStatus?: SubtitleStatus | null,
      gnlpStatus?: GNLPStatus | null,
      nlpStatus?: NLPStatus | null,
      contextchannelID: string,
      Contextchannel?:  {
        __typename: "Contextchannel",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        name: string,
        s3ImageUrl?: string | null,
        reference_id?: string | null,
        contactEmailAddresses?: Array< string | null > | null,
        dynamicConfig?: string | null,
        gnlpTasks?: Array< string | null > | null,
        speechmodelID: string,
        precomputedWordCountsS3Key?: string | null,
        posmodelID?: string | null,
        nermodelID?: string | null,
        summarymodelID?: string | null,
        sentimentmodelID?: string | null,
        categorymodelID?: string | null,
        clientID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Speakers?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Languages?:  {
        __typename: "ModelSessionLanguagesConnection",
        nextToken?: string | null,
      } | null,
      contactEmailAddresses?: Array< string | null > | null,
      Subscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validationProgressStatus?: ValidationProgressStatus | null,
      extra?: string | null,
      tags?:  Array< {
        __typename: "Tag",
        key?: string | null,
        value?: string | null,
      } | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSpeakerSubscriptionSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateSpeakerSubscriptionSubscription = {
  onCreateSpeakerSubscription?:  {
    __typename: "SpeakerSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSpeakerSubscriptionSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateSpeakerSubscriptionSubscription = {
  onUpdateSpeakerSubscription?:  {
    __typename: "SpeakerSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSpeakerSubscriptionSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteSpeakerSubscriptionSubscription = {
  onDeleteSpeakerSubscription?:  {
    __typename: "SpeakerSubscription",
    id: string,
    createdAt: string,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      teamGroup?: string | null,
      isActive?: boolean | null,
      email?: string | null,
      needsEmailChange?: boolean | null,
      firstName?: string | null,
      infix?: string | null,
      lastName?: string | null,
      avatarS3Url?: string | null,
      speakerID?: string | null,
      Speaker?:  {
        __typename: "Speaker",
        id: string,
        allowedCognitoGroups?: Array< string | null > | null,
        firstName: string,
        infix?: string | null,
        lastName: string,
        gender: Gender,
        languageID: string,
        clientID: string,
        userID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      submittedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      validatedLanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      allowedContextchannels?:  {
        __typename: "ModelUserContextchannelsConnection",
        nextToken?: string | null,
      } | null,
      ContextchannelSubscriptions?:  {
        __typename: "ModelContextchannelSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SessionSubscriptions?:  {
        __typename: "ModelSessionSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      SpeakerSubscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    speakerID: string,
    speaker?:  {
      __typename: "Speaker",
      id: string,
      allowedCognitoGroups?: Array< string | null > | null,
      firstName: string,
      infix?: string | null,
      lastName: string,
      gender: Gender,
      Subscriptions?:  {
        __typename: "ModelSpeakerSubscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      languageID: string,
      Language?:  {
        __typename: "Language",
        id: string,
        name: string,
        ISO_639_1?: string | null,
        ISO_639_2: string,
        ISO_639_3?: string | null,
        stopwords?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Languages?:  {
        __typename: "ModelSpeakerLanguagesConnection",
        nextToken?: string | null,
      } | null,
      Contextchannels?:  {
        __typename: "ModelContextchannelSpeakersConnection",
        nextToken?: string | null,
      } | null,
      Sessions?:  {
        __typename: "ModelSessionSpeakersConnection",
        nextToken?: string | null,
      } | null,
      clientID: string,
      Client?:  {
        __typename: "Client",
        id: string,
        displayName: string,
        cognitoGroupName: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      LanguageLabels?:  {
        __typename: "ModelLanguageLabelConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userID?: string | null,
      User?:  {
        __typename: "User",
        id: string,
        teamGroup?: string | null,
        isActive?: boolean | null,
        email?: string | null,
        needsEmailChange?: boolean | null,
        firstName?: string | null,
        infix?: string | null,
        lastName?: string | null,
        avatarS3Url?: string | null,
        speakerID?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
