/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const gptCompletion = /* GraphQL */ `
  query GptCompletion($input: GPTCompletionInput!) {
    gptCompletion(input: $input)
  }
`;
export const exportSession = /* GraphQL */ `
  query ExportSession($sessionID: ID!, $exportFormat: ExportFormat!) {
    exportSession(sessionID: $sessionID, exportFormat: $exportFormat)
  }
`;
export const getCognitoGroupsForUser = /* GraphQL */ `
  query GetCognitoGroupsForUser($id: ID!) {
    getCognitoGroupsForUser(id: $id) {
      teamGroup
      clientGroups
      roleGroups
    }
  }
`;
export const searchSessions = /* GraphQL */ `
  query SearchSessions(
    $filter: SearchableSessionFilterInput
    $sort: [SearchableSessionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSessionAggregationInput]
  ) {
    searchSessions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchNLPOutputs = /* GraphQL */ `
  query SearchNLPOutputs(
    $filter: SearchableNLPOutputFilterInput
    $sort: [SearchableNLPOutputSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNLPOutputAggregationInput]
  ) {
    searchNLPOutputs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        allowedCognitoGroups
        clientID
        contextchannelID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpstepID
        NLPStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputnlpoutputID
        inputNLPOutput {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputNLPOutputs {
          nextToken
          startedAt
        }
        key
        outputType
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        value
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchLanguageLabels = /* GraphQL */ `
  query SearchLanguageLabels(
    $filter: SearchableLanguageLabelFilterInput
    $sort: [SearchableLanguageLabelSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableLanguageLabelAggregationInput]
  ) {
    searchLanguageLabels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        allowedCognitoGroups
        createdAt
        submittedAt
        validatedAt
        submitteduserID
        submittedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        validateduserID
        validatedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        contextchannelID
        sessionID
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        starttime
        endtime
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        content
        contentJson
        sentimentJson
        contentOrigin
        contentJsonOrigin
        sentimentJsonOrigin
        validationStatus
        feedbackMessage
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getEnvironmentStatus = /* GraphQL */ `
  query GetEnvironmentStatus($id: ID!) {
    getEnvironmentStatus(id: $id) {
      id
      underMaintanence
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEnvironmentStatuses = /* GraphQL */ `
  query ListEnvironmentStatuses(
    $filter: ModelEnvironmentStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnvironmentStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        underMaintanence
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEnvironmentStatuses = /* GraphQL */ `
  query SyncEnvironmentStatuses(
    $filter: ModelEnvironmentStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEnvironmentStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        underMaintanence
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      displayName
      cognitoGroupName
      Contextchannels {
        items {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Speakers {
        items {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWordTaggerModuleOutput = /* GraphQL */ `
  query GetWordTaggerModuleOutput($id: ID!) {
    getWordTaggerModuleOutput(id: $id) {
      id
      createdAt
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      taggedWords {
        text
        tag
      }
      categories
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWordTaggerModuleOutputs = /* GraphQL */ `
  query ListWordTaggerModuleOutputs(
    $filter: ModelWordTaggerModuleOutputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWordTaggerModuleOutputs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        taggedWords {
          text
          tag
        }
        categories
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWordTaggerModuleOutputs = /* GraphQL */ `
  query SyncWordTaggerModuleOutputs(
    $filter: ModelWordTaggerModuleOutputFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWordTaggerModuleOutputs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        taggedWords {
          text
          tag
        }
        categories
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContextchannel = /* GraphQL */ `
  query GetContextchannel($id: ID!) {
    getContextchannel(id: $id) {
      id
      allowedCognitoGroups
      name
      s3ImageUrl
      reference_id
      contactEmailAddresses
      Subscriptions {
        items {
          id
          createdAt
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      allowedUsers {
        items {
          id
          createdAt
          isActive
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      dynamicConfig
      wordTaggerModuleSettings {
        enabled
        categories
      }
      gnlpConfig {
        prompt
        maxTokens
        temperature
        key
        frequencyPenalty
        presencePenalty
        languageISO
      }
      gnlpTasks
      speechmodelID
      SpeechModel {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      wordcloudSettings {
        whitelist
        blacklist
      }
      precomputedWordCountsS3Key
      ClusteringModuleOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          key
          leafIDs
          linkageMatrixS3Url
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      posmodelID
      POSModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nermodelID
      NERModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      summarymodelID
      SummaryModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sentimentmodelID
      SentimentModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categorymodelID
      CategoryModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Sessions {
        items {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Speakers {
        items {
          id
          createdAt
          contextchannelID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Languages {
        items {
          id
          createdAt
          enabled
          contextchannelID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      ContextchannelLogs {
        items {
          id
          allowedCognitoGroups
          createdAt
          clientID
          contextchannelID
          type
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContextchannels = /* GraphQL */ `
  query ListContextchannels(
    $filter: ModelContextchannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContextchannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContextchannels = /* GraphQL */ `
  query SyncContextchannels(
    $filter: ModelContextchannelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContextchannels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContextchannelLog = /* GraphQL */ `
  query GetContextchannelLog($id: ID!) {
    getContextchannelLog(id: $id) {
      id
      allowedCognitoGroups
      createdAt
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      ApiCallLogEntry {
        ipAddress
        userName
        method
        route
        httpVersion
        statusCode
        userAgent
      }
      SessionStatusUpdateLogEntry {
        sessionID
        sessionName
        attributeName
        oldValue
        newValue
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContextchannelLogs = /* GraphQL */ `
  query ListContextchannelLogs(
    $filter: ModelContextchannelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContextchannelLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        createdAt
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        type
        ApiCallLogEntry {
          ipAddress
          userName
          method
          route
          httpVersion
          statusCode
          userAgent
        }
        SessionStatusUpdateLogEntry {
          sessionID
          sessionName
          attributeName
          oldValue
          newValue
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContextchannelLogs = /* GraphQL */ `
  query SyncContextchannelLogs(
    $filter: ModelContextchannelLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContextchannelLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        createdAt
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        type
        ApiCallLogEntry {
          ipAddress
          userName
          method
          route
          httpVersion
          statusCode
          userAgent
        }
        SessionStatusUpdateLogEntry {
          sessionID
          sessionName
          attributeName
          oldValue
          newValue
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      createdAt
      name
      clientID
      allowedCognitoGroups
      wordcloudSettings {
        whitelist
        blacklist
      }
      wordTaggerModuleOutputs {
        items {
          id
          createdAt
          clientID
          sessionID
          categories
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      gnlpOutputs
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      title
      summary
      keywords
      rewrittenS3Key
      condensed
      category
      sourceFileS3Key
      s3ImageUrl
      metadataID
      Metadata {
        id
        allowedCognitoGroups
        metadataS3Key
        durationSeconds
        metadataStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      audioID
      Audio {
        id
        allowedCognitoGroups
        uncompressedAudioS3Key
        compressedAudioS3Key
        waveformDataS3Key
        audioStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      videoID
      Video {
        id
        allowedCognitoGroups
        compressedVideoS3Key
        videoStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      autotranscriptID
      AutoTranscript {
        id
        allowedCognitoGroups
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        transcriptSourceS3Key
        transcriptStandardizedS3Key
        transcriptTranslationS3Keys
        transcriptionStatus
        exportPlainTextS3Key
        exportJsonS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        diarizationS3Key
        summaryS3Key
        categoriesJson
        autotranscriptstatsID
        AutoTranscriptStats {
          id
          allowedCognitoGroups
          unknowns
          wordCount
          unknownPercentage
          globalConfidence
          knownConfidence
          autotranscriptID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      goldtranscriptID
      GoldTranscript {
        id
        allowedCognitoGroups
        validationProgressStatus
        LanguageLabels {
          nextToken
          startedAt
        }
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      customtranscriptID
      CustomTranscript {
        id
        allowedCognitoGroups
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      metadataStatus
      audioStatus
      videoStatus
      transcriptionStatus
      diarizationStatus
      subtitleStatus
      gnlpStatus
      nlpStatus
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultSpeaker
          sessionID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Languages {
        items {
          id
          createdAt
          isDefaultLanguage
          sessionID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      contactEmailAddresses
      Subscriptions {
        items {
          id
          createdAt
          userID
          sessionID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      validationProgressStatus
      extra
      tags {
        key
        value
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSessions = /* GraphQL */ `
  query SyncSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNLPModelVersion = /* GraphQL */ `
  query GetNLPModelVersion($id: ID!) {
    getNLPModelVersion(id: $id) {
      id
      createdAt
      allowedCognitoGroups
      name
      HFModelType
      parentmodelID
      ParentModel {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      childModels {
        items {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      modelVersionType
      checkpointKey
      checkpointStorageType
      inputlanguageID
      inputLanguage {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      outputlanguageID
      outputLanguage {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inputParameters {
        includeSpeakerInInput
        parseSpeakerFromOutput
        batchSize
        maxInputChunkLength
        inputChunkOverlapTokens
        outputChunkOverlapTokens
        outputChunkOverlapDiscardLastTokens
        truncateInput
        maxRecursionDepth
        maxRecursiveOutputLength
        allowConcatenatedOutput
        badGenerationResampleTries
        inputPrefix
        inputSuffix
        outputStartWith
        outputSeperators
        returnNMostFrequentOutputs
      }
      inferenceParameters {
        useCache
        maxNewTokens
        minNewTokens
        earlyStopping
        doSample
        numBeams
        penaltyAlpha
        temperature
        topK
        topP
        diversityPenalty
        lengthPenalty
        exponentialDecayLengthPenaltyStartIndex
        exponentialDecayLengthPenaltyDecayFactor
        repetitionPenalty
        encoderRepetitionPenalty
        noRepeatNgramSize
        encoderNoRepeatNgramSize
        forceWords
        badWords
        suppressTokens
      }
      modelParameters {
        hasQuantizedWeights
        skipQuantizeLayers
        gptqWbits
        gptqGroupsize
        loadIn8bit
        loadIn8bitThreshold
        loadIn8bitSkipModules
        torchDtype
        fromTf
        fromFlax
        ignoreMismatchedSizes
      }
      openAIParameters {
        endpoint
        model
        temperature
        maxTokens
        topP
        frequencyPenalty
        presencePenalty
        stop
      }
      outputType
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNLPModelVersions = /* GraphQL */ `
  query ListNLPModelVersions(
    $filter: ModelNLPModelVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNLPModelVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNLPModelVersions = /* GraphQL */ `
  query SyncNLPModelVersions(
    $filter: ModelNLPModelVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNLPModelVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNLPStep = /* GraphQL */ `
  query GetNLPStep($id: ID!) {
    getNLPStep(id: $id) {
      id
      createdAt
      allowedCognitoGroups
      enabled
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpmodelversionID
      NLPModelVersion {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      outputKey
      outputLanguages {
        items {
          id
          createdAt
          formality
          nlpstepID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      NLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      inputstepID
      InputStep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      OutputSteps {
        items {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNLPSteps = /* GraphQL */ `
  query ListNLPSteps(
    $filter: ModelNLPStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNLPSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNLPSteps = /* GraphQL */ `
  query SyncNLPSteps(
    $filter: ModelNLPStepFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNLPSteps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNLPOutput = /* GraphQL */ `
  query GetNLPOutput($id: ID!) {
    getNLPOutput(id: $id) {
      id
      createdAt
      allowedCognitoGroups
      clientID
      contextchannelID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpstepID
      NLPStep {
        id
        createdAt
        allowedCognitoGroups
        enabled
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        outputKey
        outputLanguages {
          nextToken
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        inputstepID
        InputStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        OutputSteps {
          nextToken
          startedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nlpmodelversionID
      NLPModelVersion {
        id
        createdAt
        allowedCognitoGroups
        name
        HFModelType
        parentmodelID
        ParentModel {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        childModels {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        NLPOutputs {
          nextToken
          startedAt
        }
        modelVersionType
        checkpointKey
        checkpointStorageType
        inputlanguageID
        inputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputlanguageID
        outputLanguage {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputParameters {
          includeSpeakerInInput
          parseSpeakerFromOutput
          batchSize
          maxInputChunkLength
          inputChunkOverlapTokens
          outputChunkOverlapTokens
          outputChunkOverlapDiscardLastTokens
          truncateInput
          maxRecursionDepth
          maxRecursiveOutputLength
          allowConcatenatedOutput
          badGenerationResampleTries
          inputPrefix
          inputSuffix
          outputStartWith
          outputSeperators
          returnNMostFrequentOutputs
        }
        inferenceParameters {
          useCache
          maxNewTokens
          minNewTokens
          earlyStopping
          doSample
          numBeams
          penaltyAlpha
          temperature
          topK
          topP
          diversityPenalty
          lengthPenalty
          exponentialDecayLengthPenaltyStartIndex
          exponentialDecayLengthPenaltyDecayFactor
          repetitionPenalty
          encoderRepetitionPenalty
          noRepeatNgramSize
          encoderNoRepeatNgramSize
          forceWords
          badWords
          suppressTokens
        }
        modelParameters {
          hasQuantizedWeights
          skipQuantizeLayers
          gptqWbits
          gptqGroupsize
          loadIn8bit
          loadIn8bitThreshold
          loadIn8bitSkipModules
          torchDtype
          fromTf
          fromFlax
          ignoreMismatchedSizes
        }
        openAIParameters {
          endpoint
          model
          temperature
          maxTokens
          topP
          frequencyPenalty
          presencePenalty
          stop
        }
        outputType
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      inputnlpoutputID
      inputNLPOutput {
        id
        createdAt
        allowedCognitoGroups
        clientID
        contextchannelID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpstepID
        NLPStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputnlpoutputID
        inputNLPOutput {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputNLPOutputs {
          nextToken
          startedAt
        }
        key
        outputType
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        value
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      outputNLPOutputs {
        items {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      key
      outputType
      languageID
      language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      value
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNLPOutputs = /* GraphQL */ `
  query ListNLPOutputs(
    $filter: ModelNLPOutputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNLPOutputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        allowedCognitoGroups
        clientID
        contextchannelID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpstepID
        NLPStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputnlpoutputID
        inputNLPOutput {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputNLPOutputs {
          nextToken
          startedAt
        }
        key
        outputType
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        value
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNLPOutputs = /* GraphQL */ `
  query SyncNLPOutputs(
    $filter: ModelNLPOutputFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNLPOutputs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        allowedCognitoGroups
        clientID
        contextchannelID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpstepID
        NLPStep {
          id
          createdAt
          allowedCognitoGroups
          enabled
          clientID
          contextchannelID
          nlpmodelversionID
          name
          outputKey
          inputstepID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nlpmodelversionID
        NLPModelVersion {
          id
          createdAt
          allowedCognitoGroups
          name
          HFModelType
          parentmodelID
          modelVersionType
          checkpointKey
          checkpointStorageType
          inputlanguageID
          outputlanguageID
          outputType
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        inputnlpoutputID
        inputNLPOutput {
          id
          createdAt
          allowedCognitoGroups
          clientID
          contextchannelID
          sessionID
          nlpstepID
          nlpmodelversionID
          inputnlpoutputID
          key
          outputType
          languageID
          value
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        outputNLPOutputs {
          nextToken
          startedAt
        }
        key
        outputType
        languageID
        language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        value
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClusteringModuleOutput = /* GraphQL */ `
  query GetClusteringModuleOutput($id: ID!) {
    getClusteringModuleOutput(id: $id) {
      id
      createdAt
      allowedCognitoGroups
      clientID
      contextchannelID
      Contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      key
      leafIDs
      linkageMatrixS3Url
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClusteringModuleOutputs = /* GraphQL */ `
  query ListClusteringModuleOutputs(
    $filter: ModelClusteringModuleOutputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClusteringModuleOutputs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        allowedCognitoGroups
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        key
        leafIDs
        linkageMatrixS3Url
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClusteringModuleOutputs = /* GraphQL */ `
  query SyncClusteringModuleOutputs(
    $filter: ModelClusteringModuleOutputFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClusteringModuleOutputs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        allowedCognitoGroups
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        key
        leafIDs
        linkageMatrixS3Url
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMetadata = /* GraphQL */ `
  query GetMetadata($id: ID!) {
    getMetadata(id: $id) {
      id
      allowedCognitoGroups
      metadataS3Key
      durationSeconds
      metadataStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMetadata = /* GraphQL */ `
  query ListMetadata(
    $filter: ModelMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetadata(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        allowedCognitoGroups
        metadataS3Key
        durationSeconds
        metadataStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMetadata = /* GraphQL */ `
  query SyncMetadata(
    $filter: ModelMetadataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMetadata(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        metadataS3Key
        durationSeconds
        metadataStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAudio = /* GraphQL */ `
  query GetAudio($id: ID!) {
    getAudio(id: $id) {
      id
      allowedCognitoGroups
      uncompressedAudioS3Key
      compressedAudioS3Key
      waveformDataS3Key
      audioStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAudio = /* GraphQL */ `
  query ListAudio(
    $filter: ModelAudioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudio(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        allowedCognitoGroups
        uncompressedAudioS3Key
        compressedAudioS3Key
        waveformDataS3Key
        audioStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAudio = /* GraphQL */ `
  query SyncAudio(
    $filter: ModelAudioFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAudio(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        uncompressedAudioS3Key
        compressedAudioS3Key
        waveformDataS3Key
        audioStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      allowedCognitoGroups
      compressedVideoS3Key
      videoStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        allowedCognitoGroups
        compressedVideoS3Key
        videoStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideos = /* GraphQL */ `
  query SyncVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        compressedVideoS3Key
        videoStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAutoTranscript = /* GraphQL */ `
  query GetAutoTranscript($id: ID!) {
    getAutoTranscript(id: $id) {
      id
      allowedCognitoGroups
      speechmodelID
      SpeechModel {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      transcriptSourceS3Key
      transcriptStandardizedS3Key
      transcriptTranslationS3Keys
      transcriptionStatus
      exportPlainTextS3Key
      exportJsonS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      diarizationS3Key
      summaryS3Key
      categoriesJson
      autotranscriptstatsID
      AutoTranscriptStats {
        id
        allowedCognitoGroups
        unknowns
        wordCount
        unknownPercentage
        globalConfidence
        knownConfidence
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAutoTranscripts = /* GraphQL */ `
  query ListAutoTranscripts(
    $filter: ModelAutoTranscriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAutoTranscripts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        allowedCognitoGroups
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        transcriptSourceS3Key
        transcriptStandardizedS3Key
        transcriptTranslationS3Keys
        transcriptionStatus
        exportPlainTextS3Key
        exportJsonS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        diarizationS3Key
        summaryS3Key
        categoriesJson
        autotranscriptstatsID
        AutoTranscriptStats {
          id
          allowedCognitoGroups
          unknowns
          wordCount
          unknownPercentage
          globalConfidence
          knownConfidence
          autotranscriptID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAutoTranscripts = /* GraphQL */ `
  query SyncAutoTranscripts(
    $filter: ModelAutoTranscriptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAutoTranscripts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        transcriptSourceS3Key
        transcriptStandardizedS3Key
        transcriptTranslationS3Keys
        transcriptionStatus
        exportPlainTextS3Key
        exportJsonS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        diarizationS3Key
        summaryS3Key
        categoriesJson
        autotranscriptstatsID
        AutoTranscriptStats {
          id
          allowedCognitoGroups
          unknowns
          wordCount
          unknownPercentage
          globalConfidence
          knownConfidence
          autotranscriptID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGoldTranscript = /* GraphQL */ `
  query GetGoldTranscript($id: ID!) {
    getGoldTranscript(id: $id) {
      id
      allowedCognitoGroups
      validationProgressStatus
      LanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      transcriptS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGoldTranscripts = /* GraphQL */ `
  query ListGoldTranscripts(
    $filter: ModelGoldTranscriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoldTranscripts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        allowedCognitoGroups
        validationProgressStatus
        LanguageLabels {
          nextToken
          startedAt
        }
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGoldTranscripts = /* GraphQL */ `
  query SyncGoldTranscripts(
    $filter: ModelGoldTranscriptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGoldTranscripts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        validationProgressStatus
        LanguageLabels {
          nextToken
          startedAt
        }
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomTranscript = /* GraphQL */ `
  query GetCustomTranscript($id: ID!) {
    getCustomTranscript(id: $id) {
      id
      allowedCognitoGroups
      transcriptS3Key
      subtitlesSrtS3Key
      subtitlesVttS3Key
      subtitleStatus
      clientID
      sessionID
      Session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomTranscripts = /* GraphQL */ `
  query ListCustomTranscripts(
    $filter: ModelCustomTranscriptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomTranscripts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomTranscripts = /* GraphQL */ `
  query SyncCustomTranscripts(
    $filter: ModelCustomTranscriptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomTranscripts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAutoTranscriptStats = /* GraphQL */ `
  query GetAutoTranscriptStats($id: ID!) {
    getAutoTranscriptStats(id: $id) {
      id
      allowedCognitoGroups
      unknowns
      wordCount
      unknownPercentage
      globalConfidence
      knownConfidence
      autotranscriptID
      AutoTranscript {
        id
        allowedCognitoGroups
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        transcriptSourceS3Key
        transcriptStandardizedS3Key
        transcriptTranslationS3Keys
        transcriptionStatus
        exportPlainTextS3Key
        exportJsonS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        diarizationS3Key
        summaryS3Key
        categoriesJson
        autotranscriptstatsID
        AutoTranscriptStats {
          id
          allowedCognitoGroups
          unknowns
          wordCount
          unknownPercentage
          globalConfidence
          knownConfidence
          autotranscriptID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAutoTranscriptStats = /* GraphQL */ `
  query ListAutoTranscriptStats(
    $filter: ModelAutoTranscriptStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAutoTranscriptStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        unknowns
        wordCount
        unknownPercentage
        globalConfidence
        knownConfidence
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAutoTranscriptStats = /* GraphQL */ `
  query SyncAutoTranscriptStats(
    $filter: ModelAutoTranscriptStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAutoTranscriptStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        unknowns
        wordCount
        unknownPercentage
        globalConfidence
        knownConfidence
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        sessionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSpeechModel = /* GraphQL */ `
  query GetSpeechModel($id: ID!) {
    getSpeechModel(id: $id) {
      id
      name
      description
      s3ImageUrl
      allowedCognitoGroups
      provider
      connectionString
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      LanguageDialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSpeechModels = /* GraphQL */ `
  query ListSpeechModels(
    $filter: ModelSpeechModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpeechModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSpeechModels = /* GraphQL */ `
  query SyncSpeechModels(
    $filter: ModelSpeechModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSpeechModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLanguageLabel = /* GraphQL */ `
  query GetLanguageLabel($id: ID!) {
    getLanguageLabel(id: $id) {
      id
      allowedCognitoGroups
      createdAt
      submittedAt
      validatedAt
      submitteduserID
      submittedUser {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      validateduserID
      validatedUser {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      clientID
      contextchannelID
      sessionID
      goldtranscriptID
      GoldTranscript {
        id
        allowedCognitoGroups
        validationProgressStatus
        LanguageLabels {
          nextToken
          startedAt
        }
        transcriptS3Key
        subtitlesSrtS3Key
        subtitlesVttS3Key
        subtitleStatus
        clientID
        sessionID
        Session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      starttime
      endtime
      speakerID
      Speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      languagedialectID
      LanguageDialect {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      content
      contentJson
      sentimentJson
      contentOrigin
      contentJsonOrigin
      sentimentJsonOrigin
      validationStatus
      feedbackMessage
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLanguageLabels = /* GraphQL */ `
  query ListLanguageLabels(
    $filter: ModelLanguageLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguageLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        allowedCognitoGroups
        createdAt
        submittedAt
        validatedAt
        submitteduserID
        submittedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        validateduserID
        validatedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        contextchannelID
        sessionID
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        starttime
        endtime
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        content
        contentJson
        sentimentJson
        contentOrigin
        contentJsonOrigin
        sentimentJsonOrigin
        validationStatus
        feedbackMessage
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLanguageLabels = /* GraphQL */ `
  query SyncLanguageLabels(
    $filter: ModelLanguageLabelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLanguageLabels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        createdAt
        submittedAt
        validatedAt
        submitteduserID
        submittedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        validateduserID
        validatedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        contextchannelID
        sessionID
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        starttime
        endtime
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        content
        contentJson
        sentimentJson
        contentOrigin
        contentJsonOrigin
        sentimentJsonOrigin
        validationStatus
        feedbackMessage
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNLPModel = /* GraphQL */ `
  query GetNLPModel($id: ID!) {
    getNLPModel(id: $id) {
      id
      modelTrainedAt
      name
      huggingfaceRef
      pipelineParametersJson
      inferenceParametersJson
      otherParametersJson
      basemodelID
      BaseModel {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      ChildModels {
        items {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      nlpmodeltypeID
      NLPModelType {
        id
        name
        huggingfaceTask
        NLPModels {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNLPModels = /* GraphQL */ `
  query ListNLPModels(
    $filter: ModelNLPModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNLPModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNLPModels = /* GraphQL */ `
  query SyncNLPModels(
    $filter: ModelNLPModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNLPModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNLPModelType = /* GraphQL */ `
  query GetNLPModelType($id: ID!) {
    getNLPModelType(id: $id) {
      id
      name
      huggingfaceTask
      NLPModels {
        items {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNLPModelTypes = /* GraphQL */ `
  query ListNLPModelTypes(
    $filter: ModelNLPModelTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNLPModelTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        huggingfaceTask
        NLPModels {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNLPModelTypes = /* GraphQL */ `
  query SyncNLPModelTypes(
    $filter: ModelNLPModelTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNLPModelTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        huggingfaceTask
        NLPModels {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSpeaker = /* GraphQL */ `
  query GetSpeaker($id: ID!) {
    getSpeaker(id: $id) {
      id
      allowedCognitoGroups
      firstName
      infix
      lastName
      gender
      Subscriptions {
        items {
          id
          createdAt
          userID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Languages {
        items {
          id
          createdAt
          isDefaultLanguage
          speakerID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Contextchannels {
        items {
          id
          createdAt
          contextchannelID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultSpeaker
          sessionID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      clientID
      Client {
        id
        displayName
        cognitoGroupName
        Contextchannels {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      LanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      userID
      User {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSpeakers = /* GraphQL */ `
  query ListSpeakers(
    $filter: ModelSpeakerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpeakers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSpeakers = /* GraphQL */ `
  query SyncSpeakers(
    $filter: ModelSpeakerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSpeakers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($id: ID!) {
    getLanguage(id: $id) {
      id
      name
      ISO_639_1
      ISO_639_2
      ISO_639_3
      stopwords
      LanguageDialects {
        items {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SpeechModels {
        items {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contextchannels {
        items {
          id
          createdAt
          enabled
          contextchannelID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      NLPSteps {
        items {
          id
          createdAt
          formality
          nlpstepID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultLanguage
          sessionID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultLanguage
          speakerID
          languageID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLanguages = /* GraphQL */ `
  query SyncLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLanguageDialect = /* GraphQL */ `
  query GetLanguageDialect($id: ID!) {
    getLanguageDialect(id: $id) {
      id
      name
      ISO_639_2_dialect
      languageID
      Language {
        id
        name
        ISO_639_1
        ISO_639_2
        ISO_639_3
        stopwords
        LanguageDialects {
          nextToken
          startedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        NLPSteps {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      SpeechModels {
        items {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Contextchannels {
        items {
          id
          createdAt
          enabled
          contextchannellanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Sessions {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          sessionlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      Speakers {
        items {
          id
          createdAt
          isDefaultLanguageDialect
          speakerlanguageID
          languagedialectID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLanguageDialects = /* GraphQL */ `
  query ListLanguageDialects(
    $filter: ModelLanguageDialectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguageDialects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLanguageDialects = /* GraphQL */ `
  query SyncLanguageDialects(
    $filter: ModelLanguageDialectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLanguageDialects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      teamGroup
      isActive
      email
      needsEmailChange
      firstName
      infix
      lastName
      avatarS3Url
      speakerID
      Speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      submittedLanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      validatedLanguageLabels {
        items {
          id
          allowedCognitoGroups
          createdAt
          submittedAt
          validatedAt
          submitteduserID
          validateduserID
          clientID
          contextchannelID
          sessionID
          goldtranscriptID
          starttime
          endtime
          speakerID
          languageID
          languagedialectID
          content
          contentJson
          sentimentJson
          contentOrigin
          contentJsonOrigin
          sentimentJsonOrigin
          validationStatus
          feedbackMessage
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      allowedContextchannels {
        items {
          id
          createdAt
          isActive
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      ContextchannelSubscriptions {
        items {
          id
          createdAt
          userID
          contextchannelID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SessionSubscriptions {
        items {
          id
          createdAt
          userID
          sessionID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      SpeakerSubscriptions {
        items {
          id
          createdAt
          userID
          speakerID
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContextchannelSubscription = /* GraphQL */ `
  query GetContextchannelSubscription($id: ID!) {
    getContextchannelSubscription(id: $id) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contextchannelID
      contextchannel {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContextchannelSubscriptions = /* GraphQL */ `
  query ListContextchannelSubscriptions(
    $filter: ModelContextchannelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContextchannelSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        userID
        user {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contextchannelID
        contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContextchannelSubscriptions = /* GraphQL */ `
  query SyncContextchannelSubscriptions(
    $filter: ModelContextchannelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContextchannelSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        userID
        user {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contextchannelID
        contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSessionSubscription = /* GraphQL */ `
  query GetSessionSubscription($id: ID!) {
    getSessionSubscription(id: $id) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      sessionID
      session {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSessionSubscriptions = /* GraphQL */ `
  query ListSessionSubscriptions(
    $filter: ModelSessionSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        userID
        user {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sessionID
        session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSessionSubscriptions = /* GraphQL */ `
  query SyncSessionSubscriptions(
    $filter: ModelSessionSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSessionSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        userID
        user {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sessionID
        session {
          id
          createdAt
          name
          clientID
          allowedCognitoGroups
          gnlpOutputs
          title
          summary
          keywords
          rewrittenS3Key
          condensed
          category
          sourceFileS3Key
          s3ImageUrl
          metadataID
          audioID
          videoID
          autotranscriptID
          goldtranscriptID
          customtranscriptID
          metadataStatus
          audioStatus
          videoStatus
          transcriptionStatus
          diarizationStatus
          subtitleStatus
          gnlpStatus
          nlpStatus
          contextchannelID
          contactEmailAddresses
          validationProgressStatus
          extra
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSpeakerSubscription = /* GraphQL */ `
  query GetSpeakerSubscription($id: ID!) {
    getSpeakerSubscription(id: $id) {
      id
      createdAt
      userID
      user {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      speakerID
      speaker {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSpeakerSubscriptions = /* GraphQL */ `
  query ListSpeakerSubscriptions(
    $filter: ModelSpeakerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpeakerSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        userID
        user {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        speakerID
        speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSpeakerSubscriptions = /* GraphQL */ `
  query SyncSpeakerSubscriptions(
    $filter: ModelSpeakerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSpeakerSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        userID
        user {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        speakerID
        speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const contextchannelsByClientByName = /* GraphQL */ `
  query ContextchannelsByClientByName(
    $clientID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContextchannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ContextchannelsByClientByName(
      clientID: $clientID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        name
        s3ImageUrl
        reference_id
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        allowedUsers {
          nextToken
        }
        dynamicConfig
        wordTaggerModuleSettings {
          enabled
          categories
        }
        gnlpConfig {
          prompt
          maxTokens
          temperature
          key
          frequencyPenalty
          presencePenalty
          languageISO
        }
        gnlpTasks
        speechmodelID
        SpeechModel {
          id
          name
          description
          s3ImageUrl
          allowedCognitoGroups
          provider
          connectionString
          languageID
          languagedialectID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        wordcloudSettings {
          whitelist
          blacklist
        }
        precomputedWordCountsS3Key
        ClusteringModuleOutputs {
          nextToken
          startedAt
        }
        NLPSteps {
          nextToken
          startedAt
        }
        posmodelID
        POSModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nermodelID
        NERModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        summarymodelID
        SummaryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        sentimentmodelID
        SentimentModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categorymodelID
        CategoryModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Sessions {
          nextToken
          startedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        ContextchannelLogs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const contextchannelLogsByContextchannelByTypeByCreatedAt = /* GraphQL */ `
  query ContextchannelLogsByContextchannelByTypeByCreatedAt(
    $contextchannelID: ID!
    $typeCreatedAt: ModelContextchannelLogContextchannelLogsByContextchannelByTypeByCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContextchannelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ContextchannelLogsByContextchannelByTypeByCreatedAt(
      contextchannelID: $contextchannelID
      typeCreatedAt: $typeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        createdAt
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        type
        ApiCallLogEntry {
          ipAddress
          userName
          method
          route
          httpVersion
          statusCode
          userAgent
        }
        SessionStatusUpdateLogEntry {
          sessionID
          sessionName
          attributeName
          oldValue
          newValue
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const sessionsByContextchannelByCreatedAt = /* GraphQL */ `
  query SessionsByContextchannelByCreatedAt(
    $contextchannelID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SessionsByContextchannelByCreatedAt(
      contextchannelID: $contextchannelID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        name
        clientID
        allowedCognitoGroups
        wordcloudSettings {
          whitelist
          blacklist
        }
        wordTaggerModuleOutputs {
          nextToken
          startedAt
        }
        gnlpOutputs
        NLPOutputs {
          nextToken
          startedAt
        }
        title
        summary
        keywords
        rewrittenS3Key
        condensed
        category
        sourceFileS3Key
        s3ImageUrl
        metadataID
        Metadata {
          id
          allowedCognitoGroups
          metadataS3Key
          durationSeconds
          metadataStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        audioID
        Audio {
          id
          allowedCognitoGroups
          uncompressedAudioS3Key
          compressedAudioS3Key
          waveformDataS3Key
          audioStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoID
        Video {
          id
          allowedCognitoGroups
          compressedVideoS3Key
          videoStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        autotranscriptID
        AutoTranscript {
          id
          allowedCognitoGroups
          speechmodelID
          transcriptSourceS3Key
          transcriptStandardizedS3Key
          transcriptTranslationS3Keys
          transcriptionStatus
          exportPlainTextS3Key
          exportJsonS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          diarizationS3Key
          summaryS3Key
          categoriesJson
          autotranscriptstatsID
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        customtranscriptID
        CustomTranscript {
          id
          allowedCognitoGroups
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        metadataStatus
        audioStatus
        videoStatus
        transcriptionStatus
        diarizationStatus
        subtitleStatus
        gnlpStatus
        nlpStatus
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Speakers {
          nextToken
        }
        Languages {
          nextToken
        }
        contactEmailAddresses
        Subscriptions {
          nextToken
          startedAt
        }
        validationProgressStatus
        extra
        tags {
          key
          value
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const clusteringModuleOutputsByContextchannelByCreatedAt = /* GraphQL */ `
  query ClusteringModuleOutputsByContextchannelByCreatedAt(
    $contextchannelID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClusteringModuleOutputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clusteringModuleOutputsByContextchannelByCreatedAt(
      contextchannelID: $contextchannelID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        allowedCognitoGroups
        clientID
        contextchannelID
        Contextchannel {
          id
          allowedCognitoGroups
          name
          s3ImageUrl
          reference_id
          contactEmailAddresses
          dynamicConfig
          gnlpTasks
          speechmodelID
          precomputedWordCountsS3Key
          posmodelID
          nermodelID
          summarymodelID
          sentimentmodelID
          categorymodelID
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        key
        leafIDs
        linkageMatrixS3Url
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const speechModelsByLanguage = /* GraphQL */ `
  query SpeechModelsByLanguage(
    $languageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpeechModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SpeechModelsByLanguage(
      languageID: $languageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const speechModelsByLanguageDialect = /* GraphQL */ `
  query SpeechModelsByLanguageDialect(
    $languagedialectID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpeechModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SpeechModelsByLanguageDialect(
      languagedialectID: $languagedialectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        s3ImageUrl
        allowedCognitoGroups
        provider
        connectionString
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const languageLabelsBySubmittedUserBySubmittedAt = /* GraphQL */ `
  query LanguageLabelsBySubmittedUserBySubmittedAt(
    $submitteduserID: ID!
    $submittedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLanguageLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LanguageLabelsBySubmittedUserBySubmittedAt(
      submitteduserID: $submitteduserID
      submittedAt: $submittedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        createdAt
        submittedAt
        validatedAt
        submitteduserID
        submittedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        validateduserID
        validatedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        contextchannelID
        sessionID
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        starttime
        endtime
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        content
        contentJson
        sentimentJson
        contentOrigin
        contentJsonOrigin
        sentimentJsonOrigin
        validationStatus
        feedbackMessage
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const languageLabelsByValidatedUserByValidatedAt = /* GraphQL */ `
  query LanguageLabelsByValidatedUserByValidatedAt(
    $validateduserID: ID!
    $validatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLanguageLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LanguageLabelsByValidatedUserByValidatedAt(
      validateduserID: $validateduserID
      validatedAt: $validatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        createdAt
        submittedAt
        validatedAt
        submitteduserID
        submittedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        validateduserID
        validatedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        contextchannelID
        sessionID
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        starttime
        endtime
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        content
        contentJson
        sentimentJson
        contentOrigin
        contentJsonOrigin
        sentimentJsonOrigin
        validationStatus
        feedbackMessage
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const languageLabelsByGoldTranscriptByStarttime = /* GraphQL */ `
  query LanguageLabelsByGoldTranscriptByStarttime(
    $goldtranscriptID: ID!
    $starttime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLanguageLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LanguageLabelsByGoldTranscriptByStarttime(
      goldtranscriptID: $goldtranscriptID
      starttime: $starttime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        createdAt
        submittedAt
        validatedAt
        submitteduserID
        submittedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        validateduserID
        validatedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        contextchannelID
        sessionID
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        starttime
        endtime
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        content
        contentJson
        sentimentJson
        contentOrigin
        contentJsonOrigin
        sentimentJsonOrigin
        validationStatus
        feedbackMessage
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const languageLabelsBySpeakerByCreatedAt = /* GraphQL */ `
  query LanguageLabelsBySpeakerByCreatedAt(
    $speakerID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLanguageLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LanguageLabelsBySpeakerByCreatedAt(
      speakerID: $speakerID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        createdAt
        submittedAt
        validatedAt
        submitteduserID
        submittedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        validateduserID
        validatedUser {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clientID
        contextchannelID
        sessionID
        goldtranscriptID
        GoldTranscript {
          id
          allowedCognitoGroups
          validationProgressStatus
          transcriptS3Key
          subtitlesSrtS3Key
          subtitlesVttS3Key
          subtitleStatus
          clientID
          sessionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        starttime
        endtime
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        languagedialectID
        LanguageDialect {
          id
          name
          ISO_639_2_dialect
          languageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        content
        contentJson
        sentimentJson
        contentOrigin
        contentJsonOrigin
        sentimentJsonOrigin
        validationStatus
        feedbackMessage
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const nLPModelsByBaseModelByModelTrainedAt = /* GraphQL */ `
  query NLPModelsByBaseModelByModelTrainedAt(
    $basemodelID: ID!
    $modelTrainedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNLPModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    NLPModelsByBaseModelByModelTrainedAt(
      basemodelID: $basemodelID
      modelTrainedAt: $modelTrainedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const nLPModelsByNLPModelType = /* GraphQL */ `
  query NLPModelsByNLPModelType(
    $nlpmodeltypeID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNLPModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    NLPModelsByNLPModelType(
      nlpmodeltypeID: $nlpmodeltypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelTrainedAt
        name
        huggingfaceRef
        pipelineParametersJson
        inferenceParametersJson
        otherParametersJson
        basemodelID
        BaseModel {
          id
          modelTrainedAt
          name
          huggingfaceRef
          pipelineParametersJson
          inferenceParametersJson
          otherParametersJson
          basemodelID
          nlpmodeltypeID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ChildModels {
          nextToken
          startedAt
        }
        nlpmodeltypeID
        NLPModelType {
          id
          name
          huggingfaceTask
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const speakersByLanguageByLastName = /* GraphQL */ `
  query SpeakersByLanguageByLastName(
    $languageID: ID!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpeakerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SpeakersByLanguageByLastName(
      languageID: $languageID
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const speakersByClientByLastName = /* GraphQL */ `
  query SpeakersByClientByLastName(
    $clientID: ID!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpeakerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SpeakersByClientByLastName(
      clientID: $clientID
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allowedCognitoGroups
        firstName
        infix
        lastName
        gender
        Subscriptions {
          nextToken
          startedAt
        }
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Languages {
          nextToken
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        clientID
        Client {
          id
          displayName
          cognitoGroupName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        LanguageLabels {
          nextToken
          startedAt
        }
        userID
        User {
          id
          teamGroup
          isActive
          email
          needsEmailChange
          firstName
          infix
          lastName
          avatarS3Url
          speakerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const languageDialectsByLanguageByName = /* GraphQL */ `
  query LanguageDialectsByLanguageByName(
    $languageID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLanguageDialectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LanguageDialectsByLanguageByName(
      languageID: $languageID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ISO_639_2_dialect
        languageID
        Language {
          id
          name
          ISO_639_1
          ISO_639_2
          ISO_639_3
          stopwords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        SpeechModels {
          nextToken
          startedAt
        }
        Contextchannels {
          nextToken
        }
        Sessions {
          nextToken
        }
        Speakers {
          nextToken
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UsersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamGroup
        isActive
        email
        needsEmailChange
        firstName
        infix
        lastName
        avatarS3Url
        speakerID
        Speaker {
          id
          allowedCognitoGroups
          firstName
          infix
          lastName
          gender
          languageID
          clientID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        submittedLanguageLabels {
          nextToken
          startedAt
        }
        validatedLanguageLabels {
          nextToken
          startedAt
        }
        allowedContextchannels {
          nextToken
        }
        ContextchannelSubscriptions {
          nextToken
          startedAt
        }
        SessionSubscriptions {
          nextToken
          startedAt
        }
        SpeakerSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
