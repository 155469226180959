import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'
import { Client } from '../../../API.service'
import { $ } from 'protractor'

@Component({
    selector: 'pia3-client-selector-single',
    templateUrl: './pia3-client-selector-single.component.html',
    styleUrls: ['./pia3-client-selector-single.component.scss'],
})
export class Pia3ClientSelectorSingleComponent implements OnInit {
    selectorMode: string = SelectorMode.SINGLE
    @Output('clientSelected') clientSelected = new EventEmitter<Client>()

    constructor() {}

    ngOnInit() {}

    _clientSelected($event) {
        //////console.log($event)
        this.clientSelected.emit($event)
    }
}
