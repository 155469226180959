import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PiaSurferComponent } from './pia-surfer.component';

@NgModule({
  imports: [CommonModule, HttpClientModule, FormsModule, IonicModule],
  declarations: [PiaSurferComponent],
  exports: [PiaSurferComponent],
})
export class PiaSurferModule {}
