import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core'
import { Router, Routes, RouterOutlet, Event, RouterEvent, NavigationEnd } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'

import { Hub, Logger } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { DataStore, Predicates } from 'aws-amplify'

import { routes } from './app-routing.module'
import { AuthService } from './auth/auth.service'
import { GenericErrorHandlerService } from './.common/services/generic-error-handler.service'
import { Session, Client, Language, LanguageDialect } from 'src/app/API.service'
import { CognitoUser } from '@aws-amplify/auth'
import { Hotkey, HotkeysService } from 'angular2-hotkeys'
import { PiaMediaEventSource, PiaMediaEventsService } from './.common/services/pia-media-events.service'
import { Pia3Service, Pia3AuthenticationService } from 'pia3'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    @ViewChild(RouterOutlet) outlet: RouterOutlet

    public currentLoggedInUser: CognitoUser = null
    public currentLoggedInUser$ = new BehaviorSubject<CognitoUser>(this.currentLoggedInUser)
    private isInitialized: boolean = false
    sectionRoutes: Routes = routes
    isFullscreenRoute: boolean = false
    defaultRedirectRoute: string = '/contextchannels' //'/contextchannels/46c9ba0f-4d2b-4383-8bf4-f50628087f48/nlp-models/list' //'/contextchannels' //'/contextchannels/d3577356-14f7-45de-b7c0-553882ebce38/nlp-models/list' //'/contextchannels/46c9ba0f-4d2b-4383-8bf4-f50628087f48/sessions/bcbd5bce-4c96-4400-87f4-eee5b149bd59/transcripts'

    // public logger = new Logger('My-Logger')

    // public listener = (data) => {
    //     switch (data.payload.event) {
    //         case 'signIn':
    //             this.logger.info('user signed in')
    //             break
    //         case 'signUp':
    //             this.logger.info('user signed up')
    //             break
    //         case 'signOut':
    //             this.logger.info('user signed out')
    //             break
    //         case 'signIn_failure':
    //             this.logger.error('user sign in failed')
    //             break
    //         case 'tokenRefresh':
    //             this.logger.info('token refresh succeeded')
    //             break
    //         case 'tokenRefresh_failure':
    //             this.logger.error('token refresh failed')
    //             break
    //         case 'configured':
    //             this.logger.info('the Auth module is configured')
    //     }
    //     this.logger.info(data.payload)
    // }

    constructor(private pia3Service: Pia3Service, private router: Router, public pia3AuthService: Pia3AuthenticationService, public hotkeyService: HotkeysService, private genericErrorHandlerService: GenericErrorHandlerService, public piaMediaEventsService: PiaMediaEventsService) {
        this.pia3Service.setupPia3()
        this.attachHotkeys()
        this.pia3AuthService.setLoginPath('/auth/login')
        this.pia3AuthService.setLoginRedirectPath(this.defaultRedirectRoute)
    }

    ngOnInit() {
        this.isInitialized = true
        this.pia3AuthService.currentLoggedInUser.subscribe(this.currentLoggedInUserChanged)
    }

    currentLoggedInUserChanged(user) {
        if (this.isInitialized) {
            if (user == null) {
                ////console.log('currentLoggedInUserChanged on app.component.ts', user)
                this.router.navigateByUrl('auth/login')
            } else {
                ////console.log('currentLoggedInUserChanged on app.component.ts', user)
                this.currentLoggedInUser = user
                this.currentLoggedInUser$.next(this.currentLoggedInUser)
                this.router.navigateByUrl(this.defaultRedirectRoute)
            }
        }
    }

    attachHotkeys() {
        let elementsWithHotkeysEnabled: Array<string> = ['BODY', 'ION-ITEM', 'TEXTAREA', 'DIV']

        //CTRL+?: PIAMEDIAEVENT:SHOW HOTKEY CHEATSHEET
        this.hotkeyService.add(
            new Hotkey(
                'ctrl+shift+x',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+shift+x pressed on app')
                    this.hotkeyService.cheatSheetToggle.next()
                    return false
                },
                elementsWithHotkeysEnabled,
                'Show the cheatsheet for all the hotkeys.'
            )
        )
        //CTRL+SPACE: PIAMEDIAEVENT:GLOBALPLAYPAUSE
        this.hotkeyService.add(
            new Hotkey(
                'ctrl+space',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+space pressed on app')
                    this.piaMediaEventsService.globalPlayPause.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Play/Pause of media. Only works when in a screen where one or more mediacompontents are loaded.'
            )
        )
        //CTRL+ARROW_LEFT: PIAMEDIAEVENT:GLOBALSEEKLEFT
        this.hotkeyService.add(
            new Hotkey(
                'ctrl+left',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+left pressed on app')
                    this.piaMediaEventsService.globalScrubLeft.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Scrub media to the left / Skip to one second earlier in the media.'
            )
        )
        //CTRL+ARROW_LEFT: PIAMEDIAEVENT:GLOBALSEEKLEFT
        this.hotkeyService.add(
            new Hotkey(
                'ctrl+right',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+right pressed on app')
                    this.piaMediaEventsService.globalScrubRight.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Scrub media to the right / Skip to one second later in the media.'
            )
        )
        //CTRL+ARROW_LEFT: PIAMEDIAEVENT:GLOBALZOOMIN
        this.hotkeyService.add(
            new Hotkey(
                'ctrl+shift+right',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+shift+right pressed on app')
                    this.piaMediaEventsService.globalZoomIn.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Zoom-in: one step per click.'
            )
        )
        //CTRL+ARROW_LEFT: PIAMEDIAEVENT:GLOBALZOOMOUT
        this.hotkeyService.add(
            new Hotkey(
                'ctrl+shift+left',
                (event: KeyboardEvent): boolean => {
                    ////console.log('ctrl+shift+left pressed on app')
                    this.piaMediaEventsService.globalZoomOut.next({ source: PiaMediaEventSource.PiaApplication, sessionId: null })
                    return false
                },
                elementsWithHotkeysEnabled,
                'Zoom-out: one step per click.'
            )
        )
    }

    redirectUserIfLoggedIn() {}

    subscribeToRouterEvents() {
        this.router.events.pipe(filter((e: Event): e is RouterEvent => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
            if (e.urlAfterRedirects.startsWith('/auth/')) {
                this.isFullscreenRoute = true
            } else {
                this.isFullscreenRoute = false
            }
        })
    }
}
