import { Pia3LanguagelabelService } from './../../languagelabel/pia3-languagelabel.service'
import { Injectable } from '@angular/core'
import * as queries from './../../../graphql/queries'
import { API, graphqlOperation } from '@aws-amplify/api'
import { ModelSortDirection, LanguageLabelsBySubmittedUserBySubmittedAtQuery, User } from './../../../API.service'
import { Pia3UserPerformanceChartTranscriptionDataminutesInterface } from './pia3-user-performance-chart-transcription-dataminutes.component'
import { rejects } from 'assert'
import { ErrorhandlingService } from '../../_common/errorhandling/errorhandling.service'
import { label } from 'aws-amplify'

@Injectable({
    providedIn: 'root',
})
export class Pia3UserPerformanceChartTranscriptionDataminutesService {
    private _validatedMinutes!: number
    private _rejectedMinutes!: number
    private _validationRequestedMinutes!: number

    private _labelsValidated: any[] = []
    private _labelsValidationRequested: any[] = []
    private _labelsRejected: any[] = []

    private _user: User

    chartData!: Pia3UserPerformanceChartTranscriptionDataminutesInterface

    constructor(private pia3LanguagelabelService: Pia3LanguagelabelService, private errorhandlingService: ErrorhandlingService) {}

    async getData(userID: string): Promise<Pia3UserPerformanceChartTranscriptionDataminutesInterface> {
        return new Promise((resolve, reject) => {
            let lablesOfUser = []

            try {
                //get labels of user from server
                this.pia3LanguagelabelService
                    .getLanguageLabelsBySubmittedUserBySubmittedAt(userID)
                    .then((result) => {
                        //labels
                        lablesOfUser = result.data.LanguageLabelsBySubmittedUserBySubmittedAt.items
                        //console.log(lablesOfUser)
                        //user
                        this._user = result.data.LanguageLabelsBySubmittedUserBySubmittedAt.items[0].submittedUser
                    }, this.errorhandlingService.handleGenericGraphQLError)
                    .catch(this.errorhandlingService.handleGenericGraphQLError)
                    .then(() => {
                        //process/calculate the data
                        if (lablesOfUser.length > 0) {
                            this.calculateLabelsValidatedTotalDuration(lablesOfUser)
                            this.calculateLabelsRejectedTotalDuration(lablesOfUser)
                            this.calculateLabelsValidationRequestedTotalDuration(lablesOfUser)

                            //prepare the data
                            this.prepareTheData()

                            resolve(this.chartData)
                        }
                    }, this.errorhandlingService.handleGenericGraphQLError)
            } catch (error) {
                reject(error) // Reject the promise with the error
            }
        })
    }

    /**
     * Prepare the data
     */
    private prepareTheData() {
        this.chartData = {
            user: this._user,
            labels: {
                validated: {
                    labels: this._labelsValidated,
                    validatedMinutes: this._validatedMinutes,
                },
                validationRequested: {
                    labels: this._labelsValidationRequested,
                    validationRequestedMinutes: this._validationRequestedMinutes,
                },
                rejected: {
                    labels: this._labelsRejected,
                    rejectedMinutes: this._rejectedMinutes,
                },
            },
        }
    }

    /**
     * Calculates validated labels total duration
     * @param lablesOfUser
     */
    private calculateLabelsValidatedTotalDuration(lablesOfUser: any[]) {
        let arrayOfLabelsValidatedDurations: string[] = []
        let arrayOfLabelsValidated: any[] = []
        try {
            lablesOfUser.map((label) => {
                if (label.validationStatus === 'Validated') {
                    let timediff = this.calculateTimeDifference(label.starttime, label.endtime)
                    arrayOfLabelsValidatedDurations.push(timediff)
                    arrayOfLabelsValidated.push(label)
                }
            })
            //calculate total duration of labels
            if (arrayOfLabelsValidatedDurations.length > 0) {
                let totalDuration = this.calculateTotalDuration(arrayOfLabelsValidatedDurations)
                let asMinuteTotalDuration = this.convertDurationToNumberMinute(totalDuration)
                this._validatedMinutes = asMinuteTotalDuration
                this._labelsValidated = arrayOfLabelsValidated
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Calculate rejected labels total duration
     * @param lablesOfUser
     */
    private calculateLabelsRejectedTotalDuration(lablesOfUser: any[]) {
        let arrayOfLabelsRejectedDurations: string[] = []
        let arrayOfLabelsRejected: any[] = []
        try {
            lablesOfUser.map((label) => {
                if (label.validationStatus === 'Rejected') {
                    let timediff = this.calculateTimeDifference(label.starttime, label.endtime)
                    arrayOfLabelsRejectedDurations.push(timediff)
                    arrayOfLabelsRejected.push(label)
                }
            })
            //calculate total duration of labels
            if (arrayOfLabelsRejectedDurations.length > 0) {
                let totalDuration = this.calculateTotalDuration(arrayOfLabelsRejectedDurations)
                let asMinuteTotalDuration = this.convertDurationToNumberMinute(totalDuration)
                this._rejectedMinutes = asMinuteTotalDuration
                this._labelsRejected = arrayOfLabelsRejected
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Calculate validation requested labels total duration
     * @param lablesOfUser
     */
    private calculateLabelsValidationRequestedTotalDuration(lablesOfUser: any[]) {
        let arrayOfLabelsValidationRequestedDurations: string[] = []
        let arrayOfLabelsValidationRequested: any[] = []

        try {
            lablesOfUser.map((label) => {
                if (label.validationStatus === 'ValidationRequested') {
                    let timediff = this.calculateTimeDifference(label.starttime, label.endtime)
                    arrayOfLabelsValidationRequestedDurations.push(timediff)
                    arrayOfLabelsValidationRequested.push(label)
                }
            })
            //calculate total duration of labels
            if (arrayOfLabelsValidationRequestedDurations.length > 0) {
                let totalDuration = this.calculateTotalDuration(arrayOfLabelsValidationRequestedDurations)
                let asMinuteTotalDuration = this.convertDurationToNumberMinute(totalDuration)
                this._validationRequestedMinutes = asMinuteTotalDuration
                this._labelsValidationRequested = arrayOfLabelsValidationRequested
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Calculates time difference
     * @param startTime
     * @param endTime
     * @returns `${hours}:${minutes}:${seconds}.${milliseconds}`
     */
    private calculateTimeDifference(startTime: string, endTime: string): string {
        try {
            const date1 = new Date(`2000-01-01T${startTime}Z`)
            const date2 = new Date(`2000-01-01T${endTime}Z`)

            const differenceInMs = Math.abs(date1.getTime() - date2.getTime())

            const hours = Math.floor(differenceInMs / (1000 * 60 * 60))
                .toString()
                .padStart(2, '0')
            const minutes = Math.floor((differenceInMs / (1000 * 60)) % 60)
                .toString()
                .padStart(2, '0')
            const seconds = Math.floor((differenceInMs / 1000) % 60)
                .toString()
                .padStart(2, '0')
            const milliseconds = Math.floor(differenceInMs % 1000)
                .toString()
                .padStart(3, '0')

            return `${hours}:${minutes}:${seconds}.${milliseconds}`
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Calculates total duration
     * @param arrayOfDurations
     * @returns string
     */
    private calculateTotalDuration(arrayOfDurations: string[]): string {
        try {
            let totalMilliseconds = 0

            for (const duration of arrayOfDurations) {
                const [hours, minutes, secondsAndMilliseconds] = duration.split(':')
                const [seconds, milliseconds] = secondsAndMilliseconds.split('.')

                const parsedHours = parseInt(hours)
                const parsedMinutes = parseInt(minutes)
                const parsedSeconds = parseInt(seconds)
                const parsedMilliseconds = parseInt(milliseconds)

                totalMilliseconds += parsedHours * 3600000 + parsedMinutes * 60000 + parsedSeconds * 1000 + parsedMilliseconds
            }

            const totalHours = Math.floor(totalMilliseconds / 3600000)
            const totalMinutes = Math.floor((totalMilliseconds % 3600000) / 60000)
            const totalSeconds = Math.floor((totalMilliseconds % 60000) / 1000)
            const totalMillisecondsPart = totalMilliseconds % 1000

            return `${this.padZero(totalHours)}:${this.padZero(totalMinutes)}:${this.padZero(totalSeconds)}.${this.padZero(totalMillisecondsPart, 3)}`
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Helper sub method of calculateTotalDuration
     * @param number
     * @param length
     * @returns
     */
    private padZero(number: number, length: number = 2): string {
        return number.toString().padStart(length, '0')
    }

    /**
     * Converts string duration to number fomat
     * @param duration
     * @returns
     */
    private convertDurationToNumberMinute(duration: string): number {
        try {
            const [hours, minutes, secondsAndMilliseconds] = duration.split(':')
            const [seconds, milliseconds] = secondsAndMilliseconds.split('.')

            const parsedHours = parseInt(hours)
            const parsedMinutes = parseInt(minutes)
            const parsedSeconds = parseInt(seconds)
            const parsedMilliseconds = parseInt(milliseconds)

            const totalMilliseconds = parsedHours * 3600000 + parsedMinutes * 60000 + parsedSeconds * 1000 + parsedMilliseconds

            const totalMinutes = totalMilliseconds / 60000

            return parseFloat(totalMinutes.toFixed(5))
        } catch (error) {
            console.error(error)
            // return 0; // Return a default value in case of error
        }
    }
}
