import { Component, Input, OnInit } from '@angular/core'
import { Pia3ThemePositionHorizontal, Pia3ThemePositionVertical } from '../../theme/pia3-theme.service'

@Component({
    selector: 'pia3-contextchannel-add-popup-button',
    templateUrl: './pia3-contextchannel-add-popup-button.component.html',
    styleUrls: ['./pia3-contextchannel-add-popup-button.component.scss'],
})
export class Pia3ContextchannelAddPopupButtonComponent implements OnInit {
    @Input('clientID') clientID: string = null
    @Input('horizontal') horizontal: Pia3ThemePositionHorizontal = Pia3ThemePositionHorizontal.End // Enum:Pia3ThemePositionHorizontal start, center, end
    @Input('vertical') vertical: Pia3ThemePositionVertical = Pia3ThemePositionVertical.Bottom // Enum:Pia3ThemePositionVertical top, center, bottom
    @Input('edge') edge: boolean = false
    public isModalOpen = false

    constructor() {}

    ngOnInit() {}

    openAddContextchannelModal() {
        this.setOpen(true)
    }

    setOpen(isOpen: boolean) {
        this.isModalOpen = isOpen
    }
}
