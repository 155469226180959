<ion-row>
  <ion-col sizeMd="8" offsetMd="2">
    <ion-card>
      <ion-card-header class="ion-no-padding">
        <ion-toolbar class="ion-padding-start" color="primary">
          <!-- <ion-button color="light" class="button-person"
            [fill]="buttonfillSolid ? 'outline':'solid'"
            (click)="selectPerson()">Person<span
              class="span-person">1</span></ion-button>
          <ion-button color="light" class="button-org"
            [fill]="!buttonfillSolid ? 'outline':'solid'"
            (click)="selectOrg()">Org<span
              class="span-org">2</span></ion-button> -->
          <ion-button
            *ngFor="let cat of categoriesWithId; index as i;"
            [id]="cat.id"
            color="light" class="button-categories"
            [fill]="cat.buttonFillSolid ? 'solid':'outline'"
            (click)="selectedCategory(cat)">{{cat.category}}<span
              class="span-categories">{{i+1}}</span></ion-button>

          <ion-button class="button-send" slot="end" (click)="emitTheWords()">
            Send
            <ion-icon class="icon-send" size="small" name="send"></ion-icon>
          </ion-button>
        </ion-toolbar>
      </ion-card-header>

      <ion-card-content>
        <ion-list class="ion-padding">
          <mark
            class="word-container"
            id="{{ item.id }}dv"
            *ngFor="let item of wordsArray"
            selection="true">
            <ion-icon
              class="remove-icon"
              [hidden]="item.isHiddenCloseIcon"
              *ngIf="item.hasCloseIcon"
              color="danger"
              size="small"
              name="close-circle"
              (click)="removeStyle(item)"></ion-icon>
            <span
              class="span-item"
              [id]="item.id"
              (mouseup)="detectSelection()">{{ item.word }}
            </span>
            <span
              class="catagory-name-of-word"
              *ngIf="item.isCatagoryNameActiveOnSelectedWord">
              <ion-text>{{item.categoryName.substring(2)}}</ion-text>
            </span>
          </mark>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
