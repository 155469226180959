import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'
import { Speaker } from './../../../API.service'

@Component({
    selector: 'pia3-speaker-selector-single',
    templateUrl: './pia3-speaker-selector-single.component.html',
    styleUrls: ['./pia3-speaker-selector-single.component.scss'],
})
export class Pia3SpeakerSelectorSingleComponent implements OnInit {
    @Input('clientID') clientID!: string
    selectorMode: string = SelectorMode.SINGLE
    @Output('speakerSelected') speakerSelected = new EventEmitter<Speaker>()

    constructor() {}

    ngOnInit() {}

    _speakerSelected($event) {
        this.speakerSelected.emit($event)
    }
}
