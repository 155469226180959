import { Component, OnInit, Input } from '@angular/core'
import { Routes, Router, Route, NavigationEnd } from '@angular/router'
import { url } from 'inspector'
import { Pia3AuthenticationService, Pia3CognitoRole } from 'pia3'

@Component({
    selector: 'app-section-navigation-buttons',
    templateUrl: './section-navigation-buttons.component.html',
    styleUrls: ['./section-navigation-buttons.component.scss'],
})
export class SectionNavigationButtonsComponent implements OnInit {
    @Input() sectionTitle: string
    @Input() sectionPath: string
    @Input() sectionRoutes: Routes

    public activePath: string = null

    constructor(private router: Router, private pia3AuthService: Pia3AuthenticationService) {}

    ngOnInit() {
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                let urlParts = e.urlAfterRedirects.split('/')

                let urlPart = this.sectionPath == '/' ? urlParts[1] : urlParts[2]
                this.activePath = urlPart
                //////console.log('event:', e.urlAfterRedirects);
                //////console.log();
                this.sectionRoutes.forEach((value, index) => {
                    value.data.active = false
                    if (e.urlAfterRedirects.toLowerCase().indexOf(value.path) > 0) {
                        //////console.log('i am on the current route:', value);
                        value.data.active = true
                    } else {
                        value.data.active = false
                    }
                })
            }
            // NavigationEnd
            // NavigationCancel
            // NavigationError
            // RoutesRecognized
        })
    }

    getRouteIsDisabled(route: Route) {
        ////console.log('route.data.allowedRoles', route.data.allowedRoles)
        let routeIsDisabled: boolean = true
        try {
            if (route.data.allowedRoles != undefined && route.data.allowedRoles != null) {
                let allowedRoles: Pia3CognitoRole[] = route.data.allowedRoles
                routeIsDisabled = !this.pia3AuthService.hasAnyPia3Role(allowedRoles)
            }
        } catch (error) {
            ////console.log('error', error)
        }

        return routeIsDisabled
    }

    getRouterLink(sectionPath: string, sectionPagePath: string) {
        let parentSection = sectionPath != '/' ? '/' + sectionPath + '/' : ''
        let routerLink: string = parentSection + sectionPagePath
        return routerLink
    }

    navigateToRouterLink(sectionPath, sectionPagePath) {
        this.router.navigateByUrl(this.getRouterLink(sectionPath, sectionPagePath))
    }
}
