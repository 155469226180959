import { Injectable, Input } from '@angular/core'
import { GPTCompletionInput, GptCompletionQuery } from './../../API.service'
import { API, graphqlOperation } from 'aws-amplify'

import * as queries from './../../graphql/queries'

@Injectable({
    providedIn: 'root',
})
export class Pia3TextconverterService {
    constructor() {}

    /**
     *
     * @param input
     * @returns Promise<{ data: GptCompletionQuery }>
     */
    public async getGptCompletion(input: GPTCompletionInput): Promise<{ data: GptCompletionQuery }> {
        return (await API.graphql<GptCompletionQuery>(graphqlOperation(queries.gptCompletion, { input: input }))) as Promise<{ data: GptCompletionQuery }>
    }
}
