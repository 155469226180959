import { Component, OnInit, Input } from '@angular/core';
import { Routes, Router, NavigationEnd } from '@angular/router';
import { url } from 'inspector';

@Component({
  selector: 'app-section-toolbar',
  templateUrl: './section-toolbar.component.html',
  styleUrls: ['./section-toolbar.component.scss'],
})
export class SectionToolbarComponent implements OnInit {

  @Input() sectionNavigationEnabled:boolean
  @Input() toolbarColor:string
  @Input() sectionTitle:string
  @Input() sectionPath:string
  @Input() sectionRoutes: Routes

  private activePath:string = null

  constructor(
    private router:Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd) {        
        let urlParts = e.urlAfterRedirects.split('/')
        let urlPart = (this.sectionPath == '/') ? urlParts[1] : urlParts[2]
        this.activePath = urlPart
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }

  getRouterLink(sectionPath:string,sectionPagePath:string){
    let parentSection = (sectionPath != '/') ? '/'+sectionPath+'/' : ''
    let routerLink:string = parentSection+sectionPagePath
    return routerLink
  }

  navigateToRouterLink(sectionPath,sectionPagePath){
    this.router.navigateByUrl(this.getRouterLink(sectionPath,sectionPagePath))
  }

}


export interface section_toolbar_highlighting {
  normal_color:string,
  highlighted_color:string,
  active_name:string
}
