import { Component, Input, OnInit, SimpleChange, Output, EventEmitter } from '@angular/core'
import { input, Storage } from 'aws-amplify'
import { Audio } from 'src/app/API.service'
import * as WaveSurfer from 'wavesurfer.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions'
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline'
import { Region } from 'wavesurfer.js/dist/plugin/wavesurfer.regions'
import { ColorValuesRGB } from '../../enums/colors'
import { Observable, Subject, Subscription } from 'rxjs'
import { GenericErrorHandlerService } from '../../services/generic-error-handler.service'
import { PiaMediaEventSource, PiaMediaEventsService } from 'src/app/.common/services/pia-media-events.service'
import { PiaSurfer, PiaSurferConfig, PiaSurferWaveform } from '../pia-surfer/pia-surfer'
import { PlaybackAction, PlaybackCommand } from '../pia-video/pia-video.component'
import { throwIfEmpty } from 'rxjs/operators'

//#region Component
@Component({
    selector: 'app-pia-surfer',
    templateUrl: './pia-surfer.component.html',
    styleUrls: ['./pia-surfer.component.scss'],
})
export class PiaSurferComponent implements OnInit {
    //#region Inputs & Outputs
    @Input() title: string
    @Input() piaAudioKey: string
    @Input() piaAudio: Audio
    @Input() piaZoomlevel: number = 125
    @Input() piaZoomlevelStep: number = 25
    @Input() piaZoomlevelMin: number = 0
    @Input() piaZoomlevelMax: number = 600
    @Input() playbackCommandSubject: Subject<PlaybackCommand>
    @Input() insertRegionSubject: Subject<Region>
    @Input() selectRegionSubject: Subject<Region>
    @Input() deselectedRegionSubject: Subject<Region>
    @Input() updateRegionSubject: Subject<Region>
    @Input() deleteRegionSubject: Subject<Region>

    @Input() piaSurferDoPlay: Subject<boolean>
    @Input() piaSurferDoStop: Subject<boolean>
    @Input() piaSurferDoPause: Subject<boolean>

    @Output()
    piaSurferOnPlaybackCommandEventEmitter: EventEmitter<PlaybackCommand> = new EventEmitter<PlaybackCommand>(true)
    @Output() piaSurferOnReady: EventEmitter<string> = new EventEmitter()

    // @Output() piaSurferOnPlayEventEmitter: EventEmitter<PlaybackCommand> =
    //   new EventEmitter();
    // @Output() piaSurferOnPauseEventEmitter: EventEmitter<PlaybackCommand> =
    //   new EventEmitter();
    // @Output() piaSurferOnStopEventEmitter: EventEmitter<PlaybackCommand> =
    //   new EventEmitter();
    // @Output() piaSurferOnSeekEventEmitter: EventEmitter<PlaybackCommand> =
    //   new EventEmitter();

    //#endregion

    //#region Local variables
    private audioUrlExpirationTime: number = 14400 //4 hours (x 60m x 60s) = 14400s
    public piaMediaEventOrigin: PiaMediaEventSource = PiaMediaEventSource.PiaSurfer
    public zoomlevel: number = this.piaZoomlevel
    public zoomlevelStep: number = this.piaZoomlevelStep
    public zoomlevelMin: number = this.piaZoomlevelMin
    public zoomlevelMax: number = this.piaZoomlevelMax
    public isLoading: boolean = true
    public hasLoaded: boolean = true
    public audioUrlFirstLoadSuccess: boolean = false
    public audioUrlReloadAttempts: number = 0
    public hasErrorLoadingWaveformData: boolean = false
    public hasErrorLoadingAudioS3Url: boolean = false
    public wavesurferItem: PiaSurfer = null
    private subscriptions: [Subscription] = [null]

    //#region Constructor
    constructor(private genericErrorHandlerService: GenericErrorHandlerService, private piaMediaEventsService: PiaMediaEventsService) {
        //Subscribe to PiaMediaEvents
        this.subscriptions.push(this.piaMediaEventsService.sessionPlay.subscribe(this.piaMediaEventSessionPlaySubscription))
        this.subscriptions.push(this.piaMediaEventsService.sessionPause.subscribe(this.piaMediaEventSessionPauseSubscription))
        this.subscriptions.push(this.piaMediaEventsService.sessionStop.subscribe(this.piaMediaEventSessionStopSubscription))
        this.subscriptions.push(this.piaMediaEventsService.globalPlayPause.subscribe(this.piaMediaEventGlobalPlayPauseSubscription))
        this.subscriptions.push(this.piaMediaEventsService.globalScrubLeft.subscribe(this.piaMediaEventGlobalScrubLeftSubscription))
        this.subscriptions.push(this.piaMediaEventsService.globalScrubRight.subscribe(this.piaMediaEventGlobalScrubRightSubscription))
        this.subscriptions.push(this.piaMediaEventsService.globalZoomIn.subscribe(this.piaMediaEventGlobalZoomInSubscription))
        this.subscriptions.push(this.piaMediaEventsService.globalZoomOut.subscribe(this.piaMediaEventGlobalZoomOutSubscription))
    }
    //#endregion

    //#region Lifecycle Events
    ngOnInit() {
        this.isLoading = true
        //Create, setup and load the wavesurfer
        if (this.piaAudioKey != null && this.piaAudioKey != '') {
            if (this.wavesurferItem != null) {
                if (this.wavesurferItem.wavesurfer != null) {
                    this.wavesurferItem.wavesurfer.destroy()
                }
            }
            //////console.log('before createSetupAnd...');
            this.createSetupAndLoadWavesurfer(this.wavesurferItem, this.piaAudioKey).then((result) => {
                //////console.log('PiaSurfer load ready');
                this.isLoading = false
            })
        }
    }

    ngOnChanges(change: SimpleChange) {
        //Create, setup and load the wavesurfer
        if (this.piaAudioKey != null && this.piaAudioKey != '') {
            if (this.wavesurferItem != null) {
                if (this.wavesurferItem.wavesurfer != null) {
                    this.wavesurferItem.wavesurfer.destroy()
                }
            }
            //////console.log('before createSetupAnd...');
            //////console.log('PiaAudioKey', this.piaAudioKey);
            this.createSetupAndLoadWavesurfer(this.wavesurferItem, this.piaAudioKey).then((result) => {
                this.isLoading = false
            })
        }
    }

    ngOnDestroy() {
        console.log('ngOnDestroy PiaSurfer')
        //Unload the wavesurfer if not null
        if (this.wavesurferItem != null) {
            if (this.wavesurferItem.wavesurfer != null) {
                console.log('unloading wavesurfer')
                this.wavesurferItem.wavesurfer.destroy()
            }
        }
        //Unsubscribe from PiaMediaEvents
        if (this.subscriptions != null) {
            this.subscriptions.forEach((subscription) => {
                if (subscription != null) {
                    console.log('unsubscribe from PiaMediaEvents', subscription)
                    subscription.unsubscribe()
                    console.log('unsubscribed from PiaMediaEvents', subscription)
                }
            })
        }
    }

    ionViewWillEnter() {
        console.log('ionViewWillEnter PiaSurfer')
    }

    ionViewDidEnter() {
        console.log('ionViewDidEnter PiaSurfer')
    }

    ionViewWillLeave() {
        console.log('ionViewWillLeave PiaSurfer')
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        console.log('canDeactivate hit on PiaSurfer')
        return true
    }
    //#endregion

    //#region Mediacontrol Subscriptions

    piaMediaEventSessionPlaySubscription = (value: { source: PiaMediaEventSource; sessionId: string; position?: number }) => {
        if (value.source != this.piaMediaEventOrigin) {
            ////console.log('PiaMediaEventsService:SessionPlay:', value)
            if (value.position) {
                let progress = value.position / this.wavesurferItem.wavesurfer.getDuration()
                ////console.log(progress)
                this.wavesurferItem.wavesurfer.seekTo(progress)
            }
            setTimeout(() => {
                this.play(this.wavesurferItem)
            }, 300)
        } else {
            ////console.log('Own PiaMediaEvent...')
        }
    }

    piaMediaEventSessionPauseSubscription = (value: { source: PiaMediaEventSource; sessionId: string; position?: number }) => {
        if (value.source != this.piaMediaEventOrigin) {
            ////console.log('PiaMediaEventsService:SessionPause:', value)
        } else {
            ////console.log('Own PiaMediaEvent...')
        }
    }

    piaMediaEventSessionStopSubscription = (value: { source: PiaMediaEventSource; sessionId: string; position?: number }) => {
        if (value.source != this.piaMediaEventOrigin) {
            ////console.log('PiaMediaEventsService:SessionStop:', value)
        } else {
            ////console.log('Own PiaMediaEvent...')
        }
    }

    piaMediaEventGlobalPlayPauseSubscription = (value: { source: PiaMediaEventSource; sessionId?: string }) => {
        if (value.source != this.piaMediaEventOrigin) {
            ////console.log('PiaMediaEventsService:GlobalPlayPause:', value)
            if (this.wavesurferItem.isPlaying) {
                this.pause(this.wavesurferItem)
            } else {
                this.play(this.wavesurferItem)
            }
        } else {
            ////console.log('Own PiaMediaEvent...')
        }
    }

    piaMediaEventGlobalScrubLeftSubscription = (value: { source: PiaMediaEventSource; sessionId?: string }) => {
        if (value.source != this.piaMediaEventOrigin) {
            ////console.log('PiaMediaEventsService:GlobalScrubLeft:', value)
            ////console.log(this.zoomlevel)
            let progress: number = 1 / (this.wavesurferItem.wavesurfer.getDuration() / (this.wavesurferItem.wavesurfer.getCurrentTime() - 1))
            this.wavesurferItem.wavesurfer.seekAndCenter(progress < 0 ? 0 : progress)
        } else {
            ////console.log('Own PiaMediaEvent...')
        }
    }

    piaMediaEventGlobalScrubRightSubscription = (value: { source: PiaMediaEventSource; sessionId?: string }) => {
        if (value.source != this.piaMediaEventOrigin) {
            ////console.log('PiaMediaEventsService:GlobalScrubRight:', value)
            let progress: number = 1 / (this.wavesurferItem.wavesurfer.getDuration() / (this.wavesurferItem.wavesurfer.getCurrentTime() + 1))
            this.wavesurferItem.wavesurfer.seekAndCenter(progress < 0 ? 0 : progress)
        } else {
            ////console.log('Own PiaMediaEvent...')
        }
    }

    piaMediaEventGlobalZoomInSubscription = (value: { source: PiaMediaEventSource; sessionId?: string }) => {
        if (value.source != this.piaMediaEventOrigin) {
            ////console.log('PiaMediaEventsService:GlobalZoomIn:', value)
            let newlevel: number = this.zoomlevel + this.zoomlevelStep
            this.zoomlevel = newlevel > this.zoomlevelMax ? this.zoomlevelMax : newlevel
        } else {
            ////console.log('Own PiaMediaEvent...')
        }
    }

    piaMediaEventGlobalZoomOutSubscription = (value: { source: PiaMediaEventSource; sessionId?: string }) => {
        if (value.source != this.piaMediaEventOrigin) {
            ////console.log('PiaMediaEventsService:GlobalZoomIn:', value)
            let newlevel: number = this.zoomlevel - this.zoomlevelStep
            this.zoomlevel = newlevel < this.zoomlevelMin ? this.zoomlevelMin : newlevel
        } else {
            ////console.log('Own PiaMediaEvent...')
        }
    }

    //#endregion

    //#region Wavesurfer Creation Methods

    public createWavesurferConfig(): PiaSurferConfig {
        return {
            container: '#wsContainer',
            cursorWidth: 3,
            barHeight: 1.5,
            height: 120,
            backend: 'MediaElement',
            fillParent: true,
            responsive: true,
            pixelRatio: 1,
            minPxPerSec: this.zoomlevel,
            waveColor: ColorValuesRGB.MEDIUM,
            progressColor: ColorValuesRGB.PRIMARY,
            plugins: [],
        }
    }

    async createNewWavesurferItem(audioKey: string): Promise<PiaSurfer> {
        //Try load AudioS3Url or except
        let audioUrl = null
        try {
            audioUrl = await this.loadSessionWavesurferAudioUrl(audioKey)
            //////console.log('audioUrl:', audioUrl);
        } catch (error) {
            alert('error loading audioUrl')
            this.genericErrorHandlerService.handleGenericError(error)
        }

        //Try load AudioWaveformData or except
        let audioWaveformData = null

        try {
            audioWaveformData = await this.loadSessionWavesurferWaveformData(audioKey)
            ////////console.log('waveformdata:', audioWaveformData);
        } catch (error) {
            alert('error loading audioWaveformData')
            this.genericErrorHandlerService.handleGenericError(error)
        }

        //Create wavesurferConfig
        let wavesurferConfig = this.createWavesurferConfig()

        //Return the object
        if (wavesurferConfig != null && audioUrl != null && audioWaveformData != null) {
            this.isLoading = false
            return {
                audioUrl: audioUrl,
                audioKey: audioKey,
                audioWaveformData: audioWaveformData,
                config: wavesurferConfig,
                id: null,
                isLoading: false,
                isPlaying: false,
                wavesurfer: null,
                selectedRegion: null,
                selectedRegions: null,
            }
        } else {
            this.isLoading = false
            //should set error options
        }
    }

    async createSetupAndLoadWavesurfer(wavesurferItem: PiaSurfer, audioKey: string) {
        //Setup the wavesurferItem (includes load of audioUrl and audioWaveformData)
        wavesurferItem = await this.createNewWavesurferItem(audioKey)
        //Add regions plugin to config
        const regionsPluginParams: RegionsPlugin.RegionsPluginParams = {
            dragSelection: false,
            regions: [],
            slop: 2, //mouse drag sensitivity
        }

        //Add RegionsPlugin
        let regionsPlugin = RegionsPlugin.create(regionsPluginParams)
        wavesurferItem.config.plugins.push(regionsPlugin)
        //Add TimelinePlugin
        let timelinePlugin = TimelinePlugin.create({
            container: '#wsTimelineContainer',
        })
        wavesurferItem.config.plugins.push(timelinePlugin)

        //Create the wavesurferElement
        wavesurferItem.wavesurfer = WaveSurfer.create(wavesurferItem.config)
        //Bind wavesurferEvent to their methods, see //#region Wavesurfer EventMethods
        //wavesurferItem.wavesurfer.on('interaction', this.wavesurferOnInteraction)
        wavesurferItem.wavesurfer.on('error', this.wavesurferOnError)
        wavesurferItem.wavesurfer.on('play', this.wavesurferOnPlay)
        wavesurferItem.wavesurfer.on('ready', this.wavesurferOnReady)
        wavesurferItem.wavesurfer.on('seek', this.wavesurferOnSeek)
        wavesurferItem.wavesurfer.on('waveform-ready', this.wavesurferOnWaveformReady)
        wavesurferItem.wavesurfer.on('region-play', this.wavesurferOnRegionPlay)
        wavesurferItem.wavesurfer.on('region-in', this.wavesurferOnRegionIn)
        wavesurferItem.wavesurfer.on('region-out', this.wavesurferOnRegionOut)

        //Load audio and waveform into the wavesurfer (async)
        this.loadWavesurfer(wavesurferItem)
            .then(
                (success) => {
                    this.isLoading = false
                    this.wavesurferItem = wavesurferItem
                },
                (reject) => {
                    ////////console.log('reject', reject);
                    this.isLoading = false
                }
            )
            .catch((error) => {
                ////////console.log('error', error);
                this.isLoading = false
                ////////console.log('7');
            })
    }

    async loadWavesurfer(wavesurferItem: PiaSurfer) {
        if (wavesurferItem.audioUrl != null && wavesurferItem.audioUrl != '' && wavesurferItem.audioWaveformData != null) {
            wavesurferItem.wavesurfer.load(wavesurferItem.audioUrl, wavesurferItem.audioWaveformData.data, true)
        } else {
            // this.audioUrlFirstLoadSuccess = false
            throw new Error('Audio of Waveform data niet beschikbaar')
        }
    }

    //#endregion

    //#region Wavesurfer EventMethods
    wavesurferOnReady = () => {
        ////console.log('wavesurferOnReady')
        this.subscribeToRegionInputSubjects()
        this.piaSurferOnReady.emit('ready')
    }

    wavesurferOnError = (error: string) => {
        ////console.log('Wavesurfer error:', error)
        // switch (error.toLowerCase()) {
        //     case 'error loading media element':
        //         if (this.audioUrlFirstLoadSuccess && this.audioUrlReloadAttempts <= 1000) {
        //             ////console.log('trying to reload the audio url attempt: ', this.audioUrlReloadAttempts)
        //             this.audioUrlReloadAttempts++
        //             this.loadWavesurfer(this.wavesurferItem)
        //         } else {
        //             if (this.audioUrlReloadAttempts > 3) {
        //                 ////console.log('To much reload attempts. File is probally not existing or accessible.')
        //             }
        //         }
        //         break
        // }
    }

    wavesurferOnWaveformReady = () => {
        ////////console.log('wavesurferOnWaveformReady');
    }

    // wavesurferOnInteraction = (event) => {
    //     ////console.log('interaction:', event)
    // }

    wavesurferOnPlay = (event) => {
        ////console.log('ws on play', event)
    }

    wavesurferOnRegionPlay = (event) => {
        ////console.log('ws region play', event)
    }
    wavesurferOnRegionIn = (event) => {
        ////console.log('ws region in', event)
    }

    wavesurferOnRegionOut = (event) => {
        ////console.log('ws region out', event)
    }

    wavesurferOnSeek = (progress: number) => {
        ////console.log('on seek')
        // this.piaSurferOnPlaybackCommandEventEmitter.emit({
        //     action: this.wavesurferItem.isPlaying ? PlaybackAction.PLAY : PlaybackAction.PAUSE,
        //     position: progress * this.wavesurferItem.wavesurfer.getDuration(),
        // })
        if (this.wavesurferItem.isPlaying) {
            this.piaMediaEventsService.sessionPlay.next({ source: this.piaMediaEventOrigin, sessionId: 'testy', position: progress * this.wavesurferItem.wavesurfer.getDuration() })
        } else {
            this.piaMediaEventsService.sessionPause.next({ source: this.piaMediaEventOrigin, sessionId: 'testy', position: progress * this.wavesurferItem.wavesurfer.getDuration() })
        }
    }
    //#endregion

    //#region Wavesurfer ControlMethods
    play(wavesurferItem: PiaSurfer, region?: Region) {
        if (region) {
            wavesurferItem.wavesurfer.regions.list[region.id].play()
        } else {
            wavesurferItem.wavesurfer.play()
        }

        wavesurferItem.isPlaying = true
        this.piaMediaEventsService.sessionIsPlaying.next({ source: this.piaMediaEventOrigin, sessionId: 'testy', playing: wavesurferItem.isPlaying })
        this.piaMediaEventsService.sessionPlay.next({ source: this.piaMediaEventOrigin, sessionId: 'testy', position: this.wavesurferItem.wavesurfer.getCurrentTime() })
        // this.piaSurferOnPlaybackCommandEventEmitter.emit({
        //     action: PlaybackAction.PLAY,
        //     position: this.wavesurferItem.wavesurfer.getCurrentTime(),
        // })
    }
    pause(wavesurferItem: PiaSurfer) {
        wavesurferItem.wavesurfer.pause()
        wavesurferItem.isPlaying = false
        // this.piaSurferOnPlaybackCommandEventEmitter.emit({
        //     action: PlaybackAction.PAUSE,
        //     position: this.wavesurferItem.wavesurfer.getCurrentTime(),
        // })
        this.piaMediaEventsService.sessionIsPlaying.next({ source: this.piaMediaEventOrigin, sessionId: 'testy', playing: wavesurferItem.isPlaying })
        this.piaMediaEventsService.sessionPause.next({ source: this.piaMediaEventOrigin, sessionId: 'testy', position: this.wavesurferItem.wavesurfer.getCurrentTime() })
    }
    stop(wavesurferItem: PiaSurfer) {
        wavesurferItem.wavesurfer.stop()
        wavesurferItem.isPlaying = false
        // this.piaSurferOnPlaybackCommandEventEmitter.emit({
        //     action: PlaybackAction.PAUSE,
        //     position: 0,
        // })
        this.piaMediaEventsService.sessionIsPlaying.next({ source: this.piaMediaEventOrigin, sessionId: 'testy', playing: wavesurferItem.isPlaying })
        this.piaMediaEventsService.sessionPlay.next({ source: this.piaMediaEventOrigin, sessionId: 'testy', position: this.wavesurferItem.wavesurfer.getCurrentTime() })
    }

    playbackCommandEmitter(action: PlaybackAction, position: number) {}

    public wavesurferZoomControlChange($event) {
        ////////console.log('zooming');
        this.isLoading = true
        ////////console.log(this.isLoading);
        this.wavesurferItem.wavesurfer.zoom($event.detail.value)
        ////console.log('zoom:', $event.detail.value)
        this.zoomlevel = $event.detail.value
        this.isLoading = false
    }
    //#endregion

    //#region Wavesurfer Region SubjectSubscription
    subscribeToRegionInputSubjects() {
        if (this.playbackCommandSubject != null) {
            this.subscriptions.push(
                this.playbackCommandSubject.subscribe((playbackcommand: PlaybackCommand) => {
                    ////console.log('received playbackCommandSubject from other component with value', playbackcommand)
                    //Todo: make sure that a region is played when we receive a command here
                    let region: Region = null
                    if (playbackcommand.languagelabel) {
                        region = this.wavesurferItem.wavesurfer.regions.list[playbackcommand.languagelabel.id]
                        ////console.log(this.wavesurferItem)
                        ////console.log('region to play:', region)
                    }

                    switch (playbackcommand.action) {
                        case PlaybackAction.PLAY:
                            this.play(this.wavesurferItem, region)
                            break
                        case PlaybackAction.PAUSE:
                            this.pause(this.wavesurferItem)
                            break
                        case PlaybackAction.STOP:
                            this.stop(this.wavesurferItem)
                            break
                    }
                })
            )
        }
        if (this.insertRegionSubject != null) {
            this.subscriptions.push(
                this.insertRegionSubject.subscribe((region: Region) => {
                    this.wavesurferItem.wavesurfer.clearRegions()
                    this.wavesurferItem.wavesurfer.addRegion(region)
                    let progress: number = 1 / (this.wavesurferItem.wavesurfer.getDuration() / region.start)
                    this.wavesurferItem.wavesurfer.seekAndCenter(progress)
                    this.zoomlevel = 125
                })
            )
        }
        if (this.updateRegionSubject != null) {
            this.subscriptions.push(
                this.updateRegionSubject.subscribe((region: Region) => {
                    // //////console.log(
                    //   'received insertRegionSubject from other component with value',
                    //   region
                    // );
                })
            )
        }
        if (this.deleteRegionSubject != null) {
            this.subscriptions.push(
                this.deleteRegionSubject.subscribe((id: string) => {
                    ////////console.log(
                    //   'received insertRegionSubject from other component with value',
                    //   id
                    // );
                })
            )
        }
        if (this.selectRegionSubject != null) {
            this.subscriptions.push(
                this.selectRegionSubject.subscribe((id: string) => {
                    ////////console.log(
                    //   'received insertRegionSubject from other component with value',
                    //   id
                    // );
                })
            )
        }
        if (this.deselectedRegionSubject != null) {
            this.subscriptions.push(
                this.deselectedRegionSubject.subscribe((id: string) => {
                    ////////console.log(
                    //   'received insertRegionSubject from other component with value',
                    //   id
                    // );
                })
            )
        }
    }

    //#region S3 Loading Methods
    //todo: replace s3PathPath with s3CompressedAudioPath
    async loadSessionWavesurferAudioUrl(audioKey: string) {
        //////console.log('Getting audioUrl for key,', audioKey);
        return await Storage.get(audioKey.replace('public/', ''), { expires: this.audioUrlExpirationTime })
    }

    //todo: replace s3PathPath with s3WaveformDataPath
    async loadSessionWavesurferWaveformData(audioKey: string): Promise<PiaSurferWaveform> {
        let waveformDataKey = audioKey.substring(0, audioKey.lastIndexOf('/')) + '/extracted.waveform.json'
        //////console.log('waveformKey', waveformDataKey);
        return await this.getS3FileAsJson(waveformDataKey)
    }

    async getS3FileAsJson(key: string) {
        //////console.log('getjson,', key);
        const downloadResult = await Storage.get(key.replace('public/', ''), {
            download: true,
        })
        const jsonTextResult = await (downloadResult.Body as Blob).text()
        const jsonData = JSON.parse(jsonTextResult)
        return jsonData
    }

    //#endregion
}
