<vg-player
  class="pia3-video-player"
  *ngIf="pia3VideoPlayer.video != null && _videoUrl != null"
  (mouseover)="onMouseOver($event)"
  (mouseenter)="onMouseEnter($event)"
  (mouseleave)="onMouseOut($event)"
  #VgPlayer
  (onPlayerReady)="onPlayerReady($event)"
>
  <!-- CONTROLBAR TOP -->
  <vg-controls
    class="topbar"
    [hidden]="!_videoplayerConfig.showControls"
    [vgAutohide]="_videoplayerConfig.autohideControls"
    [vgAutohideTime]="_videoplayerConfig.autohideControlsAfterSeconds"
  >
    <ion-toolbar>
      <ion-buttons class="left" slot="start">
        <ion-button
          class="btn_backToOverview"
          slot="start"
          [hidden]="!_videoplayerConfig.showBackButton"
          (click)="navigateBackToOverview()"
          ><ion-icon color="light" name="arrow-back"></ion-icon
        ></ion-button>
      </ion-buttons>
      <ion-title
        *ngIf="
          _videoplayerConfig.title != undefined &&
          _videoplayerConfig.title != null
        "
      >
        {{ _videoplayerConfig.title }}
      </ion-title>
      <ion-buttons class="right" slot="end">
        <!-- <ion-button slot="end" (click)="navigateBackToOverview()"
          ><ion-icon color="light" name="information-circle-outline"></ion-icon
        ></ion-button> -->
      </ion-buttons>
    </ion-toolbar>
  </vg-controls>

  <!-- OVERLAY PLAY-->
  <vg-overlay-play [hidden]="!_videoplayerConfig.showControls">
  </vg-overlay-play>

  <!-- BUFFERING-->
  <vg-buffering></vg-buffering>

  <!-- SCRUBBAR-->
  <vg-scrub-bar [hidden]="!_videoplayerConfig.showControls">
    <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
  </vg-scrub-bar>

  <!-- CONTROLBAR BOTTOM -->
  <vg-controls
    class="bottombar"
    [hidden]="!_videoplayerConfig.showControls"
    [vgAutohide]="_videoplayerConfig.autohideControls"
    [vgAutohideTime]="_videoplayerConfig.autohideControlsAfterSeconds"
  >
    <vg-play-pause></vg-play-pause>
    <ion-button slot="start" (click)="restartVideo()"
      ><ion-icon name="reload-sharp"></ion-icon
    ></ion-button>
    <vg-playback-button></vg-playback-button>

    <vg-scrub-bar style="pointer-events: none"></vg-scrub-bar>
    <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
    <!-- <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
    <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display> -->
    <!-- <ion-select
      mode="ios"
      interface="popover"
      placeholder="Select fruit"
      (ionChange)="trackChange($event)"
    >
      <ion-select-option value="nl">Nederlands</ion-select-option>
      <ion-select-option value="en">English</ion-select-option>
      <ion-select-option value="fy">Frysk</ion-select-option>
    </ion-select> -->

    <!-- <vg-track-selector></vg-track-selector> -->
    <vg-mute></vg-mute>
    <vg-volume></vg-volume>

    <vg-fullscreen></vg-fullscreen>
  </vg-controls>

  <!-- VIDEO -->
  <video
    [vgMedia]="$any(media)"
    #media
    id="singleVideo"
    preload="auto"
    [autoplay]="autoplayActive"
    crossorigin
  >
    <source [src]="_videoUrl" type="video/webm" />
    <!-- <source
      src="http://static.videogular.com/assets/videos/videogular.ogg"
      type="video/ogg"
    />
    <source
      src="http://static.videogular.com/assets/videos/videogular.webm"
      type="video/webm"
    /> -->

    <!-- <track
      kind="subtitles"
      label="English"
      src="http://static.videogular.com/assets/subs/pale-blue-dot.vtt"
      srclang="en"
      default
    />
    <track
      kind="subtitles"
      label="Español"
      src="http://static.videogular.com/assets/subs/pale-blue-dot-es.vtt"
      srclang="es"
    /> -->
  </video>
</vg-player>
