<!-- <ion-chip color="primary">{{displayWord}}({{displayFreqOfWord}})</ion-chip> -->
<angular-d3-cloud
  [animations]="true"
  [data]="data"
  [width]="width / 2"
  [height]="height"
  [padding]="5"
  font="Lato"
  [rotate]="0"
  [autoFill]="true"
  (wordClick)="onWordClickEvent($event)"
  (wordMouseOver)="wordMouseOverEvent($event)"
  (wordMouseOut)="wordMouseOutEvent($event)"
></angular-d3-cloud>
