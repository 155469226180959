import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core'
import { Storage } from 'aws-amplify'
import { S3Service } from 'src/app/.common/services/s3.service'
import { AutoTranscript } from 'src/app/API.service'
import { PiaTranscriptAutoServiceService } from 'src/app/.common/components/pia-transcripts/pia-transcript-auto/pia-transcript-auto-service.service'
import { DecimalToTimePipe } from 'src/app/.common/pipes/decimal-to-time.pipe'

@Component({
    selector: 'app-pia-transcript-auto',
    templateUrl: './pia-transcript-auto.component.html',
    styleUrls: ['./pia-transcript-auto.component.scss'],
})
export class PiaTranscriptAutoComponent implements OnInit {
    @Input() piaTranscriptAutoId: string = null

    public piaTranscriptAuto: AutoTranscript = null
    public piaTranscriptAutoIsInitialized: boolean = false
    public piaTranscriptAutoIsLoading: boolean = false
    public piaTranscriptAutoJSON: JSON = null
    public piaTranscriptAutoJSONIsInitialized: boolean = false
    public piaTranscriptAutoJSONIsLoading: boolean = false
    public piaTranscriptAutoSummaryIsLoading: boolean = false
    public piaTranscriptAutoSummaryJSON: JSON = null
    public piaTranscriptAutoSummaryJSONIsInitialized: boolean = false
    public piaTranscriptAutoSummaryJSONIsLoading: boolean = false

    constructor(private s3Service: S3Service, private piaTranscriptAutoService: PiaTranscriptAutoServiceService) {}

    ngOnInit() {}

    ngOnChanges(change: { prop: SimpleChange }) {
        if (change['piaTranscriptAutoId'] != null) {
            let value: SimpleChange = change['piaTranscriptAutoId']
            if (value.currentValue != null && value.currentValue != '' && value.currentValue != value.previousValue) {
                this.getTranscriptAuto(value.currentValue)
            }
        }
    }

    async getTranscriptAuto(id: string) {
        this.piaTranscriptAutoIsLoading = true
        let result = await this.piaTranscriptAutoService.getTranscriptAuto(id)
        if (result) {
            this.piaTranscriptAuto = result as AutoTranscript
            ////console.log(this.piaTranscriptAuto)
            this.piaTranscriptAutoIsLoading = false
            this.getTranscriptAutoJSON(this.piaTranscriptAuto.transcriptStandardizedS3Key)
            if (this.piaTranscriptAuto.summaryS3Key != undefined && this.piaTranscriptAuto.summaryS3Key != null && this.piaTranscriptAuto.summaryS3Key != '') {
                this.getTranscriptAutoSummaryJSON(this.piaTranscriptAuto.summaryS3Key)
            }
        }
    }

    async getTranscriptAutoJSON(key: string) {
        this.piaTranscriptAutoJSONIsLoading = true
        try {
            this.piaTranscriptAutoJSON = await this.s3Service.getS3FileAsJson(key)
            ////console.log(this.piaTranscriptAutoJSON)
            this.piaTranscriptAutoJSONIsLoading = false
        } catch (error) {
            this.piaTranscriptAutoJSONIsLoading = false
            alert('Error loading JSON for PiaTranscriptAuto')
            ////console.log('error getting transcriptAuto asjson:', error)
        }
    }

    async getTranscriptAutoSummaryJSON(key: string) {
        ////console.log('Getting TranscriptAutoSummaryJson')
        this.piaTranscriptAutoJSONIsLoading = true
        try {
            this.piaTranscriptAutoSummaryJSON = await this.s3Service.getS3FileAsJson(key)
            ////console.log('Got transcriptautosummary:', this.piaTranscriptAutoSummaryJSON)
            this.piaTranscriptAutoSummaryJSONIsLoading = false
        } catch (error) {
            this.piaTranscriptAutoSummaryJSONIsLoading = false
            alert('Error loading JSON for PiaTranscriptAutoSummary')
            ////console.log('error getting PiaTranscriptAutoSummary asjson:', error)
        }
    }

    phraseClicked(phrase: JSON) {
        ////console.log('a phrase was clicked:', phrase)
    }
}
