import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Language, Speaker, Contextchannel, CreateContextchannelInput, SpeechModel, Client } from './../../../API.service'
import { Pia3ContextchannelService } from './../pia3-contextchannel.service'
import { ErrorhandlingService } from '../../_common/errorhandling/errorhandling.service'
import { Router } from '@angular/router'

@Component({
    selector: 'pia3-create-context-channel',
    templateUrl: './pia3-create-context-channel.component.html',
    styleUrls: ['./pia3-create-context-channel.component.scss'],
})
export class Pia3CreateContextChannelComponent implements OnInit {
    @Input('clientID') clientID: string = null
    // creat a context channel form
    name!: string
    description!: string
    imagePath!: string
    languages!: Language[]
    speakers!: Speaker[]
    client!: Client
    speechmodel!: SpeechModel
    public hasError: boolean = false

    //
    contextChannelData = {
        clientID: null,
        client: null,
        name: null,
        description: null,
        imagePath: null,
        languages: null,
        speakers: null,
        speechmodel: null,
    }

    constructor(private pia3ContextchannelService: Pia3ContextchannelService, private pia3ErrorhandlerService: ErrorhandlingService, private router: Router) {}

    ngOnInit() {}

    clientSelected($event) {
        this.clientID = $event.id
        this.client = $event
    }

    languageSelected(languages) {
        if ((languages !== null || languages !== undefined) && languages.length > 0) {
            this.languages = languages
            alert('languageSelected')
        }
    }

    speakerSelected(speakers) {
        if ((speakers !== null || speakers !== undefined) && speakers.length > 0) {
            this.speakers = speakers
            alert('speakerSelected')
        }
    }

    speechmodelSelected(speechmodel) {
        if (speechmodel !== null || speechmodel !== undefined) {
            this.speechmodel = speechmodel
            alert('speechmodelSelected')
        }
    }

    imageSelected(imagePath) {
        this.imagePath = imagePath
    }

    async submit() {
        this.contextChannelData.name = this.name
        this.contextChannelData.description = this.description
        this.contextChannelData.languages = this.languages
        this.contextChannelData.speakers = this.speakers
        this.contextChannelData.client = this.client
        this.contextChannelData.clientID = this.clientID
        this.contextChannelData.speechmodel = this.speechmodel
        //////console.log(this.contextChannelData)

        if (
            (this.clientID != null || this.clientID != undefined) &&
            this.name != null &&
            this.name != undefined &&
            this.speechmodel != null &&
            this.speechmodel != undefined &&
            this.languages != null &&
            this.languages != undefined &&
            this.languages.length > 0 &&
            this.speakers != null &&
            this.speakers != undefined &&
            this.speakers.length > 0
        ) {
            this.hasError = false
            await this.createNewContextChannel(this.contextChannelData)
        } else {
            this.hasError = true
        }
    }

    async createNewContextChannel(contextChannelData) {
        //save context channel
        let createContextchannelInput: CreateContextchannelInput = {
            clientID: contextChannelData.client.id,
            name: contextChannelData.name,
            //description: contextChannelData.description, //todo: add description to context channel type on backend
            speechmodelID: contextChannelData.speechmodel.id,
        }
        try {
            let createContextchannelResult = await this.pia3ContextchannelService.createContextchannel(createContextchannelInput)

            //save contextchannel languages
            if (this.languages != null && this.languages != undefined && this.languages.length > 0) {
                for (let index = 0; index < this.languages.length; index++) {
                    const language = this.languages[index]
                    let createContextchannelLanguages = await this.pia3ContextchannelService.createContextchannelLanguages({ contextchannelID: createContextchannelResult.id, languageID: language.id })
                }
            }
            //save contextchannel speakers
            if (this.speakers != null && this.speakers != undefined && this.speakers.length > 0) {
                for (let index = 0; index < this.speakers.length; index++) {
                    const speaker = this.speakers[index]
                    let createContextchannelSpeakers = await this.pia3ContextchannelService.createContextchannelSpeakers({ contextchannelID: createContextchannelResult.id, speakerID: speaker.id })
                }
            }

            //redirect to newly created context channel
            this.router.navigate(['/contextchannels', createContextchannelResult.id])

            //done without errors
        } catch (error) {
            //////console.log(error)
            this.pia3ErrorhandlerService.handleGenericError(error)
        }
    }
}
