import { Component, Input, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Pia3SpeakerService } from '../pia3-speaker.service'
import { Speaker } from './../../../API.service'

@Component({
    selector: 'pia3-speaker-avatar',
    templateUrl: './pia3-speaker-avatar.component.html',
    styleUrls: ['./pia3-speaker-avatar.component.scss'],
})
export class Pia3SpeakerAvatarComponent implements OnInit {
    @Input('label') public set label(label: string) {
        this._label = label
    }
    public _label: string = null
    @Input('speaker') public set speaker(speaker: Speaker) {
        this._speaker = speaker
    }
    public _speaker: Speaker = null
    private _speaker$: BehaviorSubject<Speaker> = new BehaviorSubject<Speaker>(null)

    constructor(private pia3SpeakerService: Pia3SpeakerService) {}

    ngOnInit() {}

    getFullname(speaker: Speaker) {
        return this.pia3SpeakerService.getFullname(speaker)
    }

    getShortname(speaker: Speaker) {
        return this.pia3SpeakerService.getShortName(speaker)
    }

    getUndefinedSpeakerName() {
        if (this._label != null) {
            return this._label
        } else {
        }
    }
}
