<ion-list *ngIf="_speakerCompounedLanguagelabels.length > 0">
  <ion-item
    *ngFor="let compound of _speakerCompounedLanguagelabels; let odd = odd"
  >
    <!-- Speaker-Avatar -->
    <pia3-speaker-avatar
      [slot]="odd ? 'start' : 'start'"
      [speaker]="compound.speaker"
      [label]="compound.speakerID"
    ></pia3-speaker-avatar>
    <!-- labels-->
    <div
      *ngIf="compound.languagelabelsWithWords.length > 0"
      [ngClass]="odd ? 'ion-text-left' : 'ion-text-left'"
    >
      <span *ngFor="let label of compound.languagelabelsWithWords">
        <span
          (click)="wordClicked(word, label)"
          *ngFor="let word of label.words"
        >
          {{ word["word"] }}
        </span>
      </span>
    </div>
  </ion-item>
</ion-list>
<ion-infinite-scroll (ionInfinite)="onIonInfinite($event)">
  <ion-infinite-scroll-content
    loadingText="Laden..."
    loadingSpinner="crescent"
  ></ion-infinite-scroll-content>
</ion-infinite-scroll>
