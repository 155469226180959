import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { sectionHeaderContent } from 'aws-amplify';
import { BreakingChangeType } from 'graphql';

@Pipe({
  name: 'decimalToTime',
})
@Injectable({
  providedIn: 'root',
})
export class DecimalToTimePipe implements PipeTransform {
  public transform(value: number, ...args: unknown[]): unknown {
    let d = Number(value);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h < 10 ? '0' + h : '' + h;
    var mDisplay = m < 10 ? '0' + m : '' + m;
    var sDisplay = s < 10 ? '0' + s : '' + s;
    return hDisplay + ':' + mDisplay + ':' + sDisplay;
  }
}
