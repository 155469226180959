import { Pia3WordTaggerService } from './../pia3-word-tagger.service'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { ICategoryWithId, IWords, IWordTagger } from '../pia3-word-tagger-interfaces'
import { WordTaggerModuleOutput } from './../../../API.service'

@Component({
    selector: 'pia3-word-tagger',
    templateUrl: './pia3-word-tagger.component.html',
    styleUrls: ['./pia3-word-tagger.component.scss'],
})
export class Pia3WordTaggerComponent implements OnChanges {
    @Input('text') text: string = ''
    @Input('categories') categories: string[] = []
    @Output('taggedWords') taggedWords = new EventEmitter<IWordTagger[]>()
    categoriesWithId: ICategoryWithId[] = []
    wordsArray: IWords[] = []
    categoryNameOfWord: string = ''
    buttonfillSolid: boolean = false
    selectedWords: string[] = []

    constructor(private pia3WordTaggerService: Pia3WordTaggerService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.text || changes.categories) {
            this.pushWordsWithWhiteSpacesToArray(this.text)
            this.addIdToCategories(this.categories)
        }
        // this.pia3WordTaggerService
        //   .getWordTaggerModuleOutput('c1c85f8f-c259-400c-878d-1bf16c17cea1')
        //   .then((result) => {
        //     let res = this.pia3WordTaggerService.showWordTaggerModuleOutput(
        //       result.data.getWordTaggerModuleOutput as WordTaggerModuleOutput
        //     );
        //     this.wordsArray = res[0] as IWords[];
        //     this.categoriesWithId = res[1] as ICategoryWithId[];
        //   });
    }

    async ngOnInit() {
        if (this.text !== null || this.text !== '' || this.text !== undefined) {
            this.pushWordsWithWhiteSpacesToArray(this.text)
            this.addIdToCategories(this.categories)
        }

        // this.pia3WordTaggerService
        //   .getWordTaggerModuleOutput('c1c85f8f-c259-400c-878d-1bf16c17cea1')
        //   .then((result) => {
        //     //
        //     let res = this.pia3WordTaggerService.showWordTaggerModuleOutput(
        //       result.data.getWordTaggerModuleOutput as WordTaggerModuleOutput
        //     );
        //     //////console.log('RES[0] ===', res[0]);
        //     //////console.log('RES[1] ===', res[1]);
        //     this.wordsArray = res[0] as IWords[];
        //     this.categoriesWithId = res[1] as ICategoryWithId[];
        //   })
        //   .then(() => {
        //     this.wordsArray.map((item) => {
        //       if (item.isWordSelected) {
        //         let selectedItem = {
        //           id: item.id,
        //           word: item.word.trim(),
        //         };
        //         // this.markSelectedWords(selectedItem);BURADA KAAAAAAAAAAAAAAAAAAAAAAAALDIN
        //       }
        //     });

        //     // console.warn('Words=====', words);

        //     // this.markSelectedWords(words);
        //   });
    }

    /**
     * take a parameter as ICategoryWithId object from clicked button and change style that selected button.
     * @param catObj
     */
    selectedCategory(catObj: ICategoryWithId) {
        this.categoriesWithId.forEach((category) => {
            if (catObj.id === category.id) {
                category.buttonFillSolid = true
                this.categoryNameOfWord = catObj.category
            } else {
                category.buttonFillSolid = false
            }
        })
    }

    /**
     * Emmits selected/processed words back
     */
    emitTheWords() {
        //if any word selected do emmit otherwise give alert
        let isEmmit: boolean = false
        this.wordsArray.map((obj) => {
            if (obj.isWordSelected && obj.categoryName.substring(2) !== '') {
                isEmmit = true
            }
        })

        if (isEmmit) {
            //remove punctuation and white spaces
            const processedData: IWordTagger[] = this.wordsArray
                .filter((item) => {
                    const regex = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\s]/g // regular expression to match all punctuation marks and white spaces
                    if (!regex.test(item.word) && item.word !== '') {
                        return item
                    } else {
                        // //////console.log('Regex:', item.word);
                    }
                })
                .map((item) => {
                    if (item.isWordSelected) {
                        return {
                            text: item.word,
                            tag: item.categoryName,
                        }
                    } else {
                        return {
                            text: item.word,
                            tag: '',
                        }
                    }
                })

            this.taggedWords.emit(processedData)
        } else {
            alert('No any selected word with category')
        }
    }

    /**
     *
     * @param categories
     */
    addIdToCategories(categories: string[]) {
        this.categoriesWithId = []
        let lengthOfCategories = categories.length

        for (let i = 0; i < lengthOfCategories; i++) {
            let id = i + 1
            let category = categories[i]
            let buttonFillSolid = false
            if (category !== '' && category !== undefined) {
                this.categoriesWithId.push({ id, category, buttonFillSolid })
            }
        }
    }

    /**
     * when mouse up, It detect all selected words and it's clean all punctuations and white spaces. Then to use these words assign an array.
     */
    detectSelection() {
        //detect selected words and Ids
        let selectedIds: string[] = []
        let selectedWords: string[] = []
        const selection = window.getSelection()

        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0)
            const parentElement = range.commonAncestorContainer.parentNode

            let ionCard: Element | null = null
            let currentNode: Node | null = parentElement

            while (currentNode) {
                if (currentNode instanceof Element && currentNode.tagName.toLowerCase() === 'ion-card') {
                    ionCard = currentNode
                    break
                }

                currentNode = currentNode.parentNode
            }

            if (ionCard) {
                const selectedNodes = ionCard.querySelectorAll('mark span')
                selectedIds = []
                selectedWords = []

                for (let i = 0; i < selectedNodes.length; i++) {
                    const node = selectedNodes[i]

                    if (range.intersectsNode(node)) {
                        selectedIds.push(node.getAttribute('id'))
                        selectedWords.push(node.textContent)
                    }
                }
            }
        }

        //merge all picked ids and words in one array
        const mergedArray = selectedWords.map((text, index) => {
            const id = selectedIds[index]
            return { id: id, word: text }
        })

        //remove punctuation and white spaces
        const removedPunctuation: { id: number; word: string }[] = mergedArray
            .filter((item) => {
                const regex = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\s]/g // regular expression to match all punctuation marks and white spaces
                if (item.word.trim() !== '' && !regex.test(item.word.trim())) {
                    return regex.test(item.word)
                }
            })
            .map((item) => {
                return {
                    id: parseInt(item.id.trim()),
                    word: item.word.trim(),
                }
            })

        //Call markSelectedWords() method  that to mark selected words
        this.markSelectedWords(removedPunctuation)
    }

    /**
     * It makes marking selected words and change needed values of wordsArray
     * @param wordArrayOfSelection
     */
    markSelectedWords(wordArrayOfSelection: { id: number; word: string }[]) {
        let firstSelectedWordId: number = undefined //to asign belongsToWhichGroup value below

        if (wordArrayOfSelection.length === 1) {
            //here is only for one selected word
            wordArrayOfSelection.map((selectedItem) => {
                this.wordsArray.map((existingItem) => {
                    if (existingItem.id === selectedItem.id && !existingItem.isWordSelected) {
                        //update isHiddenCloseIcon property value to false. So Close icons of words can able to shown.
                        this.wordsArray = this.updatePropertyValueById(
                            this.wordsArray,
                            selectedItem.id, //id from selected item ids of selected words are same with wordsArray
                            'isHiddenCloseIcon',
                            false
                        )

                        if (selectedItem.id === existingItem.id) {
                            //make true isCatagoryNameActiveOnSelectedWord value
                            existingItem.isCatagoryNameActiveOnSelectedWord = true
                            //make true value
                            existingItem.isWordSelected = true

                            //style word when select only one word
                            const markElement = document.getElementById(existingItem.id + 'dv')
                            markElement.style.backgroundColor = '#ffe184'
                            markElement.style.paddingTop = '0.55em'
                            markElement.style.paddingRight = '0.35em'
                            markElement.style.paddingBottom = '0.55em'
                            markElement.style.paddingLeft = '0.35em'
                            markElement.style.margin = '1px'
                            markElement.style.borderRadius = '1px'
                        }

                        //assign group of word for single selected word and category name of word
                        if (existingItem.id === selectedItem.id) {
                            existingItem.belongsToWhichGroup = existingItem.id //group id
                            existingItem.categoryName = 'B-' + this.categoryNameOfWord //category name
                        }
                    }
                })
            })
        } else {
            //here is for multi selected words
            wordArrayOfSelection.map((selectedItem) => {
                this.wordsArray.map((existingItem) => {
                    if (selectedItem.id === existingItem.id && existingItem.isWordSelected === false) {
                        const markElement = document.getElementById(existingItem.id + 'dv')

                        //make true value
                        existingItem.isWordSelected = true

                        if (wordArrayOfSelection.indexOf(selectedItem) === 0) {
                            //update isHiddenCloseIcon property value to false. So Close icon of word can able to shown.
                            this.wordsArray = this.updatePropertyValueById(this.wordsArray, selectedItem.id, 'isHiddenCloseIcon', false)

                            //
                            firstSelectedWordId = selectedItem.id //get first selected word id and asign to firstSelectedWordId. to asign also other members of selected words
                            existingItem.belongsToWhichGroup = firstSelectedWordId //asign group to first selected word
                            existingItem.categoryName = 'B-' + this.categoryNameOfWord //category name

                            //first word style
                            markElement.style.backgroundColor = '#ffe184'
                            markElement.style.paddingTop = '0.55em'
                            markElement.style.paddingRight = '0.35em'
                            markElement.style.paddingBottom = '0.55em'
                            markElement.style.paddingLeft = '0.35em'
                            markElement.style.marginTop = '1px'
                            markElement.style.marginBottom = '1px'
                            markElement.style.marginLeft = '1px'
                            markElement.style.marginRight = '0px'
                            markElement.style.borderRadius = '1px'
                        } else if (wordArrayOfSelection.lastIndexOf(selectedItem) === wordArrayOfSelection.length - 1) {
                            //last word style
                            this.wordsArray.find((item) => {
                                if (item.id === selectedItem.id) {
                                    item.isCatagoryNameActiveOnSelectedWord = true
                                }
                            })

                            //asign group to last selected word
                            existingItem.belongsToWhichGroup = firstSelectedWordId
                            existingItem.categoryName = 'I-' + this.categoryNameOfWord // assign category to word

                            markElement.style.backgroundColor = '#ffe184'
                            markElement.style.paddingTop = '0.55em'
                            markElement.style.paddingRight = '0.35em'
                            markElement.style.paddingBottom = '0.55em'
                            markElement.style.paddingLeft = '0.35em'
                            markElement.style.marginTop = '1px'
                            markElement.style.marginBottom = '1px'
                            markElement.style.marginLeft = '0px'
                            markElement.style.marginRight = '1px'
                            markElement.style.borderRadius = '1px'
                        } else {
                            //asign group to middle selected words
                            existingItem.belongsToWhichGroup = firstSelectedWordId
                            existingItem.categoryName = 'I-' + this.categoryNameOfWord //category name

                            //other words style
                            markElement.style.backgroundColor = '#ffe184'
                            markElement.style.paddingTop = '0.55em'
                            markElement.style.paddingRight = '0.35em'
                            markElement.style.paddingBottom = '0.55em'
                            markElement.style.paddingLeft = '0.35em'
                            markElement.style.marginLeft = '0px' //
                            markElement.style.marginRight = '0px' //
                            markElement.style.marginTop = '1px'
                            markElement.style.marginBottom = '1px'
                            markElement.style.borderRadius = '1px'
                        }
                    }
                })
            })
        }
    }

    /**
     * it changes value of property by given array,id and property name according to given value. Then return chenged array
     * @param array
     * @param id
     * @param property
     * @param value
     * @returns
     */
    updatePropertyValueById(array: any[], id: number, property: string, value: any): any[] {
        return array.map((obj) => {
            if (obj.id === id) {
                obj[property] = value
            }
            return obj
        })
    }

    /**
     *
     */
    removeStyle(item) {
        this.wordsArray.map((obj) => {
            //remove multiple words style
            if (obj.belongsToWhichGroup === item.id) {
                obj.isWordSelected = false
                obj.isHiddenCloseIcon = true
                obj.isCatagoryNameActiveOnSelectedWord = false
                obj.belongsToWhichGroup = obj.id

                const markElement = document.getElementById(obj.id + 'dv')

                markElement.style.backgroundColor = '#ffffff'
                markElement.style.paddingTop = '0em'
                markElement.style.paddingRight = '0em'
                markElement.style.paddingBottom = '0em'
                markElement.style.paddingLeft = '0em'
                markElement.style.marginTop = '0px'
                markElement.style.marginBottom = '0px'
                markElement.style.marginLeft = '0px'
                markElement.style.marginRight = '0px'
                markElement.style.borderRadius = '0px'
            } else {
                //remove single word style
                item.isWordSelected = false
                item.isHiddenCloseIcon = true
                item.isCatagoryNameActiveOnSelectedWord = false
                item.belongsToWhichGroup = item.id

                const markElement = document.getElementById(item.id + 'dv')

                markElement.style.backgroundColor = '#ffffff'
                markElement.style.paddingTop = '0em'
                markElement.style.paddingRight = '0em'
                markElement.style.paddingBottom = '0em'
                markElement.style.paddingLeft = '0em'
                markElement.style.marginTop = '0px'
                markElement.style.marginBottom = '0px'
                markElement.style.marginLeft = '0px'
                markElement.style.marginRight = '0px'
                markElement.style.borderRadius = '0px'
            }
        })
    }

    /**
     * It assign all words, punctuations and white spaces of given text with new property names to an array after splitting. Then copy to wordsArray.
     * @param text
     */
    pushWordsWithWhiteSpacesToArray(text: string) {
        const words = text.split(/\b/)
        const wordsWithId = words.map((word, index) => ({
            id: index + 1,
            word: word,
            isWordSelected: false,
            hasCloseIcon: false,
            isHiddenCloseIcon: true,
            isCatagoryNameActiveOnSelectedWord: false,
            belongsToWhichGroup: undefined,
            categoryName: '',
        }))
        this.wordsArray = []
        this.wordsArray.push(...wordsWithId)
        this.checkPunctuation(this.wordsArray) //check punctuations
    }

    /**
     * It check punctuations and if have changes value of hasCloseIcon in wordsArray
     * @param array
     */
    checkPunctuation(array: any) {
        // const regex = /[.,;:!?]/g; // regular expression to match punctuation
        // const regex = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/g; //// regular expression to match all punctuation marks
        const regex = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\s]/g // regular expression to match all punctuation marks and white spaces
        array.map((item) => {
            const hasPunctuation = regex.test(item.word)
            if (hasPunctuation) {
                // do something when there's punctuation
            } else {
                // do something when there's no punctuation
                item.hasCloseIcon = true
            }
        })
    }

    //NOT IN USE
    // pushWordsWithoutWhiteSpacesToArray(text: string) {
    //   const words = text.split(/(\s+|\W+)/);
    //   const wordsWithoutEmptyStrings = words.filter((word) => word.trim() !== '');
    //   const wordsWithId = wordsWithoutEmptyStrings.map((word, index) => ({
    //     id: index + 1,
    //     word,
    //     isWordSelected:false,
    //     hasCloseIcon: false,
    //     isHiddenCloseIcon: true,
    //     isCatagoryNameActiveOnSelectedWord:false,
    //     belongsToWhichGroup: undefined,
    //   }));
    //   this.wordsArray.push(...wordsWithId);
    //   this.checkPunctuation();
    // }
}
