import { Injectable } from '@angular/core'
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import * as queries from './../../graphql/queries'
import * as mutations from './../../graphql/mutations'
import { GetLanguageLabelQuery, LanguageLabelsByGoldTranscriptByStarttimeQuery, LanguageLabelsBySubmittedUserBySubmittedAtQuery, ModelSortDirection, UpdateLanguageLabelInput, UpdateLanguageLabelMutation, ValidationStatus } from './../../API.service'
import { ErrorhandlingService } from '../_common/errorhandling/errorhandling.service'

@Injectable({
    providedIn: 'root',
})
export class Pia3LanguagelabelService {
    constructor(private genericErrorHandlerService: ErrorhandlingService) {}

    async listLanguageLabelsByGoldTranscriptIdByStarttime(goldtranscriptid: string, limit: number = 999, nextToken: string = null): Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }> {
        return API.graphql(
            graphqlOperation(queries.languageLabelsByGoldTranscriptByStarttime, {
                goldtranscriptID: goldtranscriptid,
                limit: limit,
                nextToken: nextToken,
            })
        ) as Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }>
    }

    async listAllLanguageLabelsByGoldTranscriptIdByStarttime(goldtranscriptid: string): Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }> {
        return API.graphql(
            graphqlOperation(queries.languageLabelsByGoldTranscriptByStarttime, {
                goldtranscriptID: goldtranscriptid,
                limit: 999,
            })
        ) as Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }>
    }

    async get(id: string): Promise<{ data: GetLanguageLabelQuery }> {
        return API.graphql(graphqlOperation(queries.getLanguageLabel, { id: id })) as Promise<{ data: GetLanguageLabelQuery }>
    }

    async updateContent(id: string, content: string, _version: number): Promise<{ data: UpdateLanguageLabelMutation }> {
        let input: UpdateLanguageLabelInput = {
            id: id,
            content: content,
            _version: _version,
        }
        //console.log(input)
        return API.graphql(graphqlOperation(mutations.updateLanguageLabel, { input: input })) as Promise<{ data: UpdateLanguageLabelMutation }>
    }

    async updateSpeaker(id: string, speakerID: string, _version: number): Promise<{ data: UpdateLanguageLabelMutation }> {
        let input: UpdateLanguageLabelInput = {
            id: id,
            speakerID: speakerID,
            _version: _version,
        }
        return API.graphql(graphqlOperation(mutations.updateLanguageLabel, { input: input })) as Promise<{ data: UpdateLanguageLabelMutation }>
    }

    async updateValidationStatus(id: string, validationStatus: ValidationStatus, _version: number): Promise<{ data: UpdateLanguageLabelMutation }> {
        let input: UpdateLanguageLabelInput = {
            id: id,
            validationStatus: validationStatus,
            _version: _version,
        }
        return API.graphql(graphqlOperation(mutations.updateLanguageLabel, { input: input })) as Promise<{ data: UpdateLanguageLabelMutation }>
    }

    async getLanguageLabelsBySubmittedUserBySubmittedAt(userID: string, startDate?: string, endDate?: string, sortDirection?: ModelSortDirection, limit?: number, nexttoken?: string): Promise<{ data: LanguageLabelsBySubmittedUserBySubmittedAtQuery }> {
        return API.graphql(
            graphqlOperation(queries.languageLabelsBySubmittedUserBySubmittedAt, {
                submitteduserID: userID,
                // submittedAt: {
                //   between: [startDate, endDate],
                // },
                sortDirection: sortDirection,
                limit: limit != null ? limit : 100,
                nextToken: nexttoken,
            })
        ) as Promise<{ data: LanguageLabelsBySubmittedUserBySubmittedAtQuery }>
    }
}
