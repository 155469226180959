import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'
import { SpeechModel } from './../../../API.service'

@Component({
    selector: 'pia3-speechmodel-selector-single',
    templateUrl: './pia3-speechmodel-selector-single.component.html',
    styleUrls: ['./pia3-speechmodel-selector-single.component.scss'],
})
export class Pia3SpeechmodelSelectorSingleComponent implements OnInit {
    @Input('clientID') clientID!: string
    selectorMode: string = SelectorMode.SINGLE
    @Output('speechmodelSelected') speechmodelSelected = new EventEmitter<SpeechModel>()

    constructor() {}

    ngOnInit() {}

    _speechmodelSelected($event) {
        this.speechmodelSelected.emit($event)
    }
}
