<ion-grid>
  <ion-row>
    <ion-col size="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
      <ion-list>
        <ion-item
          (click)="sessionClicked(session)"
          *ngFor="let session of sessions"
        >
          {{ session.name }}
        </ion-item>
      </ion-list>
      <!-- <pia3-session-card
        [session]="session"
        [sessionCardConfig]="null"
        (cardClickEvent)="ionSessionCardClicked($event)"
      ></pia3-session-card> -->
      <!-- TODO: [PIA3-203] PIA3-SESSION-LIST-ITEM HERE -->
    </ion-col>
  </ion-row>
</ion-grid>
