import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IonicModule } from '@ionic/angular'

import { CurrentUserFabComponent } from './current-user-fab/current-user-fab.component'
import { Pia3Module } from 'pia3'
//import { CurrentUserFabPopoverComponent } from './current-user-fab-popover/current-user-fab-popover.component';

@NgModule({
    declarations: [CurrentUserFabComponent],
    imports: [CommonModule, IonicModule, Pia3Module],
    exports: [
        CurrentUserFabComponent,
        //CurrentUserFabPopoverComponent
    ],
})
export class ComponentsModule {}
