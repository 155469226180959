import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'

import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import * as queries from 'src/graphql/queries'
import * as mutations from 'src/graphql/mutations'
//import * as mutations from 'src/graphql/mutations';
//import * as subscriptions from 'src/graphql/subscriptions';
import { ListLanguageLabelsQuery, LanguageLabelsByGoldTranscriptByStarttimeQuery, LanguageLabel, GetLanguageLabelQuery, UpdateLanguageLabelMutation, UpdateLanguageLabelInput, ValidationStatus } from './../../API.service'
import { RouterParamsService } from './../services/router-params.service'
import { GenericErrorHandlerService } from './../services/generic-error-handler.service'
import { Language } from 'src/models'

@Injectable({
    providedIn: 'root',
})
export class LanguageLabelService {
    constructor(private routerParamsService: RouterParamsService, private genericErrorHandlerService: GenericErrorHandlerService) {}

    async listLanguageLabelsByGoldTranscriptIdByStarttime(goldtranscriptid: string, limit: number = 999, nextToken: string = null): Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }> {
        return API.graphql(
            graphqlOperation(queries.languageLabelsByGoldTranscriptByStarttime, {
                goldtranscriptID: goldtranscriptid,
                limit: limit,
                nextToken: nextToken,
            })
        ) as Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }>
    }

    async listAllLanguageLabelsByGoldTranscriptIdByStarttime(goldtranscriptid: string): Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }> {
        return API.graphql(
            graphqlOperation(queries.languageLabelsByGoldTranscriptByStarttime, {
                goldtranscriptID: goldtranscriptid,
                limit: 999,
            })
        ) as Promise<{ data: LanguageLabelsByGoldTranscriptByStarttimeQuery }>
    }

    async get(id: string): Promise<{ data: GetLanguageLabelQuery }> {
        return API.graphql(graphqlOperation(queries.getLanguageLabel, { id: id })) as Promise<{ data: GetLanguageLabelQuery }>
    }

    async updateContent(id: string, content: string, submmitedByID: string, _version: number): Promise<{ data: UpdateLanguageLabelMutation }> {
        let input: UpdateLanguageLabelInput = {
            id: id,
            content: content,
            submitteduserID: submmitedByID,
            _version: _version,
        }
        //console.log(input)
        return API.graphql(graphqlOperation(mutations.updateLanguageLabel, { input: input })) as Promise<{ data: UpdateLanguageLabelMutation }>
    }

    async updateSpeaker(id: string, speakerID: string, submittedByID: string, _version: number): Promise<{ data: UpdateLanguageLabelMutation }> {
        let input: UpdateLanguageLabelInput = {
            id: id,
            speakerID: speakerID,
            submitteduserID: submittedByID,
            _version: _version,
        }
        return API.graphql(graphqlOperation(mutations.updateLanguageLabel, { input: input })) as Promise<{ data: UpdateLanguageLabelMutation }>
    }

    async updateValidationStatus(id: string, validationStatus: ValidationStatus, submittedByID: string, _version: number): Promise<{ data: UpdateLanguageLabelMutation }> {
        let input: UpdateLanguageLabelInput = {
            id: id,
            validationStatus: validationStatus,
            _version: _version,
        }
        switch (validationStatus) {
            case ValidationStatus.Validated:
                input.validateduserID = submittedByID
                break
            case ValidationStatus.Rejected:
                input.validateduserID = submittedByID
                break
            default:
                input.submitteduserID = submittedByID
                break
        }

        return API.graphql(graphqlOperation(mutations.updateLanguageLabel, { input: input })) as Promise<{ data: UpdateLanguageLabelMutation }>
    }
}
