import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'pia3-component',
    templateUrl: './pia3.component.html',
    styleUrls: ['./pia3.component.scss'],
})
export class Pia3Component implements OnInit {
    constructor() {}

    ngOnInit() {}
}
