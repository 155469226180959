import { Pipe, PipeTransform } from '@angular/core';
import { withLatestFrom } from 'rxjs/operators';

@Pipe({
  name: 'iso8601time'
})
export class Iso8601timePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(this.isISO8601timetype){
      let seconds = this.getTotalSecondsFromISO8601Time(value)
      return seconds;
    }else{
      return "error not an ISO8601TimeString"
    }
  }

  getTotalSecondsFromISO8601Time(value:string)  
  {
    let parts = value.split(':')
    return (+parts[0]*3600)+(+parts[1]*60)+(+parts[2])
  }

  isISO8601timetype(value:string){
    let doubledotCount:number = 0;
    let doubledotIndex:number = 0;
    while (value.indexOf(':',doubledotIndex)) {
      doubledotCount = doubledotCount + 1;
      doubledotIndex = value.indexOf(':',doubledotIndex);
    }
    let singledotCount:number = 0;
    let singledotIndex:number = 0;
    while (value.indexOf('.',singledotIndex)) {
      singledotCount = singledotCount + 1;
      singledotIndex = value.indexOf('.',singledotIndex);
    }
    console.log('doubledots:'+doubledotCount+' singledots:'+singledotCount)
    return (doubledotCount == 3 && singledotCount == 1)
  }

}
