import { Injectable } from '@angular/core';
import { Event, RouterEvent, NavigationEnd, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { GraphQLResult } from '@aws-amplify/api';

@Injectable({
  providedIn: 'root',
})
export class GenericErrorHandlerService {
  public toasts: Array<any>;
  public toast: any = null;

  constructor(public toastController: ToastController, public router: Router) {
    this.subscribeToRouterEvents();
  }

  handleGenericError = (error) => {
    //Undefined
    if (error == undefined || error == null || error == '' || error == '') {
      this.presentErrorToast(
        'Undefined error, see javascript console for more information.'
      );
      return;
    } else {
      this.presentErrorToast(error);
    }
  };

  handleGenericGraphQLError = (error: GraphQLResult) => {
    console.error(error);
    error.errors.forEach((err) => {
      this.presentErrorToast(err.message);
    });
  };

  async presentErrorToast(message) {
    if (this.toast) {
      this.toast.dismiss();
    }

    this.toast = await this.toastController.create({
      message: message,
      color: 'danger',
      position: 'top',
      keyboardClose: true,
      //duration: 3000,
      mode: 'md',
      buttons: [
        {
          text: '',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    this.toast.present();
  }

  subscribeToRouterEvents() {
    this.router.events
      .pipe(filter((e: Event): e is RouterEvent => e instanceof NavigationEnd))
      .subscribe((e: RouterEvent) => {
        // if (this.toast != null) {
        //   try {
        //     this.toastController.dismiss();
        //   } catch (error) {
        //     console.error('Error while trying to close toast-overlay');
        //   }
        // }
      });
  }
}
