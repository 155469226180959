import { Pia3UserPerformanceChartTranscriptionDataminutesService } from './pia3-user-performance-chart-transcription-dataminutes.service'
import { User } from './../../../API.service'
import { Component, Input, OnInit } from '@angular/core'
import 'anychart'
import { Pia3ThemeColorValue, Pia3ThemeService } from '../../theme/pia3-theme.service'

export interface Pia3UserPerformanceChartTranscriptionDataminutesInterface {
    user: User
    labels: {
        validated: {
            labels: Array<any>[]
            validatedMinutes: number
        }
        validationRequested: {
            labels: Array<any>[]
            validationRequestedMinutes: number
        }
        rejected: {
            labels: Array<any>[]
            rejectedMinutes: number
        }
    }
}

@Component({
    selector: 'pia3-user-performance-chart-transcription-dataminutes',
    templateUrl: './pia3-user-performance-chart-transcription-dataminutes.component.html',
    styleUrls: ['./pia3-user-performance-chart-transcription-dataminutes.component.scss'],
})
export class Pia3UserPerformanceChartTranscriptionDataminutesComponent implements OnInit {
    @Input('userID') userID: string
    @Input('ColorValidatedColumn') colorValidatedColumn: Pia3ThemeColorValue = Pia3ThemeColorValue.Success
    @Input('ColorValidationRequestedColumn')
    colorValidationRequestedColumn: Pia3ThemeColorValue = Pia3ThemeColorValue.Warning
    @Input('ColorRejectedColumn') colorRejectedColumn: Pia3ThemeColorValue = Pia3ThemeColorValue.Danger

    chartData!: Pia3UserPerformanceChartTranscriptionDataminutesInterface
    hasError: boolean = false
    errorMessage: string = 'Unknown error in chart component'

    constructor(private pia3UserPerformanceService: Pia3UserPerformanceChartTranscriptionDataminutesService) {}

    async ngOnInit() {
        //////console.log('init from chart component')
        try {
            let data = await this.getData(this.userID)
            this.drawChart(data)
        } catch (error) {
            this.setError(error)
        }
    }

    //#region Temp remove after test
    cancel() {}
    confirm() {}
    //#endregion

    private setError(message: string) {
        this.hasError = true
        this.errorMessage = message
    }

    async getData(userID: string) {
        //////console.log('get data from chart component', this.userID)
        try {
            return this.pia3UserPerformanceService.getData(userID)
        } catch (error) {
            this.setError(error)
        }
    }

    /**
     * Draws chart
     * @param chartData
     */
    async drawChart(chartData: Pia3UserPerformanceChartTranscriptionDataminutesInterface) {
        try {
            if (!this.hasError) {
                //user name
                const userName = chartData.user.firstName + ' ' + chartData.user.lastName
                // datas
                const rejectedMinutes = chartData.labels.rejected.rejectedMinutes
                const validatedMinutes = chartData.labels.validated.validatedMinutes
                const validationRequestedMinutes = chartData.labels.validationRequested.validationRequestedMinutes
                // colors
                const colorRrejected: string = this.colorRejectedColumn
                const colorValidated: string = this.colorValidatedColumn
                const colorValidationRequested = this.colorValidationRequestedColumn

                //chart part start
                anychart.onDocumentReady(function () {
                    var data = anychart.data.set([[userName, rejectedMinutes, validatedMinutes, validationRequestedMinutes]])

                    // map the data
                    var seriesData_1 = data.mapAs({ x: 0, value: 1 })
                    var seriesData_2 = data.mapAs({ x: 0, value: 2 })
                    var seriesData_3 = data.mapAs({ x: 0, value: 3 })

                    // create a chart
                    var chart = anychart.column()

                    /* enable the value stacking mode
          on the default primary value scale*/
                    chart.yScale().stackMode('value')

                    // create column series
                    chart.column(seriesData_1).name('Rejected(min)').color(colorRrejected)
                    chart.column(seriesData_2).name('Validated(min)').color(colorValidated)
                    chart.column(seriesData_3).name('Validation Requested(min)').color(colorValidationRequested)
                    // chart.column(seriesData_4);

                    // set the chart title
                    chart.title('Transcriptie minuten per status')

                    //set title color
                    chart.title().fontColor('#4F1DDB')

                    //set credit info
                    let credits = chart.credits()
                    credits.text('Humainr')
                    credits.alt('A.I. voor de mensen')
                    credits.url('https://www.humainr.com')

                    // set the container id
                    chart.container('adf34435463444')

                    // initiate drawing the chart
                    chart.draw()
                })
            }
        } catch (error) {
            console.error(error)
        }
    }
}
