<ion-item *ngIf="currentUser != null" color="primary" (click)="presentCurrentUserFabPopover($event)">
    <ion-label>
        {{ currentUser.attributes.email }}
        <!-- <ion-badge color="danger">3</ion-badge><ion-badge color="warning">33</ion-badge><ion-badge color="success">11</ion-badge>-->
    </ion-label>
    <ion-avatar slot="end">
        {{ currentUser.attributes.email.substring(0, 1) }}
        <!-- <img src="/assets/profile-images/1572517049080.jfif" /> -->
    </ion-avatar>
</ion-item>
