import { Injectable } from '@angular/core'

import { Observable, BehaviorSubject } from 'rxjs'

import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api'
import * as queries from './../../graphql/queries'
import * as mutations from './../../graphql/mutations'
import * as subscriptions from './../../graphql/subscriptions'
import { ListSpeechModelsQuery, ListSpeakersQuery, SpeechModel } from './../../API.service'

import { ErrorhandlingService } from '../_common/errorhandling/errorhandling.service'
import { Pia3ContextchannelService } from '../contextchannel/pia3-contextchannel.service'

@Injectable({
    providedIn: 'root',
})
export class Pia3SpeechmodelService {
    constructor() {}

    async listSpeechModelsByClientByName(clientID: string, limit: number = 100): Promise<{ data: ListSpeechModelsQuery }> {
        return API.graphql(
            graphqlOperation(queries.listSpeechModels, {
                limit: limit,
            })
        ) as Promise<{ data: ListSpeechModelsQuery }>
    }
}
