import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { Pia3AuthenticationService } from './authentication.service'
import { ErrorhandlingService as Pia3ErrorhandlingService } from './../_common/errorhandling/errorhandling.service'
import { CognitoUser } from 'amazon-cognito-identity-js'

@Injectable({
    providedIn: 'root',
})
export class Pia3RoleGuard implements CanActivate {
    public currentUser: CognitoUser
    public currentUserNotAuthorizedForContent: boolean = false

    constructor(private pia3AuthenticationService: Pia3AuthenticationService, private pia3ErrorhandlingService: Pia3ErrorhandlingService) {
        this.pia3AuthenticationService.currentLoggedInUser.subscribe((val) => {
            this.currentUser = val
            this.currentUserNotAuthorizedForContent = val == null
        })
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const expectedRoles = route.data.allowedRoles as string[]
        const currentUserRoles = this.currentUser.getSignInUserSession().getAccessToken().payload['cognito:groups'] as string[]

        //Return route if no roles are required
        if (expectedRoles == undefined || expectedRoles.length == 0) {
            return true
        }
        //Return false if user is not logged in
        if (this.currentUser == null || this.currentUser == undefined) {
            return false
        } else {
            //Proces protected route
            console.warn('currentUserRoles :', currentUserRoles)
            console.warn('expectedRoles :', expectedRoles)
            console.warn(expectedRoles.some((role) => currentUserRoles.includes(role)))
            const allowed = expectedRoles.some((role) => currentUserRoles.includes(role))
            if (!allowed) {
                this.pia3ErrorhandlingService.presentErrorToast('Unauthorized: You are not authorized to view this content')
            }
            return allowed
        }
    }

    userHasRole(expectedRoles: string[]): boolean {
        const currentUserRoles = this.currentUser.getSignInUserSession().getAccessToken().payload['cognito:groups'] as string[]

        //Return route if no roles are required
        if (expectedRoles == undefined || expectedRoles.length == 0) {
            return true
        }
        //Return false if user is not logged in
        if (this.currentUser == null || this.currentUser == undefined) {
            return false
        } else {
            return expectedRoles.some((role) => currentUserRoles.includes(role))
        }
    }
}
