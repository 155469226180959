import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { AlertController, IonModal } from '@ionic/angular'
import { OverlayEventDetail } from '@ionic/core/components'
import { ImageCroppedEvent } from 'ngx-image-cropper' //npm install ngx-image-cropper

@Component({
    selector: 'pia3-image-cropper',
    templateUrl: './pia3-image-cropper.component.html',
    styleUrls: ['./pia3-image-cropper.component.scss'],
})
export class Pia3ImageCropperComponent implements OnInit {
    @Output('imageSelectedPath') imageSelectedPath = new EventEmitter<string>()

    imgChangeEvt: any = ''
    cropImgPreview: any = ''
    cropImgPreviewDummy: any = ''

    // Modal
    @ViewChild(IonModal) modalCropper: IonModal

    message = ''
    name: string

    constructor(private alertController: AlertController) {}

    ngOnInit() {}

    onFileChange(event: any): void {
        this.imgChangeEvt = event
    }
    cropImg(e: ImageCroppedEvent) {
        // this.cropImgPreview = e.base64;
        this.cropImgPreviewDummy = e.base64
    }
    imgLoad() {
        // display cropper tool
    }
    initCropper() {
        // init cropper
    }

    imgFailed() {
        // error msg
    }

    // Modal
    present() {
        this.modalCropper.present()
    }

    cancel() {
        this.modalCropper.dismiss(null, 'cancel')
    }

    confirm() {
        this.modalCropper.dismiss(this.name, 'confirm')

        //
        if (this.cropImgPreviewDummy !== '') {
            this.cropImgPreview = this.cropImgPreviewDummy
            this.savePicture()
        } else {
            this.presentNoSelectedImageAlert()
        }
    }

    onWillDismiss(event: Event) {
        const ev = event as CustomEvent<OverlayEventDetail<string>>
        if (ev.detail.role === 'confirm') {
            // this.message = `Hello, ${ev.detail.data}!`;
        }
    }

    //
    async savePicture() {
        const base64Data = this.cropImgPreview

        // Write the file to the data directory
        const fileName = new Date().getTime() + '.png'
        const savedFile = await Filesystem.writeFile({
            path: fileName,
            data: base64Data,
            directory: Directory.Data,
        })

        //
        // this.download(savedFile.uri,fileName);
        // this.readFile(fileName);
        // this.download(base64Data,fileName);

        //path of saved file
        // //////console.log(savedFile);
        if (this.cropImgPreview !== '') {
            let imagePath = savedFile.uri.toString()
            let imageName = imagePath.split('/')

            this.message = imageName[2]
            this.imageSelectedPath.emit(imagePath)
        }

        // Use webPath to display the new image instead of base64 since it's
        // already loaded into memory
        // return {
        //   filepath: fileName,
        //   // webviewPath: photo.webPath,
        //   webviewPath: this.cropImgPreview.webPath,
        // };
    }

    download(url: any, fileName: string) {
        const a = document.createElement('a')
        a.href = url
        // a.download = url.split('/').pop();
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    /*Alerts*/
    //alert-no checked item
    async presentNoSelectedImageAlert() {
        const alert = await this.alertController.create({
            header: 'No selected image',
            // subHeader: 'No results were found in the selected date range!',
            message: 'Please select a image.',
            buttons: ['OK'],
            mode: 'ios',
        })

        await alert.present()
    }
}
