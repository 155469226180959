export enum Colors {
  PRIMARY = 'primary',
  PRIMARY_CONTRAST = 'primary-contrast',
  PRIMARY_SHADE = 'primary-shade',
  PRIMARY_TINT = 'primary-tint',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
  DARK = 'dark',
  MEDIUM = 'medium',
  LIGHT = 'light',
}

export enum ColorValuesRGB {
  PRIMARY = '#4638C8',
  PRIMARY_CONTRAST = '#ffffff',
  PRIMARY_SHADE = '#392caa',
  PRIMARY_TINT = '#5141df',
  SECONDARY = '#5E50E2',
  TERTIARY = '#1ddba5',
  DANGER = '#eb445a',
  WARNING = '#ffc409',
  SUCCESS = '#2dd36f',
  DARK = '#222428',
  MEDIUM = '#92949c',
  LIGHT = '#f4f5f8',
}

export enum ColorValuesRGBA {
  PRIMARY = '56, 128, 255',
  SECONDARY = '61, 194, 255',
  TERTIARY = '82, 96, 255',
  DANGER = '235, 68, 90',
  WARNING = '255, 196, 9',
  SUCCESS = '45, 211, 111',
  DARK = '34, 36, 40',
  MEDIUM = '146, 148, 156',
  LIGHT = '244, 245, 248',
}
