import { Component, OnInit, Input, inject, ChangeDetectorRef, EventEmitter, Output, OnDestroy } from '@angular/core'
import { Pia3SessionService } from './../pia3-session.service'
import { Observable, Subject } from 'rxjs'
import { Pia3ThemeColor, Pia3ThemeService } from '../../theme/pia3-theme.service'
import { Language, Session } from './../../../API.service'
import { Pia3LanguageService } from '../../language/pia3-language.service'
import { IPia3VideoplayerConfig } from '../../video/pia3-video-player/pia3-video-player.component'
import { IPia3AudioplayerConfig } from '../../audio/pia3-audio-player/pia3-audio-player.component'

export interface Pia3SessionCardConfig {
    showAudioplayer: boolean
    showVideoplayer: boolean
    showTitle: boolean
    showSummary: boolean
    showKeywords: boolean
    showContextLabel: boolean
    showSpeakerAvatar: boolean
    cardColor: Pia3ThemeColor
    contextLabelColor: Pia3ThemeColor
    audioplayerConfig?: IPia3AudioplayerConfig
    videoplayerConfig?: IPia3VideoplayerConfig
}

@Component({
    selector: 'pia3-session-card',
    templateUrl: './pia3-session-card.component.html',
    styleUrls: ['./pia3-session-card.component.scss'],
})
export class Pia3SessionCardComponent implements OnDestroy {
    //#region Local Variables
    protected _session: Session = null
    public _sessionTitle: string = null
    public _currentLanguage: Language = null
    public _audioPlayerWaveColor: string = 'white'
    public _audioPlayerWaveProgressColor: string = 'pink'
    public _sessionCardConfig: Pia3SessionCardConfig = {
        showAudioplayer: false,
        showVideoplayer: false,
        showTitle: false,
        showSummary: false,
        showKeywords: false,
        showContextLabel: false,
        showSpeakerAvatar: false,
        cardColor: Pia3ThemeColor.Light,
        contextLabelColor: Pia3ThemeColor.Light,
        audioplayerConfig: {
            autoplay: false,
            autoplayOnHover: true,
            showControls: false,
        },
        videoplayerConfig: {
            autoplay: false,
            showControls: false,
        },
    }
    //#endregion

    //#region INPUTS
    @Input('session') public set session(session: Session) {
        if (session != null) {
            this._session = session
            this._sessionTitle = this.getGNLPTitle(session)
        }
    }
    @Input('sessionCardConfig') public set sessionCardConfig(sessionCardConfig: Pia3SessionCardConfig) {
        if (sessionCardConfig != null) {
            this._sessionCardConfig = sessionCardConfig
        }
        this.configureSessionCard(this._sessionCardConfig)
    }
    //#endregion

    //#region OUTPUTS
    @Output('cardClickEvent') cardClickEvent: EventEmitter<Session> = new EventEmitter<Session>()
    //#endregion

    constructor(private pia3LanguageService: Pia3LanguageService, private pia3ThemeService: Pia3ThemeService, private pia3SessionService: Pia3SessionService) {}

    ngOnInit() {
        //////console.log('onInit')
        this.pia3LanguageService.currentLanguage.subscribe((language: Language) => {
            this._currentLanguage = language
            this._sessionTitle = this.getGNLPTitle(this._session)
        })
        this.configureSessionCard(this._sessionCardConfig)
    }

    ngOnDestroy() {}

    public configureSessionCard(config: Pia3SessionCardConfig) {
        this._audioPlayerWaveColor = this.pia3ThemeService.getThemeColorTintValue(config.cardColor)
        this._audioPlayerWaveProgressColor = config.cardColor
    }

    public cardClick(session: Session) {
        //////console.log('session click on card')
        this.cardClickEvent.emit(session)
    }

    public getGNLPCategory(_session): string {
        return this.pia3SessionService.getGNLPCategory(_session)
    }

    public getGNLPSummaryCondensed(_session): string {
        return this.pia3SessionService.getGNLPSummaryCondensed(_session)
    }

    public getGNLPTitle(session: Session): string {
        return this.pia3SessionService.getGNLPTitle(session)
    }

    public getGNLPSummary(session: Session): string {
        return this.pia3SessionService.getGNLPSummary(session)
    }

    public getGNLPKeywords(session: Session): string[] {
        return this.pia3SessionService.getGNLPKeywords(session)
    }
}
