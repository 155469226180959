import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { IonicModule } from '@ionic/angular'
import { PiaVideoComponent } from './pia-video.component'
//import { VimeModule } from '@vime/angular';

@NgModule({
    //imports: [CommonModule, FormsModule, IonicModule, VimeModule],
    imports: [CommonModule, FormsModule, IonicModule],
    declarations: [PiaVideoComponent],
    exports: [PiaVideoComponent],
})
export class PiaVideoModule {}
