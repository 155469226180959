import { IWordTagger } from './../wordtagger/pia3-word-tagger-interfaces'
import { Pia3ContextchannelService } from './../contextchannel/pia3-contextchannel.service'
import { Pia3WordTaggerService } from './../wordtagger/pia3-word-tagger.service'
import { Pia3Service } from './../../pia3.service'
import { Language, Session, Contextchannel, WordTaggerModuleOutput } from './../../API.service'
import { getSession } from './../../graphql/queries'
import { Pia3SessionService } from './../session/pia3-session.service'
import { IWordWithFrequency } from '../wordcloud/pia3-word-cloud-interfaces'
import { Pia3WordCloudService } from './../wordcloud/pia3-word-cloud.service'
import { Component, OnInit, inject } from '@angular/core'

@Component({
    selector: 'pia3HomeComponent',
    templateUrl: './pia3Home.component.html',
    styleUrls: ['./pia3Home.component.scss'],
})
export class Pia3HomeComponent implements OnInit {
    // private _sessionId: string = 'fbacdd7f-35e4-4080-a4bc-06adf287a911';
    private _sessionId: string = '40982313-5aaa-4838-a8fc-356f4f292bb5'
    private _contextChannelId = 'd3577356-14f7-45de-b7c0-553882ebce38'
    stopWords: string[] = null
    sessionText: string = ''
    sessionWordsWithFrequency: IWordWithFrequency[] = []
    contextWordsWithFrequency: IWordWithFrequency[] = []
    wordTaggerCategories: string[] = ['Quote', 'Bijvangst', 'Productsentiment']

    constructor(private pia3wordCloudService: Pia3WordCloudService, private pia3WordTaggerService: Pia3WordTaggerService, private pia3ContextchannelService: Pia3ContextchannelService) {}

    async ngOnInit() {
        //get contextWordsWithFrequency
        await this.pia3wordCloudService.getContextWordsWithFreq(this._contextChannelId).then((result) => {
            this.contextWordsWithFrequency = result
        })

        //get stopWords
        await this.pia3wordCloudService.getStopWords(this._sessionId).then((result) => {
            this.stopWords = result
        })

        //get sessionText then sessionWordsWithFrequency
        await this.pia3wordCloudService
            .getSessionText(this._sessionId)
            .then((result) => {
                this.sessionText = result
            })
            .then(async () => {
                await this.pia3wordCloudService.getWordsWithFreq(this.sessionText).then((result) => {
                    this.sessionWordsWithFrequency = result
                })
            })

        //get configured cat.
        // await this.pia3ContextchannelService
        //   .getContextchannel(this._contextChannelId)
        //   .then(async (ctxchannel) => {
        //     let contextchannel: Contextchannel = null;
        //     contextchannel = ctxchannel.data.getContextchannel as Contextchannel;
        //     //////console.log('contextchannel :', contextchannel);
        //     await this.pia3WordTaggerService
        //       .getConfiguredCategories(contextchannel)
        //       .then((categories) => {
        //         //////console.log('CATEGORIES :', categories);
        //       });
        //   });

        //
        // this.pia3WordTaggerService
        //   .getWordTaggerModuleOutput('c1c85f8f-c259-400c-878d-1bf16c17cea1')
        //   .then((result) => {
        //     //
        //     let res = this.pia3WordTaggerService.showWordTaggerModuleOutput(
        //       result.data.getWordTaggerModuleOutput as WordTaggerModuleOutput
        //     );
        //     //////console.log('RES[0] ===', res[0]);
        //     //////console.log('RES[1] ===', res[1]);
        //     //
        //     let wt: IWordTagger[] = result.data.getWordTaggerModuleOutput
        //       .taggedWords as IWordTagger[];
        //     //////console.log('WordTagger array according to the id  :', wt);
        //   });

        //
        // this.pia3WordTaggerService.listWordTaggerModuleOutput().then((result) => {
        //   //////console.log(
        //     'listWordTaggerModuleOutpue',
        //     result.data.listWordTaggerModuleOutputs.items
        //   );
        // });
    }

    async taggedData($event) {
        // //////console.log('Word Tagger :', $event);

        let taggedWords: IWordTagger[] = $event

        await this.pia3wordCloudService.getSession(this._sessionId).then(async (session) => {
            await this.pia3WordTaggerService.createWordTaggerModuleOutput(session, taggedWords, this.wordTaggerCategories).then((query) => {
                //////console.log('Saved Data :', query)
            })
        })
    }
}
