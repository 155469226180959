<h1>Contextchannels</h1>
<ion-list>
  <ion-item
    (click)="contextchannelClicked(ctx)"
    [detail]="true"
    *ngFor="let ctx of contextchannels"
  >
    <ion-label>
      <h1>{{ ctx.name }}</h1>
    </ion-label>
  </ion-item>
</ion-list>
