import { Session } from './../../API.service'
import { Pia3SessionService } from './../session/pia3-session.service'
import { Injectable } from '@angular/core'
//import { IWordWithFrequency } from './pia3-word-cloud-interfaces';
import { Pia3LanguageService } from '../language/pia3-language.service'
import { Pia3ContextchannelService } from '../contextchannel/pia3-contextchannel.service'
import { Pia3Service } from '../../pia3.service'

export interface IWordWithFrequency {
    word: string
    freq: number
}

@Injectable({
    providedIn: 'root',
})
export class Pia3WordCloudService {
    private _session: Session

    constructor(public pia3SessionService: Pia3SessionService, public pia3LanguageService: Pia3LanguageService, public pia3ContextchannelService: Pia3ContextchannelService, public pia3Service: Pia3Service) {
        // this.getSession();
    }

    //#region of ...

    /**
     *
     * @param sessionId
     * @returns Promise<Session>
     */
    public async getSession(sessionId: string): Promise<Session> {
        return await this.pia3SessionService.getSession(sessionId).then((result) => {
            // this._session = result.data.getSession as Session;
            return result.data.getSession as Session
        })
    }

    /**
     *
     * @param sessionId
     * @returns Promise<string>
     */
    public async getSessionText(sessionId: string): Promise<string> {
        let text: string
        return await this.getSession(sessionId)
            .then((session) => {
                this._session = session
            })
            .then(async () => {
                await this.pia3SessionService.getSessionText(this._session).then((txt) => {
                    text = txt
                })
            })
            .then(async () => {
                return text
            })
        // return await this.pia3SessionService
        //   .getSessionText(this._session)
        //   .then((txt) => {
        //     return txt;
        //   });
    }

    /**
     *
     * @param sessionId
     * @returns Promise<string[]>
     */
    public async getStopWords(sessionId: string): Promise<string[]> {
        let stopWords: string[] = []
        return await this.getSession(sessionId)
            .then((session) => {
                this._session = session
            })
            .then(async () => {
                await this.pia3LanguageService.getLanguage(this._session.AutoTranscript.SpeechModel.languageID).then((language) => {
                    stopWords = language.data.getLanguage.stopwords
                })
            })
            .then(async () => {
                return stopWords
            })

        // return await this.pia3LanguageService
        //   .getLanguage(this._session.AutoTranscript.SpeechModel.languageID)
        //   .then((language) => {
        //     // //console.log(language);
        //     // this.stopWords = language.data.getLanguage.stopwords;
        //     return language.data.getLanguage.stopwords;
        //   });
    }

    /**
     *
     * @param contextChannelId
     * @returns Promise<IWordWithFrequency[]>
     */
    public async getContextWordsWithFreq(contextChannelId: string): Promise<IWordWithFrequency[]> {
        const contextchannelQuery = await this.pia3ContextchannelService.getContextchannel(contextChannelId)
        const contextchannel = contextchannelQuery.data.getContextchannel
        const contextWords = await this.pia3Service.getS3FileAsJson('public/' + contextchannel.Client.id + '/' + contextchannel.id + '/extracted.wordcounts.json')

        const arr = Object.entries(contextWords).map(([word, freq]) => ({
            word,
            freq,
        }))

        const wordArray: IWordWithFrequency[] = []
        arr.map((obj) => {
            wordArray.push({ word: String(obj.word), freq: Number(obj.freq) })
        })

        return wordArray
    }

    //#endregion of ...

    /**
     * Process the text and create an object as IWordWithFrequency
     * @param text: string
     * @returns  Promise<IWordWithFrequency[]>
     */
    getWordsWithFreq(text: string): Promise<IWordWithFrequency[]> {
        return new Promise((resolve, reject) => {
            const words: string[] = text.split(/[,\. ]+/g)
            const wordCounts: Record<string, number> = {}

            words.forEach((word: string) => {
                if (word) {
                    if (!wordCounts[word]) {
                        wordCounts[word] = 0
                    }
                    wordCounts[word]++
                }
            })

            const wordArray: IWordWithFrequency[] = []
            for (let word in wordCounts) {
                wordArray.push({ word: word, freq: wordCounts[word] })
            }

            resolve(wordArray)
        })
    }

    /**
     * Filtering word according to the stopWords
     * @param wordsWithFreq
     * @param stopWords
     * @returns Promise<IWordWithFrequency[]>
     */
    filterWords(wordsWithFreq: IWordWithFrequency[], stopWords: string[]): Promise<IWordWithFrequency[]> {
        return new Promise((resolve, reject) => {
            const filteredData: IWordWithFrequency[] = []

            if (wordsWithFreq !== undefined && stopWords !== undefined) {
                wordsWithFreq.map((obj) => {
                    const lowerCasedObjText = obj.word.toLowerCase()
                    if (stopWords.includes(lowerCasedObjText, 0)) {
                    } else {
                        filteredData.push(obj)
                    }
                })

                //console.log('Words :', wordsWithFreq)
                //console.log('Stop Words :', stopWords)
                //console.log('Filtered words', filteredData)

                resolve(filteredData)
            } else {
                resolve(wordsWithFreq)
            }
        })
    }
}
