import { Injectable } from '@angular/core';
import { Storage } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class S3Service {
  constructor() {}

  public async getS3FileAsJson(key: string) {
    if (key.indexOf('public/') != -1) {
      key = key.replace('public/', '');
    }
    const downloadResult = await Storage.get(key, { download: true });
    const jsonTextResult = await (downloadResult.Body as Blob).text();
    const jsonData = JSON.parse(jsonTextResult);
    return jsonData;
  }
}
