import { Component, OnInit } from '@angular/core'
import { Language, ModelSortDirection } from './../../../API.service'
import { Pia3LanguageService } from '../../language/pia3-language.service'
import { SpecialObjectClass } from '../pia3-general-selector/_special-object-class'

export interface IProfil {
    name: string
    lastName: string
    age: number
    vehicles: Vehicle[]
}

export interface Vehicle {
    name: string
    model: number
}

@Component({
    selector: 'pia3-general-selector-single',
    templateUrl: './pia3-general-selector-single.component.html',
    styleUrls: ['./pia3-general-selector-single.component.scss'],
})
export class Pia3GeneralSelectorSingleComponent implements OnInit {
    public languages: Language[]
    //
    dataNumberArray: number[] = [1, 2, 3, 4]
    dataStringArray: string[] = ['aplle', 'orange', 'peach']
    dataObjectArray: IProfil[] = [
        {
            name: 'Davut',
            lastName: 'Bilgic',
            age: 25,
            vehicles: [
                {
                    name: 'Vehicle Name 1',
                    model: 2005,
                },
                {
                    name: 'Vehicle Name 2',
                    model: 2009,
                },
            ],
        },
        {
            name: 'John',
            lastName: 'Doe',
            age: 30,
            vehicles: [
                {
                    name: 'Vehicle Name x',
                    model: 2015,
                },
                {
                    name: 'Vehicle Name y',
                    model: 2019,
                },
            ],
        },
    ]

    constructor(private pia3LanguageService: Pia3LanguageService) {}

    async ngOnInit() {
        this.languages = (await this.getLanguages()).data.listLanguages.items as Language[]
        //////console.log('🚀 ~ Pia3GeneralSelectorSingleComponent ~ ngOnInit ~ this.languages', this.languages)

        let result = new SpecialObjectClass(this.languages).getAllPropertyNamesWithLevels()
        //////console.log('🚀 ~ Pia3GeneralSelectorSingleComponent ~ ngOnInit ~ result', result)
    }

    //get languages from server
    async getLanguages(nexttoken: string = null) {
        let result = this.pia3LanguageService.getLanguages(undefined, ModelSortDirection.ASC, 999, nexttoken)
        return result
    }
}
