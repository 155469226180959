import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
//import { Player, Video, Captions } from '@vime/angular'
import { Subject, Subscription } from 'rxjs'
import { GenericErrorHandlerService } from 'src/app/.common/services/generic-error-handler.service'
import { Storage } from 'aws-amplify'
import { Video as PiaVideo, VideoStatus, AutoTranscript as PiaAutoTranscript, LanguageLabel } from 'src/app/API.service'
import { PiaMediaEventSource, PiaMediaEventsService } from 'src/app/.common/services/pia-media-events.service'

export interface Track {
    default: boolean
    kind: string
    src: string
    srclang: string
    label: string
}

export enum PlaybackAction {
    PLAY,
    PAUSE,
    STOP,
}

export interface PlaybackCommand {
    action: PlaybackAction
    position: number
    languagelabel?: LanguageLabel
}

@Component({
    selector: 'app-pia-video',
    templateUrl: './pia-video.component.html',
    styleUrls: ['./pia-video.component.scss'],
})
export class PiaVideoComponent implements OnInit {
    // @ViewChild('player', { static: false }) public player!: Player
    // public subscriptions: Array<Subscription> = []
    // @Input() piaVideo: PiaVideo
    // @Input() piaTranscript: PiaAutoTranscript
    // @Input() piaSubtitlesKey: string
    // @Input() receiveVideoUrl: Subject<string>
    // @Input() receiveVideoSubtitlesUrl: Subject<string>
    // @Input() public receivePlaybackCommand: Subject<PlaybackCommand> = new Subject()
    // public piaMediaEventOrigin: PiaMediaEventSource = PiaMediaEventSource.PiaVideo
    // public videoFileUrl: string = null
    // public subtitlesFileUrl: string = null
    // public track: Track = null
    // public currentTime: number = 0
    // constructor(public genericErrorHandlerService: GenericErrorHandlerService, private piaMediaEventsService: PiaMediaEventsService) {
    //     //Subscribe to PiaMediaEvents
    //     this.piaMediaEventsService.sessionPlay.subscribe(this.piaMediaEventSessionPlaySubscription)
    //     this.piaMediaEventsService.sessionPause.subscribe(this.piaMediaEventSessionPauseSubscription)
    //     this.piaMediaEventsService.sessionStop.subscribe(this.piaMediaEventSessionStopSubscription)
    // }
    // //#region Lifecylce Events
    ngOnInit() {}
    // ngAfterViewInit() {
    //     //Load Video
    //     if (this.piaTranscript != undefined && this.piaTranscript != null) {
    //         //alert('got transcript');
    //         this.loadPlayerSubtitlesUrl(this.piaTranscript.subtitlesVttS3Key.replace('public/', ''))
    //             .then(
    //                 (url) => {
    //                     //alert('got subs');
    //                     //////console.log('subtitles url is: ', url);
    //                     this.subtitlesFileUrl = url
    //                     let track: Track = {
    //                         default: true,
    //                         kind: 'subtitles',
    //                         label: 'Fries Nederlands',
    //                         srclang: 'frl/nl',
    //                         src: url,
    //                     }
    //                     this.track = track
    //                     //Load Video
    //                     if (this.piaVideo != undefined && this.piaVideo != null && this.piaVideo.videoStatus == VideoStatus.Succeeded) {
    //                         this.loadPlayerVideoFileUrl(this.piaVideo.compressedVideoS3Key.replace('public/', ''))
    //                             .then(
    //                                 (url) => {
    //                                     this.videoFileUrl = url
    //                                 },
    //                                 (reject) => {
    //                                     alert('LoadPlayerVideoUrl from s3 reject' + reject)
    //                                 }
    //                             )
    //                             .catch((error) => {
    //                                 alert('loadPlayerVideoUrl error:' + error)
    //                             })
    //                     }
    //                 },
    //                 (reject) => {
    //                     alert('LoadPlayerVideoUrl from s3 reject' + reject)
    //                 }
    //             )
    //             .catch((error) => {
    //                 alert('loadPlayerVideoUrl error:' + error)
    //             })
    //     }
    // }
    // ngOnDestroy() {
    //     //////console.log('ngOnDestroy from PiaVideo');
    // }
    // //#endregion
    // //#region Player Initialization Methods
    // async loadPlayerSubtitlesUrl(subtitlesKey: string) {
    //     return await Storage.get(subtitlesKey)
    // }
    // async loadPlayerVideoFileUrl(piaVideoKey: string) {
    //     return await Storage.get(piaVideoKey)
    // }
    // receiveVideoUrlHandler = (videoFileUrl) => {
    //     this.loadPlayerVideoFileUrl(videoFileUrl).then((url) => {
    //         this.videoFileUrl = url
    //     })
    // }
    // receiveVideoSubtitlesUrlHandler = (url) => {
    //     this.loadPlayerSubtitlesUrl(url).then((subtitleFileUrl) => {
    //         if (subtitleFileUrl != null) {
    //             let track: Track = {
    //                 default: true,
    //                 kind: 'subtitles',
    //                 label: 'Ondertitels',
    //                 srclang: 'nl',
    //                 src: subtitleFileUrl,
    //             }
    //             //////console.log('subs:', subtitleFileUrl);
    //             //remove the two lines below. This is just for testing purposes
    //             //track.src = '/assets/vtt/extracted.subtitles_0.1_sec_silence.vtt';
    //             // track.src =
    //             //   '/assets/vtt/extracted.subtitles_smart_silence_twolines.vtt';
    //             //end remove the two lines above...
    //             this.track = track
    //         }
    //     })
    // }
    // //#endregion
    // //#region Player Control & Interaction Methods
    // async play(position: number) {
    //     ////console.log('playing video')
    //     this.player.currentTime = position
    //     this.player.play()
    // }
    // async pause(position: number) {
    //     ////console.log('pausing video', this.player.playbackStarted)
    //     if (!this.player.playbackStarted) {
    //         this.player.play()
    //     }
    //     this.player.currentTime = position
    //     this.player.pause()
    //     this.player.currentTime = position
    // }
    // public receivePlaybackCommandHandler = (playbackCommand: PlaybackCommand) => {
    //     switch (playbackCommand.action) {
    //         case PlaybackAction.PLAY:
    //             ////console.log('received play-command:', playbackCommand)
    //             this.play(playbackCommand.position).then()
    //             break
    //         case PlaybackAction.PAUSE:
    //             ////console.log('received pause-command:', playbackCommand)
    //             this.pause(playbackCommand.position).then()
    //             break
    //         case PlaybackAction.STOP:
    //             ////console.log('received stop-command:', playbackCommand)
    //             this.pause(0).then()
    //             break
    //     }
    // }
    // //#endregion
    // //#region PiaMediaEvent Subscriptions
    // piaMediaEventSessionPlaySubscription = (value: { source: PiaMediaEventSource; sessionId: string; position?: number }) => {
    //     ////console.log('PiaMediaEventsService:SessionPlay:', value)
    //     if (value.source != this.piaMediaEventOrigin) {
    //         this.play(value.position != null ? value.position : 0)
    //     }
    // }
    // piaMediaEventSessionPauseSubscription = (value: { source: PiaMediaEventSource; sessionId: string; position?: number }) => {
    //     ////console.log('PiaMediaEventsService:SessionPause:', value)
    //     if (value.source != this.piaMediaEventOrigin) {
    //         this.pause(value.position != null ? value.position : 0)
    //     }
    // }
    // piaMediaEventSessionStopSubscription = (value: { source: PiaMediaEventSource; sessionId: string; position?: number }) => {
    //     ////console.log('PiaMediaEventsService:SessionStop:', value)
    //     if (value.source != this.piaMediaEventOrigin) {
    //         this.pause(value.position != null ? value.position : 0)
    //     }
    // }
    // //#endregion
    // //#region Player Events
    // onReady($event) {
    //     //////console.log('vmPlayer ready', $event);
    // }
    // onPlaybackReady($event) {
    //     //////console.log('vime playback ready', $event);
    //     this.player.muted = true
    //     this.receivePlaybackCommand.subscribe(this.receivePlaybackCommandHandler, this.genericErrorHandlerService.handleGenericError)
    // }
    // onTimeUpdate($event) {
    //     ////////console.log($event);
    // }
    // onFullscreenChange($event) {}
    // onTextTracksChange($event) {
    //     ////////console.log('vime textracks changed:', $event);
    // }
    // //#endregion
}
