import { Component, EventEmitter, OnInit } from '@angular/core'
import { Pia3ContextchannelService } from './../pia3-contextchannel.service'
import { Contextchannel } from './../../../API.service'
import { Pia3Service } from '../../../pia3.service'
import { Output } from '@angular/core'

@Component({
    selector: 'pia3-contextchannel-list',
    templateUrl: './pia3-contextchannel-list.component.html',
    styleUrls: ['./pia3-contextchannel-list.component.scss'],
})
export class Pia3ContextchannelListComponent implements OnInit {
    @Output('contextchannelClick')
    contextchannelClick: EventEmitter<Contextchannel> = new EventEmitter<Contextchannel>(true)

    public contextchannels: Contextchannel[] = []

    constructor(private contextchannelService: Pia3ContextchannelService, private pia3Service: Pia3Service) {}

    async ngOnInit() {
        this.contextchannels = (await (await this.contextchannelService.getContextchannelsForCurrentUserWithImages()).data.listContextchannels.items) as Contextchannel[]
        //////console.log(this.contextchannels)
    }

    contextchannelClicked(ctx) {
        this.contextchannelClick.emit(ctx)
    }
}
