import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { GenericErrorHandlerService } from 'src/app/.common/services/generic-error-handler.service'
import { SpeakerService } from 'src/app/.common/services/speaker.service'
import { Speaker, SpeakersByClientByLastNameQuery } from 'src/app/API.service'

@Component({
    selector: 'app-pia-speaker-list',
    templateUrl: './pia-speaker-list.component.html',
    styleUrls: ['./pia-speaker-list.component.scss'],
})
export class PiaSpeakerListComponent implements OnInit {
    @Input('speakers') speakers: Speaker[] = []

    @Output('speakerSelectedEvent') speakerSelectedEvent: EventEmitter<Speaker> = new EventEmitter()

    constructor() {}

    ngOnInit() {}

    speakerSelected(speaker: Speaker) {
        this.speakerSelectedEvent.emit(speaker)
    }

    // async getSpeakersForClient(clientID: string) {
    //     this.speakerService.listSpeakersByClientByLastname(clientID, 100).then(this.speakersLoaded, this.genericErrorHandlerService.handleGenericGraphQLError).catch(this.genericErrorHandlerService.handleGenericError)
    // }

    // speakersLoaded = (result: { data: SpeakersByClientByLastNameQuery }) => {
    //     this.speakers.push(...(result.data.SpeakersByClientByLastName.items as Speaker[]))
    // }
}
