import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { AlertController, IonModal } from '@ionic/angular'
import { Language, ModelSortDirection } from './../../../API.service'
import { Pia3LanguageService } from '../pia3-language.service'
import { OverlayEventDetail } from '@ionic/core/components'
import { SelectorMode } from '../../_common/selector-mode/selector-mode'

@Component({
    selector: 'pia3-language-selector-multi-with-search',
    templateUrl: './pia3-language-selector-multi-with-search.component.html',
    styleUrls: ['./pia3-language-selector-multi-with-search.component.scss'],
})
export class Pia3LanguageSelectorMultiWithSearchComponent implements OnInit {
    selectorMode: string = SelectorMode.MULTI_WITH_SEARCH
    @Output('languageSelected') languagesSelected = new EventEmitter<Language[]>()

    constructor() {}

    ngOnInit() {}

    _languageSelected($event) {
        this.languagesSelected.emit($event)
    }
}
