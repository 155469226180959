import { Component, Input, Output, OnInit, OnChanges, SimpleChange, EventEmitter } from '@angular/core'
import { $ } from 'protractor'
import { IonRadioGroupCustomEvent, RadioGroupChangeEventDetail } from '@ionic/core'
import { LanguageLabelService } from 'src/app/.common/services/languagelabel.service'
import { GoldTranscript, LanguageLabel, Speaker, ValidationStatus, Gender } from 'src/app/API.service'
import { Iso8601timePipe } from 'src/app/.common/pipes/iso8601time.pipe'
import { PlaybackCommand, PlaybackAction } from '../../pia-video/pia-video.component'
import { Language } from 'src/models'
import { Hotkey, HotkeysService } from 'angular2-hotkeys'
import { log } from 'console'

enum UpdateStatus {
    NORMAL = 'normal',
    WORKING = 'working',
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
}

@Component({
    selector: 'app-pia-transcript-languagelabel',
    templateUrl: './pia-transcript-languagelabel.component.html',
    styleUrls: ['./pia-transcript-languagelabel.component.scss'],
})
export class PiaTranscriptLanguagelabelComponent implements OnInit {
    @Input() currentlanguagelabelID: string = null
    @Input() currentUserID: string = null
    @Input() languagelabel: LanguageLabel = null
    @Input() speakers: Speaker[] = []
    @Output() languageLabelClickEvent = new EventEmitter<LanguageLabel>(true)
    @Output()
    piaLanguageLabelOnPlaybackCommandEventEmitter: EventEmitter<PlaybackCommand> = new EventEmitter<PlaybackCommand>(true)

    public color: string = null
    public hasFocus: boolean = false
    public isUpdating: boolean = false
    public error: string = null
    public contentBeforeChange: string = null
    public contentAfterChange: string = null
    public contentSelected: string = null
    public updateStatus: UpdateStatus = UpdateStatus.NORMAL

    constructor(private languagelabelService: LanguageLabelService) {}

    ngOnInit() {}

    ngOnChanges(change: { prop: SimpleChange }) {
        //LanguageLabel change
        if (change['languagelabel'] != null) {
            let value: SimpleChange = change['languagelabel']
            if (value.currentValue != null && value.currentValue != '' && value.currentValue != value.previousValue) {
                this.languagelabel = value.currentValue
                this.contentBeforeChange = this.languagelabel.content
            }
        }
        //currentLanguageLabelID change
        if (change['currentlanguagelabelID'] != null) {
            let value: SimpleChange = change['currentlanguagelabelID']
            if (value.currentValue != null && value.currentValue != '' && value.currentValue == this.languagelabel.id) {
                this.hasFocus = true
                this.languageLabelClickEvent.emit(this.languagelabel)

                //TODO: remove this if hotkeys on wavesurfer work better
                //////console.log('should bind the hotkeys here');
                //this.enableHotkeys();
            } else {
                //TODO: remove this if hotkeys on wavesurfer work better
                //////console.log('should remove the hotkeys here');
                //this.disableHotkeys();
                this.hasFocus = false
            }
        }
    }

    //#region Hotkeys

    //#endregion

    //#region Item Events

    itemFocus() {
        ////console.log('item focus')
        this.languageLabelClickEvent.emit(this.languagelabel)
        this.hasFocus
    }

    itemBlur() {
        ////console.log('item blur')
    }

    //#endregion

    //#region Media Control Methods

    play() {
        ////console.log('play clicked on langagelabel')
        let command: PlaybackCommand = {
            action: PlaybackAction.PLAY,
            position: 0,
            languagelabel: this.languagelabel,
        }
        //this.piaLanguageLabelOnPlaybackCommandEventEmitter.emit(command);
    }

    pause() {
        let command: PlaybackCommand = {
            action: PlaybackAction.PAUSE,
            position: 0,
            languagelabel: this.languagelabel,
        }
        this.piaLanguageLabelOnPlaybackCommandEventEmitter.emit(command)
    }

    stop() {
        let command: PlaybackCommand = {
            action: PlaybackAction.STOP,
            position: 0,
            languagelabel: this.languagelabel,
        }
        this.piaLanguageLabelOnPlaybackCommandEventEmitter.emit(command)
    }
    //#endregion

    //#region Speaker

    speakerCompareWith(spkId1: string, spkId2: string) {
        return spkId1 === spkId2
    }

    speakerOnChange($event) {
        ////console.log('speaker change:', $event)
        if ($event.detail.value != this.languagelabel.speakerID) {
            //save the content
            this.languagelabelService
                .updateSpeaker(this.languagelabel.id, $event.detail.value, this.currentUserID, this.languagelabel._version)
                .then(
                    (result) => {
                        ////console.log('updated the speaker:', result)
                        this.languagelabel = result.data.updateLanguageLabel as LanguageLabel
                        this.reflectUpdateStatus(UpdateStatus.SUCCESS)
                        this.error = null
                    },
                    (reject) => {
                        ////console.log('rejected update content:', reject)
                        this.reflectUpdateStatus(UpdateStatus.ERROR)
                        this.error = reject
                    }
                )
                .catch((error) => {
                    ////console.log('error update content:', error)
                    this.reflectUpdateStatus(UpdateStatus.ERROR)
                    this.error = error
                })
        }
    }

    //#endregion

    //#region Content
    addHtmlTags(content) {
        this.languagelabel.content += '<span class="language-tool language-nl">testy</span>'
    }

    handleSelection($event) {
        this.contentSelected = $event.text == '' ? null : $event.text
        ////console.log('selected text is:', $event)
    }

    tagButtonClick(type, value) {
        ////console.log('button clicked:', type + ' ' + value)
        ////console.log('selected text is:', this.contentSelected)
    }

    contentBlur($event: CustomEvent) {
        ////console.log('content blur')
        this.saveContentWhenChanged()
        this.hasFocus = false
    }

    private saveContentWhenChanged() {
        ////console.log('save content when changed')
        if (!this.checkIsUpdating()) {
            ////console.log(this.languagelabel.content)
            ////console.log(this.contentBeforeChange)
            ////console.log(this.contentAfterChange)
            if (this.languagelabel.content != null && this.languagelabel.content != '' && this.languagelabel.content != this.contentBeforeChange) {
                ////console.log('should update now')
                this.updateStatus = UpdateStatus.WORKING
                //save the content
                this.languagelabelService
                    .updateContent(this.languagelabel.id, this.languagelabel.content, this.currentUserID, this.languagelabel._version)
                    .then(
                        (result) => {
                            ////console.log('updated the content:', result)
                            this.languagelabel = result.data.updateLanguageLabel as LanguageLabel
                            this.reflectUpdateStatus(UpdateStatus.SUCCESS)
                            this.error = null
                        },
                        (reject) => {
                            ////console.log('rejected update content:', reject)
                            this.reflectUpdateStatus(UpdateStatus.ERROR)
                            this.error = reject.error
                        }
                    )
                    .catch((error) => {
                        ////console.log('error update content:', error)
                        this.reflectUpdateStatus(UpdateStatus.ERROR)
                        this.error = error
                    })
                    .finally(() => {
                        this.updateStatus = UpdateStatus.NORMAL
                    })
                //update contentBeforeFocus to new value
                this.contentBeforeChange = this.contentAfterChange
            }
        }
    }

    contentChange($event) {
        this.contentAfterChange = $event.detail.value
    }

    contentFocus($event) {
        this.languageLabelClickEvent.emit(this.languagelabel)
    }

    contentInput($event) {}

    //#endregion

    //#region Validation
    //todo: rework this so it works with the new projectmanagementsystem
    // validationRequestChange($event) {
    //     //Log the change
    //     ////console.log('validationrequest change:', $event)
    //     //save the change
    //     if ($event.detail.checked) {
    //         //this.languagelabel.validationStatus = ValidationStatus.ValidationRequested;
    //         this.updateValidationStatus(this.languagelabel, ValidationStatus.ValidationRequested)
    //     } else {
    //         //this.languagelabel.validationStatus = ValidationStatus.Updated;
    //         this.updateValidationStatus(this.languagelabel, ValidationStatus.Updated)
    //     }
    // }

    validationValidateChange($event: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail<string>>) {
        //Log the change
        ////console.log('validate event:', $event)
        ////console.log('validate change:', $event.detail.value)
        let status: ValidationStatus = ValidationStatus.New
        switch ($event.detail.value) {
            case 'New':
                status = ValidationStatus.New
                break
            case 'ValidationRequested':
                status = ValidationStatus.ValidationRequested
                break
            case 'Validated':
                status = ValidationStatus.Validated
                break
            case 'Rejected':
                status = ValidationStatus.Rejected
                break
        }
        //Save the change
        this.updateValidationStatus(this.languagelabel, status)
    }

    updateValidationStatus(languagelabel: LanguageLabel, validationStatus: ValidationStatus) {
        setTimeout(() => {
            if (!this.checkIsUpdating()) {
                this.updateStatus = UpdateStatus.WORKING
                //Save the change
                this.languagelabelService
                    .updateValidationStatus(this.languagelabel.id, validationStatus, this.currentUserID, this.languagelabel._version)
                    .then(
                        (result) => {
                            this.languagelabel = result.data.updateLanguageLabel as LanguageLabel
                            this.reflectUpdateStatus(UpdateStatus.SUCCESS)
                        },
                        (reject) => {
                            this.error = reject
                            this.reflectUpdateStatus(UpdateStatus.ERROR)
                        }
                    )
                    .catch((error) => {
                        this.error = error
                        this.reflectUpdateStatus(UpdateStatus.ERROR)
                    })
                    .finally(() => {
                        this.updateStatus = UpdateStatus.NORMAL
                    })
            }
        }, 1000)
    }

    //#endregion

    //#region Global

    async reflectUpdateStatus(status: UpdateStatus) {
        this.color = status
        switch (status) {
            case UpdateStatus.SUCCESS:
                setTimeout(() => {
                    this.color = UpdateStatus.NORMAL
                }, 300)
                break
            case UpdateStatus.WARNING:
                this.color = status.toString()
                setTimeout(() => {
                    this.color = UpdateStatus.NORMAL
                }, 300)
                break
            case UpdateStatus.ERROR:
                this.color = status.toString()
                break
        }
    }

    checkIsUpdating() {
        if (this.updateStatus == UpdateStatus.WORKING) {
            alert('Kan bewerking niet uitvoeren omdat er nog een andere bewerking gaande is. Probeer nogmaals.')
        }
        return this.updateStatus == UpdateStatus.WORKING
    }

    //#endregion
}
