import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as queries from 'src/graphql/queries';
//import * as mutations from 'src/graphql/mutations';
//import * as subscriptions from 'src/graphql/subscriptions';
import { GetAutoTranscriptQuery } from 'src/app/API.service';

import { RouterParamsService } from 'src/app/.common/services/router-params.service';
import { GenericErrorHandlerService } from 'src/app/.common/services/generic-error-handler.service';
import { ContextchannelService } from 'src/app/contextchannels/contextchannel.service';

@Injectable({
  providedIn: 'root',
})
export class PiaTranscriptAutoServiceService {
  constructor(
    private routerParamsService: RouterParamsService,
    private genericErrorHandlerService: GenericErrorHandlerService,
    private contextchannelService: ContextchannelService
  ) {}

  async getTranscriptAuto(id: string) {
    const query = await (API.graphql(
      graphqlOperation(queries.getAutoTranscript, { id: id })
    ) as Promise<{ data: GetAutoTranscriptQuery }>);
    return query.data.getAutoTranscript;
  }
}
