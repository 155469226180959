import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core'
import { IonSlides } from '@ionic/angular'
import { Pia3ThemeColor } from './../../theme/pia3-theme.service'
import { v4 as uuidv4 } from 'uuid'
import { Session } from './../../../API.service'
import { Pia3SessionCardConfig } from '../pia3-session-card/pia3-session-card.component'

@Component({
    selector: 'pia3-session-slider-cards',
    templateUrl: './pia3-session-slider-cards.component.html',
    styleUrls: ['./pia3-session-slider-cards.component.scss'],
})
export class Pia3SessionSliderCardsComponent implements OnInit {
    //#region VIEWCHILDS
    @ViewChild(IonSlides) slides: IonSlides
    //#endregion

    //#region INPUTS
    @Input('sessions') sessions: Session[]
    @Input('sessionsLoading') sessionsLoading: boolean = false
    @Input('sessionCardConfig') sessionCardConfig: Pia3SessionCardConfig
    @Input('cardColor') cardColor: Pia3ThemeColor = Pia3ThemeColor.Primary
    @Input('ionSliderReachedEndNoMoreData')
    ionSliderReachedEndNoMoreData: boolean = false
    @Input('slidesPerViewXs') slidesPerViewXs: number
    @Input('slidesPerViewSm') slidesPerViewSm: number
    @Input('slidesPerViewMd') slidesPerViewMd: number
    @Input('slidesPerViewLg') slidesPerViewLg: number
    @Input('slidesPerViewXl') slidesPerViewXl: number
    @Input('slidesPerViewXXl') slidesPerViewXXl: number

    //#endregion

    //#region OUTPUTS
    @Output('ionSliderSessionCardClick')
    ionSliderSessionCardClick: EventEmitter<Session> = new EventEmitter<Session>(true)
    @Output('sessionCardClick') sessionCardClickEmitter: EventEmitter<Session> = new EventEmitter<Session>(true)
    @Output('ionSliderReachEnd') ionSliderReachEnd: EventEmitter<Event> = new EventEmitter<Event>(true)

    //#endregion

    //#region COMPONENTS VARIABLES

    public slidesID: string = uuidv4()
    public slidesOptions = {}
    // public slidesOptions: {
    //   direction: string;
    //   slidesPerView: number;
    //   speed: number;
    //   navigation: { nextEl: string; prevEl: string };
    //   breakpoints: {};
    // };

    //#endregion

    constructor() {}

    ngOnInit() {
        this.slidesOptions = {
            initialSlide: 0,
            direction: 'horizontal',
            speed: 300,
            spaceBetween: 8,
            slidesPerView: 4,
            freeMode: false,
            slidesPerGroup: 4,
            loop: false,
            breakpoints: {
                // when window width is >= 320px
                0: {
                    slidesPerView: this.slidesPerViewXs,
                    spaceBetween: 0,
                },
                // when window width is >= 480px
                576: {
                    slidesPerView: this.slidesPerViewSm,
                    slidesPerGroup: this.slidesPerViewSm,
                    spaceBetween: 0,
                },
                // when window width is >= 640px
                768: {
                    slidesPerView: this.slidesPerViewMd,
                    slidesPerGroup: this.slidesPerViewMd,
                    spaceBetween: 0,
                },
                // when window width is >= 960px
                1100: {
                    slidesPerView: this.slidesPerViewLg,
                    slidesPerGroup: this.slidesPerViewLg,
                    spaceBetween: 0,
                },
                // when window width is >= 1200px
                1400: {
                    slidesPerView: this.slidesPerViewXl,
                    slidesPerGroup: this.slidesPerViewXl,
                    spaceBetween: 0,
                },
                1921: {
                    slidesPerView: this.slidesPerViewXXl,
                    slidesPerGroup: this.slidesPerViewXXl,
                    spaceBetween: 0,
                },
            },
        }
    }

    ionViewDidEnter() {}

    sessionCardClick(session: Session) {
        //////console.log('session clicked on pia3SessionSliderCards')
        //this.sessionCardClickEmitter.emit(session);
    }

    ionSliderNavigationSlidePrev() {
        this.slides.slidePrev()
    }

    ionSliderNavigationSlideNext() {
        this.slides.slideNext()
    }

    ionSliderReachedEnd($event: Event) {
        if (!this.ionSliderReachedEndNoMoreData) {
            this.ionSliderReachEnd.emit($event)
        }
    }

    ionSliderSessionCardClicked(session: Session) {
        this.sessionCardClickEmitter.emit(session)
    }
}
