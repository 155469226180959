<div *ngIf="!isWorking">
    <ion-item>
        <ion-label position="floating">E-mailadres</ion-label>
        <ion-input placeholder="Voer e-mailadres in" [(ngModel)]="email" type="email" inputmode="email"></ion-input>
    </ion-item>
    <ion-item>
        <ion-label position="floating">Wachtwoord</ion-label>
        <ion-input placeholder="Voer wachtwoord in" [(ngModel)]="password" type="password" inputmode="password"></ion-input>
    </ion-item>
    <ion-item [hidden]="loginError == ''">
        <ion-text color="warning">{{ loginError }}</ion-text>
    </ion-item>
    <ion-item>
        <ion-button (click)="inloggen()">inloggen</ion-button>
        <ion-button [disabled]="false" [routerLink]="resetpasswordPath">wachtwoord vergeten?</ion-button>
    </ion-item>
</div>
<div *ngIf="isWorking" class="ion-text-center">
    <ion-spinner></ion-spinner>
</div>
