<div class="slides_container">
  <!-- SLIDES NAVIGATION BUTTONS -->
  <ion-button
    class="slidesNavigationButton swiper-button-prev-{{ slidesID }} prev"
    (click)="ionSliderNavigationSlidePrev()"
    ><ion-icon name="chevron-back-outline"></ion-icon
  ></ion-button>
  <ion-button
    class="slidesNavigationButton swiper-button-next-{{ slidesID }} next"
    (click)="ionSliderNavigationSlideNext()"
    ><ion-icon name="chevron-forward-outline"></ion-icon
  ></ion-button>
  <!-- SLIDES Component -->
  <ion-slides
    [options]="slidesOptions"
    pager="true"
    (ionSlideReachEnd)="ionSliderReachedEnd($event)"
  >
    <ion-slide *ngFor="let session of sessions">
      <pia3-session-card
        [session]="session"
        [sessionCardConfig]="sessionCardConfig"
        (cardClickEvent)="ionSliderSessionCardClicked($event)"
      ></pia3-session-card>
    </ion-slide>
    <ion-slide *ngIf="sessionsLoading">
      <ion-spinner></ion-spinner>
    </ion-slide>
  </ion-slides>
</div>
