import { Injectable } from '@angular/core'
import { Pia3Service } from './../../pia3.service'
import * as Wavesurfer from 'wavesurfer.js'
import { Audio } from './../../API.service'
import { BehaviorSubject } from 'rxjs'

export interface IPia3AudioWaveform {
    bits: number
    channels: number
    data: Array<number>
    length: number
    sample_rate: number
    samples_per_pixels: number
    version: number
}

export interface IPia3AudioPlayer {
    wavesurfer: Wavesurfer
    isPlaying: boolean
    isLoading: boolean
    hasError: boolean
    audio: Audio
}

export enum AudioControlCommand {
    PLAY,
    PAUSE,
    STOP,
    SEEK,
    FULLSCREEN,
}

export interface IPia3AudioControlCommand {
    sender: any
    controlID: string
    command: AudioControlCommand
    value: any
}

@Injectable({
    providedIn: 'root',
})
export class Pia3AudioService {
    public audioControl$: BehaviorSubject<IPia3AudioControlCommand> = new BehaviorSubject<IPia3AudioControlCommand>({
        sender: null,
        controlID: null,
        command: null,
        value: null,
    })

    constructor(public pia3Service: Pia3Service) {
        this.audioControl$.subscribe(this.onAudioControlMessage)
    }

    public test() {
        return 'test'
    }

    public async getAudioUrl(key: string, expirationTime: number = 1000) {
        return await this.pia3Service.storageGetS3Url(key.replace('public/', ''))
    }

    public async getAudioWaveformData(key: string): Promise<IPia3AudioWaveform> {
        return await this.pia3Service.getS3FileAsJson(key.substring(0, key.lastIndexOf('/')) + '/extracted.waveform.json')
    }

    public convertTimestringToSeconds(timestring: string): number {
        //format timestring: 00:02:34.68
        if (timestring.toString().indexOf(':') != -1) {
            let parts = timestring.split(':')
            let h = Number(parts[0])
            let m = Number(parts[1])
            let s = Number(parts[2])
            let secondsTotal = h * 3600 + m * 60 + s

            return secondsTotal
        } else {
            return Number(timestring)
        }
    }

    public isMe(controlID: string, command: IPia3AudioControlCommand) {
        return controlID == command.controlID
    }

    //#region AudioControl Events
    public onAudioControlMessage = (command: IPia3AudioControlCommand) => {
        if (command.sender == this) {
            //console.log(command.sender + ' send a audiocontrolmessage:', command)
        } else {
            //console.log('Audiocontrol received a audiocontrolmessage from' + command.sender, command)
        }
    }
    //#endregion
}
