import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IonicModule } from '@ionic/angular'

import { PipesModule } from 'src/app/.common/pipes/pipes.module'

import { PiaTranscriptAutoComponent } from './pia-transcript-auto/pia-transcript-auto.component'
import { PiaTranscriptGoldenComponent } from './pia-transcript-golden/pia-transcript-golden.component'
import { PiaTranscriptCustomComponent } from './pia-transcript-custom/pia-transcript-custom.component'
import { PiaTranscriptViewerComponent } from './pia-transcript-viewer/pia-transcript-viewer.component'

import { PiaTranscriptLanguagelabelComponent } from './pia-transcript-languagelabel/pia-transcript-languagelabel.component'

import { TextSelectDirective } from './pia-transcript-languagelabel/pia-transcript-languagelabel-textselect.directive'

import { TranscriptStandardized, TranscriptStandardizedPhrase, TranscriptStandardizedPhraseWord } from './standardized.transcript.format'
import { FormsModule } from '@angular/forms'

@NgModule({
    declarations: [PiaTranscriptViewerComponent, PiaTranscriptAutoComponent, PiaTranscriptGoldenComponent, PiaTranscriptCustomComponent, PiaTranscriptLanguagelabelComponent, TextSelectDirective],
    imports: [CommonModule, IonicModule, FormsModule, PipesModule],
    exports: [PiaTranscriptViewerComponent, PiaTranscriptAutoComponent, PiaTranscriptGoldenComponent, PiaTranscriptCustomComponent],
})
export class PiaTranscriptsModule {}
