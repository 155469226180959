import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pia3-register-component',
  templateUrl: './pia3-register.component.html',
  styleUrls: ['./pia3-register.component.scss'],
})
export class Pia3RegisterComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
