<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-button (click)="closeSearch()"
        ><ion-icon slot="icon-only" name="arrow-back"></ion-icon
      ></ion-button>
    </ion-buttons>
    <ion-searchbar
      (ionBlur)="searchOnBlur($event)"
      (ionCancel)="searchOnCancel($event)"
      (ionClear)="searchOnClear($event)"
      (ionChange)="searchOnChange($event)"
      (ionFocus)="searchOnFocus($event)"
      (ionInput)="searchOnInput($event)"
      animated="true"
      debounce="1000"
      inputmode="search"
      type="search"
      color="primary"
      placeholder="zoeken"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding ion-text-center">
  <ion-text color="light" *ngIf="searchTerm == '' || searchTerm == null">
    <h1>Vul je zoekwoord(en) in om te beginnen met zoeken.</h1>
  </ion-text>
  <ion-spinner color="light" *ngIf="searchLoading"></ion-spinner>
  <!-- TODO: [PIA3-204] FIX THE ISSUE WHERE PIA3-SESSION-GRID-CARDS COPONENT ISNT RECOGNISED-->
  <!-- <pia3-session-grid-cards
    *ngIf="searchTerm != '' && searchTerm != null && sessions.length > 0"
    [sessions]="sessions"
    [sessionCardConfig]="sessionCardConfig"
  ></pia3-session-grid-cards> -->
</ion-content>
