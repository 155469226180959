import { Component, OnInit } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import { AuthService } from '../../auth.service'
import { CurrentUserFabPopoverComponent } from '../current-user-fab-popover/current-user-fab-popover.component'

@Component({
    selector: 'app-current-user-fab',
    templateUrl: './current-user-fab.component.html',
    styleUrls: ['./current-user-fab.component.scss'],
})
export class CurrentUserFabComponent implements OnInit {
    public currentUser: any = null

    constructor(private authService: AuthService, public popoverController: PopoverController) {
        //Listen for currentLoggedInUser
        this.authService.currentLoggedInUser.subscribe((user) => {
            this.currentUser = user
        })
    }

    ngOnInit() {}

    async presentCurrentUserFabPopover(event: any) {
        const popover = await this.popoverController.create({
            component: CurrentUserFabPopoverComponent,
            componentProps: {
                senderEvent: event,
            },
            cssClass: 'currentUserFabPopoverComponent',
            event: event,
            translucent: true,
        })
        await popover.present()

        const { role } = await popover.onDidDismiss()
        //////console.log('onDidDismiss resolved with role', role)
    }
}
